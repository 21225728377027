import { AxiosResponse } from 'axios';
import service from '../api/api.services';
import { getAPIURL, getApiUrlForCampus, getLicenseKey } from './application.settings';

const api = service();

export const getStaffListByUserTypeId = (userTypeId: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(getAPIURL() + `getStaffListByUserTypeId/${getLicenseKey}/${userTypeId}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getStaffListByUserTypeId - ' + error.message);
			console.log('error caught in service : getStaffListByUserTypeId');
		},
	);

export const getProfileForStaff = (staffDetailsId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.get(getAPIURL() + `getProfileForStaff/${getLicenseKey}/${staffDetailsId}`,
		response => {
			success(response)
		},
		error => {
			failure("getProfileForStaff - " + error.message)
			console.log('error caught in service : getProfileForStaff')
		},
	);

export const updateStaffRole = (
	addStaffRolePostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `updateStaffRole`,
		addStaffRolePostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateStaffRole');
		},
	);

export const updateStaffRoleForCampus = (
	addStaffRolePostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getApiUrlForCampus() + `updateStaffRole`,
		addStaffRolePostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateStaffRole');
		},
	);


export const updateStaffResigningDetails = (
	exitOrResignPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `updateStaffResigningDetails`,
		exitOrResignPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateStaffResigningDetails');
		},
	);

export const updateStaffResigningDetailsForCampus = (
	exitOrResignPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getApiUrlForCampus() + `updateStaffResigningDetails`,
		exitOrResignPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateStaffResigningDetails');
		},
	);

export const deleteStaffDetails = (
	staffDeletePostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `deleteStaffDetails`,
		staffDeletePostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : deleteStaffDetails');
		},
	);

export const deleteStaffDetailsForCampus = (
	staffDeletePostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getApiUrlForCampus() + `deleteStaffDetails`,
		staffDeletePostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : deleteStaffDetails');
		},
	);

export const getStaffDegree = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getStaffDegree/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getStaffDegree - ' + error.message);
			console.log('error caught in service : getStaffDegree');
		},
	);

export const addStaffDetails = (
	addStaffPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `addStaffDetails`,
		addStaffPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : addStaffDetails');
		},
	);

export const addStaffDetailsForCampus = (
	addStaffPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getApiUrlForCampus() + `addStaffDetails`,
		addStaffPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : addStaffDetails');
		},
	);


export const getStaffDetails = (
	staffDetailsId: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getStaffDetails/${getLicenseKey}/${staffDetailsId}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getStaffDetails - ' + error.message);
			console.log('error caught in service : getStaffDetails');
		},
	);

export const getStaffDetailsForCampus = (
	staffDetailsId: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getApiUrlForCampus() + `getStaffDetails/${getLicenseKey}/${staffDetailsId}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getStaffDetails - ' + error.message);
			console.log('error caught in service : getStaffDetails');
		},
	);

export const updateStaffDetails = (
	updateStaffDetailsPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `updateStaffDetails`,
		updateStaffDetailsPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateStaffDetails');
		},
	);

export const updateStaffDetailsForCampus = (
	updateStaffDetailsPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getApiUrlForCampus() + `updateStaffDetails`,
		updateStaffDetailsPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateStaffDetails');
		},
	);

export const getStaffListByDepartmentId = (
	departmentId: number,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getStaffListByDepartmentId/${getLicenseKey}/${departmentId}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getStaffListByDepartmentId - ' + error.message);
			console.log('error caught in service : getStaffListByDepartmentId');
		},
	);

export const getStaffSalaryDetails = (
	salaryTemplateId: any,
	ctcAmount: any,
	basicAmount: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() +
		`getStaffSalaryDetails/${getLicenseKey}/${salaryTemplateId}/${ctcAmount}/${basicAmount}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getStaffSalaryDetails - ' + error.message);
			console.log('error caught in service : getStaffSalaryDetails');
		},
	);

export const getStaffSalaryAdvanceRequestHistory = (requestTableId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.get(getAPIURL() + `getStaffRequestHistory/${getLicenseKey}/${requestTableId}/22`,
		response => {
			success(response)
		},
		error => {
			failure("getStaffRequestHistory - " + error.message)
			console.log('error caught in service : getStaffRequestHistory')
		},
	);


export const getStaffLoginDetails = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getStaffLoginDetails/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getStaffLoginDetails - ' + error.message);
			console.log('error caught in service : getStaffLoginDetails');
		},
	);

export const addStaffLoginDetails = (
	addStaffLoginPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `addStaffLoginDetails`,
		addStaffLoginPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : addStaffLoginDetails');
		},
	);

export const updateStaffLoginDetails = (
	editStaffLoginPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `updateStaffLoginDetails`,
		editStaffLoginPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateStaffLoginDetails');
		},
	);

export const deleteStaffLoginDetails = (
	editStaffLoginPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `deleteStaffLoginDetails`,
		editStaffLoginPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : deleteStaffLoginDetails');
		},
	);

export const updateStaffPfDetails = (
	pfPostData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `updateStaffPfDetails`,
		pfPostData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateStaffPfDetails');
		},
	);


export const getDeletedStaffList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.get(getAPIURL() + `getDeletedStaffList/${getLicenseKey}`,
		response => {
			success(response)
		},
		error => {
			failure("getDeletedStaffList - " + error.message)
			console.log('error caught in service : getDeletedStaffList')
		},
	);

export const rejoinStaffDetails = (staffRejoinPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getAPIURL() + `rejoinStaffDetails`, staffRejoinPostData,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : rejoinStaffDetails')
		}
	);


export const rejoinStaffDetailsForCampus = (staffRejoinPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.post(getApiUrlForCampus() + `rejoinStaffDetails`, staffRejoinPostData,
		response => {
			success(response)
		},
		error => {
			failure(error.message)
			console.log('error caught in service : rejoinStaffDetails')
		}
	);
