import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, {
	CardBody,
	CardLabel,
	CardTitle,
} from '../bootstrap/Card';
import Button, { ButtonGroup } from '../bootstrap/Button';
import Icon from '../icon/Icon';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Alert from '../bootstrap/Alert';
import AlertService from '../../services/AlertService';
import { useFormik } from 'formik';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas';
import useDarkMode from '../../hooks/useDarkMode';
import classNames from 'classnames';
import DeleteComponents from '../../common/components/DeleteComponents';
import {
	addWorkLocation,
	deleteWorkLocation,
	getWorkLocationsList,
	updateWorkLocation,
} from '../../services/setting.services';
import { TableLoader, showLoader } from '../../services/loader.services';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import AuthContext from '../../contexts/authContext';
import { getStates, getStatesAndCity } from '../../services/common.service';
import { getLicenseKey } from '../../services/application.settings';
import { toasts } from '../../services/toast.service';
import SearchableSelect from '../../common/components/SearchableSelect';
import Badge from '../bootstrap/Badge';
import NoDataMsg from '../../common/components/NoDataMsg';
import Checks from '../bootstrap/forms/Checks';

const WorkLocations = () => {
	const { userAccountId, organisationDetailsId } = useContext(AuthContext);

	useEffect(() => {
		getWorkLocations(organisationDetailsId);
		getStatesList();
		getCityList(31);
	}, []);

	const [workLocationData, setWorkLocationData] = useState<any>([]);
	const { darkModeStatus } = useDarkMode();
	const [addWorkLocationOffCanvas, setAddWorkLocationOffCanvas] = useState(false);
	const [editWorkLocationOffCanvas, setEditWorkLocationOffCanvas] = useState(false);
	const [statesData, setStatesData] = useState([]);
	const [stateId, setStateId] = useState<any>({ value: 31, label: 'Tamil Nadu' });
	const [cityId, setCityId] = useState<any>(Number);
	const [cityData, setCityData] = useState([]);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [dataStatus, setDataStatus] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	const addWorkLocationForm = useFormik({
		initialValues: {
			workLocationName: '',
			addressLine: '',
			addressLine2: '',
			cityId: '',
			stateId: '',
			pincode: '',
			isFillingAddress: 0,
		},

		validate: (values) => {
			const errors: {
				workLocationName?: string;
				addressLine?: string;
				cityId?: string;
				stateId?: string;
				pincode?: string;
			} = {};

			if (!values.workLocationName) {
				errors.workLocationName = 'Required';
			}
			if (!values.addressLine) {
				errors.addressLine = 'Required';
			}
			if (!cityId) {
				errors.cityId = 'Required';
			}
			if (!stateId) {
				errors.stateId = 'Required';
			}
			if (!values.pincode) {
				errors.pincode = 'Required';
			} else if (!/^\d{6}$/.test(values.pincode)) {
				errors.pincode = 'Must be 6 Numbers';
			}
			return errors;
		},

		onSubmit: () => {
			addWorkLocationSubmit();
		},
	});

	const editWorkLocationForm = useFormik({
		initialValues: {
			workLocationsId: '',
			workLocationName: '',
			addressLine: '',
			addressLine2: '',
			cityId: '',
			stateId: '',
			pincode: '',
			isFillingAddress: 0,
		},

		validate: (values) => {
			const errors: {
				workLocationName?: string;
				addressLine?: string;
				// addressLine2?: string;
				cityId?: string;
				stateId?: string;
				pincode?: string;
			} = {};

			if (!values.workLocationName) {
				errors.workLocationName = 'Required';
			}
			if (!values.addressLine) {
				errors.addressLine = 'Required';
			}
			// if (!values.addressLine2) {
			// 	errors.addressLine2 = 'Required';
			// }
			if (!cityId) {
				errors.cityId = 'Required';
			}
			if (!stateId) {
				errors.stateId = 'Required';
			}
			if (!values.pincode) {
				errors.pincode = 'Required';
			} else if (values.pincode.toString().length !== 6) {
				errors.pincode = 'Must be 6 characters';
			}
			return errors;
		},
		validateOnChange: false,

		onSubmit: () => {
			updateWorkLocationList();
		},
	});

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedWorkLocation = i;
			setEditWorkLocationOffCanvas(true);
			if (selectedWorkLocation.stateId != '') {
				getCityList(selectedWorkLocation.stateId);
			}
			setStateId(
				selectedWorkLocation.stateId != null
					? { value: selectedWorkLocation.stateId, label: selectedWorkLocation.stateName }
					: '',
			);

			setCityId(
				selectedWorkLocation.cityId != null
					? { value: selectedWorkLocation.cityId, label: selectedWorkLocation.cityName }
					: '',
			);
			editWorkLocationForm.setValues({
				workLocationsId: selectedWorkLocation.workLocationsId,
				workLocationName: selectedWorkLocation.locationName,
				addressLine: selectedWorkLocation.address,
				addressLine2: selectedWorkLocation.address1,
				stateId: selectedWorkLocation.stateId,
				cityId: selectedWorkLocation.cityId,
				pincode: selectedWorkLocation.pincode,
				isFillingAddress: selectedWorkLocation.isFillingAddress == true ? 1 : 0,
			});
		}
	}

	function getWorkLocations(organisationDetailsId: any) {
		setDataStatus(true);
		getWorkLocationsList(
			organisationDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.workLocationsList;
					if (data != undefined) {
						setWorkLocationData(data);
						setDataStatus(false);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
							data[i].action = (
								<div>
									<Button
										isOutline={!darkModeStatus}
										color='dark'
										isLight={darkModeStatus}
										className={classNames('text-nowrap', {
											'border-light': !darkModeStatus,
										})}
										icon='Edit'>
										Edit
									</Button>
								</div>
							);
						}
					} else {
						setDataStatus(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(false);
					// toasts(response.data.message, 'Error');
					setNoDataMsg(response.data.message);
					setWorkLocationData([]);
				}
			},
			(error) => {
				setDataStatus(false);
				toasts(error, 'Error');
			},
		);
	}

	function setWorkLocationDetails() {
		return {
			organisationDetailsId: organisationDetailsId,
			locationName: addWorkLocationForm.values.workLocationName,
			address1: addWorkLocationForm.values.addressLine,
			address2: addWorkLocationForm.values.addressLine2,
			stateId: stateId?.value,
			cityId: cityId?.value,
			pincode: addWorkLocationForm.values.pincode,
			isFillingAddress: addWorkLocationForm.values.isFillingAddress,
			licenseKey: getLicenseKey,
			createdBy: userAccountId,
		};
	}

	function addWorkLocationSubmit() {
		showLoader(true);
		if (addWorkLocationForm.isValid) {
			let workLocationPostData = setWorkLocationDetails();
			addWorkLocation(
				workLocationPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getWorkLocations(organisationDetailsId);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (addWorkLocationForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function setWorkLocationEditDetails() {
		return {
			workLocationId: editWorkLocationForm.values.workLocationsId,
			locationName: editWorkLocationForm.values.workLocationName,
			address1: editWorkLocationForm.values.addressLine,
			address2: editWorkLocationForm.values.addressLine2,
			stateId: stateId?.value,
			cityId: cityId?.value,
			pincode: editWorkLocationForm.values.pincode,
			isFillingAddress: editWorkLocationForm.values.isFillingAddress,
			licenseKey: getLicenseKey,
			updatedBy: userAccountId,
			isActive: 1,
		};
	}

	function updateWorkLocationList() {
		showLoader(true);
		if (editWorkLocationForm.isValid) {
			let workLocationPostData = setWorkLocationEditDetails();
			updateWorkLocation(
				workLocationPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getWorkLocations(organisationDetailsId);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editWorkLocationForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function deleteWorkLocationList() {
		showLoader(true);
		if (editWorkLocationForm.isValid) {
			let workLocationPostData = setWorkLocationEditDetails();
			deleteWorkLocation(
				workLocationPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						getWorkLocations(organisationDetailsId);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editWorkLocationForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	function getStatesList() {
		getStates(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.statesAndCity;
					if (data != undefined) {
						setStatesData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, 'Error');
					setStatesData([]);
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const selectState = (e: any) => {
		setCityId('');
		let stateId = e;
		setStateId(stateId);
		if (stateId?.value != undefined) {
			getCityList(stateId?.value);
		}
	};

	function getCityList(stateId: number) {
		getStatesAndCity(
			stateId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.statesAndCity[0]['city'];
					if (data != undefined) {
						setCityData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, 'Error');
					setCityData([]);
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function closeAndReset() {
		setAddWorkLocationOffCanvas(false);
		addWorkLocationForm.resetForm();
		setEditWorkLocationOffCanvas(false);
		editWorkLocationForm.resetForm();
		setIsDeleteOpen(false);
		setStateId({ value: 31, label: 'Tamil Nadu' });
		setCityId('');
	}

	return (
		<PageWrapper title='Work Locations'>
			<SubHeader>
				<SubHeaderLeft>
					<CardLabel icon='AddLocation' iconColor='info'>
						<CardTitle tag='div' className='h5'>
							Work Locations
						</CardTitle>
					</CardLabel>
				</SubHeaderLeft>
				<SubHeaderRight>
					<SubheaderSeparator />
					<Button
						color='info'
						icon='Add'
						isLight
						onClick={() => setAddWorkLocationOffCanvas(true)}>
						Add New
					</Button>
				</SubHeaderRight>
			</SubHeader>

			<Page container='fluid'>
				{dataStatus ? (
					<TableLoader />
				) : (
					<>
						{workLocationData != '' ? (
							<div className='row row-cols-xxl-3 row-cols-lg-3 row-cols-md-2'>
								{workLocationData.map((workLocation: any) => (
									<div key={workLocation.workLocationsId} className='col'>
										<Card>
											<CardBody>
												<div className='row'>
													<div className='col-10'>
														<p className='lead fw-bold'>
															{workLocation.locationName}
														</p>
													</div>
													<div className='col-2'>
														<Button
															color='dark'
															size='sm'
															isLight
															onClick={() => onActivate(workLocation)}
															icon='Edit'></Button>
													</div>
													<div className='col-12 mt-3'>
														{workLocation.workLocationsName}
													</div>

													<div className='col-6 mt-3'>
														<p className='h6 text-primary fw-bold'>
															<Icon icon='People' size={'2x'} />
															{workLocation.totalEmployees}{' '}
															{workLocation.totalEmployees > 1
																? 'Staffs'
																: 'Staff'}
														</p>
													</div>
													{workLocation.isFillingAddress ? (
														<div className='col-6 mt-3'>
															<Button
																color='storybook'
																icon='Home'
																size='sm'
																isLight>
																FILLING ADDRESS
															</Button>
														</div>
													) : null}
												</div>
											</CardBody>
										</Card>
									</div>
								))}
							</div>
						) : (
							<table className='table table-modern table-hover'>
								<tbody>
									<NoDataMsg columnsCount={5} msg={noDataMsg} />
								</tbody>
							</table>
						)}
					</>
				)}
			</Page>

			{/* ADD LOCATION */}
			<OffCanvas
				setOpen={setAddWorkLocationOffCanvas}
				isOpen={addWorkLocationOffCanvas}
				titleId='addWorkLocationOffCanvas'
				isBodyScroll
				placement='end'
				tag='form'
				noValidate
				onSubmit={addWorkLocationForm.handleSubmit}>
				<OffCanvasHeader setOpen={setAddWorkLocationOffCanvas} onClick={closeAndReset}>
					<OffCanvasTitle id='addWorkLocationOffCanvas'>Add Work Location</OffCanvasTitle>
				</OffCanvasHeader>

				<OffCanvasBody>
					<div className='row g-4'>
						<div className='col-12'>
							<FormGroup id='workLocationName' label='Work Location Name'>
								<Input
									placeholder='Enter Work Location'
									onChange={addWorkLocationForm.handleChange}
									onBlur={addWorkLocationForm.handleBlur}
									value={addWorkLocationForm.values.workLocationName}
									isValid={addWorkLocationForm.isValid}
									isTouched={addWorkLocationForm.touched.workLocationName}
									invalidFeedback={addWorkLocationForm.errors.workLocationName}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='addressLine' label='Address Line 1'>
								<Input
									placeholder='Enter Address Line 1'
									onChange={addWorkLocationForm.handleChange}
									onBlur={addWorkLocationForm.handleBlur}
									value={addWorkLocationForm.values.addressLine}
									isValid={addWorkLocationForm.isValid}
									isTouched={addWorkLocationForm.touched.addressLine}
									invalidFeedback={addWorkLocationForm.errors.addressLine}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='addressLine2' label='Address Line 2'>
								<Input
									placeholder='Enter Address Line 2'
									onChange={addWorkLocationForm.handleChange}
									value={addWorkLocationForm.values.addressLine2}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='StateId' label='State'>
								<SearchableSelect
									ariaLabel='State'
									onChange={selectState}
									value={stateId}
									list={statesData.map((data: any) => ({
										value: data.stateId,
										label: data.stateName,
									}))}
									onBlur={addWorkLocationForm.handleBlur}
									isValid={addWorkLocationForm.isValid}
									isTouched={addWorkLocationForm.touched.stateId}
									invalidFeedback={addWorkLocationForm.errors.stateId}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='cityId' label='City'>
								<SearchableSelect
									ariaLabel='City'
									onChange={(e: any) => setCityId(e)}
									value={cityId}
									list={cityData.map((data: any) => ({
										value: data.cityId,
										label: data.cityName,
									}))}
									onBlur={addWorkLocationForm.handleBlur}
									isValid={addWorkLocationForm.isValid}
									isTouched={addWorkLocationForm.touched.cityId}
									invalidFeedback={addWorkLocationForm.errors.cityId}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='pincode' label='Pincode'>
								<Input
									placeholder='Pincode'
									onChange={addWorkLocationForm.handleChange}
									onBlur={addWorkLocationForm.handleBlur}
									value={addWorkLocationForm.values.pincode}
									isValid={addWorkLocationForm.isValid}
									isTouched={addWorkLocationForm.touched.pincode}
									invalidFeedback={addWorkLocationForm.errors.pincode}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<Checks
								className='border border-primary'
								type='checkbox'
								id='isFillingAddress'
								label={
									<>
										<b className='text-primary'>Filling Address</b>
									</>
								}
								onChange={addWorkLocationForm.handleChange}
								checked={
									addWorkLocationForm.values.isFillingAddress == 0 ? false : true
								}
							/>
						</div>
						<div className='col-12'>
							{/* <Alert
								isLight
								className='border-0'
								shadow='md'
								color='success'
								rounded={2}>
								<span>
									<Icon icon='Info' color='dark' size='lg' />
									&nbsp; Labour Welfare Fund is applicable for TamilNadu. If
									you've not configured it yet, <br />
									configure it in Settings {'>'} Statutory Components.
								</span>
							</Alert> */}
							<br />
							<br />
							<br />
						</div>
					</div>
				</OffCanvasBody>
				<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
					<div className='row m-0'>
						<Button color='info' icon='Save' className='w-100' type='submit'>
							Save
						</Button>
					</div>
				</div>
			</OffCanvas>

			{/* EDIT LOCATION */}
			<OffCanvas
				setOpen={setEditWorkLocationOffCanvas}
				isOpen={editWorkLocationOffCanvas}
				titleId='editWorkLocationOffCanvas'
				isBodyScroll
				placement='end'
				tag='form'
				onSubmit={editWorkLocationForm.handleSubmit}>
				<OffCanvasHeader setOpen={setEditWorkLocationOffCanvas} onClick={closeAndReset}>
					<OffCanvasTitle id='editWorkLocationOffCanvas'>
						Edit Work Location
					</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='row g-4'>
						<div className='col-12'>
							<FormGroup id='workLocationName' label='Work Location Name'>
								<Input
									onChange={editWorkLocationForm.handleChange}
									onBlur={editWorkLocationForm.handleBlur}
									value={editWorkLocationForm.values.workLocationName}
									isValid={editWorkLocationForm.isValid}
									isTouched={editWorkLocationForm.touched.workLocationName}
									invalidFeedback={editWorkLocationForm.errors.workLocationName}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='addressLine' label='Address Line 1'>
								<Input
									placeholder='Enter Address Line 1'
									onChange={editWorkLocationForm.handleChange}
									onBlur={editWorkLocationForm.handleBlur}
									value={editWorkLocationForm.values.addressLine}
									isValid={editWorkLocationForm.isValid}
									isTouched={editWorkLocationForm.touched.addressLine}
									invalidFeedback={editWorkLocationForm.errors.addressLine}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='addressLine2' label='Address Line 2'>
								<Input
									placeholder='Enter Address Line 2'
									onChange={editWorkLocationForm.handleChange}
									value={editWorkLocationForm.values.addressLine2}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='stateId' label='State'>
								<SearchableSelect
									ariaLabel='State'
									onChange={selectState}
									onBlur={editWorkLocationForm.handleBlur}
									value={stateId}
									list={statesData.map((data: any) => ({
										value: data.stateId,
										label: data.stateName,
									}))}
									isTouched={editWorkLocationForm.touched.stateId}
									required
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='cityId' label='City'>
								<SearchableSelect
									ariaLabel='City'
									onChange={(e: any) => setCityId(e)}
									onBlur={editWorkLocationForm.handleBlur}
									value={cityId}
									list={cityData.map((data: any) => ({
										value: data.cityId,
										label: data.cityName,
									}))}
									isTouched={editWorkLocationForm.touched.cityId}
									required
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='pincode' label='Pincode'>
								<Input
									placeholder='Enter Pincode'
									onChange={editWorkLocationForm.handleChange}
									onBlur={editWorkLocationForm.handleBlur}
									value={editWorkLocationForm.values.pincode}
									isValid={editWorkLocationForm.isValid}
									isTouched={editWorkLocationForm.touched.pincode}
									invalidFeedback={editWorkLocationForm.errors.pincode}
								/>
							</FormGroup>
						</div>

						<div className='col-12'>
							<Checks
								className='border border-primary'
								type='checkbox'
								id='isFillingAddress'
								label={
									<>
										<b className='text-primary'>Filling Address</b>
										<br />
										{/* <span>
											According to Rule 3(A), employees availing medical loan
											or any loan below ₹20,000 can be exempted from
											perquisite calculation.
										</span> */}
									</>
								}
								onChange={editWorkLocationForm.handleChange}
								checked={
									editWorkLocationForm.values.isFillingAddress == 0 ? false : true
								}
							/>
						</div>

						<div className='col-12'>
							{/* <Alert
								isLight
								className='border-0'
								shadow='md'
								color='success'
								rounded={2}>
								<span>
									<Icon icon='Info' color='dark' size='lg' />
									&nbsp; Labour Welfare Fund is applicable for Tamil Nadu. If
									you've not configured it yet, <br />
									configure it in Settings {'>'} Statutory Components.
								</span>
							</Alert> */}
							<br />
							<br />
							<br />
						</div>
						<div className='d-flex justify-content-center position-absolute top-60 start-50 translate-middle'>
							<DeleteComponents
								isDeleteOpen={isDeleteOpen}
								setIsDeleteOpen={setIsDeleteOpen}
								deleteFunction={deleteWorkLocationList}
							/>
						</div>
					</div>
				</OffCanvasBody>
				<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
					<div className='row m-0'>
						<div className='col-6 p-3'>
							<Button
								color='danger'
								className='w-100'
								icon='Delete'
								onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
								Delete
							</Button>
						</div>
						<div className='col-6 p-3'>
							<Button color='info' icon='Save' type='submit' className='w-100'>
								Update
							</Button>
						</div>
					</div>
				</div>
			</OffCanvas>
			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default WorkLocations;
