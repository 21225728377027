import React, { useContext, useEffect, useRef, useState } from 'react'
import PageWrapper from '../../../layout/PageWrapper/PageWrapper'
import Page from '../../../layout/Page/Page'
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card'
import Button, { ButtonGroup } from '../../bootstrap/Button'
import { useFormik } from 'formik'
import { addSalaryTemplate, deleteSalaryTemplate, getEarningDetailsList, getSalaryTemplateList } from '../../../services/salary.service'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../bootstrap/OffCanvas'
import FormGroup from '../../bootstrap/forms/FormGroup'
import Input from '../../bootstrap/forms/Input'
import Icon from '../../icon/Icon'
import Select from '../../bootstrap/forms/Select'
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader'
import { TableLoader, showLoader } from '../../../services/loader.services'
import AlertService from '../../../services/AlertService'
import classNames from 'classnames'
import useDarkMode from '../../../hooks/useDarkMode'
import AuthContext from '../../../contexts/authContext'
import { getLicenseKey } from '../../../services/application.settings'
import { toasts } from '../../../services/toast.service'
import SearchableSelect from '../../../common/components/SearchableSelect'
import NoDataMsg from '../../../common/components/NoDataMsg'
import DeleteComponents from '../../../common/components/DeleteComponents'

function SalaryTemplates() {

    const { userAccountId, organisationDetailsId } = useContext(AuthContext);

    useEffect(() => {
        getSalaryTemplate(organisationDetailsId, 0);
    }, [])

    const componentRef = useRef(null);
    const [createSalaryTemplateOffCanvas, setCreateSalaryTemplateOffCanvas] = useState(false)
    const [editSalaryTemplateOffCanvas, setEditSalaryTemplateOffCanvas] = useState(false)
    const [inputList, setInputList] = useState<any>([])
    const [salaryTemplateList, setSalaryTemplateList] = useState<any>([]);
    const [earningData, setEarningData] = useState([]);
    const [earningTemplateDetails, setEarningTemplateDetails] = useState<any>([])

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [dataStatus, setDataStatus] = useState(false)
    const { darkModeStatus } = useDarkMode();
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    const [templatesDetails, setTemplatesDetails] = useState([]);
    const [noDataMsg, setNoDataMsg] = useState('')

    const addSalaryTemplateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            templateName: '',
            salaryTemplateId: 0,
        },
        validate: (values) => {
            const errors: {
                templateName?: string;
            } = {};
            if (!values.templateName) {
                errors.templateName = 'Required';
            }
            return errors;
        },
        validateOnChange: false,
        onSubmit: () => { addSalaryTemplateSubmit() },
    });

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedTemplate = i
            setEditSalaryTemplateOffCanvas(true)
            addSalaryTemplateForm.setValues({
                salaryTemplateId: selectedTemplate.salaryTemplateId,
                templateName: selectedTemplate.templateName,
            });
            setTemplatesDetails(selectedTemplate.details)
            for (let i = 0; i < selectedTemplate.details.length; i++) {
                let details = selectedTemplate.details[i]
                inputList.push({ earningDetailsId: details.earningDetailsId, percentage: details.labelValue, displayOrder: details.displayOrder, earningDetailsIdLabel: { value: details.earningDetailsId, label: details.earningName } })
                earningTemplateDetails.push({ earningDetailsId: details.earningDetailsId, percentage: details.labelValue, displayOrder: details.displayOrder, earningDetailsIdLabel: { value: details.earningDetailsId, label: details.earningName } })
            }
            getEarningDetails();
        }
    }

    function getSalaryTemplate(organisationDetailsId: any, salaryTemplateId: any) {
        setDataStatus(false)
        getSalaryTemplateList(organisationDetailsId, salaryTemplateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.salaryTemplateList;
                    if (data != undefined) {
                        setDataStatus(true)
                        setSalaryTemplateList(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getEarningDetails() {
        getEarningDetailsList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.earningDetailsList;
                    if (data != undefined) {
                        setEarningData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "warning")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setSalaryTemplateDetails() {
        return ({
            createdBy: userAccountId,
            licenseKey: getLicenseKey,
            salaryTemplateId: addSalaryTemplateForm.values.salaryTemplateId,
            templateName: addSalaryTemplateForm.values.templateName,
            organisationDetailsId: organisationDetailsId,
            templateDetails: earningTemplateDetails
        })
    }

    function setDeleteSalaryTemplateDetails() {
        return ({
            userAccountId: userAccountId,
            licenseKey: getLicenseKey,
            salaryTemplateId: addSalaryTemplateForm.values.salaryTemplateId,
        })
    }

    function addSalaryTemplateSubmit() {
        showLoader(true);
        let checkExistingTemplate = earningTemplateDetails.filter((data: any) => data.existingTemplate == true)
        if (addSalaryTemplateForm.isValid) {
            if (earningTemplateDetails.length > 0) {
                if (checkExistingTemplate == '') {
                    let salaryTemplatePostData = setSalaryTemplateDetails()
                    addSalaryTemplate(salaryTemplatePostData,
                        (response) => {
                            const data = response.data;
                            if (data.success == true) {
                                showLoader(false);
                                setAlertStatus({ message: data.message, type: "success" });
                                setIsOpen(true);
                                getSalaryTemplate(organisationDetailsId, 0)
                                closeAndReset();
                            }
                            else if (data.success == false) {
                                showLoader(false);
                                setAlertStatus({ message: data.message, type: "warning" });
                                setIsOpen(true);
                            }
                            else {
                                let errorCode = response.data.error[0].error_code;
                                let errorDescription = response.data.error[0].error_description;
                                showLoader(false)
                                setIsOpen(true);
                                setAlertStatus({ message: errorDescription, type: "error" });
                            }
                        }, (error) => {
                            showLoader(false);
                            setAlertStatus({ message: error, type: "error" });
                            setIsOpen(true);
                        }
                    )
                } else {
                    showLoader(false);
                    setAlertStatus({ message: 'Please provide different Earning Details!', type: "error" });
                    setIsOpen(true);
                }
            } else {
                showLoader(false);
                setAlertStatus({ message: 'Please Select Earning Details!', type: "error" });
                setIsOpen(true);
            }
        } else if (addSalaryTemplateForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
            setIsOpen(true);
        }
    }


    function deleteSalaryTemplateSubmit() {
        showLoader(true);
        let salaryTemplatePostData = setDeleteSalaryTemplateDetails();
        deleteSalaryTemplate(salaryTemplatePostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: 'success' });
                    setIsOpen(true);
                    getSalaryTemplate(organisationDetailsId, 0)
                    closeAndReset();
                } else if (data.success == false) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: 'warning' });
                    setIsOpen(true);
                } else {
                    showLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: 'error' });
                    setIsOpen(true);
                }
            },
            (error) => {
                setAlertStatus({ message: error, type: 'error' });
                setIsOpen(true);
            },
        );
    }

    function openAddNew() {
        setInputList([{ earningDetailsId: "", percentage: "", displayOrder: 1 }])
        getEarningDetails()
        setCreateSalaryTemplateOffCanvas(true)
    }

    const [count, setCount] = useState(2)

    function handleInputChange(e: any, index: any) {

        const value = e?.value;
        const list = [...inputList];
        list[index]['earningDetailsId'] = value;
        setInputList(list);
        let earningDetailsData: any = earningData.filter((data: any) => data.earningDetailsId == value)
        let existingTemplateData = earningTemplateDetails.filter((data: any) => data.earningDetailsId == value && data.displayOrder != list[index]['displayOrder'])

        const newData: any = [];

        inputList.forEach((item: any, index: any) => {

            if (existingTemplateData == '' && item['earningDetailsId'] == value) {
                item['earningDetailsId'] = value
                item['earningDetailsIdLabel'] = e
                item['percentage'] = earningDetailsData != '' ? earningDetailsData[0]['labelValue'] : ''
                item['existingTemplate'] = existingTemplateData != '' ? true : false
            } else {
                item['existingTemplate'] = existingTemplateData != '' && item['earningDetailsId'] == value ? true : false
            }
            newData.push(item)
        });
        setInputList(newData)

        if (earningTemplateDetails.length == 0) {
            var obj
            obj = {
                displayOrder: 1,
                earningDetailsId: value,
                existingTemplate: existingTemplateData != '' ? true : false,
            };
            earningTemplateDetails.push(obj);
        }
        else {
            let selectedItem: any
            earningTemplateDetails.forEach((item: any) => {
                if (item['displayOrder'] == list[index]['displayOrder']) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['earningDetailsId'] = value
                selectedItem['existingTemplate'] = existingTemplateData != '' ? true : false
            } else {
                var obj
                obj = {
                    displayOrder: list[index]['displayOrder'],
                    earningDetailsId: value,
                    existingTemplate: existingTemplateData != '' ? true : false,
                };
                earningTemplateDetails.push(obj);
            }
        }
    }

    function add() {
        for (let i = 0; i < count; i++) {
            setCount(count + 1)
        }
        setInputList([...inputList, { earningDetailsId: '', percentage: '', displayOrder: count }])
    }

    function handleremove(earningDetailsId: any, index: any) {
        const list = [...inputList];
        list.splice(index, 1)
        setInputList(list)
        let selectedItem
        earningTemplateDetails.forEach((item: any) => {
            if (item['earningDetailsId'] == earningDetailsId) {
                selectedItem = item
                earningTemplateDetails.splice(earningTemplateDetails.indexOf(selectedItem), 1);
            }
        });
    }

    function closeAndReset() {
        setCreateSalaryTemplateOffCanvas(false);
        setEditSalaryTemplateOffCanvas(false);
        setInputList([]);
        setEarningTemplateDetails([]);
        setTemplatesDetails([]);
        addSalaryTemplateForm.resetForm();
        setCount(0);
        setIsDeleteOpen(false);
    }

    return (
        <PageWrapper title='Salary Templates'>
            <SubHeader>
                <SubHeaderLeft>
                    <CardLabel icon='AutoAwesomeMotion' iconColor='info'>
                        <CardTitle tag='div' className='h5'>Salary Templates</CardTitle>
                    </CardLabel>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <SubheaderSeparator />
                    <Button color='primary' icon='Add' isLight onClick={openAddNew}>
                        Add New
                    </Button>
                </SubHeaderRight>
            </SubHeader>
            <Page>
                {!dataStatus ? <TableLoader /> :
                    <>
                        {salaryTemplateList != '' ?
                            <div className='row'>
                                {salaryTemplateList.map((salaryTemplateList: any) => (
                                    <div className='col-6' key={salaryTemplateList.salaryTemplateId}>
                                        <Card>
                                            <CardBody>
                                                <div className='row'>
                                                    <div className='col-md-11'>
                                                        <p className='lead fw-bold text-primary'>
                                                            {salaryTemplateList.templateName}
                                                        </p>
                                                    </div>
                                                    <div className='col-md-1'>
                                                        <Button
                                                            color='dark'
                                                            size='sm'
                                                            isLight
                                                            icon='Edit'
                                                            onClick={() => onActivate(salaryTemplateList)} />
                                                    </div>
                                                    <div className='col-md-12 mt-2'>
                                                        <table className="table table-modern table-hover text-nowrap text-md-nowrap text-center mg-b-0">
                                                            <thead>
                                                                <tr className='table-primary fs-6'>
                                                                    <th>Earnings</th>
                                                                    <th>Monthly Amount</th>
                                                                    <th>Annual Amount</th>
                                                                </tr>
                                                            </thead>

                                                            {salaryTemplateList?.details.map((details: any) => (
                                                                <tbody key={details.salaryTemplateDetailsId}>
                                                                    <tr>
                                                                        <td className='fw-bold'>{details.earningName}</td>
                                                                        <td>{details.monthlyAmount}</td>
                                                                        <td>{details.annualAmount}</td>
                                                                    </tr>
                                                                </tbody>
                                                            ))}
                                                            <tbody>
                                                                <tr className='fw-bold h6'>
                                                                    <td>COST TO COMPANY</td>
                                                                    <td>{salaryTemplateList.totalMonthlyAmount}</td>
                                                                    <td>{salaryTemplateList.totalAnnualAmount}
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </div>
                                ))}
                            </div>
                            :
                            <table className='table table-modern table-hover'><tbody><NoDataMsg columnsCount={5} msg={noDataMsg} /></tbody></table>}
                    </>
                }
            </Page>

            <OffCanvas
                setOpen={setCreateSalaryTemplateOffCanvas}
                isOpen={createSalaryTemplateOffCanvas}
                titleId='createSalaryTemplateOffCanvas'
                isBodyScroll
                isNotClose
                tag='form' noValidate
                onSubmit={addSalaryTemplateForm.handleSubmit}>
                <OffCanvasHeader setOpen={setCreateSalaryTemplateOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="createSalaryTemplateOffCanvas">Create Template</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="col-12">
                        <FormGroup id='templateName' label='Template Name'>
                            <Input
                                placeholder="Template Name"
                                onChange={addSalaryTemplateForm.handleChange}
                                value={addSalaryTemplateForm.values.templateName}
                                onBlur={addSalaryTemplateForm.handleBlur}
                                isValid={addSalaryTemplateForm.isValid}
                                isTouched={addSalaryTemplateForm.touched.templateName}
                                invalidFeedback={addSalaryTemplateForm.errors.templateName}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-12 mt-4">
                        <table className='table table-modern table-hover mt-2'>
                            <thead>
                                <tr className='table-primary text-center'>
                                    <th style={{ width: '60%' }}>Earning</th>
                                    <th style={{ width: '40%' }}>Percentage(%)</th>
                                    <td />
                                </tr>
                            </thead>
                            {inputList.map((x: any, i: any) => {
                                return (
                                    <tbody key={x.displayOrder}>
                                        <tr>
                                            <td>
                                                <FormGroup>
                                                    <SearchableSelect
                                                        ariaLabel={''}
                                                        placeholder='Select Earning'
                                                        className={x.existingTemplate ? 'border border-danger' : ''}
                                                        value={x.earningDetailsIdLabel}
                                                        list={earningData.map((data: any) => (
                                                            { value: data.earningDetailsId, label: data.earningName }
                                                        ))}
                                                        onChange={e => handleInputChange(e, i)} />
                                                </FormGroup>
                                                <>
                                                    {x.existingTemplate ?
                                                        <div className='mt-2 lh-sm text-danger'>
                                                            This Earning Details Already Selected.{' '}<Icon icon='Report' color='danger' className='mx-1' size='lg' />
                                                        </div> : null}
                                                </>
                                            </td>
                                            <td>
                                                <FormGroup>
                                                    <Input type='text' placeholder='0.00 %' value={x.percentage} disabled />
                                                </FormGroup>
                                            </td>
                                            <td>
                                                {inputList.length !== 1 &&
                                                    <Button color='danger' isLight icon='Close' onClick={() => handleremove(x.earningDetailsId, i)} />
                                                }
                                            </td>
                                        </tr>
                                        {inputList.length - 1 === i &&
                                            <tr>
                                                <td colSpan={3}>
                                                    <FormGroup className='mt-2'>
                                                        <Button onClick={add} icon='Add' isOutline color='info' size={'sm'} className='w-100'>Add</Button>
                                                    </FormGroup>
                                                </td>
                                            </tr>
                                        }
                                    </tbody>
                                );
                            })}
                        </table>
                    </div>
                    <div className="mt-5"></div>
                </OffCanvasBody>
                <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                    <Button color='info' type="submit" className='w-100' > Save</Button>
                </div>
            </OffCanvas>

            <OffCanvas
                setOpen={setEditSalaryTemplateOffCanvas}
                isOpen={editSalaryTemplateOffCanvas}
                titleId='editEmployeeOffCanvas'
                isBodyScroll
                tag='form'
                onSubmit={addSalaryTemplateForm.handleSubmit}
                isNotClose
            >
                <OffCanvasHeader setOpen={setEditSalaryTemplateOffCanvas} onClick={closeAndReset}>
                    <OffCanvasTitle id="editEmployeeOffCanvas">Edit Template</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-2">
                    <div className="col-12">
                        <FormGroup id='templateName' label='Template Name'>
                            <Input
                                placeholder="Template Name"
                                onChange={addSalaryTemplateForm.handleChange}
                                value={addSalaryTemplateForm.values.templateName}
                                onBlur={addSalaryTemplateForm.handleBlur}
                                isValid={addSalaryTemplateForm.isValid}
                                isTouched={addSalaryTemplateForm.touched.templateName}
                                invalidFeedback={addSalaryTemplateForm.errors.templateName}
                            />
                        </FormGroup>
                    </div>
                    <div className="col-12 mt-4">
                        <table className='table table-modern table-hover mt-2'>
                            <thead>
                                <tr className='table-primary text-center'>
                                    <th style={{ width: '60%' }}>Earning</th>
                                    <th style={{ width: '40%' }}>Percentage(%)</th>
                                    <td />
                                </tr>
                            </thead>

                            {inputList.map((x: any, i: any) => (
                                <tbody key={x.displayOrder}>
                                    <tr>
                                        <td>
                                            <FormGroup>
                                                <SearchableSelect
                                                    ariaLabel={''}
                                                    placeholder='Select Earning'
                                                    className="form-control"
                                                    value={x.earningDetailsIdLabel}
                                                    list={earningData.map((data: any) => (
                                                        { value: data.earningDetailsId, label: data.earningName }
                                                    ))}
                                                    onChange={e => handleInputChange(e, i)} />
                                            </FormGroup>
                                            <>
                                                {x.existingTemplate ?
                                                    <div className='mt-2 lh-sm text-danger'>
                                                        This Earning Details Already Selected.{' '}<Icon icon='Report' color='danger' className='mx-1' size='lg' />
                                                    </div> : null}
                                            </>
                                        </td>
                                        <td>
                                            <FormGroup>
                                                <Input type='text' placeholder='0.00 %' value={x.percentage} disabled />
                                            </FormGroup>
                                        </td>
                                        <td>
                                            {inputList.length !== 1 &&
                                                <Button color='danger' isLight icon='Close'
                                                    onClick={() => handleremove(x.earningDetailsId, i)}
                                                />
                                            }
                                        </td>
                                    </tr>
                                </tbody>
                            ))}
                            <tbody>
                                <tr>
                                    <td colSpan={3}>
                                        <FormGroup className='mt-2'>
                                            <Button onClick={add} icon='Add' isOutline color='info' size={'sm'} className='w-100'>Add</Button>
                                        </FormGroup>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="d-flex justify-content-center position-absolute top-60 start-50 translate-middle">
                        <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteSalaryTemplateSubmit} />
                    </div>
                    <div className="mt-5"></div>
                    </div>
                </OffCanvasBody>
                <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                    <div className='row m-0'>
                        <div className='col-6 p-3'>
                            <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                Delete
                            </Button>
                        </div>
                        <div className='col-6 p-3'>
                            <Button color='info' icon="Save" type="submit" className='w-100' > Update</Button>
                        </div>
                    </div>
                </div>
            </OffCanvas>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    )
}

export default SalaryTemplates