import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import showNotification from '../../components/extras/showNotification';
import Icon from '../../components/icon/Icon';
import Card, { CardBody, CardHeader, CardLabel, CardTabItem, CardTitle, } from '../../components/bootstrap/Card';
import Button from '../../components/bootstrap/Button';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Avatar from '../../components/Avatar';
import USERS from '../../common/data/userDummyData';
import Label from '../../components/bootstrap/forms/Label';
import Checks from '../../components/bootstrap/forms/Checks';
import Alert, { AlertHeading } from '../bootstrap/Alert';
import classNames from 'classnames';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Tooltips from '../bootstrap/Tooltips';
import Modal, { ModalBody, ModalHeader, ModalTitle } from '../bootstrap/Modal';

const statutoryComponents = () => {

    const professionalTaxForm = useFormik({
        initialValues: {
            workLocation: 'Half-Yearly',
            deductionCycle: 'Head Office(TamilNadu)',
            ptNumber: '',
            checkOne: true,
            checkTwo: false,
            checkThree: true,
        },
        validate: (values) => {
            const errors: {
                workLocation?: string;
                deductionCycle?: string;
                ptNumber?: string;

            } = {};
            if (!values.workLocation) {
                errors.workLocation = 'Required';
            }
            if (!values.deductionCycle) {
                errors.deductionCycle = 'Required';
            }
            return errors;
        },
        onSubmit: () => {
            setIsLoading(true);
            setTimeout(handleSave, 2000);
        },
    });

    const employeesStateInsuranceForm = useFormik({
        initialValues: {
            esiNumber: '',
            deductionCycle: 'Monthly',
            employeesContribution: '0.75%',
            employersContribution: '3.25%',
            checkOne: true,
            checkTwo: false,
            checkThree: true,
        },
        validate: (values) => {
            const errors: {
                esiNumber?: string;
                deductionCycle?: string;
                employeesContribution?: string;
                employersContribution?: string;
            } = {};
            if (!values.esiNumber) {
                errors.esiNumber = '';
            }
            if (!values.deductionCycle) {
                errors.deductionCycle = 'Required';
            }
            if (!values.employeesContribution) {
                errors.employeesContribution = 'Required';
            }
            if (!values.employersContribution) {
                errors.employersContribution = 'Required';
            }
            return errors;
        },
        onSubmit: () => {
            setIsLoading(true);
            setTimeout(handleSave, 2000);
        },
    });

    const navigate = useNavigate();
    const [stateInsuranceOffCanvas, setStateInsuranceOffCanvas] = useState(false);
    const [professionalTaxOffCanvas, setProfessionalTaxOffCanvas] = useState(false);
    const [isTaxSlabOpen, setIsTaxSlabOpen] = useState(false);
    const [previewEpf, setpreviewEpf] = useState(false);

    const flexRadios = useFormik({
        initialValues: {
            flexRadioDefault: 'first',
            flexRadioDisabled: 'third',
        },
        onSubmit: () => { },
    });
    const [lastSave, setLastSave] = useState<Dayjs | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSave = () => {
        setLastSave(dayjs());
        setIsLoading(false);
        showNotification(
            <span className='d-flex align-items-center'>
                <Icon icon='Info' size='lg' className='me-1' />
                <span>Updated Successfully</span>
            </span>,
            "The user's account details have been successfully updated.",
        );
    };

    const [inputlist, setinputlist] = useState<any>
        ([{ stateRange: '', endRange: '', halfYearlyTaxAmount: '' }])

    function handleInputchange(e: any, index: any) {
        const { name, value } = e.target;
        const list = [...inputlist];
        list[index][name] = value;
        setinputlist(list);
    }

    function add() {
        setinputlist([...inputlist, { stateRange: '', endRange: '', halfYearlyTaxAmount: '' }])
    }

    function handleremove(index: any) {
        const list = [...inputlist];
        list.splice(index, 1)
        setinputlist(list)
    }

    return (
        <PageWrapper>
            <Page>
                <div className='h5 fw-bold ms-4'>
                    <Icon icon='Handyman' color='primary' size='2x' />&nbsp;
                    Statutory Components
                </div>
                <Card hasTab className='div'>
                    <CardTabItem
                        id='statutoryComponents'
                        title='EPF'
                    >
                        <div className='row h-100 align-content-center'>
                            <div className='col-md-12'>
                                <CardBody>
                                    <div className='col-12 text-center'>
                                        <Avatar
                                            className=''
                                            srcSet={USERS.JOHN.srcSet}
                                            src={USERS.JOHN.src}
                                            color={USERS.JOHN.color}
                                            rounded={3}
                                        />
                                        <div className='col-lg'>
                                            <div className='row'>
                                                <div className='col-12' >

                                                    <br /><h5 className='h5 text-center fs-5 col-lg-12'><b>Are you registered for EPF?</b></h5>
                                                    <p className=' text-center fs-6'>
                                                        Any organisation with 20 or more employees must register for the <br /> Employee Provident Fund (EPF) scheme, a retirement benefit plan for all <br /> salaried employees.
                                                    </p>
                                                    <div className='col-12  text-center mt-2'>
                                                        <Button color='info' type="submit" className='mt-2' onClick={() => navigate('../StatutoryComponents/employeesProvidentFund')}> Enable EPF</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </div>
                        </div>
                    </CardTabItem>

                    <CardTabItem
                        id='statutoryComponents'
                        title='ESI'
                    >
                        <div className='row h-100 align-content-center'>
                            <div className='col-md-12'>
                                <CardBody>
                                    <div className='col-12 text-center'>
                                        <Avatar
                                            className=''
                                            srcSet={USERS.JOHN.srcSet}
                                            src={USERS.JOHN.src}
                                            color={USERS.JOHN.color}
                                            rounded={3}
                                        />
                                        <div className='col-lg'>
                                            <div className='row'>
                                                <div className='col-12' >

                                                    <br /><h5 className='h5 text-center fs-5 col-lg-12'><b>Are you registered for EPF?</b></h5>
                                                    <p className=' text-center fs-6'>
                                                        Organisations having 10 or more employees must register for Employee <br />State Insurance (ESI). This scheme provides cash allowances and <br />medical benefits for employees whose monthly salary is less than ₹21,000.
                                                    </p>
                                                    <div className='col-12 text-center mt-2'>
                                                        <Button color='info' onClick={() => setStateInsuranceOffCanvas(true)}> Enable ESI</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </CardBody>
                            </div>
                        </div>
                    </CardTabItem>

                    <CardTabItem
                        id='statutoryComponents'
                        title='Professional Tax'
                    >
                        <div className='row h-100 align-content-space-between'>
                            <div className='col-md-12'>
                                <CardHeader>
                                    <CardLabel>
                                        <CardTitle className='h5'>
                                            Professional Tax
                                            <br />
                                            <small className='text-muted fs-6'>
                                                This tax is levied on an employee’s income by the State Government. Tax slabs differ in each state.
                                            </small>
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <div className='col-6'>
                                    <Card>
                                        <CardBody>
                                            <FormGroup title='Head Office'>
                                                <div className='row'>
                                                    <div className='col-lg-6 text-start'>
                                                        <strong><h5>Head Office</h5></strong>
                                                    </div>
                                                    <div className='col-lg-6 text-start'>
                                                        <div
                                                            className={classNames(
                                                                'rounded-3',
                                                                'd-flex align-items-center justify-content-end',
                                                            )}>
                                                            <Button color='link' onClick={() => setProfessionalTaxOffCanvas(true)} ><Icon icon='Edit' size={"lg"} color='info' /></Button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <br />
                                                <div className='row'>
                                                    <div className='col-12'>
                                                        <table className='table table-borderless'>
                                                            <tbody>
                                                                <tr>
                                                                    <td>PT Number
                                                                    </td>
                                                                    <td>-</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>State
                                                                    </td>
                                                                    <td>Tamil Nadu</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Deduction Cycle
                                                                    </td>
                                                                    <td>Half Yearly</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>PT Slabs
                                                                    </td>
                                                                    <td onClick={() => setIsTaxSlabOpen(!isTaxSlabOpen)} className='text-primary cursor-pointer'>View tax slabs</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                    <Modal isOpen={isTaxSlabOpen} setIsOpen={setIsTaxSlabOpen} isCentered isAnimation={true} size={'lg'}>
                                                        <ModalHeader setIsOpen={setIsTaxSlabOpen}>
                                                            <ModalTitle id='taxSlab'>Tax Slabs for Head Office2</ModalTitle>
                                                        </ModalHeader>
                                                        <ModalBody>
                                                            <div className='col-12'>
                                                                <table className='table table-bordered'>
                                                                    <thead>
                                                                        <tr>
                                                                            <th>HALF YEARLY GROSS SALARY (₹)</th>
                                                                            <th>HALF YEARLY TAX AMOUNT (₹)</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <tr>
                                                                            <td>1 - 21000
                                                                            </td>
                                                                            <td>0</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>21001 - 30000
                                                                            </td>
                                                                            <td>135</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>30001 - 45000
                                                                            </td>
                                                                            <td>315</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>45001 - 60000
                                                                            </td>
                                                                            <td>690</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>60001 - 75000
                                                                            </td>
                                                                            <td>1025</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td>75001 - 999999999
                                                                            </td>
                                                                            <td>1250</td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </ModalBody>
                                                        <div className='col-md-3 ms-3 '>
                                                            <Button isOutline color='info' onClick={() => setpreviewEpf(false)}>Okay, got it</Button>
                                                        </div>
                                                        <br />
                                                    </Modal >
                                                </div>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </CardTabItem>

                    <CardTabItem
                        id='statutoryComponents'
                        title='Labour Welfare Fund'
                    >
                        <div className='row'>
                            <div className='col-md-12'>
                                <CardHeader>
                                    <CardLabel>
                                        <CardTitle className='h5'>
                                            Labour Welfare Fund
                                            <br />
                                            <small className='fs-6'>
                                                Labour Welfare Fund act ensures social security and improves working conditions for employees.
                                            </small>
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <div className='col-6'>
                                    <Card>
                                        <CardBody>
                                            <FormGroup title='Head Office'>
                                                <div className='row'>
                                                    <div className='col-lg-6 text-start'>
                                                        <strong><h5>Tamil Nadu</h5></strong>
                                                    </div>
                                                </div>
                                                <div className='col-12'>
                                                    <table className='table table-borderless'>
                                                        <tbody>
                                                            <tr>
                                                                <td>Employees's Contribution
                                                                </td>
                                                                <td>₹ 20.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Employer's Contribution
                                                                </td>
                                                                <td>₹ 40.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Deduction Cycle
                                                                </td>
                                                                <td>Yearly</td>
                                                            </tr>
                                                            <tr>
                                                                <td>Status
                                                                </td>
                                                                <td><p className='text-success'>Enabled<span className='text-primary cursor-pointer'> (Disabled)</span></p></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </FormGroup>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </div>
                    </CardTabItem>
                </Card>

                {/* Employees' State Insurance */}
                <OffCanvas
                    setOpen={setStateInsuranceOffCanvas}
                    isOpen={stateInsuranceOffCanvas}
                    titleId='ediemployeeOffCanvas'
                    isBodyScroll
                    placement='end'
                    isBackdrop>
                    <OffCanvasHeader setOpen={setStateInsuranceOffCanvas} onClick={close}>
                        <OffCanvasTitle id="ediemployeeOffCanvas">Employees' State Insurance</OffCanvasTitle>
                    </OffCanvasHeader>
                    <form noValidate onSubmit={flexRadios.handleSubmit}>
                        <OffCanvasBody>
                            <div className='row g-3'>
                                <div className='col-md-12'>
                                    <FormGroup id='esiNumber' label='ESI Number'>
                                        <Input
                                            type='number'
                                            placeholder='00-00-000000-000-0000'
                                            className='align-items-center'
                                            autoComplete='additional-name'
                                            onChange={employeesStateInsuranceForm.handleChange}
                                            onBlur={employeesStateInsuranceForm.handleBlur}
                                            value={employeesStateInsuranceForm.values.esiNumber}
                                            isValid={employeesStateInsuranceForm.isValid}
                                            isTouched={employeesStateInsuranceForm.touched.esiNumber}
                                            invalidFeedback={employeesStateInsuranceForm.errors.esiNumber}

                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-md-12 mt-1'>
                                    <Label>Deduction Cycle <Tooltips title='Employee State Insurance(ESI) contribution for each month shoud be deposited to Employee State Insurance Corporation(ESIC) within the 21st of the following month.' placement='right'><Icon icon='info'></Icon></Tooltips></Label>
                                    <FormGroup id='deductionCycle'>
                                        <Input
                                            disabled
                                            onChange={employeesStateInsuranceForm.handleChange}
                                            onBlur={employeesStateInsuranceForm.handleBlur}
                                            value={employeesStateInsuranceForm.values.deductionCycle}
                                            isValid={employeesStateInsuranceForm.isValid}
                                            isTouched={employeesStateInsuranceForm.touched.deductionCycle}
                                            invalidFeedback={employeesStateInsuranceForm.errors.deductionCycle}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-md-9 mt-1'>
                                    <FormGroup id='employeesContribution' label='Employees Contribution'>
                                        <Input
                                            disabled
                                            onChange={employeesStateInsuranceForm.handleChange}
                                            onBlur={employeesStateInsuranceForm.handleBlur}
                                            value={employeesStateInsuranceForm.values.employeesContribution}
                                            isValid={employeesStateInsuranceForm.isValid}
                                            isTouched={employeesStateInsuranceForm.touched.employeesContribution}
                                            invalidFeedback={employeesStateInsuranceForm.errors.employeesContribution}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-md-3 mt-5'>
                                    <p className='text-muted'>of Gross Pay</p>
                                </div>
                                <div className='col-md-9 mt-1'>
                                    <FormGroup id='employersContribution' label='Employers Contribution'>
                                        <Input
                                            disabled
                                            onChange={employeesStateInsuranceForm.handleChange}
                                            onBlur={employeesStateInsuranceForm.handleBlur}
                                            value={employeesStateInsuranceForm.values.employersContribution}
                                            isValid={employeesStateInsuranceForm.isValid}
                                            isTouched={employeesStateInsuranceForm.touched.employersContribution}
                                            invalidFeedback={employeesStateInsuranceForm.errors.employersContribution}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-md-3 mt-5'>
                                    <p className='text-muted'>of Gross Pay</p>
                                </div>
                                <div className='col-lg-12'>
                                    <Checks
                                        id='flexCheckDefault'
                                        name='defaultCheck'
                                        label="Include employer's contribution in the CTC"
                                        onChange={employeesStateInsuranceForm.handleChange}
                                        checked={employeesStateInsuranceForm.values.employersContribution}
                                    />
                                </div>
                                <div className='col-lg-12'>
                                    <Alert color='warning' isLight>
                                        <AlertHeading className='col-lg-12 fs-5'>
                                            <small className='text-muted fs-6'> <b>Note:</b> ESI deductions will be made only if the employee’s monthly salary is less than or equal to ₹21,000. If the employee gets a salary revision which increases their monthly salary above ₹21,000, they would have to continue making ESI contributions till the end of the contribution period in which the salary was revised (April-September or October-March).</small>
                                        </AlertHeading>
                                    </Alert>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                            <Button color='info' type="submit" className='w-100' > Enable</Button>
                        </div>
                    </form>
                </OffCanvas>

                {/* Professional Tax */}
                <OffCanvas
                    setOpen={setProfessionalTaxOffCanvas}
                    isOpen={professionalTaxOffCanvas}
                    titleId='ediemployeeOffCanvas'
                    isBackdrop>
                    <OffCanvasHeader setOpen={setProfessionalTaxOffCanvas} onClick={close}>
                        <OffCanvasTitle id="ediemployeeOffCanvas">Professional Tax</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className='row g-3'>
                            <div className='col-md-12'>
                                <FormGroup id='deductionCycle' label='Work Location'>
                                    <Input
                                        disabled
                                        onChange={professionalTaxForm.handleChange}
                                        onBlur={professionalTaxForm.handleBlur}
                                        value={professionalTaxForm.values.deductionCycle}
                                        isValid={professionalTaxForm.isValid}
                                        isTouched={professionalTaxForm.touched.deductionCycle}
                                        invalidFeedback={professionalTaxForm.errors.deductionCycle}
                                        validFeedback='Looks good!'
                                    />
                                </FormGroup>
                            </div><br />
                            <div className='col-md-12'>
                                <Label>Deduction Cycle <Tooltips title='Professional Tax (PT) deduction cycle is automatically set as Monthly, Half-Yearly or Yearly depending on the state in which your organization is located. The deducted amount should be deposited according to the deduction cycle.' placement='top'><Icon icon='info' color='danger' size="lg"></Icon></Tooltips></Label>
                                <FormGroup id='workLocation'>
                                    <Input
                                        disabled
                                        type='text'
                                        className='align-items-center'
                                        autoComplete='additional-name'
                                        onChange={professionalTaxForm.handleChange}
                                        onBlur={professionalTaxForm.handleBlur}
                                        value={professionalTaxForm.values.workLocation}
                                        isValid={professionalTaxForm.isValid}
                                        isTouched={professionalTaxForm.touched.workLocation}
                                        invalidFeedback={professionalTaxForm.errors.workLocation}
                                        validFeedback='Looks good!'
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-md-12'>
                                <FormGroup id='ptNumber' label='PT Number'>
                                    <Input
                                        onChange={professionalTaxForm.handleChange}
                                        onBlur={professionalTaxForm.handleBlur}
                                        value={professionalTaxForm.values.ptNumber}
                                        isValid={professionalTaxForm.isValid}
                                        isTouched={professionalTaxForm.touched.ptNumber}
                                        invalidFeedback={professionalTaxForm.errors.ptNumber}
                                    />
                                </FormGroup>
                            </div>
                        </div>

                        <div className='col-12 mt-3'>
                            <h6>Tax Slabs based on Half Yearly Gross Salary</h6>
                            <table>
                                <thead>
                                    <tr className='text-primary'>
                                        <td width={'18%'}><small>START RANGE(₹)</small></td>
                                        <td width={'18%'}><small>END RANGE(₹)</small></td>
                                        <td width={'30%'}><small>HALF YEARLY TAX AMOUNT(₹)</small></td>
                                        <td width={'1%'}></td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {inputlist.map((x: any, i: any) => {
                                        return (<>
                                            <tr>
                                                <td>
                                                    <input className="form-control" type='number' step='0' onChange={e => handleInputchange(e, i)} />&nbsp;
                                                </td>
                                                <td>
                                                    <input className="form-control" type='number' step='0' onChange={e => handleInputchange(e, i)} />&nbsp;
                                                </td>
                                                <td>
                                                    <input className="form-control" type='number' step='0' onChange={e => handleInputchange(e, i)} />&nbsp;
                                                </td>
                                                <td>
                                                    {inputlist.length !== 1 &&
                                                        <Icon icon='Close' size={"sm"} onClick={() => handleremove(i)}></Icon>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={3}>
                                                    {inputlist.length - 1 === i &&

                                                        <Button onClick={add} icon='Add' isOutline color='info' size={'sm'} className='w-100' >  Additional Slab</Button>
                                                    }
                                                </td>
                                            </tr>
                                        </>);
                                    })}
                                </tbody>

                            </table>
                        </div>
                    </OffCanvasBody>
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <Button color='info' type="submit" className='w-100' > Save</Button>
                    </div>
                </OffCanvas>
            </Page>
        </PageWrapper>
    );
};
export default statutoryComponents;