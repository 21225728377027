import React, { useContext, useEffect, useState } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import CommonHeaderChat from './CommonHeaderChat';
import Search from '../../../components/Search';
import CommonHeaderRight from './CommonHeaderRight';
import useDarkMode from '../../../hooks/useDarkMode';
import AuthContext from '../../../contexts/authContext';
import { getEmployeeProfile } from '../../../services/employee.service';
import { toasts } from '../../../services/toast.service';

const DashboardHeader = () => {
	const { darkModeStatus } = useDarkMode();
	const { userTypeId, userAccountId } = useContext(AuthContext);
	const [userData, setUserData] = useState<any>({});
	const [employeeProfileData, setEmployeeProfileData] = useState<any>([]);
	const [dataSuccess, setDataSuccess] = useState(false)

	useEffect(() => {
		if (userTypeId == 10) {
			getEmployeeProfileList()
		}
	}, []);

	function getEmployeeProfileList() {
        getEmployeeProfile(userAccountId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.employeeProfile
                    console.log("getEmployeeList:" + data);
                    if (data != undefined) {
                        setEmployeeProfileData(data);
                        setDataSuccess(false)

                    } else {
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                    }
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }


	return (
		<Header>
			<HeaderLeft>
				<Search />
			</HeaderLeft>
			<CommonHeaderRight afterChildren={<CommonHeaderChat />} />
		</Header>
	);
};

export default DashboardHeader;
