import React, { useEffect, useRef, useState } from 'react'
import FormGroup from '../bootstrap/forms/FormGroup'
import Select from "../bootstrap/forms/Select";
import Card, { CardBody, CardFooter, CardFooterLeft, CardFooterRight, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Input from "../bootstrap/forms/Input";
import Checks from '../bootstrap/forms/Checks';
import Button from '../bootstrap/Button';
import Alert from '../bootstrap/Alert';
import Icon from '../icon/Icon';
import { useFormik } from 'formik';
import { showLoader } from '../../services/loader.services';
import { addEarningDetails, getEarningTypeList } from '../../services/salary.service';
import { useNavigate } from 'react-router-dom';
import { getLicenseKey } from '../../services/application.settings';
import SearchableSelect from '../../common/components/SearchableSelect';
import AlertService from '../../services/AlertService';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';
import { toasts } from '../../services/toast.service';

const earning = () => {

    useEffect(() => {
        getEarningType();
    }, []);

    const earningForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            earningTypeId: '',
            earningName: '',
            nameInPaySlip: '',
            payType: '',
            fromCTc: '',
            fromBasicSalary: '',
            amount: '',
            percentage: '',
            isActive: '',
            isPartInEmployeeSalary: '',
            isTaxableEarning: '',
            isCalculateOnProRataBasis: '',
            isConsiderForEpfContribution: '',
            isConsiderForEsiContribution: '',
            isShownInPaySlip: '',
        },
        validate: (values) => {
            const errors: {
                earningTypeId?: string;
                earningName?: string;
                nameInPaySlip?: string;
                calculationTypeId?: string;
                isPartInEmployeeSalary?: string;
                isTaxableEarning?: string;
                isCalculateOnProRataBasis?: string;
                isConsiderForEpfContribution?: string;
                isConsiderForEsiContribution?: string;
                isShownInPaySlip?: string;
            } = {};
            if (!earningTypeId?.value) {
                errors.earningTypeId = 'Required';
            }
            if (!values.earningName) {
                errors.earningName = 'Required';
            }
            if (!values.nameInPaySlip) {
                errors.nameInPaySlip = 'Required';
            }
            if (!calculationTypeId) {
                errors.calculationTypeId = 'Required';
            }
            return errors;
        },
        validateOnChange: false,
        onSubmit: () => { AddEarning() },
    });

    const navigate = useNavigate();
    const [earningTypeId, setEarningTypeId] = useState<any>('');
    const componentRef = useRef(null);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isEpfContribution, setEpfContribution] = useState(false);
    const [calculationTypeId, setCalculationTypeId] = useState('1');
    const [earningData, setEarningData] = useState([]);

    function setAddEarningDetails() {

        return {
            earningTypeId: earningTypeId?.value == '' ? 0 : earningTypeId?.value,
            earningName: earningForm.values.earningName,
            nameInPaySlip: earningForm.values.nameInPaySlip,
            payType: '1',
            calculationType: calculationTypeId,
            fromCTc: earningTypeId == '1' && calculationTypeId == '2' ? 1 : 0,
            fromBasicSalary: earningTypeId != '1' && calculationTypeId == '2' ? 1 : 0,
            amount: earningForm.values.amount,
            percentage: earningForm.values.percentage != '' ? earningForm.values.percentage : 0,
            isActive: earningForm.values.isActive ? 1 : 0,
            isPartInEmployeeSalary: earningForm.values.isPartInEmployeeSalary ? 1 : 0,
            isTaxableEarning: earningForm.values.isTaxableEarning ? 1 : 0,
            isCalculateOnProRataBasis: earningForm.values.isCalculateOnProRataBasis ? 1 : 0,
            isConsiderForEpfContribution: earningForm.values.isConsiderForEpfContribution ? 1 : 0,
            isConsiderForEsiContribution: earningForm.values.isConsiderForEsiContribution ? 1 : 0,
            isShownInPaySlip: earningForm.values.isShownInPaySlip ? 1 : 0,
            createdBy: '1',
            licenseKey: getLicenseKey
        }
    }

    function getEarningType() {
        getEarningTypeList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.earningTypeList;
                    if (data != undefined) {
                        setEarningData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }
    function AddEarning() {
        showLoader(true)
        if (earningForm.isValid) {
            let earningsData = setAddEarningDetails()
            addEarningDetails(earningsData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )

        } else if (earningForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    const selectEaring = (e: any) => {
        let earningTypeId = e
        setEarningTypeId(earningTypeId)
    }

    return (

        <PageWrapper>
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color='info'
                        isLink
                        icon='ArrowBack'
                        tag='a'
                        to={`../salary/salaryComponents`}>
                        Back to List
                    </Button>
                    <SubheaderSeparator />
                </SubHeaderLeft>
            </SubHeader>
            <Page>
                <form noValidate onSubmit={earningForm.handleSubmit}>
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel>
                                <CardTitle tag='div' className='h5'>New Earning</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className="row">
                                <div className="col-6">
                                    <Card borderSize={1}>
                                        <CardBody>
                                            <div className="row g-2">
                                                <div className='col-12'>
                                                    <FormGroup id='earningTypeId' label='Select Correction' >
                                                        <SearchableSelect
                                                            placeholder='Earning Type'
                                                            ariaLabel='Earning Type'
                                                            list={earningData.map((data: any) => (
                                                                { value: data.earningTypeId, label: data.typeName }
                                                            ))}
                                                            onChange={selectEaring}
                                                            value={earningTypeId}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-12 ms-2'>
                                                    <Alert isLight className='border-0' shadow='md' color='success' rounded={2}>
                                                        <span>
                                                            <Icon icon='Info' color='danger' size="lg" />
                                                            &nbsp;
                                                            Fixed amount paid at the end of every month.
                                                        </span>
                                                    </Alert>
                                                </div>
                                                <div className='col-12'>
                                                    <FormGroup id='earningName' label='Earning Name'>
                                                        <Input
                                                            placeholder="earningName"
                                                            onChange={earningForm.handleChange}
                                                            value={earningForm.values.earningName}
                                                            onBlur={earningForm.handleBlur}
                                                            isValid={earningForm.isValid}
                                                            isTouched={earningForm.touched.earningName}
                                                            invalidFeedback={earningForm.errors.earningName}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-12 mt-1'>
                                                    <FormGroup id='nameInPaySlip' label='Name in Payslip' >
                                                        <Input
                                                            placeholder="nameInPaySlip"
                                                            onChange={earningForm.handleChange}
                                                            value={earningForm.values.nameInPaySlip}
                                                            onBlur={earningForm.handleBlur}
                                                            isValid={earningForm.isValid}
                                                            isTouched={earningForm.touched.nameInPaySlip}
                                                            invalidFeedback={earningForm.errors.nameInPaySlip}
                                                        />
                                                    </FormGroup>
                                                </div>

                                                <div className='col-12'>
                                                    <div className='fw-bold'> Calculation Type<span className='text-danger'>*</span></div>&nbsp;&nbsp;
                                                    <Checks
                                                        type='radio'
                                                        name='flexRadioDefault'
                                                        id='flexRadioDefault1'
                                                        label='Flat Amount'
                                                        value='1'
                                                        onChange={() => setCalculationTypeId("1")}
                                                        checked={calculationTypeId}
                                                    />
                                                    &nbsp;&nbsp;
                                                    <Checks
                                                        type='radio'
                                                        name='flexRadioDefault'
                                                        id='flexRadioDefault2'
                                                        label={earningTypeId == '1' ? 'Percentage of CTC' : 'Percentage of Basic'}
                                                        value='2'
                                                        onChange={() => setCalculationTypeId("2")}
                                                        checked={calculationTypeId}
                                                    />&nbsp;&nbsp;
                                                </div>
                                            </div>
                                            {calculationTypeId == '1' ?
                                                <div className='col-12'>
                                                    <div className="input-group col-md-10 mt-2">
                                                        <FormGroup id='amount' label='Enter Amount' >
                                                            <Input
                                                                type='number'
                                                                placeholder="amount"
                                                                onChange={earningForm.handleChange}
                                                                value={earningForm.values.amount}
                                                                onBlur={earningForm.handleBlur}
                                                                isValid={earningForm.isValid}
                                                                isTouched={earningForm.touched.amount}
                                                                invalidFeedback={earningForm.errors.amount}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div> :
                                                null}
                                            {calculationTypeId == '2' ?
                                                <div className='col-12'>
                                                    <div className="input-group col-md-10 mt-2">
                                                        <FormGroup id='percentage' label='Enter Percentage' >
                                                            <Input
                                                                type='number'
                                                                placeholder="percentage"
                                                                onChange={earningForm.handleChange}
                                                                value={earningForm.values.percentage}
                                                                onBlur={earningForm.handleBlur}
                                                                isValid={earningForm.isValid}
                                                                isTouched={earningForm.touched.percentage}
                                                                invalidFeedback={earningForm.errors.percentage}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div> : null
                                            }
                                            <br />
                                            <div className="col-12">
                                                <Checks
                                                    id='isActive'
                                                    label='Mark this as Active'
                                                    name='isActive'
                                                    onChange={earningForm.handleChange}
                                                    checked={earningForm.values.isActive}
                                                />
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className="col-6">
                                    <Card borderSize={1}>
                                        <CardHeader borderSize={1}>
                                            <CardLabel>
                                                <CardTitle tag='div' className='h5'>Other Configurations</CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row g-4">
                                                <div className="col-12">
                                                    <Checks
                                                        id='isPartInEmployeeSalary'
                                                        label='Make this earning a part of the employee’s salary structure'
                                                        name='isPartInEmployeeSalary'
                                                        onChange={earningForm.handleChange}
                                                        checked={earningForm.values.isPartInEmployeeSalary}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <Checks
                                                        id='isTaxableEarning'
                                                        label='This is a taxable earning 
                                                    The income tax amount will be divided equally and deducted every month across the financial year.'
                                                        name='isTaxableEarning'
                                                        onChange={earningForm.handleChange}
                                                        checked={earningForm.values.isTaxableEarning}
                                                    />
                                                </div>
                                                <div className="col-12">
                                                    <Checks
                                                        id='isCalculateOnProRataBasis'
                                                        label='Calculate on pro-rata basis
                                                    Pay will be adjusted based on employee working days.'
                                                        name='isCalculateOnProRataBasis'
                                                        onChange={earningForm.handleChange}
                                                        checked={earningForm.values.isCalculateOnProRataBasis}
                                                    />
                                                </div>

                                                <div className="col-12">
                                                    <Checks
                                                        id='isConsiderForEpfContribution'
                                                        label='Consider for EPF Contribution'
                                                        name='isConsiderForEpfContribution'
                                                        onChange={() => setEpfContribution(!isEpfContribution)}
                                                        checked={isEpfContribution}
                                                    />
                                                    {isEpfContribution ?
                                                        <div className="col-12 mt-2">
                                                            <Checks
                                                                type='radio'
                                                                id='Always'
                                                                label='Always'
                                                                name='isCalculateOnProRataBasis'
                                                                onChange={earningForm.handleChange}
                                                            />
                                                            <div className='col-12'>
                                                                <Checks
                                                                    type='radio'
                                                                    id='isCalculateOnProRataBasis'
                                                                    label='Only when PF Wage is less than ₹ 15,000.'
                                                                    name='isCalculateOnProRataBasis'
                                                                    onChange={earningForm.handleChange}
                                                                    checked={earningForm.values.isCalculateOnProRataBasis}
                                                                />
                                                                &nbsp;&nbsp;
                                                            </div>
                                                        </div>
                                                        : null}
                                                    <div className="col-12 mt-2">
                                                        <Checks
                                                            id='isConsiderForEsiContribution'
                                                            label='Consider for ESI Contribution'
                                                            name='isConsiderForEsiContribution'
                                                            onChange={earningForm.handleChange}
                                                            checked={earningForm.values.isConsiderForEsiContribution}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-12">
                                                    <Checks
                                                        id='isShownInPaySlip'
                                                        label='Show this component in payslip'
                                                        name='isShownInPaySlip'
                                                        onChange={earningForm.handleChange}
                                                        checked={earningForm.values.isShownInPaySlip}
                                                    />
                                                </div>
                                            </div>
                                            <br />
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                            <div className='col-12 ms-1'>
                                <Alert isLight className='border-0' shadow='md' color='danger' rounded={2}>
                                    <span>
                                        <p>
                                            <strong>Note :</strong>&nbsp;
                                            Once you associate this component with an employee, you will only be able to edit the Name and Amount/Percentage. The changes you make to Amount/Percentage will apply only to new employees.
                                        </p>
                                    </span>
                                </Alert>
                            </div>
                            <CardFooter>
                                <CardFooterRight>
                                    <Button
                                        color='info'
                                        type="submit"
                                        isDisable={!earningForm.isValid && !earningForm.submitCount}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        isOutline
                                        color='info'
                                        isLight
                                        onClick={() => navigate(-1)}>
                                        Cancel
                                    </Button>
                                </CardFooterRight>
                            </CardFooter>
                        </CardBody>
                    </Card>
                </form>
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    )
}
export default earning