import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import showNotification from '../extras/showNotification';
import Icon from '../icon/Icon';
import Card, { CardBody, } from '../bootstrap/Card';
import Button, { ButtonGroup } from '../bootstrap/Button';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Tooltips from '../bootstrap/Tooltips';

const professionalTax = () => {

  const professionalTaxForm = useFormik({
    initialValues: {
      workLocation: 'Half-Yearly',
      deductionCycle: 'Head Office(TamilNadu)',
      ptNumber: '',
      checkOne: true,
      checkTwo: false,
      checkThree: true,
    },

    validate: (values) => {
      const errors: {
        workLocation?: string;
        deductionCycle?: string;
        ptNumber?: string;

      } = {};
      if (!values.workLocation) {
        errors.workLocation = 'Required';
      }
      if (!values.deductionCycle) {
        errors.deductionCycle = 'Required';
      }
      return errors;
    },

    onSubmit: () => {
      setIsLoading(true);
      setTimeout(handleSave, 2000);
    },
  });

  const flexChecks = useFormik({
    initialValues: {
      defaultCheck: false,
      checkedCheck: true,
    },
    onSubmit: () => { },
  });

  const navigate = useNavigate();
  const [lastSave, setLastSave] = useState<Dayjs | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  
  const handleSave = () => {
    setLastSave(dayjs());
    setIsLoading(false);
    showNotification(
      <span className='d-flex align-items-center'>
        <Icon icon='Info' size='lg' className='me-1' />
        <span>Updated Successfully</span>
      </span>,
      "The user's account details have been successfully updated.",
    );
  };

  const [inputlist, setinputlist] = useState<any>([{ stateRange: '', endRange: '', halfYearlyTaxAmount: '' }])
  function handleInputchange(e: any, index: any) {
    const { name, value } = e.target;
    const list = [...inputlist];
    list[index][name] = value;
    setinputlist(list);
  }
  function add() {
    setinputlist([...inputlist, { stateRange: '', endRange: '', halfYearlyTaxAmount: '' }])
  }
  const [remove, setRemove] = useState(false);
  function handleremove(index: any) {
    const list = [...inputlist];
    list.splice(index, 1)
    setinputlist(list)
  }

  return (
    <PageWrapper>
      <Page>
        <Card>
          <br /><br />
          <div className="row h5 fe-bold ms-4"><b>Professional Tax</b></div>
          <br />
          <div className='row'>
            <div className='col-6'>
              <Card borderSize={2}>
                <CardBody>
                  <div className='row'>
                    <div className='col-md-10  mt-4 '>
                      <h6 className='h6 text-start col-lg-6'><b>Work Location</b></h6>
                      <FormGroup id='deductionCycle' >
                        <Input
                          disabled
                          onChange={professionalTaxForm.handleChange}
                          onBlur={professionalTaxForm.handleBlur}
                          value={professionalTaxForm.values.deductionCycle}
                          isValid={professionalTaxForm.isValid}
                          isTouched={professionalTaxForm.touched.deductionCycle}
                          invalidFeedback={professionalTaxForm.errors.deductionCycle}
                          validFeedback='Looks good!'
                        />
                      </FormGroup>
                    </div><br />
                    <div className='col-md-10 mt-4'>
                      <h6 className='h6 text-start col-lg-6'><b>Deduction Cycle <Tooltips title='Professional Tax (PT) deduction cycle is automatically set as Monthly, Half-Yearly or Yearly depending on the state in which your organization is located. The deducted amount should be deposited according to the deduction cycle.' placement='top'><Icon icon='info' color='danger' size="lg"></Icon></Tooltips></b></h6>
                      <FormGroup id='workLocation'>
                        <Input
                          disabled
                          type='text'
                          className='align-items-center'
                          autoComplete='additional-name'
                          onChange={professionalTaxForm.handleChange}
                          onBlur={professionalTaxForm.handleBlur}
                          value={professionalTaxForm.values.workLocation}
                          isValid={professionalTaxForm.isValid}
                          isTouched={professionalTaxForm.touched.workLocation}
                          invalidFeedback={professionalTaxForm.errors.workLocation}
                          validFeedback='Looks good!'
                        />
                      </FormGroup>
                    </div>
                    <div className='col-md-10 mt-4'>
                      <h6 className='h6 text-start col-lg-6'><b>PT Number</b></h6>
                      <FormGroup id='ptNumber'>
                        <Input
                          onChange={professionalTaxForm.handleChange}
                          onBlur={professionalTaxForm.handleBlur}
                          value={professionalTaxForm.values.ptNumber}
                          isValid={professionalTaxForm.isValid}
                          isTouched={professionalTaxForm.touched.ptNumber}
                          invalidFeedback={professionalTaxForm.errors.ptNumber}
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <br />
                </CardBody>
              </Card>
            </div>
            <div className="col-6">
              <Card borderSize={2}>
                <CardBody>
                  <div className='h6 text-start col-lg-6'>                  
                    <b>Tax Slabs based on Half Yearly Gross Salary</b>
                  </div>
                  <hr />
                  <div className='col-12 mt-3'>
                    <table>
                      <thead>
                        <th>START RANGE(₹) &nbsp;</th>
                        <th>END RANGE(₹)&nbsp;</th>
                        <th>HALF YEARLY TAX AMOUNT(₹)&nbsp;</th>
                      </thead>
                      <tbody>
                        <td>
                          <input className="form-control" type='number' step='0' value={1} />
                        </td>
                        <td>
                          <input className="form-control" type='number' step='0' value={21000} />
                        </td>
                        <td>
                          <input className="form-control" step='0' type='number' value={0} />
                        </td>
                        {inputlist.map((x: any, i: any) => {
                          return (
                            <tbody key={x.displayOrder}>
                              <tr>
                                <td>
                                  <FormGroup className='mt-2'>
                                    <input className="form-control" type='number' step='0' onChange={e => handleInputchange(e, i)} />
                                  </FormGroup>
                                </td>
                                <td>
                                  <ButtonGroup>
                                    <>
                                      <FormGroup className='mt-2 ms-3 '>
                                        <Input type='text' placeholder='0.00 %' value={x.persentage} disabled />
                                      </FormGroup>
                                      {inputlist.length !== 1 &&
                                        <Icon
                                          className='mt-2 ms-2'
                                          icon='Close'
                                          size="2x"
                                          onClick={() => handleremove(x.earningDetailsId)}
                                        />
                                      }</>
                                  </ButtonGroup>
                                </td>
                              </tr>
                              {inputlist.length - 1 === i &&
                                <tr>
                                  <td colSpan={3} className='border'>
                                    <FormGroup className='mt-2'>
                                      <Button onClick={add} icon='Add' isOutline color='info' size={'sm'} className='w-100'>Add</Button>
                                    </FormGroup>
                                  </td>
                                </tr>
                              }
                            </tbody>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className='col-1 ms-2 '>
              <Button color='info' type="submit" >
                Save</Button>
            </div>
            <div className='col-1'>
              <Button isOutline color='info' isLight onClick={() => navigate(-1)}>Cancel</Button>
            </div>
          </div>
          <br />
        </Card>
      </Page >
    </PageWrapper >

  );
};
export default professionalTax;


 {/* {inputlist.map((x: any, i: any) => {
                          return (
                            <tr>
                              <td width={"13%"}>
                                <input className="form-control" type='number' step='0' onChange={e => handleInputchange(e, i)} />&nbsp;
                              </td>
                              <td width={"13%"}>
                                <input className="form-control" type='number' step='0' onChange={e => handleInputchange(e, i)} />&nbsp;
                              </td>
                              <td width={"13%"}>
                                <input className="form-control" type='number' step='0' onChange={e => handleInputchange(e, i)} />&nbsp;
                              </td>
                              {inputlist.length !== 1 &&
                                <td width={"2%"}> <Button onClick={() => handleremove(i)}><Icon icon='Close' size={"md"}></Icon></Button></td>}
                              {inputlist.length - 1 === i &&
                                <td width={"2%"} ><Button onClick={add}><Icon icon='Add' size={"md"}></Icon> Add</Button></td>
                              }
                            </tr>
                          );
                        })
 } */}