import { useContext, useEffect, useState } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from "../bootstrap/Card";
import Popovers from "../bootstrap/Popovers";
import classNames from "classnames";
import useDarkMode from "../../hooks/useDarkMode";
import AlertService from "../../services/AlertService";
import { useFormik } from "formik";
import { getCurrentDateAndTime, getGenderDetailsList, getStates, getStatesAndCity, onlyAllowNumber, profilePic, profilePicUpload } from "../../services/common.service";
import Button from "../bootstrap/Button";
import Input from "../bootstrap/forms/Input";
import { getLicenseKey } from "../../services/application.settings";
import FormGroup from "../bootstrap/forms/FormGroup";
import AuthContext from "../../contexts/authContext";
import SearchableSelect from "../../common/components/SearchableSelect";
import { toasts } from "../../services/toast.service";
import { getDepartmentList, getDesignationList, getUserRoleList, getWorkLocationsList } from "../../services/setting.services";
import Checks from "../bootstrap/forms/Checks";
import Tooltips from "../bootstrap/Tooltips";
import Icon from "../icon/Icon";
import { getSalaryTemplateList } from "../../services/salary.service";
import InputGroup, { InputGroupText } from "../bootstrap/forms/InputGroup";
import { addEmployeeDetails, getEmployeeSalaryDetails } from "../../services/employee.service";
import { showLoader } from "../../services/loader.services";


function AddEmployee() {

    const titles = [
        'Basics Info',
        'Salary Details',
        'Personal Info',
        "Payment Info",
    ];

    const [step, setStep] = useState<number>(0);
    const totalSteps: number = 4;

    const handleNext = () => {
        if (step < totalSteps) {
            setStep(step + 1);
        }
    };

    const handlePrevious = () => {
        setStep(step - 1);
    };

    const handleStepClick = (newStep: any) => {
        setStep(newStep);
    };

    const { userAccountId, organisationDetailsId } = useContext(AuthContext);

    useEffect(() => {
        getGenderDetails();
        getWorkLocations();
        getSalaryTemplate(organisationDetailsId, 0);
        getUserRole();
        getDepartment();
        getDesignation();
        getStatesList();
        getCityList(31);
    }, [])

    const { themeStatus, darkModeStatus } = useDarkMode();
    const [genderId, setGenderId] = useState<any>('')
    const [workLocationId, setWorkLocationId] = useState<any>('')
    const [salaryTemplateId, setSalaryTemplateId] = useState<any>('')
    const [annualCTC, setAnnualCTC] = useState<any>('')
    const [salaryTemplateListById, setSalaryTemplateListById] = useState<any>([])
    const [genderData, setGenderData] = useState<any>([])
    const [workLocationData, setWorkLocationData] = useState<any>([]);
    const [salaryTemplateData, setSalaryTemplateData] = useState<any>([]);
    const [employeeSalaryDetails, setEmployeeSalaryDetails] = useState<any>([])
    const [totalMonthlyAmount, setTotalMonthlyAmount] = useState<any>(0)
    const [totalAnnualAmount, setTotalAnnualAmount] = useState<any>(0)
    const [dateOfBirth, setBirthday] = useState('');
    const [age, setAge] = useState<any>('');
    const [userRoleData, setUserRoleData] = useState([]);
    const [userRoleId, setUserRoleId] = useState<any>(Number)
    const [departmentData, setDepartmentData] = useState([]);
    const [departmentId, setDepartmentId] = useState<any>(Number)
    const [designationData, setDesignationData] = useState([]);
    const [designationId, setDesignationId] = useState<any>(Number)
    const [statesData, setStatesData] = useState([]);
    const [cityData, setCityData] = useState([]);
    const [stateId, setStateId] = useState<any>({ value: 31, label: 'Tamil Nadu' })
    const [cityId, setCityId] = useState<any>(Number)
    const [accountTypeId, setAccountTypeId] = useState<any>(Number)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);

    const basicInfoForm = useFormik({
        initialValues: {
            employeeFirstName: '',
            employeeMiddleName: '',
            employeeLastName: '',
            dateOfJoining: getCurrentDateAndTime('date'),
            genderId: '',
            workEmail: '',
            mobile: '',
            dateOfBirth: '',
            email: '',
            password: '',
            confirmPassword: '',
            workLocationId: '',
            isInterestedofCompany: 0,
            isPortalAccess: 0,
            isProfessionalTax: 0,
            isLabourWelfareFund: 0,

        },
        validate: (values) => {
            const errors: {
                employeeFirstName?: string;
                dateOfJoining?: string;
                genderId?: string;
                workLocationId?: string;
                workEmail?: string;
                mobile?: string;
                email?: string;
                password?: string;
                confirmPassword?: string;

            } = {};
            if (!values.employeeFirstName) {
                errors.employeeFirstName = 'Required';
            }
            if (!values.dateOfJoining) {
                errors.dateOfJoining = 'Required';
            }
            if (!genderId) {
                errors.genderId = 'Required';
            }
            if (!workLocationId) {
                errors.workLocationId = 'Required';
            }
            if (!values.workEmail) {
                errors.workEmail = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.workEmail)) {
                errors.workEmail = 'Invalid email address';
            }
            if (!values.password) {
                errors.password = 'Required';
            } else if (!/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,16}$/i.test(values.password)) {
                errors.password =
                    'Password must be 8-16 characters, containing at least 1 special character, 1 capital letter, 1 small letter, and 1 number.';
            }
            if (values.confirmPassword && values.password !== values.confirmPassword) {
                errors.confirmPassword = 'Passwords do not match.';
            }
            return errors;
        },
        onSubmit: () => { handleNext() },
    });

    const salaryInfoForm = useFormik({
        initialValues: {
            salaryTemplateId: '',
            annualCTC: '',
            basicCtcPercentage: '',

        },
        validate: (values) => {
            const errors: {
                salaryTemplateId?: string;
                annualCTC?: string;

            } = {};
            if (!salaryTemplateId) {
                errors.salaryTemplateId = 'Required';
            }
            if (!annualCTC) {
                errors.annualCTC = 'Required';
            }
            return errors;
        },
        onSubmit: () => { handleNext() },
    });

    const personalInfoForm = useFormik({
        initialValues: {
            email: '',
            dateOfBirth: '',
            mobile: '',
            age: '',
            fatherName: '',
            panNumber: '',
            userRoleId: '',
            departmentId: '',
            designationId: '',
            address1: '',
            address2: '',
            stateId: '',
            cityId: '',
            pincode: '',

        },
        validate: (values) => {
            const errors: {
                email?: string;
                mobile?: string;
                panNumber?: string;
                userRoleId?: string;
                departmentId?: string;
                designationId?: string;
                address1?: string;
                stateId?: string;
                cityId?: string;
                pincode?: string;

            } = {};
            if (!values.email) {
                errors.email = 'Required';
            } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                errors.email = 'Invalid email address';
            }
            if (!values.mobile) {
                errors.mobile = 'Required';
            } else if (values.mobile.length < 10 || values.mobile.length > 10) {
                errors.mobile = 'Must be 10 Numbers'
            } else if (!/^[6-9]\d{9}$/i.test(values.mobile)) {
                errors.mobile = 'Please enter valid mobile number'
            }
            if (!values.panNumber) {
                errors.panNumber = 'Required';
            } else if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/i.test(values.panNumber) && values.panNumber != '') {
                errors.panNumber = 'Formate - AAAAA0000A';
            }
            if (!userRoleId) {
                errors.userRoleId = 'Required';
            }
            if (!departmentId) {
                errors.departmentId = 'Required';
            }
            if (!designationId) {
                errors.designationId = 'Required';
            }
            if (!values.address1) {
                errors.address1 = 'Required';
            }
            if (!stateId) {
                errors.stateId = 'Required';
            }
            if (!cityId) {
                errors.cityId = 'Required';
            }
            if (!values.pincode) {
                errors.pincode = 'Required'
            }
            else if (!/^\d{6}$/.test(values.pincode)) {
                errors.pincode = 'Must be 6 Numbers';
            }

            return errors;
        },
        onSubmit: () => { handleNext() },
    });

    const paymentInfoForm = useFormik({
        initialValues: {
            accountHolderName: '',
            bankName: '',
            accountNumber: '',
            ifsc: '',
            accountTypeId: '',

        },
        validate: (values) => {
            const errors: {
                accountHolderName?: string;
                bankName?: string;
                accountNumber?: string;
                accountTypeId?: string;

            } = {};
            if (!values.accountHolderName) {
                errors.accountHolderName = 'Required';
            }
            if (!values.bankName) {
                errors.bankName = 'Required';
            }
            if (!values.accountNumber) {
                errors.accountNumber = 'Required';
            }
            if (!accountTypeId) {
                errors.accountTypeId = 'Required';
            }
            return errors;
        },
        onSubmit: () => { AddEmployeeSubmit() },
    });


    function selectSalaryTemplate(salaryTemplateId: any) {
        setSalaryTemplateId(salaryTemplateId)
        let salaryTemplate = salaryTemplateData.filter((item: any) => item.salaryTemplateId == salaryTemplateId)
        setSalaryTemplateListById(salaryTemplate[0])
    }

    function multiply(annualCTC: any) {
        setAnnualCTC(Number(annualCTC))
    }

    const selectState = (e: any) => {
        let stateId = e
        setStateId(stateId)
        if (stateId?.value != undefined) {
            getCityList(stateId?.value)
        }
    }

    function setAddEmployeeDetails() {
        return {
            organisationDetailsId: organisationDetailsId,
            employeeFirstName: basicInfoForm.values.employeeFirstName != '' ? basicInfoForm.values.employeeFirstName : null,
            employeeMiddleName: basicInfoForm.values.employeeMiddleName != '' ? basicInfoForm.values.employeeMiddleName : null,
            employeeLastName: basicInfoForm.values.employeeLastName != '' ? basicInfoForm.values.employeeLastName : null,
            dateOfJoining: basicInfoForm.values.dateOfJoining,
            workEmail: basicInfoForm.values.workEmail != '' ? basicInfoForm.values.workEmail : null,
            workLocationsId: workLocationId?.value != undefined ? workLocationId?.value : null,
            designationId: designationId?.value != undefined ? designationId?.value : null,
            departmentId: departmentId?.value != undefined ? departmentId?.value : null,
            userRoleId: userRoleId?.value != undefined ? userRoleId?.value : null,
            genderId: genderId?.value != undefined ? genderId?.value : null,
            isProfessionalTax: basicInfoForm.values.isProfessionalTax == 0 ? 0 : 1,
            isLabourWelfareFund: basicInfoForm.values.isLabourWelfareFund == 0 ? 0 : 1,
            isPortalAccess: basicInfoForm.values.isPortalAccess == 0 ? 0 : 1,
            isInterestedofCompany: basicInfoForm.values.isInterestedofCompany == 0 ? 0 : 1,
            salaryTemplateId: salaryTemplateId?.value ? salaryTemplateId?.value : null,
            annualCTC: annualCTC,
            basicCtcPercentage: salaryInfoForm.values.basicCtcPercentage != '' ? salaryInfoForm.values.basicCtcPercentage : null,
            email: personalInfoForm.values.email != '' ? personalInfoForm.values.email : null,
            mobile: personalInfoForm.values.mobile != '' ? personalInfoForm.values.mobile : null,
            dateOfBirth: dateOfBirth != '' ? dateOfBirth : null,
            fatherName: personalInfoForm.values.fatherName != '' ? personalInfoForm.values.fatherName : null,
            address1: personalInfoForm.values.address1 != '' ? personalInfoForm.values.address1 : null,
            address2: personalInfoForm.values.address2 != '' ? personalInfoForm.values.address2 : null,
            cityId: cityId?.value != undefined ? cityId?.value : null,
            stateId: stateId?.value != undefined ? stateId?.value : null,
            pincode: personalInfoForm.values.pincode != '' ? personalInfoForm.values.pincode : null,
            panNumber: personalInfoForm.values.panNumber,
            paymentTypeId: "1",
            accountHolderName: paymentInfoForm.values.accountHolderName != '' ? paymentInfoForm.values.accountHolderName : null,
            bankName: paymentInfoForm.values.bankName != '' ? paymentInfoForm.values.bankName : null,
            accountNumber: paymentInfoForm.values.accountNumber != '' ? paymentInfoForm.values.accountNumber : null,
            ifsc: paymentInfoForm.values.ifsc,
            accountType: accountTypeId?.value != undefined ? accountTypeId?.value : null,
            createdBy: userAccountId,
            licenseKey: getLicenseKey,

        }
    }

    function AddEmployeeSubmit() {
        showLoader(true);
        if (paymentInfoForm.isValid) {
            let employeePostData = setAddEmployeeDetails()
            addEmployeeDetails(employeePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        if (selectedFile != '') {
                            onUpload(data.data.addEmployeeDetails)
                        }
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(`../employee/employeeList`)
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )

        } else if (paymentInfoForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    function getGenderDetails() {
        getGenderDetailsList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.genderDetails;
                    if (data != undefined) {
                        setGenderData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setGenderData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getWorkLocations() {
        getWorkLocationsList(userAccountId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.workLocationsList;
                    if (data != undefined) {
                        setWorkLocationData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setWorkLocationData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSalaryTemplate(organisationDetailsId: any, salaryTemplateId: any) {
        getSalaryTemplateList(organisationDetailsId, salaryTemplateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.salaryTemplateList;
                    if (data != undefined) {
                        setSalaryTemplateData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setSalaryTemplateData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getEmployeeSalaryList(salaryTemplateId: any, annualCTC: any) {
        getEmployeeSalaryDetails(salaryTemplateId, annualCTC, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.employeeSalaryDetails;
                    if (data != undefined) {
                        setEmployeeSalaryDetails(data)

                        let monthlyAmount = data.map((item: any) => item.monthlyAmount)
                        let annualAmount = data.map((item: any) => item.annualAmount)

                        let totalMonthlyAmount = (monthlyAmount.reduce((a: any, v: any) => a = a + v, 0))
                        let totalAnnualAmount = (annualAmount.reduce((a: any, v: any) => a = a + v, 0))

                        setTotalMonthlyAmount(totalMonthlyAmount);
                        setTotalAnnualAmount(totalAnnualAmount);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setEmployeeSalaryDetails([])
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getUserRole() {
        getUserRoleList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userRoleList;
                    if (data != undefined) {
                        setUserRoleData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setUserRoleData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.departmentList;
                    if (data != undefined) {
                        setDepartmentData(data);

                    } else {
                        toasts("undefined data", "error")
                    }
                }
                else if (response.data.success === false) {
                    setDepartmentData([]);
                }
                else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDesignation() {
        getDesignationList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.designationList;
                    if (data != undefined) {
                        setDesignationData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDesignationData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStatesList() {
        getStates(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity;
                    if (data != undefined) {
                        setStatesData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setStatesData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCityList(stateId: number) {
        getStatesAndCity(stateId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.statesAndCity[0]['city'];
                    if (data != undefined) {
                        setCityData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setCityData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [selectedFile, setSelectedFile] = useState<any>('')

    const handleFileChange = (e: any) => {
        if (e.target.files && e.target.files[0]) {
            setSelectedFile(e.target.files[0]);
        }
    };

    const handleDeleteAvatar = () => {
        setSelectedFile(null);
        const fileInput = document.getElementById('fileInput') as HTMLInputElement;
        if (fileInput) {
            fileInput.value = '';
        }
    };

    function onUpload(employeeDetailsId: any) {
        const fileData = new FormData();
        fileData.append('profilePic', selectedFile, selectedFile.name)
        fileData.append('licenseKey', getLicenseKey.toString())
        fileData.append('employeeDetailsId', employeeDetailsId.toString())
        fileData.append('userAccountId', userAccountId)

        profilePicUpload(fileData,
            (response) => {
                console.log('Profile pic upload');
            }
            , (error) => {
                console.log('Profile pic not upload');

            }
        )
    }

    //date and age
    function getAge(e: any) {
        let dateOfBirth = e.target.value
        setBirthday(dateOfBirth)
        let bday = new Date(dateOfBirth)
        let today = new Date()
        let distance = today.getTime() - bday.getTime()
        let daysOld = Math.floor(distance / (1000 * 60 * 60 * 24))
        let yearsOld = Number((daysOld / 365).toFixed(0))
        setAge(yearsOld)
    }

    const accountTypeData = [{ accountTypeId: 1, accountType: 'Current' }, { accountTypeId: 2, accountType: 'Savings' }]


    return (
        <PageWrapper title="Add Employee">
            <Page container='fluid'>
                <Card stretch>
                    <CardHeader>
                        <CardLabel icon="Assignment" color="info">
                            {[...Array(totalSteps)].map((_, index) => (
                                <CardTitle
                                    key={index}
                                    className={index !== step ? 'd-none' : undefined}
                                >{titles[step]}
                                </CardTitle>
                            ))}
                        </CardLabel>
                    </CardHeader>
                    <CardBody>
                        <div className='wizard-progress position-relative'>
                            <div className='progress'>
                                <div
                                    className='progress-bar bg-primary'
                                    role='progressbar'
                                    style={{ width: `${(100 / (totalSteps - 1)) * step}%` }}
                                    aria-valuenow={(100 / (totalSteps - 1)) * step}
                                    aria-valuemin={0}
                                    aria-valuemax={100}
                                    aria-label='progress'
                                />
                            </div>
                            {[...Array(totalSteps)].map((child: any, index: any) => (
                                <Popovers
                                    key={index}
                                    desc={titles[index]}
                                    trigger='hover'>
                                    <button
                                        type='button'
                                        className={classNames(
                                            'wizard-progress-btn',
                                            'position-absolute p-0 top-0',
                                            'translate-middle',
                                            'btn btn-sm',
                                            {
                                                [`btn-primary`]: step >= index,
                                                [`btn-${themeStatus}`]: step < index,
                                            },
                                            'rounded-pill',
                                        )}
                                        style={{
                                            left: `${(100 / (totalSteps - 1)) * index}%`,
                                        }}
                                        onClick={() => { handleStepClick(index) }}>
                                        {index + 1}
                                    </button>
                                </Popovers>
                            ))}
                        </div>

                        {step == 0 && (
                            <Card shadow='none'>
                                <form noValidate onSubmit={basicInfoForm.handleSubmit}>
                                    <div className="d-grid gap-4">
                                        <div className="row g-4">
                                            <div className='col-12'>
                                                <div className='row g-4 align-items-center'>
                                                    <div className="row col-6 g-4">
                                                        <div className="col-lg-12">
                                                            <FormGroup id='employeeFirstName' isFloating label='First Name'>
                                                                <Input
                                                                    aria-placeholder='First Name'
                                                                    autoComplete=""
                                                                    onChange={basicInfoForm.handleChange}
                                                                    onBlur={basicInfoForm.handleBlur}
                                                                    value={basicInfoForm.values.employeeFirstName}
                                                                    isValid={basicInfoForm.isValid}
                                                                    invalidFeedback={basicInfoForm.errors.employeeFirstName}
                                                                    isTouched={basicInfoForm.touched.employeeFirstName}
                                                                    type="text"
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <FormGroup id='employeeMiddleName' isFloating label='Middle Name'>
                                                                <Input
                                                                    aria-placeholder='MiddleName'
                                                                    autoComplete=''
                                                                    onChange={basicInfoForm.handleChange}
                                                                    onBlur={basicInfoForm.handleBlur}
                                                                    value={basicInfoForm.values.employeeMiddleName}
                                                                    isValid={basicInfoForm.isValid}
                                                                    invalidFeedback={basicInfoForm.errors.employeeMiddleName}
                                                                    type="text"
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className="col-lg-6">
                                                            <FormGroup id='employeeLastName' isFloating label='Last Name'>
                                                                <Input
                                                                    aria-placeholder='LastName'
                                                                    autoComplete=''
                                                                    onChange={basicInfoForm.handleChange}
                                                                    onBlur={basicInfoForm.handleBlur}
                                                                    value={basicInfoForm.values.employeeLastName}
                                                                    isValid={basicInfoForm.isValid}
                                                                    invalidFeedback={basicInfoForm.errors.employeeLastName}
                                                                    type="text"
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className='col-12'>
                                                            <div className='row g-4 align-items-center'>
                                                                <div className='col-lg-3'>
                                                                    {selectedFile ?
                                                                        <img style={{ borderRadius: '50%' }}
                                                                            src={URL.createObjectURL(selectedFile)}
                                                                            height='120' width='120' />
                                                                        :
                                                                        <img style={{ borderRadius: '50%' }}
                                                                            src={profilePic(1)}
                                                                            height='120' width='120'
                                                                        />}
                                                                </div>
                                                                <div className='col-lg-9 mt-3'>
                                                                    <div className='row g-4'>
                                                                        <div className='col-8'>
                                                                            <Input
                                                                                type='file'
                                                                                id="fileInput"
                                                                                autoComplete='photo'
                                                                                ariaLabel='Upload image file'
                                                                                onChange={handleFileChange}
                                                                            />
                                                                        </div>
                                                                        <div className='col-12'>
                                                                            <Button
                                                                                color='dark'
                                                                                isLight
                                                                                icon='Delete'
                                                                                onClick={handleDeleteAvatar}>
                                                                                Delete Avatar
                                                                            </Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-3'>
                                                        <FormGroup id='genderId' isFloating label='Gender'>
                                                            <SearchableSelect isFloating ariaLabel="" placeholder="Select Gender"
                                                                onChange={(e: any) => setGenderId(e)}
                                                                value={genderId}
                                                                onBlur={basicInfoForm.handleBlur}
                                                                isValid={basicInfoForm.isValid}
                                                                invalidFeedback={basicInfoForm.errors.genderId}
                                                                isTouched={basicInfoForm.touched.genderId}
                                                                list={genderData.map((data: any) => (
                                                                    { value: data.genderId, label: data.gender }
                                                                ))} />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-3'>
                                                        <FormGroup id='dateOfJoining' isFloating label='Date of Joining'>
                                                            <Input
                                                                aria-placeholder=''
                                                                autoComplete=''
                                                                onChange={basicInfoForm.handleChange}
                                                                onBlur={basicInfoForm.handleBlur}
                                                                value={basicInfoForm.values.dateOfJoining}
                                                                isValid={basicInfoForm.isValid}
                                                                invalidFeedback={basicInfoForm.errors.dateOfJoining}
                                                                type='date'
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-6'>
                                                        <FormGroup id='workLocationId' isFloating label='Work Location'>
                                                            <SearchableSelect isFloating ariaLabel="" placeholder="Select Gender"
                                                                onChange={(e: any) => setWorkLocationId(e)}
                                                                value={workLocationId}
                                                                onBlur={basicInfoForm.handleBlur}
                                                                isValid={basicInfoForm.isValid}
                                                                invalidFeedback={basicInfoForm.errors.workLocationId}
                                                                isTouched={basicInfoForm.touched.workLocationId}
                                                                list={workLocationData.map((data: any) => (
                                                                    { value: data.workLocationsId, label: data.locationName + '(' + data.address1 + ',' + data.stateName + ',' + data.pincode + ')' }
                                                                ))} />
                                                        </FormGroup>
                                                    </div>
                                                    <div className="col-6">
                                                        <Card>
                                                            <CardBody>
                                                                <Checks
                                                                    type='checkbox'
                                                                    id='isInterestedofCompany'
                                                                    label={
                                                                        <>
                                                                            <b className="text-primary">Director/person</b><br />
                                                                            <span>
                                                                                Employee is a Director/person with substantial <br /> interest in the company
                                                                            </span>
                                                                            &nbsp;
                                                                            <Tooltips title=
                                                                                'Substantial interest in a company means that the employee is a beneficial owner of shares and carries at least 20% of the voting power. This detail will help us fill out Form 12BA for this employee.' placement='right'><Icon icon='info' />
                                                                            </Tooltips>
                                                                        </>
                                                                    }
                                                                    onChange={basicInfoForm.handleChange}
                                                                    checked={basicInfoForm.values.isInterestedofCompany == 0 ? false : true}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    <div className="col-6">
                                                        <Card>
                                                            <CardBody>
                                                                <Checks
                                                                    type='checkbox'
                                                                    id='isPortalAccess'
                                                                    label={
                                                                        <>
                                                                            <b className="text-primary">Enable Portal Access</b><br />
                                                                            <span>
                                                                                The employee will be able to view payslips, submit their IT declaration and create reimbursement claims through the employee portal.
                                                                            </span>
                                                                        </>
                                                                    }
                                                                    onChange={basicInfoForm.handleChange}
                                                                    checked={basicInfoForm.values.isPortalAccess == 0 ? false : true}
                                                                />
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    <div className="col-6">
                                                        <Card>
                                                            <CardBody>
                                                                <div><b className='text-primary'>Statutory Components</b><br /><span>
                                                                    Enable the necessary benefits and tax applicable for this employee.</span>
                                                                </div>
                                                                <div className="row mt-3">
                                                                    <div className="col-4">
                                                                        <Checks
                                                                            type='checkbox'
                                                                            id='isProfessionalTax'
                                                                            label='Professional Tax'
                                                                            onChange={basicInfoForm.handleChange}
                                                                            checked={basicInfoForm.values.isProfessionalTax == 0 ? false : true}
                                                                        />
                                                                    </div>
                                                                    <div className="col-6">
                                                                        <Checks
                                                                            type='checkbox'
                                                                            id='isLabourWelfareFund'
                                                                            label='Labour Welfare Fund'
                                                                            onChange={basicInfoForm.handleChange}
                                                                            checked={basicInfoForm.values.isLabourWelfareFund == 0 ? false : true}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </div>
                                                    <div className="row g-4">
                                                        <div className="col-lg-12">
                                                            <CardLabel icon="Login" color="info">
                                                                <CardTitle className="h5">Login Info</CardTitle>
                                                            </CardLabel>
                                                        </div>
                                                        <div className='col-3'>
                                                            <FormGroup id='workEmail' isFloating label='Work Email'>
                                                                <Input
                                                                    type='email'
                                                                    placeholder='Email address'
                                                                    autoComplete='email'
                                                                    onChange={basicInfoForm.handleChange}
                                                                    onBlur={basicInfoForm.handleBlur}
                                                                    value={basicInfoForm.values.workEmail}
                                                                    isValid={basicInfoForm.isValid}
                                                                    invalidFeedback={basicInfoForm.errors.workEmail}
                                                                    isTouched={basicInfoForm.touched.workEmail}

                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-3'>
                                                            <FormGroup id='password' isFloating label='Password'>
                                                                <Input
                                                                    type='password'
                                                                    placeholder='Enter Password'
                                                                    autoComplete='Password'
                                                                    onChange={basicInfoForm.handleChange}
                                                                    onBlur={basicInfoForm.handleBlur}
                                                                    value={basicInfoForm.values.password}
                                                                    isValid={basicInfoForm.isValid}
                                                                    invalidFeedback={basicInfoForm.errors.password}
                                                                    isTouched={basicInfoForm.touched.password}

                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-3'>
                                                            <FormGroup id="confirmPassword" label="Enter Confirm Password" isFloating>
                                                                <Input type="password" placeholder="Enter Confirm Password"
                                                                    onChange={basicInfoForm.handleChange}
                                                                    value={basicInfoForm.values.confirmPassword}
                                                                    onBlur={basicInfoForm.handleBlur}
                                                                    isValid={basicInfoForm.isValid}
                                                                    isTouched={basicInfoForm.touched.confirmPassword}
                                                                    invalidFeedback={basicInfoForm.errors.confirmPassword} />
                                                            </FormGroup>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ textAlign: 'right' }} className="mt-4">
                                        <Button color="primary" type="submit" isLight>Next</Button>
                                    </div>
                                </form>
                            </Card>
                        )}
                        {step == 1 && (
                            <Card shadow='none'>
                                <form noValidate onSubmit={salaryInfoForm.handleSubmit}>
                                    <div className="d-grid gap-4">
                                        <div className='row'>
                                            <div className='col-4'>
                                                <FormGroup id='salaryTemplateId' isFloating label="Salary Templates">
                                                    <SearchableSelect isFloating ariaLabel="" placeholder="Select Template"
                                                        onChange={(e: any) => selectSalaryTemplate(e)}
                                                        value={salaryTemplateId}
                                                        onBlur={salaryInfoForm.handleBlur}
                                                        isValid={salaryInfoForm.isValid}
                                                        invalidFeedback={salaryInfoForm.errors.salaryTemplateId}
                                                        isTouched={salaryInfoForm.touched.salaryTemplateId}
                                                        list={salaryTemplateData.map((data: any) => (
                                                            { value: data.salaryTemplateId, label: data.templateName }
                                                        ))} />
                                                </FormGroup>
                                            </div>
                                            <div className='col-4'>
                                                <FormGroup id='annualCTC' isFloating label="Annual CTC (Per Year)">
                                                    <Input
                                                        aria-placeholder=''
                                                        placeholder='Enter Annual CTC'
                                                        onInput={(e: any) => multiply(Number(e.target.value))}
                                                        value={annualCTC}
                                                        onBlur={salaryInfoForm.handleBlur}
                                                        isValid={salaryInfoForm.isValid}
                                                        invalidFeedback={salaryInfoForm.errors.annualCTC}
                                                        isTouched={salaryInfoForm.touched.annualCTC}
                                                        onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                    />
                                                </FormGroup>
                                            </div>
                                            <div className='col-3 mt-2'>
                                                <Button icon='ArrowDownward' color='primary' isDisable={annualCTC > 0 ? false : true} onClick={() => getEmployeeSalaryList(salaryTemplateId?.value, annualCTC)}>View Salary Details</Button>
                                            </div>

                                            {employeeSalaryDetails != '' ?
                                                <div className='col-12 mt-4'>
                                                    <table className='table table-modern table-hover text-nowrap'>
                                                        <thead >
                                                            <tr className='table-success text-decoration-underline mt-4' >
                                                                <th>Salary Components</th>
                                                                <th>Calculation Type</th>
                                                                <th>Monthly Amount</th>
                                                                <th>Annual Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {employeeSalaryDetails?.map((i: any) => (
                                                                <tr className='text-start' key={i.salaryTemplateId}>
                                                                    <td>
                                                                        {i.earningName}
                                                                    </td>
                                                                    <td>
                                                                        {i.labelValue}
                                                                    </td>
                                                                    <td>
                                                                        {i.monthlyAmount}
                                                                    </td>
                                                                    <td>
                                                                        {i.annualAmount}
                                                                    </td>
                                                                </tr>))}
                                                            <tr>
                                                                <td>Fixed Allowance <br />Monthly CTC - Sum of all other components</td>
                                                                <td className='text-start'>
                                                                    <div className='mt-2 ms-1'>Fixed amount</div>
                                                                </td>
                                                                <td className='text-start'>
                                                                    <div className='mt-2 ms-1'>0.00</div>
                                                                </td>
                                                                <td className='text-start'>
                                                                    <div className='mt-2 ms-1'>0.00</div>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colSpan={2}><strong>Cost to Company</strong></td>
                                                                <td className='text-start'>
                                                                    <strong className='mt-2 ms-1'>
                                                                        ₹ {totalMonthlyAmount}</strong>
                                                                </td>
                                                                <td className='text-start'>
                                                                    <strong className='mt-2 ms-1'
                                                                    >₹ {totalAnnualAmount}</strong>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div> : null}
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                        <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                        <Button color="primary" type="submit" isLight>Next</Button>
                                    </div>
                                </form>
                            </Card>
                        )}
                        {step == 2 && (
                            <Card shadow='none'>
                                <form noValidate onSubmit={personalInfoForm.handleSubmit}>
                                    <div className='row g-4'>
                                        <div className='col-md-4'>
                                            <FormGroup id='email' isFloating label='Personal Email Address'>
                                                <Input
                                                    type='email'
                                                    placeholder='Email address'
                                                    autoComplete='email'
                                                    onChange={personalInfoForm.handleChange}
                                                    onBlur={personalInfoForm.handleBlur}
                                                    value={personalInfoForm.values.email}
                                                    isValid={personalInfoForm.isValid}
                                                    isTouched={personalInfoForm.touched.email}
                                                    invalidFeedback={
                                                        personalInfoForm.errors.email
                                                    }
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-4'>
                                            <FormGroup id='mobile' label='Mobile Number' isFloating>
                                                <Input
                                                    onChange={personalInfoForm.handleChange}
                                                    onBlur={personalInfoForm.handleBlur}
                                                    value={personalInfoForm.values.mobile}
                                                    isValid={personalInfoForm.isValid}
                                                    isTouched={personalInfoForm.touched.mobile}
                                                    invalidFeedback={personalInfoForm.errors.mobile}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                    type="number"
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-4'>
                                            <FormGroup id='dateOfBirth' isFloating label='Date Of Birth'>
                                                <Input
                                                    aria-placeholder=''
                                                    autoComplete=''
                                                    onChange={getAge}
                                                    onBlur={personalInfoForm.handleBlur}
                                                    value={dateOfBirth}
                                                    isValid={personalInfoForm.isValid}
                                                    invalidFeedback={personalInfoForm.errors.dateOfBirth}
                                                    type='date'
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-4'>
                                            <FormGroup id='age' isFloating label='Age'>
                                                <Input
                                                    aria-placeholder='age'
                                                    autoComplete=''
                                                    onChange={personalInfoForm.handleChange}
                                                    onBlur={personalInfoForm.handleBlur}
                                                    value={age}
                                                    isValid={personalInfoForm.isValid}
                                                    invalidFeedback={personalInfoForm.errors.age}
                                                    disabled
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-4'>
                                            <FormGroup id='fatherName' isFloating label='Father Name'>
                                                <Input
                                                    aria-placeholder='father Name'
                                                    autoComplete=''
                                                    onChange={personalInfoForm.handleChange}
                                                    onBlur={personalInfoForm.handleBlur}
                                                    value={personalInfoForm.values.fatherName}
                                                    isValid={personalInfoForm.isValid}
                                                    invalidFeedback={personalInfoForm.errors.fatherName}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-4'>
                                            <FormGroup id='panNumber' isFloating label='PAN'>
                                                <Input
                                                    aria-placeholder='panNumber'
                                                    autoComplete=''
                                                    onChange={personalInfoForm.handleChange}
                                                    onBlur={personalInfoForm.handleBlur}
                                                    value={personalInfoForm.values.panNumber}
                                                    isTouched={personalInfoForm.touched.panNumber}
                                                    invalidFeedback={personalInfoForm.errors.panNumber}
                                                />
                                            </FormGroup>
                                        </div>
                                        <br />
                                        <div className="col-lg-12">
                                            <CardLabel icon="AssignmentInd" color="info">
                                                <CardTitle className="h5">Joining Details</CardTitle>
                                            </CardLabel>
                                        </div>

                                        <div className='col-md-4'>
                                            <FormGroup id="userRoleId" label="Role" isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel="role"
                                                    placeholder="role"
                                                    onChange={(e: any) => setUserRoleId(e)}
                                                    value={userRoleId}
                                                    list={userRoleData.map((data: any) => (
                                                        { value: data.userRoleId, label: data.roleName }
                                                    ))}
                                                    isValid={personalInfoForm.isValid}
                                                    isTouched={personalInfoForm.touched.userRoleId}
                                                    invalidFeedback={personalInfoForm.errors.userRoleId}
                                                    onBlur={personalInfoForm.handleBlur}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-4'>
                                            <FormGroup id='departmentId' isFloating label='Department'>
                                                <SearchableSelect isFloating
                                                    placeholder='department'
                                                    ariaLabel='department'
                                                    onChange={(e: any) => setDepartmentId(e)}
                                                    value={departmentId}
                                                    list={departmentData.map((data: any) => (
                                                        { value: data.departmentId, label: data.departmentName }
                                                    ))}
                                                    isValid={personalInfoForm.isValid}
                                                    isTouched={personalInfoForm.touched.departmentId}
                                                    invalidFeedback={personalInfoForm.errors.departmentId}
                                                    onBlur={personalInfoForm.handleBlur}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-4'>
                                            <FormGroup id='designationId' isFloating label='Designation'>
                                                <SearchableSelect isFloating
                                                    placeholder='designation'
                                                    ariaLabel='designation'
                                                    onChange={(e: any) => setDesignationId(e)}
                                                    value={designationId}
                                                    list={designationData.map((data: any) => (
                                                        { value: data.designationId, label: data.designationName }
                                                    ))}
                                                    isValid={personalInfoForm.isValid}
                                                    isTouched={personalInfoForm.touched.designationId}
                                                    invalidFeedback={personalInfoForm.errors.designationId}
                                                    onBlur={personalInfoForm.handleBlur}
                                                />
                                            </FormGroup>
                                        </div>
                                        <br />
                                        <br />
                                        <div className="col-lg-12">
                                            <CardLabel icon="Home" color="info">
                                                <CardTitle className="h5">Address Info</CardTitle>
                                            </CardLabel>
                                        </div>
                                        <div className='col-md-6'>
                                            <FormGroup id='address1' isFloating label=' Residential Address'>
                                                <Input
                                                    aria-placeholder='addressLine 1'
                                                    onChange={personalInfoForm.handleChange}
                                                    onBlur={personalInfoForm.handleBlur}
                                                    value={personalInfoForm.values.address1}
                                                    isValid={personalInfoForm.isValid}
                                                    invalidFeedback={personalInfoForm.errors.address1}
                                                    isTouched={personalInfoForm.touched.address1}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-6'>
                                            <FormGroup id='address2' isFloating label='Address Line 2'>
                                                <Input
                                                    aria-placeholder='addressLine2'
                                                    autoComplete=''
                                                    onChange={personalInfoForm.handleChange}
                                                    onBlur={personalInfoForm.handleBlur}
                                                    value={personalInfoForm.values.address2}
                                                // isValid={personalInfoForm.isValid}
                                                // invalidFeedback={personalInfoForm.errors.address2}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-4'>
                                            <FormGroup id='stateId' isFloating label='State'>
                                                <SearchableSelect isFloating
                                                    placeholder='State'
                                                    ariaLabel='State'
                                                    onChange={selectState}
                                                    value={stateId}
                                                    list={statesData.map((data: any) => (
                                                        { value: data.stateId, label: data.stateName }
                                                    ))}
                                                    isValid={personalInfoForm.isValid}
                                                    isTouched={personalInfoForm.touched.stateId}
                                                    invalidFeedback={personalInfoForm.errors.stateId}
                                                    onBlur={personalInfoForm.handleBlur}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-4'>
                                            <FormGroup id='cityId' isFloating label='City'>
                                                <SearchableSelect isFloating
                                                    ariaLabel=''
                                                    placeholder='City'
                                                    onChange={(e: any) => setCityId(e)}
                                                    value={cityId}
                                                    list={cityData.map((data: any) => (
                                                        { value: data.cityId, label: data.cityName }
                                                    ))}
                                                    isValid={personalInfoForm.isValid}
                                                    isTouched={personalInfoForm.touched.cityId}
                                                    invalidFeedback={personalInfoForm.errors.cityId}
                                                    onBlur={personalInfoForm.handleBlur}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-md-4'>
                                            <FormGroup id='pincode' isFloating label='Pin Code'>
                                                <Input
                                                    aria-placeholder='Pin Code'
                                                    onChange={personalInfoForm.handleChange}
                                                    onBlur={personalInfoForm.handleBlur}
                                                    value={personalInfoForm.values.pincode}
                                                    isValid={personalInfoForm.isValid}
                                                    invalidFeedback={personalInfoForm.errors.pincode}
                                                    onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                    isTouched={personalInfoForm.touched.pincode}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                        <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                        <Button color="primary" type="submit" isLight>Next</Button>
                                    </div>
                                </form>
                            </Card>
                        )}
                        {step == 3 && (
                            <Card shadow='none'>
                                <form noValidate onSubmit={paymentInfoForm.handleSubmit}>
                                    <div className="row g-3 mt-4">
                                        <div className='col-4'>
                                            <FormGroup id='accountHolderName' label='Account Holder Name' isFloating>
                                                <Input
                                                    aria-placeholder=''
                                                    placeholder='Enter Account Holder Name'
                                                    onChange={paymentInfoForm.handleChange}
                                                    onBlur={paymentInfoForm.handleBlur}
                                                    value={paymentInfoForm.values.accountHolderName}
                                                    isValid={paymentInfoForm.isValid}
                                                    invalidFeedback={paymentInfoForm.errors.accountHolderName}
                                                    type='text'
                                                    isTouched={personalInfoForm.touched.email}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-4'>
                                            <FormGroup id='bankName' label='Bank Name' isFloating>
                                                <Input
                                                    aria-placeholder=''
                                                    placeholder='Enter Bank Name'
                                                    onChange={paymentInfoForm.handleChange}
                                                    onBlur={paymentInfoForm.handleBlur}
                                                    value={paymentInfoForm.values.bankName}
                                                    isValid={paymentInfoForm.isValid}
                                                    invalidFeedback={paymentInfoForm.errors.bankName}
                                                    isTouched={paymentInfoForm.touched.bankName}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-4'>
                                            <FormGroup id='accountNumber' label='Account Number' isFloating>
                                                <Input
                                                    aria-placeholder=''
                                                    placeholder='Enter Account Number'
                                                    onChange={paymentInfoForm.handleChange}
                                                    onBlur={paymentInfoForm.handleBlur}
                                                    value={paymentInfoForm.values.accountNumber}
                                                    isValid={paymentInfoForm.isValid}
                                                    invalidFeedback={paymentInfoForm.errors.accountNumber}
                                                    isTouched={paymentInfoForm.touched.accountNumber}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-4'>
                                            <FormGroup id='ifsc' label='IFSC' isFloating>
                                                <Input
                                                    aria-placeholder=''
                                                    placeholder='Enter IFSC Code'
                                                    onChange={paymentInfoForm.handleChange}
                                                    onBlur={paymentInfoForm.handleBlur}
                                                    value={paymentInfoForm.values.ifsc}
                                                    isValid={paymentInfoForm.isValid}
                                                    invalidFeedback={paymentInfoForm.errors.ifsc}
                                                    isTouched={paymentInfoForm.touched.ifsc}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className='col-4'>
                                            <FormGroup id='accountType' label='Account Type' isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel=''
                                                    placeholder='Account Type'
                                                    onChange={(e: any) => setAccountTypeId(e)}
                                                    value={accountTypeId}
                                                    list={accountTypeData.map((data: any) => (
                                                        { value: data.accountTypeId, label: data.accountType }
                                                    ))}
                                                    isValid={paymentInfoForm.isValid}
                                                    isTouched={paymentInfoForm.touched.accountTypeId}
                                                    invalidFeedback={paymentInfoForm.errors.accountTypeId}
                                                    onBlur={paymentInfoForm.handleBlur}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-4">
                                        <Button color="primary" onClick={handlePrevious} type="submit" isLink>Previous</Button>
                                        <Button color="info" type="submit" >Submit</Button>
                                    </div>
                                </form>
                            </Card>
                        )}
                    </CardBody>
                </Card>
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />
        </PageWrapper>
    )
}
export default AddEmployee;