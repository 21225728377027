import React, { useContext, useEffect, useState } from 'react';
import { demoPagesMenu } from '../../../menu';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import SubHeader, { SubHeaderLeft, SubheaderSeparator, } from '../../../layout/SubHeader/SubHeader';
import Page from '../../../layout/Page/Page';
import ThemeContext from '../../../contexts/themeContext';
import AuthContext from '../../../contexts/authContext';
import { useTour } from '@reactour/tour';
import TodayAbsentCount from './dashboard-component/TodayAbsentCount';
import TotalSalaryDetails from './dashboard-component/TotalSalaryDetails';

const DashboardPage = () => {
	const { mobileDesign } = useContext(ThemeContext);
	const { userAccountId, userTypeId, userData } = useContext(AuthContext);
	/**
	 * Tour Start
	 */
	const { setIsOpen } = useTour();
	useEffect(() => {
		if (localStorage.getItem('tourModalStarted') !== 'shown' && !mobileDesign) {
			setTimeout(() => {
				setIsOpen(true);
				localStorage.setItem('tourModalStarted', 'shown');
			}, 7000);
		}
		return () => { };
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<PageWrapper title={demoPagesMenu.sales.subMenu.dashboard.text}>
			<SubHeader>
				<SubHeaderLeft>
					<span className='fs-5'>Today Overview </span>
					<SubheaderSeparator />
					<strong className='fs-5'>Hi, <span className='text-primary'>{`${userData.fullname}`}</span> !</strong>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				<div className='row'>
					{/* ----------------For Admin DashBoard----------------*/}
					<div className='col-xl-8'>
						<TotalSalaryDetails />
					</div>
					<div className='col-xl-4'>
						<TodayAbsentCount />
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default DashboardPage;