import dayjs from "dayjs";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AuthContext from "../../contexts/authContext";
import useDarkMode from "../../hooks/useDarkMode";
import Page from "../../layout/Page/Page";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import AlertService from "../../services/AlertService";
import { getLicenseKey } from "../../services/application.settings";
import { addAmountCollection } from "../../services/collection.service";
import { getCurrentDateAndTime, profilePic, pictNotLoading, onlyAllowNumber } from "../../services/common.service";
import { showLoader, TableLoader } from "../../services/loader.services";
import { getProfileForStaff } from "../../services/staff.service";
import { toasts } from "../../services/toast.service";
import Button from "../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardBody } from "../bootstrap/Card";
import FormGroup from "../bootstrap/forms/FormGroup";
import Textarea from "../bootstrap/forms/Textarea";
import Input from "../bootstrap/forms/Input";
import Icon from "../icon/Icon";
import { getLoanScheduleDetails } from "../../services/loans.service";


const PayLoan = () => {

    const { loansDetailsId } = useParams();
    const { userAccountId, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        setDataSuccess(false);
        getLoanSchedule(loansDetailsId);
    }, []);

    const TABS = {
        CASH_PAYMENTS: 'Cash',
        CHEQUE: 'Cheque',
        NEFT_UPI: 'NEFT / UPI',
        CARD_PAYMENT: 'Card',
    };

    const [activeTab, setActiveTab] = useState(TABS.CASH_PAYMENTS);
    const navigate = useNavigate();

    const { darkModeStatus } = useDarkMode();
    const [dataSuccess, setDataSuccess] = useState(false);
    const [amountCollectionDetails, setAmountCollectionDetails] = useState<any>([])
    const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([])
    const [staffProfileData, setStaffProfileData] = useState<any>([]);
    const [paymentTypeId, setPaymentTypeId] = useState(1)
    const [fivehunderd_total, setFivehunderd_total] = useState<any>("")
    const [twoHundred_total, setTwoHundred_total] = useState<any>("")
    const [hundred_total, setHundred_total] = useState<any>("")
    const [fifty_total, setFifty_total] = useState<any>("")
    const [twenty_total, setTwenty_total] = useState<any>("")
    const [ten_total, setTen_total] = useState<any>("")
    const [five_total, setFive_total] = useState<any>("")
    const [totalDenominationAmount, setTotalDenominationAmount] = useState<any>("")
    const [balCashAmount, setBalCashAmount] = useState<any>("")
    const [totalCollectionAmount, setTotalCollectionAmount] = useState<any>(0)
    const [loanAmount, setLoanAmount] = useState<any>(0)
    const [totalPaidAmount, setTotalPaidAmount] = useState<any>(0)
    const [totalBalanceAmount, setTotalBalanceAmount] = useState<any>(0)
    const [staffDetailsId, setStaffDetailsId] = useState<any>(0)

    const [loanPayScheduleData, setLoanPayScheduleData] = useState<any>([]);
    const [staffLoanData, setStaffLoanData] = useState<any>([]);
    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);

    const payFeesDenominationForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            dateOfCollection: getCurrentDateAndTime('date'),
            paymentTypeId: '',
            twoThousand_total: '',
            twoThousand_count: '',
            thousand_total: '',
            thousand_count: '',
            fivehunderd_total: '',
            fivehunderd_count: '',
            twoHundred_total: '',
            twoHundred_count: '',
            hundred_total: '',
            hundred_count: '',
            fifty_total: '',
            fifty_count: '',
            twenty_total: '',
            twenty_count: '',
            ten_total: '',
            ten_count: '',
            five_total: '',
            five_count: '',
            totalAmount: '',
            chequeNumber: '',
            chequeDate: getCurrentDateAndTime('date'),
            chequeAmount: '',
            chequeBankName: '',
            neftRefNumber: '',
            neftTranDate: getCurrentDateAndTime('date'),
            neftAmount: '',
            neftBankName: '',
            cardNumber: '',
            cardDate: getCurrentDateAndTime('date'),
            cardAmount: '',
            cardBankName: '',
            balCashAmount: '',
            narration: ''
        },
        onSubmit: () => { },
    });

    const updateAmountReceivedValue = (cell: number, amount: any) => {

        loanPayScheduleData.forEach((item: any) => {

            const newData: any = [];
            if (item.loanPayScheduleId == cell) {
                item.amount = amount
            }
            newData.push(item)
            setFilteredDataToRefresh(newData)
        });

        if (amountCollectionDetails.length == 0) {
            const obj = {
                loanPayScheduleId: cell,
                salaryAdvancePayScheduleId: 0,
                amount: amount,
            };
            amountCollectionDetails.push(obj);
        } else {
            let selectedItem: any;
            amountCollectionDetails.forEach((item: any, index: any) => {
                if (item['loanPayScheduleId'] == cell) {
                    selectedItem = item
                    selectedItem['amount'] = amount
                }
            });
            if (selectedItem != undefined) {
                selectedItem['amount'] = amount
            } else {
                var obj
                obj = {
                    loanPayScheduleId: cell,
                    salaryAdvancePayScheduleId: 0,
                    amount: amount,
                };
                amountCollectionDetails.push(obj);
            }
        }
        totalPaid()
    }

    function totalPaid() {
        let totalAmount1 = 0;
        for (let data of amountCollectionDetails) {
            let collectionAmount = Number(data.amount)
            totalAmount1 += collectionAmount;
        }
        setTotalCollectionAmount(totalAmount1);
        balanceAmountCal1(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total, totalAmount1);
    }

    function getLoanSchedule(loansDetailsId: any) {
        getLoanScheduleDetails(loansDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffLoanDetails;
                    let data1 = data[0].loanPaySchedule;
                    if (data != undefined) {
                        setStaffLoanData(data);
                        setLoanPayScheduleData(data1);
                        setStaffDetailsId(data[0].staffDetailsId)
                        getStaffProfile(data[0].staffDetailsId);
                        setLoanAmount(data[0].loanAmount)

                        let totalPaidAmount1 = 0;
                        let totalBalanceAmount1 = 0;
                        for (let data of data1) {
                            let paidAmount = Number(data.paidAmount)
                            let balanceAmount = Number(data.balanceAmount)
                            totalPaidAmount1 += paidAmount;
                            totalBalanceAmount1 += balanceAmount;
                        }

                        setTotalPaidAmount(totalPaidAmount1);
                        setTotalBalanceAmount(totalBalanceAmount1);
                        setDataSuccess(true);
                    } else {
                        toasts('Undefined Data', 'Error');
                        setDataSuccess(true);
                    }
                } else if (response.data.success === false) {
                    setStaffLoanData([]);
                    setDataSuccess(true);
                }
            },
            (error) => {
                toasts(error, 'Error');
                setDataSuccess(true);
            },
        );
    }

    function getStaffProfile(staffDetailsId: any) {
        getProfileForStaff(
            staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.profileForStaff;
                    if (data != undefined) {
                        setStaffProfileData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    const multiply500 = (e: any) => {
        const count500 = e.target.value;
        setFivehunderd_total(count500 * 500)
        multiply(count500 * 500, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total)
        balanceAmountCal(count500 * 500, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total)
    }

    const multiply200 = (e: any) => {
        const count200 = e.target.value;
        setTwoHundred_total(count200 * 200);
        multiply(fivehunderd_total, count200 * 200, hundred_total, fifty_total, twenty_total, ten_total, five_total)
        balanceAmountCal(fivehunderd_total, count200 * 200, hundred_total, fifty_total, twenty_total, ten_total, five_total)
    }

    const multiply100 = (e: any) => {
        const count100 = e.target.value;
        setHundred_total(count100 * 100);
        multiply(fivehunderd_total, twoHundred_total, count100 * 100, fifty_total, twenty_total, ten_total, five_total)
        balanceAmountCal(fivehunderd_total, twoHundred_total, count100 * 100, fifty_total, twenty_total, ten_total, five_total)
    }

    const multiply50 = (e: any) => {
        const count50 = e.target.value;
        setFifty_total(count50 * 50);
        multiply(fivehunderd_total, twoHundred_total, hundred_total, count50 * 50, twenty_total, ten_total, five_total)
        balanceAmountCal(fivehunderd_total, twoHundred_total, hundred_total, count50 * 50, twenty_total, ten_total, five_total)

    }

    const multiply20 = (e: any) => {
        const count20 = e.target.value;
        setTwenty_total(count20 * 20);
        multiply(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, count20 * 20, ten_total, five_total)
        balanceAmountCal(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, count20 * 20, ten_total, five_total)

    }

    const multiply10 = (e: any) => {
        const count10 = e.target.value;
        setTen_total(count10 * 10);
        multiply(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, count10 * 10, five_total)
        balanceAmountCal(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, count10 * 10, five_total)
    }

    const multiply5 = (e: any) => {
        const count5 = e.target.value;
        setFive_total(count5 * 5);
        multiply(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, count5 * 5)
        balanceAmountCal(fivehunderd_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, count5 * 5)
    }

    const multiply = (fivehunderd_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any) => {
        setTotalDenominationAmount(Number(fivehunderd_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total));
    }

    const balanceAmountCal = (fivehunderd_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any) => {
        setBalCashAmount(Number(fivehunderd_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total) - Number(totalCollectionAmount));
    }

    const balanceAmountCal1 = (fivehunderd_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any, totalCollectionAmount: any) => {
        setBalCashAmount(Number(fivehunderd_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total) - Number(totalCollectionAmount));
    }

    function setAmountDetails() {
        return ({

            staffDetailsId: staffDetailsId,
            screenId: 12,
            dateOfCollection: payFeesDenominationForm.values.dateOfCollection,
            amountCollectionDetails: amountCollectionDetails,
            paymentTypeId: paymentTypeId,
            twoThousand_total: Number(payFeesDenominationForm.values.twoThousand_total) != 0 ? Number(payFeesDenominationForm.values.twoThousand_total) : null,
            twoThousand_count: Number(payFeesDenominationForm.values.twoThousand_count) != 0 ? Number(payFeesDenominationForm.values.twoThousand_count) : null,
            thousand_total: Number(payFeesDenominationForm.values.thousand_total) != 0 ? Number(payFeesDenominationForm.values.thousand_total) : null,
            thousand_count: Number(payFeesDenominationForm.values.thousand_count) != 0 ? Number(payFeesDenominationForm.values.thousand_count) : null,
            fivehunderd_total: Number(fivehunderd_total) != 0 ? Number(fivehunderd_total) : null,
            fivehunderd_count: Number(payFeesDenominationForm.values.fivehunderd_count) != 0 ? Number(payFeesDenominationForm.values.fivehunderd_count) : null,
            twoHundred_total: Number(twoHundred_total) != 0 ? Number(twoHundred_total) : null,
            twoHundred_count: Number(payFeesDenominationForm.values.twoHundred_count) != 0 ? Number(payFeesDenominationForm.values.twoHundred_count) : null,
            hundred_total: Number(hundred_total) != 0 ? Number(hundred_total) : null,
            hundred_count: Number(payFeesDenominationForm.values.hundred_count) != 0 ? Number(payFeesDenominationForm.values.hundred_count) : null,
            fifty_total: Number(fifty_total) != 0 ? Number(fifty_total) : null,
            fifty_count: Number(payFeesDenominationForm.values.fifty_count) != 0 ? Number(payFeesDenominationForm.values.fifty_count) : null,
            twenty_total: Number(twenty_total) != 0 ? Number(twenty_total) : null,
            twenty_count: Number(payFeesDenominationForm.values.twenty_count) != 0 ? Number(payFeesDenominationForm.values.twenty_count) : null,
            ten_total: Number(ten_total) != 0 ? Number(ten_total) : null,
            ten_count: Number(payFeesDenominationForm.values.ten_count) != 0 ? Number(payFeesDenominationForm.values.ten_count) : null,
            five_total: Number(five_total) != 0 ? Number(five_total) : null,
            five_count: Number(payFeesDenominationForm.values.five_count) != 0 ? Number(payFeesDenominationForm.values.five_count) : null,
            totalAmount: Number(totalDenominationAmount),

            /// Cheque Payment ///
            chequeNumber: payFeesDenominationForm.values.chequeNumber != '' ? payFeesDenominationForm.values.chequeNumber : null,
            chequeDate: paymentTypeId == 2 ? payFeesDenominationForm.values.chequeDate : null,
            chequeAmount: Number(payFeesDenominationForm.values.chequeAmount) != 0 ? Number(payFeesDenominationForm.values.chequeAmount) : null,
            chequeBankName: payFeesDenominationForm.values.chequeBankName != '' ? payFeesDenominationForm.values.chequeBankName : null,

            /// Neft Payment ///
            neftRefNumber: payFeesDenominationForm.values.neftRefNumber != '' ? payFeesDenominationForm.values.neftRefNumber : null,
            neftTranDate: paymentTypeId == 3 ? payFeesDenominationForm.values.neftTranDate : null,
            neftAmount: Number(payFeesDenominationForm.values.neftAmount) != 0 ? Number(payFeesDenominationForm.values.neftAmount) : null,
            neftBankName: payFeesDenominationForm.values.neftBankName != '' ? payFeesDenominationForm.values.neftBankName : null,

            /// Card Payment ///
            cardNumber: payFeesDenominationForm.values.cardNumber != '' ? payFeesDenominationForm.values.cardNumber : null,
            cardDate: paymentTypeId == 4 ? payFeesDenominationForm.values.cardDate : null,
            cardAmount: Number(payFeesDenominationForm.values.cardAmount) != 0 ? Number(payFeesDenominationForm.values.cardAmount) : null,
            cardBankName: payFeesDenominationForm.values.cardBankName != '' ? payFeesDenominationForm.values.cardBankName : null,

            narration: payFeesDenominationForm.values.narration != '' ? payFeesDenominationForm.values.narration : null,
            balCashAmount: Number(balCashAmount) != 0 ? Number(balCashAmount) : null,
            amount: Number(totalCollectionAmount),
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function addAmountCollectionSubmit() {
        showLoader(true);
        if (payFeesDenominationForm.isValid) {
            let amountPostData = setAmountDetails()
            addAmountCollection(amountPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(`../loan/paySchedule/${loansDetailsId}`)
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )

        } else if (payFeesDenominationForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    function formReset() {
        payFeesDenominationForm.resetForm();
        setPaymentTypeId(0)
        setFivehunderd_total("")
        setTwoHundred_total("")
        setHundred_total("")
        setFifty_total("")
        setTwenty_total("")
        setTen_total("")
        setFive_total("")
        setTotalDenominationAmount("")
        setBalCashAmount("")
    }

    return (

        <PageWrapper title="Amount Collection">
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color='info'
                        isLink
                        icon='ArrowBack'
                        tag='a'
                        onClick={() => navigate(`../loan/paySchedule/${loansDetailsId}`)} >
                        Back to List
                    </Button>
                    <SubheaderSeparator />
                    {staffProfileData.map((staff: any) => (
                        <div key={staff.staffUserAccountId}>
                            <img
                                src={staff.profilePath != null ? staff.profilePath : profilePic(staff.genderId)}
                                height='40' width='40' className="rounded" onError={(e: any) => pictNotLoading(e, staff.genderId)}
                            />
                            <span>
                                <strong className='ms-1'>{staff.fullname} {" "}, </strong>
                            </span>
                            <span className='border border-success border-2 text-success fw-bold px-3 py-2 ms-1 rounded'>
                                {staff.empNumber}
                            </span>
                        </div>))}
                </SubHeaderLeft>
            </SubHeader>

            <Page container='fluid'>
                {!dataSuccess ?
                    <div className='col-lg-12 text-center'>
                        <TableLoader />
                    </div> :
                    <Card tag="form" noValidate onSubmit={payFeesDenominationForm.handleSubmit}>
                        <CardHeader>
                            <CardLabel icon='Task' iconColor='primary'>
                                <CardTitle tag='div' className='h5'>
                                    Loan Salary Summary
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='table-responsive'>
                                <table className='table table-modern table-hover text-center'>
                                    <thead>
                                        <tr className='table-primary'>
                                            <th>DATE OF DEDUCTION</th>
                                            <th>TOTAL AMOUNT</th>
                                            <th>TOTAL RECEIVED</th>
                                            <th>BALANCE AMOUNT</th>
                                            <th style={{ width: '15%' }}>AMOUNT </th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-center align-middle">
                                        {loanPayScheduleData.map((loanDetails: any) => (
                                            <tr key={loanDetails.loanPayScheduleId}>
                                                <td>{loanDetails.disbursementDateForView}</td>
                                                <td>{loanDetails.totalAmount}</td>
                                                <td className='text-success'>{loanDetails.paidAmount}</td>
                                                <td className='text-danger'>{loanDetails.balanceAmount}</td>
                                                {loanDetails.balanceAmount == 0 ? <td className='fs-6 fw-bold text-success'>PAID</td> :
                                                    <td>
                                                        <FormGroup id='amount'>
                                                            <Input className="text-center w-100" placeholder='0' value={loanDetails.amount} onInput={(e: any) => updateAmountReceivedValue(loanDetails.loanPayScheduleId, e.target.value)}
                                                                onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                        </FormGroup>
                                                    </td>}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <br />
                            </div>
                            <div className='row g-4'>
                                <div className='col-md-3'>
                                    <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                        }-warning bg-l${darkModeStatus ? 'o50' : '10'
                                        }-warning-hover transition-base rounded-2 mb-4`}
                                        shadow='sm'>
                                        <CardBody>
                                            <div className='d-flex align-items-center pb-3'>
                                                <div className='flex-shrink-0'>
                                                    <Icon
                                                        icon='LocalOffer'
                                                        size='4x'
                                                        color='warning'
                                                    />
                                                </div>
                                                <div className='flex-grow-1 ms-3'>
                                                    <div className='fw-bold fs-3 text-warning mb-0'>
                                                        ₹ {loanAmount}
                                                    </div>
                                                    <div className='text-muted'>
                                                        <b> Total Amount</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-md-3'>
                                    <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                        }-success bg-l${darkModeStatus ? 'o50' : '10'
                                        }-success-hover transition-base rounded-2 mb-4`}
                                        shadow='sm'>
                                        <CardBody>
                                            <div className='d-flex align-items-center pb-3'>
                                                <div className='flex-shrink-0'>
                                                    <Icon
                                                        icon='ConfirmationNumber'
                                                        size='4x'
                                                        color='success'
                                                    />
                                                </div>
                                                <div className='flex-grow-1 ms-3'>
                                                    <div className='fw-bold fs-3 text-success mb-0'>
                                                        ₹ {totalPaidAmount}
                                                    </div>
                                                    <div className='text-muted'>
                                                        <b> Paid amount</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-md-3'>
                                    <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                        }-info bg-l${darkModeStatus ? 'o50' : '10'
                                        }-info-hover transition-base rounded-2 mb-4`}
                                        shadow='sm'>
                                        <CardBody>
                                            <div className='d-flex align-items-center pb-3'>
                                                <div className='flex-shrink-0'>
                                                    <Icon
                                                        icon='PointOfSale'
                                                        size='4x'
                                                        color='info'
                                                    />
                                                </div>
                                                <div className='flex-grow-1 ms-3'>
                                                    <div className='fw-bold fs-3 text-info mb-0'>
                                                        ₹ {totalCollectionAmount}
                                                    </div>
                                                    <div className='text-muted'>
                                                        <b>collected Amount</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-md-3'>
                                    <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                        }-danger bg-l${darkModeStatus ? 'o50' : '10'
                                        }-danger-hover transition-base rounded-2 mb-4`}
                                        shadow='sm'>
                                        <CardBody>
                                            <div className='d-flex align-items-center pb-3'>
                                                <div className='flex-shrink-0'>
                                                    <Icon
                                                        icon='Report'
                                                        size='4x'
                                                        color='danger'
                                                    />
                                                </div>
                                                <div className='flex-grow-1 ms-3'>
                                                    <div className='fw-bold fs-3 text-danger mb-0'>
                                                        ₹ {totalBalanceAmount - totalCollectionAmount}
                                                    </div>
                                                    <div className='text-muted'>
                                                        <b>Balance to be paid</b>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </CardBody>

                        <CardBody>
                            <div className='row h-100 pb-3'>
                                <div className='col-lg-4 col-md-6'>
                                    <Card stretch>
                                        <CardHeader>
                                            <CardLabel icon='List'>
                                                <CardTitle tag='div' className='h5'>
                                                    Payment Denomination
                                                </CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className='row g-3'>
                                                <div className='col-12'>
                                                    <Button
                                                        icon='PendingActions'
                                                        color='info'
                                                        className='w-100 p-3'
                                                        isLight={TABS.CASH_PAYMENTS !== activeTab}
                                                        onClick={() => { setActiveTab(TABS.CASH_PAYMENTS); formReset(); setPaymentTypeId(1); }}>
                                                        {TABS.CASH_PAYMENTS}
                                                    </Button>
                                                </div>
                                                <div className='col-12'>
                                                    <Button
                                                        icon='LocalPolice'
                                                        color='info'
                                                        className='w-100 p-3'
                                                        isLight={TABS.CHEQUE !== activeTab}
                                                        onClick={() => { setActiveTab(TABS.CHEQUE); formReset(); setPaymentTypeId(2); }}>
                                                        {TABS.CHEQUE}
                                                    </Button>
                                                </div>
                                                <div className='col-12'>
                                                    <Button
                                                        icon='Style'
                                                        color='info'
                                                        className='w-100 p-3'
                                                        isLight={TABS.NEFT_UPI !== activeTab}
                                                        onClick={() => { setActiveTab(TABS.NEFT_UPI); formReset(); setPaymentTypeId(3); }}>
                                                        {TABS.NEFT_UPI}
                                                    </Button>
                                                </div>
                                                <div className='col-12'>
                                                    <Button
                                                        icon='CreditCard'
                                                        color='info'
                                                        className='w-100 p-3'
                                                        isLight={TABS.CARD_PAYMENT !== activeTab}
                                                        onClick={() => { setActiveTab(TABS.CARD_PAYMENT); formReset(); setPaymentTypeId(4); }}>
                                                        {TABS.CARD_PAYMENT}
                                                    </Button>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-lg-8 col-md-6'>
                                    {TABS.CASH_PAYMENTS === activeTab && (
                                        <Card stretch>
                                            <CardHeader>
                                                <CardLabel icon='PendingActions'>
                                                    <CardTitle tag='div' className='h5'>
                                                        Cash Denomination
                                                    </CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody>
                                                <div className='row'>
                                                    <div className="d-grid gap-4">
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-4 align-self-center h6">
                                                            </div>
                                                            <div className="col-lg-4 justify-content-center">
                                                                Count
                                                            </div>

                                                            <div className="col-lg-4 justify-content-center">
                                                                Amount
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-1 align-self-center h6">
                                                            </div>
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>500 X</p>
                                                            </div>
                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="fivehunderd_count" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.fivehunderd_count} onInput={multiply500} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="fivehunderd_total" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={fivehunderd_total} disabled />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-1 align-self-center h6">
                                                            </div>
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>200 X</p>
                                                            </div>
                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="twoHundred_total" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.twoHundred_total} onInput={multiply200} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="twoHundred_total" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={twoHundred_total} disabled />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-1 align-self-center h6">
                                                            </div>
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>100 X</p>
                                                            </div>
                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="hundred_count" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.hundred_count} onInput={multiply100} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="hundred_total" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={hundred_total} disabled />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-1 align-self-center h6">
                                                            </div>
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>50 X</p>
                                                            </div>
                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="fifty_count" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.fifty_count} onInput={multiply50} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="fifty_total" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={fifty_total} disabled />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-1 align-self-center h6">
                                                            </div>
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>20 X</p>
                                                            </div>
                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="twenty_count" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.twenty_count} onInput={multiply20} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="twenty_total" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={twenty_total} disabled />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-1 align-self-center h6">
                                                            </div>
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>10 X</p>
                                                            </div>
                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="ten_count" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.ten_count} onInput={multiply10} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="ten_total" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={ten_total} disabled />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-1 align-self-center h6">
                                                            </div>
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>5 X</p>
                                                            </div>
                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="five_count" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.five_count} onInput={multiply5} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                </FormGroup>
                                                            </div>

                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="five_total" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={five_total} disabled />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-1 align-self-center h6">
                                                            </div>
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p></p>
                                                            </div>
                                                            <div className="col-lg-4 mt-2 justify-content-center">
                                                                <p>Total Amount</p>
                                                            </div>
                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="totalDenominationAmount" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={totalDenominationAmount} disabled />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-1 align-self-center h6">
                                                            </div>
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p></p>
                                                            </div>
                                                            <div className="col-lg-4 mt-2 justify-content-center">
                                                                <p>Balance Cash Amount</p>
                                                            </div>
                                                            <div className="col-lg-4 justify-content-center">
                                                                <FormGroup id="balCashAmount" label="">
                                                                    <Input className={balCashAmount < 0 ? 'border border-danger' : ''} type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={balCashAmount} readOnly />
                                                                    <>
                                                                        {balCashAmount < 0 ?
                                                                            <div className='ms-2 text-danger'>
                                                                                Amount Not Matching{' '}<Icon icon='Report' color='danger' className='mx-1' size='lg' />
                                                                            </div> : null}
                                                                    </>
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    )}
                                    {TABS.CHEQUE === activeTab && (
                                        <Card stretch>
                                            <CardHeader>
                                                <CardLabel icon='LocalPolice'>
                                                    <CardTitle tag='div' className='h5'>
                                                        Cheque Details
                                                    </CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody>
                                                <div className='row'>
                                                    <div className="d-grid gap-4">
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>Cheque Number</p>
                                                            </div>
                                                            <div className="col-lg-5 justify-content-center">
                                                                <FormGroup id="chequeNumber" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.chequeNumber} />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>Amount</p>
                                                            </div>
                                                            <div className="col-lg-5 justify-content-center">
                                                                <FormGroup id="chequeAmount" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} onBlur={payFeesDenominationForm.handleBlur} value={payFeesDenominationForm.values.chequeAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                </FormGroup>
                                                                <>
                                                                    {totalCollectionAmount == Number(payFeesDenominationForm.values.chequeAmount) ? null : (
                                                                        <div className="ms-2 mt-2 text-danger">
                                                                            Amount Not Matching !{' '}
                                                                            <Icon icon="Report" color="danger" className="mx-1" size="lg" />
                                                                        </div>
                                                                    )}
                                                                </>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>Cheque Date</p>
                                                            </div>
                                                            <div className="col-lg-5 justify-content-center">
                                                                <FormGroup id="chequeDate" label="">
                                                                    <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.chequeDate} />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>Bank Name</p>
                                                            </div>
                                                            <div className="col-lg-5 justify-content-center">
                                                                <FormGroup id="chequeBankName" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.chequeBankName} />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    )}
                                    {TABS.NEFT_UPI === activeTab && (
                                        <Card stretch>
                                            <CardHeader>
                                                <CardLabel icon='Style' iconColor='primary'>
                                                    <CardTitle tag='div' className='h5'>
                                                        NEFT / UPI Details
                                                    </CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody>
                                                <div className='row'>
                                                    <div className="d-grid gap-4">
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>NEFT / UPI Ref Number</p>
                                                            </div>
                                                            <div className="col-lg-5 justify-content-center">
                                                                <FormGroup id="neftRefNumber" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftRefNumber} />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>Amount</p>
                                                            </div>
                                                            <div className="col-lg-5 justify-content-center">
                                                                <FormGroup id="neftAmount" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                </FormGroup>
                                                                <>
                                                                    {totalCollectionAmount == Number(payFeesDenominationForm.values.neftAmount) ? null : (
                                                                        <div className="ms-2 mt-2 text-danger">
                                                                            Amount Not Matching !{' '}
                                                                            <Icon icon="Report" color="danger" className="mx-1" size="lg" />
                                                                        </div>
                                                                    )}
                                                                </>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>Transaction Date</p>
                                                            </div>
                                                            <div className="col-lg-5 justify-content-center">
                                                                <FormGroup id="neftTranDate" label="">
                                                                    <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftTranDate} />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>Bank Name</p>
                                                            </div>
                                                            <div className="col-lg-5 justify-content-center">
                                                                <FormGroup id="neftBankName" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.neftBankName} />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    )}
                                    {TABS.CARD_PAYMENT === activeTab && (
                                        <Card stretch>
                                            <CardHeader>
                                                <CardLabel icon='CreditCard' iconColor='primary'>
                                                    <CardTitle tag='div' className='h5'>
                                                        Card Details
                                                    </CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody>
                                                <div className='row'>
                                                    <div className="d-grid gap-4">
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>Card Number</p>
                                                            </div>
                                                            <div className="col-lg-5 justify-content-center">
                                                                <FormGroup id="cardNumber" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardNumber} />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>Amount</p>
                                                            </div>
                                                            <div className="col-lg-5 justify-content-center">
                                                                <FormGroup id="cardAmount" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />
                                                                </FormGroup>
                                                                <>
                                                                    {totalCollectionAmount == Number(payFeesDenominationForm.values.cardAmount) ? null : (
                                                                        <div className="ms-2 mt-2 text-danger">
                                                                            Amount Not Matching !{' '}
                                                                            <Icon icon="Report" color="danger" className="mx-1" size="lg" />
                                                                        </div>
                                                                    )}
                                                                </>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>Transaction Date</p>
                                                            </div>
                                                            <div className="col-lg-5 justify-content-center">
                                                                <FormGroup id="cardDate" label="">
                                                                    <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardDate} />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-lg-3 mt-2 align-self-center h6">
                                                                <p>Bank Name</p>
                                                            </div>
                                                            <div className="col-lg-5 justify-content-center">
                                                                <FormGroup id="cardBankName" label="">
                                                                    <Input type="text" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.cardBankName} />
                                                                </FormGroup>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    )}
                                </div>
                            </div>
                        </CardBody>
                        <CardBody>
                            <div className='row h-100 pb-3'>
                                <div className="col-lg-3">
                                    <FormGroup id="dateOfCollection" label="Date Of Collection" isFloating>
                                        <Input type="date" placeholder="" onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.dateOfCollection} />
                                    </FormGroup>
                                </div>
                                <div className="col-lg-5">
                                    <FormGroup id="narration" label="Remarks" isFloating>
                                        <Textarea placeholder='Enter Remarks' onChange={payFeesDenominationForm.handleChange} value={payFeesDenominationForm.values.narration} />
                                    </FormGroup>
                                </div>
                                {paymentTypeId == 1 ?
                                    <div className="col-lg-3 mt-2">
                                        <Button color='info' icon='Save' type="submit" isDisable={totalDenominationAmount > 0 ? false : true} onClick={addAmountCollectionSubmit}>Save</Button>
                                    </div>
                                    : null}
                                {paymentTypeId == 2 ?
                                    <div className="col-lg-3 mt-2">
                                        <Button color='info' icon='Save' type="submit" isDisable={Number(totalCollectionAmount) == Number(payFeesDenominationForm.values.chequeAmount) ? true : false} onClick={addAmountCollectionSubmit}>Save</Button>
                                    </div>
                                    : null}

                                {paymentTypeId == 3 ?
                                    <div className="col-lg-3 mt-2">
                                        <Button color='info' icon='Save' type="submit" isDisable={Number(totalCollectionAmount) == Number(payFeesDenominationForm.values.neftAmount) ? true : false} onClick={addAmountCollectionSubmit}>Save</Button>
                                    </div>
                                    : null}

                                {paymentTypeId == 4 ?
                                    <div className="col-lg-3 mt-2">
                                        <Button color='info' icon='Save' type="submit" isDisable={Number(totalCollectionAmount) == Number(payFeesDenominationForm.values.cardAmount) ? true : false} onClick={addAmountCollectionSubmit}>Save</Button>
                                    </div>
                                    : null}
                            </div>
                        </CardBody>
                    </Card>
                }
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />
        </PageWrapper>
    );
};

export default PayLoan;