import React, { useEffect, useRef, useState } from 'react'
import FormGroup from '../bootstrap/forms/FormGroup'
import Card, { CardBody, CardFooter, CardFooterLeft, CardFooterRight, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Input from "../bootstrap/forms/Input";
import Checks from '../bootstrap/forms/Checks';
import Button from '../bootstrap/Button';
import Alert from '../bootstrap/Alert';
import Icon from '../icon/Icon';
import { useFormik } from 'formik';
import { getEarningDetailsList } from '../../services/salary.service';
import SubHeader, { SubHeaderLeft } from '../../layout/SubHeader/SubHeader';
import { useNavigate } from 'react-router-dom';
import { toasts } from '../../services/toast.service';

const editEarning = () => {

    useEffect(() => {
        getEarningDetails()
    }, []);

    const earningEditForm = useFormik({        
        initialValues: {
            calculationType: ''
        },

        validate: () => {
            const errors: {
                calculationType?: string;
            } = {};
            return errors;
        },

        onSubmit: () => { },
    });

    const [earningData, setEarningData] = useState<any>([]);
    const [earingId, setEarningId] = useState([]);
    const componentRef = useRef(null);
    const [dataSuccess, setDataSuccess] = useState(false)

    const navigate = useNavigate();

    function getEarningDetails() {        
        getEarningDetailsList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.earningDetailsList;
                    if (data != undefined) {
                        setEarningData(data[0]);
                        setDataSuccess(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            data[i].id = data[i].earningDetailsId.toString();
                        }
                        earningEditForm.setValues({
                            calculationType: data[0].calculationType
                        })
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const [doChecked, setDoChecked] = useState(false);
    function doCheck() {
        setDoChecked(!doChecked)
    }

    return (
        <PageWrapper>         
            <Page>
                <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                    <CardHeader borderSize={1}>
                        <CardLabel >
                            <CardTitle tag='div' className='h5'>Edit Earning</CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody >
                        <div className="row">
                            <div className="col-6">
                                <Card borderSize={1}>
                                    <CardBody>
                                        <div className="row g-3">
                                            <div className='col-12'>
                                                <div className='col-12'>
                                                    <FormGroup id='earningDetailsId' label='Earning Type'  >
                                                        <Input value={earningData.typeName} disabled />
                                                    </FormGroup>
                                                </div>
                                            </div>
                                            <div className='col-12 ms-2 '>
                                                <Alert isLight className='border-0' shadow='md' color='success' rounded={2}>
                                                    <span>
                                                        <Icon icon='Info' color='danger' size="lg" />
                                                        &nbsp;
                                                        Fixed amount paid at the end of every month.
                                                    </span>
                                                </Alert>

                                            </div>
                                            <div className='col-12'>
                                                <FormGroup id='earningDetailsId' label='Name in Payslip' >
                                                    <Input value={earningData.nameInPaySlip} />
                                                </FormGroup>
                                            </div>

                                            <div className="h6">Calculation Type <span className='text-danger'> *</span></div>
                                            <div className="col-12 mt-4">
                                                <Checks
                                                    type='radio'
                                                    name='calculationType'
                                                    id='calculationType'
                                                    label='Flat Amount'
                                                    value='amount'
                                                    onChange={earningEditForm.handleChange}
                                                    checked={earningEditForm.values.calculationType}
                                                />
                                            </div>
                                            <div className="col-12 mt-4">
                                                <Checks
                                                    type='radio'
                                                    name='calculationType'
                                                    id='calculationType1'
                                                    label='Percentage of CTC'
                                                    value='Percentage'
                                                    onChange={earningEditForm.handleChange}
                                                    checked={earningEditForm.values.calculationType}
                                                />
                                            </div>
                                            {earningEditForm.values?.calculationType == 'Percentage' ?
                                                <div className='col-12'>
                                                    <div className="input-group col-md-10 mt-2">
                                                        <FormGroup id='percentage' label='Enter Percentage' >
                                                            <Input className="form-control" value={earningData.percentage} />
                                                        </FormGroup>
                                                    </div>
                                                </div> :
                                                null}
                                            {earningEditForm.values?.calculationType == 'amount' ?
                                                <div className='col-12'>
                                                    <div className="input-group col-md-10 mt-2">
                                                        <FormGroup id='amount' label='Enter Amount' >
                                                            <Input className="form-control" value={earningData.amount} />
                                                        </FormGroup>
                                                    </div>
                                                </div> :
                                                null}

                                            <div className="col-12">
                                                <Checks
                                                    id='flexCheckDefault'
                                                    label='Mark this as Active'
                                                    name='defaultCheck'
                                                    onChange={doCheck}
                                                    checked={doChecked}
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                            <div className="col-6">
                                <Card borderSize={1}>
                                    <CardHeader borderSize={1}>
                                        <CardLabel >
                                            <CardTitle tag='div' className='h5'>Other Configurations</CardTitle>
                                        </CardLabel>
                                    </CardHeader>
                                    <CardBody>
                                        <div className="row g-3">
                                            <div className="col-12">
                                                <Checks
                                                    id='flexCheckDefault'
                                                    label='Make this earning a part of the employee’s salary structure'
                                                    name='defaultCheck'
                                                    onChange={doCheck}
                                                    checked={doChecked}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <Checks
                                                    id='flexCheckDefault'
                                                    label='This is a taxable earning
                                                    The income tax amount will be divided equally and deducted every month across the financial year.'
                                                    name='defaultCheck'
                                                    onChange={doCheck}
                                                    checked={doChecked}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <Checks
                                                    id='flexCheckDefault'
                                                    label='Calculate on pro-rata basis
                                                    Pay will be adjusted based on employee working days.'
                                                    name='defaultCheck'
                                                    onChange={doCheck}
                                                    checked={doChecked}
                                                />
                                            </div>
                                            <div className="h6">Consider for EPF Contribution</div>
                                            <div className="col-12">
                                                <Checks
                                                    id='flexCheckDefault'
                                                    label='Always.'
                                                    name='defaultCheck'
                                                    onChange={doCheck}
                                                    checked={doChecked}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <Checks
                                                    id='flexCheckDefault'
                                                    label='Only when EPF Wage is less than ₹ 15,000.'
                                                    name='isConsiderForEpfContribution'
                                                    onChange={doCheck}
                                                    checked={earningData.isConsiderForEpfContribution == 'Yes' ? true : false}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <Checks
                                                    id='flexCheckDefault'
                                                    label='Consider for ESI Contribution'
                                                    name='defaultCheck'
                                                    onChange={doCheck}
                                                    checked={earningData.isConsiderForEsiContribution == 'Yes' ? true : false}
                                                />
                                            </div>
                                            <div className="col-12">
                                                <Checks
                                                    id='flexCheckDefault'
                                                    label='Show this component in payslip'
                                                    name='defaultCheck'
                                                    onChange={doCheck}
                                                    checked={doChecked}
                                                />
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </div>
                        <div className='col-12 ms-2 '>
                            <Alert isLight className='border-0' shadow='md' color='danger' rounded={2}>
                                <span>
                                    <p ><strong>Note:</strong> Once you associate this component with an employee, you will only be able to edit the Name and Amount/Percentage. The changes you make to Amount/Percentage will apply only to new employees. </p>
                                </span>
                            </Alert>
                        </div>
                        <CardFooter borderSize={1}>
                            <CardFooterLeft>
                                <Button
                                    color='info'
                                    type="submit"
                                // isDisable={!employeesProvidentFundForm.isValid && !employeesProvidentFundForm.submitCount}
                                >
                                    Save
                                </Button>
                                <Button
                                    isOutline
                                    color='info'
                                    isLight
                                    onClick={() => navigate(-1)}>
                                    Cancel
                                </Button>
                            </CardFooterLeft>
                            <CardFooterRight>
                                <div className=''>
                                    <p className='text-danger'>* indicates mandatory fields</p>
                                </div>
                            </CardFooterRight>
                        </CardFooter>
                        {/* <div className='col-12 ms-2 '>
                            <Button color='info' type="submit" >
                                Save</Button>&nbsp;&nbsp;
                            <Button isOutline color='info' isLight >Cancel</Button>
                            <div className='col-12 text-end p-3 '>
                                <p className='text-danger'>* indicates mandatory fields</p>
                            </div>
                        </div> */}
                    </CardBody>
                </Card>
            </Page>
        </PageWrapper >
    )
}

export default editEarning