import { useContext, useEffect, useState } from "react";
import { ITodoListItem } from "../../components/extras/Todo";
import AuthContext from "../../contexts/authContext";
import { checkDataAvailableOrNot } from "../../services/common.service";
import { toasts } from "../../services/toast.service";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from "../../components/bootstrap/Card";
import Progress from "../../components/bootstrap/Progress";
import Timeline, { TimelineItem } from "../../components/extras/Timeline";
import Button from "../../components/bootstrap/Button";


const LetsStarted = () => {
    useEffect(() => {
        checkDataAvailable(organisationDetailsId)
    }, []);

    const [tableDataStatus, setTableDataStatus] = useState<any>([]);
    const [list, setList] = useState<ITodoListItem[]>([]);
    const { organisationDetailsId } = useContext(AuthContext);


    function checkDataAvailable(organisationDetailsId: any) {
        checkDataAvailableOrNot(organisationDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.dataAvailableOrNot;
                    if (data != undefined) {
                        setTableDataStatus(data)
                        let list = [{
                            id: 1,
                            status: data[0].getOrganisationDetailsDataCount,
                        },
                        {
                            id: 2,
                            status: data[0].getDepartmentDataCount,
                        },
                        {
                            id: 3,
                            status: data[0].getSalaryComponentDataCount,
                        },
                        {
                            id: 4,
                            status: data[0].getDesignationDataCount,
                        },
                        {
                            id: 5,
                            status: data[0].getSalaryTemplateDataCount,
                        },
                        {
                            id: 6,
                            status: data[0].getWorkLocationsDataCount,
                        },
                        {
                            id: 7,
                            status: data[0].getUserTypeDataCount,
                        },
                        ]
                        setList(list)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const listLength = list.length;
    const completeTaskLength = list.filter((i) => i.status == true).length;

    return (
        <PageWrapper title="Following steps to have">
            <Page container='fluid'>
                <div className="row">
                    <div className="col-1"></div>
                    <div className="col-10">
                        <Card stretch>
                            <CardHeader>
                                <CardLabel icon='PlaylistAddCheck' iconColor='warning'>
                                    <CardTitle tag='div' className='h5 '>
                                        Get started with <span className='text-danger'>Payroll</span> !
                                    </CardTitle>
                                    <CardSubTitle tag='div'>
                                        Complete the following steps to Payroll
                                        <div className='mt-1'>
                                            <Progress
                                                isAnimated
                                                height={8}
                                                max={listLength}
                                                value={completeTaskLength}
                                                color={completeTaskLength === listLength ? 'primary' : 'danger'}
                                            />
                                        </div>
                                    </CardSubTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                {tableDataStatus.map((data: any, index: any) => (
                                    <Timeline key={index}>
                                        <div className="row">
                                            <div className="col-8">
                                                <TimelineItem label='' color='warning'>
                                                    Organisation Details
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getOrganisationDetailsDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../organisation/organisationDetails`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../organisation/organisationDetails`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>
                                            <div className="col-8">
                                                <TimelineItem label='' color='primary'>
                                                    Work Locations
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getWorkLocationsDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../organisation/workLocations`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../organisation/workLocations`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>
                                            <div className="col-8">
                                                <TimelineItem label='' color='danger'>
                                                    Salary Components
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getSalaryComponentDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../salary/salaryComponents`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../salary/salaryComponents`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>
                                            <div className="col-8">
                                                <TimelineItem label='' color='warning'>
                                                    User Type
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getUserTypeDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../userManagement/userAndRolesList`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../userManagement/userAndRolesList`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>
                                            <div className="col-8">
                                                <TimelineItem label='' color='success'>
                                                    Salary Template
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getSalaryTemplateDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../salary/salaryTemplates`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../salary/salaryTemplates`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>
                                            <div className="col-8">
                                                <TimelineItem label='' color='secondary'>
                                                    Designation
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getDesignationDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../settings/designationList`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../settings/designationList`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>
                                            <div className="col-8">
                                                <TimelineItem label='' color='warning'>
                                                    Department
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getDepartmentDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../settings/departmentList`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../settings/departmentList`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>
                                            <div className="col-8">
                                                <TimelineItem label='' color='primary'>
                                                    Working Days
                                                </TimelineItem>
                                            </div>
                                            <div className="col-2">
                                                {data.getWorkingDayDataCount ?
                                                    <Button
                                                        tag='a'
                                                        to={`../paySchedule/workingDays`}
                                                        size='sm'
                                                        isLink
                                                        color='success'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Completed
                                                    </Button> :
                                                    <Button
                                                        tag='a'
                                                        to={`../paySchedule/workingDays`}
                                                        size='sm'
                                                        isLink
                                                        color='danger'
                                                        icon='Report'
                                                        className='text-nowrap'>
                                                        Pending
                                                    </Button>
                                                }
                                            </div>
                                        </div>
                                    </Timeline>
                                ))}
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-1"></div>
                </div>

            </Page>
        </PageWrapper>
    );
};

export default LetsStarted;