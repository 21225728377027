import { useContext, useRef, useState } from 'react';
import AuthContext from '../../../contexts/authContext';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Collapse from '../../bootstrap/Collapse';
import Card, {
	CardActions,
	CardBody,
	CardHeader,
	CardLabel,
	CardTitle,
} from '../../bootstrap/Card';
import { useFormik } from 'formik';
import useDarkMode from '../../../hooks/useDarkMode';
import useSortableData from '../../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import {
	convertDateToEpoch,
	getColumnsForDataTable,
	getCurrentDateAndTime,
	updateColumnsForDataTable,
	updateFilter,
} from '../../../services/common.service';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import OpenCardComponent from '../../../common/components/OpenCardComponents';
import { toasts } from '../../../services/toast.service';
import Icon from '../../icon/Icon';
import NoDataMsg from '../../../common/components/NoDataMsg';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import { getLicenseKey } from '../../../services/application.settings';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/export.service';
import { useReactToPrint } from 'react-to-print';
import { getStaffAttendanceReportList } from '../../../services/attendance.service';

const TodayAbsenteesReport = () => {
	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [staffDetailsData, setStaffDetailsData] = useState([]);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(1000);
	const { items, requestSort, getClassNamesFor } = useSortableData(staffDetailsData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [isLoader, setIsLoader] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');

	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);

	const studentAttendanceForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			dateOfAttendance: getCurrentDateAndTime('date'),
		},
		validate: (values) => {
			const errors: {
				dateOfAttendance?: string;
			} = {};

			if (!values.dateOfAttendance) {
				errors.dateOfAttendance = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => {
			viewStaffAttendance();
		},
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => {},
		//validateOnChange: false,
		onSubmit: () => {},
	});

	// Filter
	const filteredData = updateFilter(
		onCurrentPageData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	function viewStaffAttendance() {
		setDataSuccess(false);
		setIsLoader(true);
		let dateInMilliSeconds = convertDateToEpoch(studentAttendanceForm.values.dateOfAttendance);
		getStaffAttendanceReport(dateInMilliSeconds);
	}

	function getStaffAttendanceReport(dateOfAttendance: any) {
		// getStaffAttendanceReportList(
		// 	dateOfAttendance,
		// 	(response) => {
		// 		if (response.data.success) {
		// 			let data = response.data.data.staffAttendanceReportList;
		// 			if (data != undefined) {
		// 				getColumnsForTable('getStaffAttendanceReportList', 'get');
		// 				setStaffDetailsData(data);
		// 				setIsLoader(false);
		// 				setIsOpenListCard(false);
		// 				setDataSuccess(true);
		// 				for (let i = 0; i < data.length; i++) {
		// 					data[i].sno = i + 1;
		// 				}
		// 			} else {
		// 				setDataSuccess(false);
		// 				setIsOpenListCard(true);
		// 				setIsLoader(false);
		// 				toasts('Undefined Data', 'Error');
		// 			}
		// 		} else if (response.data.success === false) {
		// 			getColumnsForTable('getStaffAttendanceReportList', 'get');
		// 			setDataSuccess(true);
		// 			setStaffDetailsData([]);
		// 			setIsOpenListCard(false);
		// 			setIsLoader(false);
		// 			setNoDataMsg(response.data.message);
		// 		} else {
		// 			setDataSuccess(false);
		// 			setIsOpenListCard(true);
		// 			setIsLoader(false);
		// 			let errorCode = response.data.error[0].error_code;
		// 			let errorDescription = response.data.error[0].error_description;
		// 			toasts(errorDescription, 'Error');
		// 		}
		// 	},
		// 	(error) => {
		// 		setIsLoader(false);
		// 		toasts(error, 'Error');
		// 	},
		// );
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
							{
								columnVisibilityId: columnVisibilityId,
								isDisplay: isDisplay ? 0 : 1,
							},
						],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getStaffAttendanceReportList', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	// Export Configuration
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper title='Attendance'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card
						stretch
						data-tour='list'
						tag='form'
						noValidate
						onSubmit={studentAttendanceForm.handleSubmit}>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Staff Attendance Report
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row g-4'>
								<div className='col-3'>
									<FormGroup id='dateOfAttendance' label='Date' isFloating>
										<Input
											onChange={studentAttendanceForm.handleChange}
											value={studentAttendanceForm.values.dateOfAttendance}
											onBlur={studentAttendanceForm.handleBlur}
											isValid={studentAttendanceForm.isValid}
											isTouched={
												studentAttendanceForm.touched.dateOfAttendance
											}
											invalidFeedback={
												studentAttendanceForm.errors.dateOfAttendance
											}
											type='date'
										/>
									</FormGroup>
								</div>

								<div className='col-3'>
									<Button
										className='mt-2'
										icon='ArrowDownward'
										color='primary'
										type='submit'
										isDisable={
											!studentAttendanceForm.isValid &&
											!!studentAttendanceForm.submitCount
										}>
										View
									</Button>
								</div>
							</div>
						</CardBody>
					</Card>
				</Collapse>

				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>

				{dataSuccess ? (
					<Card stretch data-tour='list' ref={componentRef}>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-6'>
								<CardTitle tag='div' className='h5'>
									StaffAttendanceList
								</CardTitle>
							</CardLabel>
							&nbsp;&nbsp;
							<ButtonGroup className='col-lg-3 d-print-none' color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>
							<CardActions className='d-print-none'>
								<Dropdown
									isOpen={columnVisibilityMenu}
									setIsOpen={setColumnVisibilityMenu}
									isButtonGroup>
									<DropdownToggle>
										<Button icon='FilterAlt' color='primary' isLight>
											Filter
										</Button>
									</DropdownToggle>
									<DropdownMenu
										isAlignmentEnd
										size='lg'
										isCloseAfterLeave={false}>
										<DropdownItem>
											<div className='container py-2'>
												<form className='row g-3'>
													<div className='col-12'>
														<FormGroup>
															<h6>Select All</h6>
															<Checks
																id='available'
																type='switch'
																label='Select All Columns'
																onChange={() =>
																	getColumnsForTable(
																		'getStaffAttendanceReportList',
																		'post',
																	)
																}
																checked={isChecked}
																ariaLabel='Available status'
															/>
														</FormGroup>
													</div>
													<div
														className='col-12'
														style={{
															maxHeight: '200px',
															overflowY: 'scroll',
														}}>
														<FormGroup>
															<h6>Columns</h6>
															<ChecksGroup>
																{allColumnsData.map((i: any) => (
																	<Checks
																		key={i.columnVisibilityId}
																		label={i.columnName}
																		onChange={() =>
																			updateColumnsSubmit(
																				i.columnVisibilityId,
																				i.isDisplay,
																				'',
																			)
																		}
																		checked={i.isDisplay}
																		disabled={
																			i.isDefault == true
																		}
																	/>
																))}
															</ChecksGroup>
														</FormGroup>
													</div>
												</form>
											</div>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'>
											{' '}
											Export{' '}
										</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadExcel(
														'StaffAttendanceList',
														staffDetailsData,
														columnVisibilityData,
													)
												}>
												{' '}
												Excel{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='PictureAsPdf'
												onClick={() =>
													convertJsonToPdf(
														staffDetailsData,
														columnVisibilityData,
														'StaffAttendanceList',
													)
												}>
												{' '}
												PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadFile(
														'StaffAttendanceList',
														staffDetailsData,
														columnVisibilityData,
													)
												}>
												{' '}
												CSV{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='Print'
												onClick={handlePrint}>
												{' '}
												Print{' '}
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</CardActions>
						</CardHeader>

						<CardBody className='table-responsive' isScrollable>
							<table className='table table-modern table-hover text-nowrap'>
								<thead>
									<tr>
										<th
											scope='col'
											onClick={() => requestSort('sno')}
											className='cursor-pointer text-decoration-underline'>
											S.No{''}
											<Icon
												size='lg'
												className={getClassNamesFor('sno')}
												icon='FilterList'
											/>
										</th>
										{columnVisibilityData.map((column: any) => (
											<th
												key={column.keyName}
												scope='col'
												onClick={() => requestSort(column.keyName)}
												className='cursor-pointer text-decoration-underline'>
												{column.columnName}
												<Icon
													size='lg'
													className={getClassNamesFor(column.keyName)}
													icon='FilterList'
												/>
											</th>
										))}
										<td className='d-print-none' />
									</tr>
								</thead>
								<tbody>
									{filteredData != '' ? (
										<>
											{filteredData.map((rowData: any) => (
												<tr key={rowData.staffDetailsId}>
													<td>{rowData.sno}</td>
													{columnVisibilityData.map((column: any) =>
														column.isDisplay &&
														rowData[column.keyName] ? (
															<td
																className='text-nowrap'
																key={`${rowData.staffDetailsId}-${column.keyName}`}>
																{rowData[column.keyName]}
															</td>
														) : (
															<td key={`empty-${column.keyName}`} />
														),
													)}
												</tr>
											))}
										</>
									) : (
										<NoDataMsg
											columnsCount={columnVisibilityData.length + 2}
											msg={noDataMsg}
										/>
									)}
								</tbody>
							</table>
						</CardBody>

						<PaginationButtons
							className='d-print-none'
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>
				) : null}
			</Page>
		</PageWrapper>
	);
};

export default TodayAbsenteesReport;
