import { useContext, useEffect, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import useDarkMode from '../../hooks/useDarkMode';
import useSortableData from '../../hooks/useSortableData';
import Button, { ButtonGroup } from '../bootstrap/Button';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import FormGroup from '../bootstrap/forms/FormGroup';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas';
import { useFormik } from 'formik';
import Textarea from '../bootstrap/forms/Textarea';
import AlertService from '../../services/AlertService';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../services/export.service';
import { TableLoader, showLoader } from '../../services/loader.services';
import AuthContext from '../../contexts/authContext';
import PaginationButtons, { dataPagination } from '../PaginationButtons';
import {
	getColumnsForDataTable,
	getCurrentDateAndTime,
	getStatus,
	onlyAllowNumber,
	updateColumnsForDataTable,
	updateFilter,
} from '../../services/common.service';
import NoDataMsg from '../../common/components/NoDataMsg';
import { getLicenseKey } from '../../services/application.settings';
import { toasts } from '../../services/toast.service';
import SearchableSelect from '../../common/components/SearchableSelect';
import Alert from '../bootstrap/Alert';
import {
	addLoanRequestType,
	addStaffLoanDetails,
	deleteStaffLoansDetails,
	getLoanRequestHistory,
	getLoanRequestType,
	getStaffLoanDetails,
	updateStaffLoanDetails,
} from '../../services/loans.service';
import DeleteComponents from '../../common/components/DeleteComponents';
import Collapse from '../bootstrap/Collapse';
import OpenCardComponent from '../../common/components/OpenCardComponents';
import { getUserType } from '../../services/userType.service';
import { getStaffListByUserTypeId } from '../../services/staff.service';
import dayjs from 'dayjs';
import Modal, { ModalHeader, ModalTitle, ModalBody } from '../bootstrap/Modal';
import { useNavigate } from 'react-router-dom';

function LoansList() {

	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		getUserTypeList();

		if (userTypeId != 1) {
			setIsLoader(true);
			setDataStatus(true);
			getStaffLoan(userTypeId, userAccountId)
		}
	}, []);

	const TABS = {
		CASH_PAYMENTS: 'Cash',
		CHEQUE: 'Cheque',
		NEFT_UPI: 'NEFT / UPI',
		CARD_PAYMENT: 'Card',
	};

	const [activeTab, setActiveTab] = useState(TABS.CASH_PAYMENTS);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const componentRef = useRef(null);
	const [isChecked, setIsChecked] = useState(false);
	const [staffLoanData, setStaffLoanData] = useState<any>([]);
	const [loanRequestTypeData, setLoanRequestTypeData] = useState<any>([]);
	const [staffDetailsId, setStaffDetailsId] = useState<any>('');
	const [staffDetailsIdForAdd, setStaffDetailsIdForAdd] = useState<any>('');
	const [staffDetailsData, setStaffDetailsData] = useState<any>([]);
	const [staffListData, setStaffListData] = useState<any>([]);
	const [approvalStaffListData, setApprovalStaffListData] = useState<any>([]);
	const [userTypeData, setUserTypeData] = useState<any>([]);
	const [staffTypeId, setStaffTypeId] = useState<any>({ value: 0, label: 'Select All' });

	// For Table and offCanvas
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(50);
	const { items, requestSort, getClassNamesFor } = useSortableData(staffLoanData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [addLoanOffCanvas, setAddLoanOffCanvas] = useState(false);
	const [editLoanOffCanvas, setEditLoanOffCanvas] = useState(false);
	const [addLoanTypeOffCanvas, setAddLoanTypeOffCanvas] = useState(false);
	const [recordPaymentOffCanvas, setRecordPaymentOffCanvas] = useState(false);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [dataStatus, setDataStatus] = useState(false);

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [columnDataById, setColumnDataById] = useState([]);
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [approvalStaff, setApprovalStaff] = useState<any>('')

	const [noDataMsg, setNoDataMsg] = useState('');
	const [loanRequestTypeId, setLoanRequestTypeId] = useState<any>('');
	const [statusId, setStatusId] = useState<any>('')
	const [statusData, setStatusData] = useState<any>([]);
	const [statusModal, setStatusModal] = useState(false);
	const [staffRequestHistoryData, setStaffRequestHistoryData] = useState([]);
	const navigate = useNavigate();

	const [isDisposal, setIsDisposal] = useState<any>('')
	const [disposalModal, setDisposalModal] = useState(false)

	const [paymentTypeId, setPaymentTypeId] = useState<any>("")
	const [fiveHundred_total, setFiveHundred_total] = useState<any>("")
	const [twoHundred_total, setTwoHundred_total] = useState<any>("")
	const [hundred_total, setHundred_total] = useState<any>("")
	const [fifty_total, setFifty_total] = useState<any>("")
	const [twenty_total, setTwenty_total] = useState<any>("")
	const [ten_total, setTen_total] = useState<any>("")
	const [five_total, setFive_total] = useState<any>("")
	const [totalAmount, setTotalAmount] = useState<any>("")
	const [balCashAmount, setBalCashAmount] = useState<any>("")
	const [loanTotalAmount, setLoanTotalAmount] = useState<any>(0)

	const addLoanForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			loanRequestTypeId: '',
			staffDetailsId: '',
			loanAmount: 0,
			disbursementDate: getCurrentDateAndTime('date'),
			reason: '',
			EMIDeductionDate: '',
			deductionMonth: 0,
			approvalStaff: '',
			staffDetailsIdForAdd: '',
		},
		validate: (values) => {
			const errors: {
				loanRequestTypeId?: string;
				staffDetailsId?: string;
				loanAmount?: string;
				disbursementDate?: string;
				reason?: string;
				EMIDeductionDate?: string;
				deductionMonth?: string;
				approvalStaff?: string;
				staffDetailsIdForAdd?: string;
			} = {};

			if (!loanRequestTypeId) {
				errors.loanRequestTypeId = 'Required';
			}
			if (userTypeId == 1) {
				if (!staffDetailsIdForAdd) {
					errors.staffDetailsId = 'Required';
				}
			}
			if (!values.loanAmount) {
				errors.loanAmount = 'Required';
			}
			if (!values.disbursementDate) {
				errors.disbursementDate = 'Required';
			}
			if (!values.reason) {
				errors.reason = 'Required';
			}
			if (!values.EMIDeductionDate) {
				errors.EMIDeductionDate = 'Required';
			}
			if (!values.deductionMonth) {
				errors.deductionMonth = 'Required';
			}
			if (!approvalStaff) {
				errors.approvalStaff = 'Required';
			}

			return errors;
		},
		validateOnChange: false,
		onSubmit: () => {
			addStaffLoanSubmit();
		},
	});

	const editLoanForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			loanRequestTypeId: '',
			staffDetailsId: '',
			loanAmount: 0,
			disbursementDate: getCurrentDateAndTime('date'),
			reason: '',
			EMIDeductionDate: '',
			deductionMonth: 0,
			loansDetailsId: 0,
			loanNumber: '',
			approvalStaff: '',
			statusId: '',
			remarks: '',

		},
		validate: (values) => {
			const errors: {
				loanRequestTypeId?: string;
				staffDetailsId?: string;
				loanAmount?: string;
				disbursementDate?: string;
				EMIDeductionDate?: string;
				deductionMonth?: string;
				approvalStaff?: string;
				statusId?: string;
				reason?: string;
			} = {};

			if (!loanRequestTypeId) {
				errors.loanRequestTypeId = 'Required';
			}
			if (userTypeId == 1) {
				if (!staffDetailsIdForAdd) {
					errors.staffDetailsId = 'Required';
				}
			}
			if (!values.loanAmount) {
				errors.loanAmount = 'Required';
			}
			if (!values.disbursementDate) {
				errors.disbursementDate = 'Required';
			}
			if (!values.EMIDeductionDate) {
				errors.EMIDeductionDate = 'Required';
			}
			if (!values.deductionMonth) {
				errors.deductionMonth = 'Required';
			}
			if (!values.reason) {
				errors.reason = 'Required';
			}
			if (!approvalStaff) {
				errors.approvalStaff = 'Required';
			}
			if (!statusId) {
				errors.statusId = 'Required';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: () => {
			updateStaffLoansSubmit();
		},
	});

	const addLoanTypeForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			loanRequestName: '',
			perquisiteRate: '',
		},
		validate: (values) => {
			const errors: {
				loanRequestName?: string;
				perquisiteRate?: string;
			} = {};
			if (!values.loanRequestName) {
				errors.loanRequestName = 'Required';
			}
			if (!values.perquisiteRate) {
				errors.perquisiteRate = 'Required';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: () => {
			addLoanRequestTypeSubmit();
		},
	});

	const editRepaymentForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			repaymentAmount: '',
			repaymentDate: '',
			paymentMode: '',
			remainingAmount: '',
		},
		validate: (values) => {
			const errors: {
				repaymentAmount?: string;
				repaymentDate?: string;
				paymentMode?: string;
				remainingAmount?: string;
			} = {};
			if (!values.repaymentAmount) {
				errors.repaymentAmount = 'Required';
			}
			if (!values.repaymentDate) {
				errors.repaymentDate = 'Required';
			}
			if (!values.paymentMode) {
				errors.paymentMode = 'Required';
			}
			if (!values.remainingAmount) {
				errors.remainingAmount = 'Required';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: () => {
			;
		},
	});

	const payLoanAmountDenominationForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			paymentTypeId: '',
			twoThousand_total: '',
			twoThousand_count: '',
			thousand_total: '',
			thousand_count: '',
			fiveHundred_total: '',
			fiveHundred_count: '',
			twoHundred_total: '',
			twoHundred_count: '',
			hundred_total: '',
			hundred_count: '',
			fifty_total: '',
			fifty_count: '',
			twenty_total: '',
			twenty_count: '',
			ten_total: '',
			ten_count: '',
			five_total: '',
			five_count: '',
			totalAmount: '',
			chequeNumber: '',
			chequeDate: getCurrentDateAndTime('date'),
			chequeAmount: '',
			chequeBankName: '',
			neftRefNumber: '',
			neftTranDate: getCurrentDateAndTime('date'),
			neftAmount: '',
			neftBankName: '',
			cardNumber: '',
			cardDate: getCurrentDateAndTime('date'),
			cardAmount: '',
			cardBankName: '',
			balCashAmount: '',
			narration: ''
		},
		// validate: (values) => {
		// },
		//validateOnChange: false,
		onSubmit: () => { updateStaffLoansSubmit() },
	});

	//search function
	const columnVisibleForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		validateOnChange: false,
		onSubmit: () => { },
	});

	function onActivate(i: any, modal: any) {
		if (i != undefined) {
			let selectedLoan = i;

			if (modal == 'edit') {
				setEditLoanOffCanvas(true)
				setIsDisposal(0)
			} else {
				setDisposalModal(true)
				setIsDisposal(1)
			}

			setLoanRequestTypeId(selectedLoan.loanRequestTypeId != 0 ? { value: selectedLoan.loanRequestTypeId, label: selectedLoan.loanRequestName } : '');
			setStaffDetailsIdForAdd(selectedLoan.staffDetailsIdForAdd != 0 ? { value: selectedLoan.staffDetailsId, label: selectedLoan.staffName } : '');
			setStatusId({ value: selectedLoan.statusId, label: selectedLoan.statusName });
			setApprovalStaff({ value: selectedLoan.assignedBy, label: selectedLoan.assignedStaff });
			setLoanTotalAmount(selectedLoan.loanAmount);

			editLoanForm.setValues({

				approvalStaff: selectedLoan.assignedBy,
				statusId: selectedLoan.statusId,
				remarks: '',
				loanRequestTypeId: selectedLoan.loanRequestTypeId,
				staffDetailsId: selectedLoan.staffDetailsId,
				loanAmount: selectedLoan.loanAmount,
				disbursementDate: selectedLoan.disbursementDate,
				reason: selectedLoan.reason,
				EMIDeductionDate: selectedLoan.EMIDeductionDate,
				deductionMonth: selectedLoan.deductionMonth,
				loansDetailsId: selectedLoan.loansDetailsId,
				loanNumber: selectedLoan.loanNumber,
			});

			getLoanRequestTypeList(0);
			getStaffList(0);
			getStatusList();
		}
	}

	// Filter
	const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibleForm);

	function setStaffLoanDetails() {

		return {
			loanRequestTypeId: loanRequestTypeId?.value,
			assignedBy: approvalStaff?.value,
			staffDetailsId: userTypeId != 1 ? userAccountId : staffDetailsIdForAdd?.value,
			loanAmount: addLoanForm.values.loanAmount,
			disbursementDate: addLoanForm.values.disbursementDate,
			reason: addLoanForm.values.reason,
			EMIDeductionDate: addLoanForm.values.EMIDeductionDate,
			deductionMonth: addLoanForm.values.deductionMonth,
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
		};
	}

	function setUpdateStaffLoansDetails() {

		return {
			loansDetailsId: editLoanForm.values.loansDetailsId,
			assignedBy: approvalStaff?.value,
			statusId: statusId?.value,
			remarks: editLoanForm.values.remarks,
			loanNumber: editLoanForm.values.loanNumber,
			loanRequestTypeId: loanRequestTypeId?.value,
			staffDetailsId: staffDetailsIdForAdd?.value,
			loanAmount: editLoanForm.values.loanAmount,
			disbursementDate: editLoanForm.values.disbursementDate,
			reason: editLoanForm.values.reason,
			EMIDeductionDate: editLoanForm.values.EMIDeductionDate,
			deductionMonth: editLoanForm.values.deductionMonth,
			isDisposal: isDisposal,

			/// Disposal Denomination 
			paymentTypeId: paymentTypeId == 0 ? 1 : paymentTypeId,
			twoThousand_total: Number(payLoanAmountDenominationForm.values.twoThousand_total) != 0 ? Number(payLoanAmountDenominationForm.values.twoThousand_total) : null,
			twoThousand_count: Number(payLoanAmountDenominationForm.values.twoThousand_count) != 0 ? Number(payLoanAmountDenominationForm.values.twoThousand_count) : null,
			thousand_total: Number(payLoanAmountDenominationForm.values.thousand_total) != 0 ? Number(payLoanAmountDenominationForm.values.thousand_total) : null,
			thousand_count: Number(payLoanAmountDenominationForm.values.thousand_count) != 0 ? Number(payLoanAmountDenominationForm.values.thousand_count) : null,
			fivehunderd_total: Number(fiveHundred_total) != 0 ? Number(fiveHundred_total) : null,
			fivehunderd_count: Number(payLoanAmountDenominationForm.values.fiveHundred_count) != 0 ? Number(payLoanAmountDenominationForm.values.fiveHundred_count) : null,
			twoHundred_total: Number(twoHundred_total) != 0 ? Number(twoHundred_total) : null,
			twoHundred_count: Number(payLoanAmountDenominationForm.values.twoHundred_count) != 0 ? Number(payLoanAmountDenominationForm.values.twoHundred_count) : null,
			hundred_total: Number(hundred_total) != 0 ? Number(hundred_total) : null,
			hundred_count: Number(payLoanAmountDenominationForm.values.hundred_count) != 0 ? Number(payLoanAmountDenominationForm.values.hundred_count) : null,
			fifty_total: Number(fifty_total) != 0 ? Number(fifty_total) : null,
			fifty_count: Number(payLoanAmountDenominationForm.values.fifty_count) != 0 ? Number(payLoanAmountDenominationForm.values.fifty_count) : null,
			twenty_total: Number(twenty_total) != 0 ? Number(twenty_total) : null,
			twenty_count: Number(payLoanAmountDenominationForm.values.twenty_count) != 0 ? Number(payLoanAmountDenominationForm.values.twenty_count) : null,
			ten_total: Number(ten_total) != 0 ? Number(ten_total) : null,
			ten_count: Number(payLoanAmountDenominationForm.values.ten_count) != 0 ? Number(payLoanAmountDenominationForm.values.ten_count) : null,
			five_total: Number(five_total) != 0 ? Number(five_total) : null,
			five_count: Number(payLoanAmountDenominationForm.values.five_count) != 0 ? Number(payLoanAmountDenominationForm.values.five_count) : null,
			totalAmount: Number(loanTotalAmount),

			/// Cheque Payment ///
			chequeNumber: payLoanAmountDenominationForm.values.chequeNumber != '' ? payLoanAmountDenominationForm.values.chequeNumber : null,
			chequeDate: paymentTypeId == 2 ? payLoanAmountDenominationForm.values.chequeDate : null,
			chequeAmount: Number(payLoanAmountDenominationForm.values.chequeAmount) != 0 ? Number(payLoanAmountDenominationForm.values.chequeAmount) : null,
			chequeBankName: payLoanAmountDenominationForm.values.chequeBankName != '' ? payLoanAmountDenominationForm.values.chequeBankName : null,

			/// Neft Payment ///
			neftRefNumber: payLoanAmountDenominationForm.values.neftRefNumber != '' ? payLoanAmountDenominationForm.values.neftRefNumber : null,
			neftTranDate: paymentTypeId == 3 ? payLoanAmountDenominationForm.values.neftTranDate : null,
			neftAmount: Number(payLoanAmountDenominationForm.values.neftAmount) != 0 ? Number(payLoanAmountDenominationForm.values.neftAmount) : null,
			neftBankName: payLoanAmountDenominationForm.values.neftBankName != '' ? payLoanAmountDenominationForm.values.neftBankName : null,

			/// Card Payment ///
			cardNumber: payLoanAmountDenominationForm.values.cardNumber != '' ? payLoanAmountDenominationForm.values.cardNumber : null,
			cardDate: paymentTypeId == 4 ? payLoanAmountDenominationForm.values.cardDate : null,
			cardAmount: Number(payLoanAmountDenominationForm.values.cardAmount) != 0 ? Number(payLoanAmountDenominationForm.values.cardAmount) : null,
			cardBankName: payLoanAmountDenominationForm.values.cardBankName != '' ? payLoanAmountDenominationForm.values.cardBankName : null,

			narration: editLoanForm.values.remarks,
			balCashAmount: Number(balCashAmount) != 0 ? Number(balCashAmount) : null,

			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
		};
	}

	function setDeleteStaffLoansDetails() {
		return {
			loansDetailsId: editLoanForm.values.loansDetailsId,
			staffDetailsId: staffDetailsIdForAdd?.value,
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
		};
	}

	function getStaffListByUserType(userTypeId: any) {
		getStaffListByUserTypeId(userTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffListByUserTypeId;
					if (data != undefined) {
						const staffDetailsData = [{ staffDetailsId: 0, empAndStaffName: 'Select All' }];
						data.forEach((obj: { staffDetailsId: number; empAndStaffName: string }) => {
							let staffDetails1 = staffDetailsData.push(obj);
						});
						setStaffDetailsData(staffDetailsData);
						setStaffDetailsId({ value: 0, label: 'Select All' })
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					setStaffDetailsData([]);
					setStaffDetailsId([])
					//toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getStaffList(userTypeId: any) {
		getStaffListByUserTypeId(
			userTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffListByUserTypeId;
					if (data != undefined) {
						setStaffListData(data);
						setApprovalStaffListData(data.filter((item: any) => item.userTypeId != 7 && item.userTypeId != 9 && item.userTypeId != 10 && item.userTypeId != 11));
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setStaffListData([]);
					setApprovalStaffListData([]);
					//toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getUserTypeList() {
		getUserType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.userType;
					if (data != undefined) {
						const userTypeData = [{ userTypeId: 0, userType: 'Select All' }];
						data.forEach((obj: { userTypeId: number; userType: string }) => {
							let userType = userTypeData.push(obj);
						});
						setUserTypeData(userTypeData);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setUserTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStaffLoan(userTypeId: any, staffDetailsId: any) {
		setIsLoader(true);
		getStaffLoanDetails(userTypeId, staffDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffLoanDetails;
					if (data != undefined) {
						setStaffLoanData(data);
						getColumnsForDataTableList('getStaffLoanDetails', 'get');
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setIsLoader(false);
						setDataStatus(true);
						setIsOpenListCard(false);
					} else {
						setIsLoader(false);
						setDataStatus(true);
						setIsOpenListCard(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					getColumnsForDataTableList('getStaffLoanDetails', 'get');
					setStaffLoanData([]);
					setNoDataMsg(response.data.message);
					setIsLoader(false);
					setDataStatus(true);
					setIsOpenListCard(false);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					setDataStatus(true);
					setIsOpenListCard(false);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function getLoanRequestTypeList(loanRequestTypeId: any) {
		getLoanRequestType(
			loanRequestTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.loanRequestType;
					if (data != undefined) {
						setLoanRequestTypeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success == false) {
					setLoanRequestTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStatusList() {
		getStatus(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.status;
					if (data != undefined) {
						setStatusData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStatusData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getStaffLoanRequestHistory(requestTableId: any) {
		getLoanRequestHistory(requestTableId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffRequestHistory;
					if (data != undefined) {
						setStaffRequestHistoryData(data);
						setStatusModal(true)
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					//toasts(response.data.message, "Error")
					setStaffRequestHistoryData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function addStaffLoanSubmit() {
		showLoader(true);
		if (addLoanForm.isValid) {
			let loanPostData = setStaffLoanDetails();
			addStaffLoanDetails(loanPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndResetForm();
						if (userTypeId == 1) {
							ViewStaffLoanList()
						} else {
							getStaffLoan(staffTypeId?.value == undefined ? 0 : staffTypeId?.value, staffDetailsId?.value == undefined ? 0 : staffDetailsId?.value);
						}
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (addLoanForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
			setIsOpen(true);
		}
	}

	function updateStaffLoansSubmit() {
		showLoader(true);
		if (editLoanForm.isValid) {
			let loanPostData = setUpdateStaffLoansDetails();
			updateStaffLoanDetails(
				loanPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						if (userTypeId == 1) {
							ViewStaffLoanList()
						} else {
							getStaffLoan(staffTypeId?.value == undefined ? 0 : staffTypeId?.value, staffDetailsId?.value == undefined ? 0 : staffDetailsId?.value);
						}
						closeAndResetForm();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (addLoanForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
			setIsOpen(true);
		}
	}

	function deleteStaffLoanSubmit() {
		showLoader(true);
		if (editLoanForm.isValid) {
			let loanPostData = setDeleteStaffLoansDetails();
			deleteStaffLoansDetails(
				loanPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						if (userTypeId == 1) {
							ViewStaffLoanList()
						} else {
							getStaffLoan(staffTypeId?.value == undefined ? 0 : staffTypeId?.value, staffDetailsId?.value == undefined ? 0 : staffDetailsId?.value);
						}
						closeAndResetForm();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (addLoanForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
			setIsOpen(true);
		}
	}

	function getColumnsForDataTableList(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						let keyData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData);

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => !item.isDefault,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const updateColumnsSubmit = (columnVisibilityId: any, isDisplay: any, type: any) => {
		if (columnVisibleForm.isValid) {
			setColumnDataById(isDisplay);
			const postData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(postData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForDataTableList('getStaffLoanDetails', 'get');
					} else if (data.success == false) {
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibleForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	};

	const selectUserType = (e: any) => {
		setStaffDetailsId('')
		setDataStatus(false)
		setIsOpenListCard(true)
		let staffTypeId = e;
		setStaffTypeId(staffTypeId);
		if (staffTypeId?.value != undefined) {
			getStaffListByUserType(staffTypeId?.value);
		}
	};

	const selectStaffForAdd = (e: any) => {
		let staffDetailsIdForAdd = e;
		setStaffDetailsIdForAdd(staffDetailsIdForAdd);
	};

	function openAddLoanOffCanvas() {
		setAddLoanOffCanvas(true);
		getLoanRequestTypeList(0);
		getStaffList(0);
		getStatusList();
		resetForm()
	}

	function ViewStaffLoanList() {
		setDataStatus(false);
		setIsLoader(true);
		getStaffLoan(staffTypeId?.value, staffDetailsId?.value != undefined ? staffDetailsId?.value : 0)
	}

	function setLoanRequestTypeDetails() {
		return {
			loanRequestName: addLoanTypeForm.values.loanRequestName,
			perquisiteRate: addLoanTypeForm.values.perquisiteRate,
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
		};
	}

	function addLoanRequestTypeSubmit() {
		showLoader(true);
		if (addLoanTypeForm.isValid) {
			let loanTypePostData = setLoanRequestTypeDetails();
			addLoanRequestType(
				loanTypePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getLoanRequestTypeList(0);
						closeAndResetForm();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (addLoanTypeForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
			setIsOpen(true);
		}
	}

	function closeAndResetForm() {
		setAddLoanOffCanvas(false);
		setEditLoanOffCanvas(false);
		setAddLoanTypeOffCanvas(false);
		setIsDeleteOpen(false);
		setIsDisposal(0);
		setDisposalModal(false)
		resetForm()
		formReset()
		setStaffDetailsIdForAdd('')
	}

	function resetForm() {
		// setStaffDetailsIdForAdd('')
		setLoanRequestTypeId('');
		setApprovalStaff('')
		setStatusId('')
		addLoanForm.resetForm();
		editLoanForm.resetForm()
		addLoanTypeForm.resetForm()
	}

	const selectStaffDetailsId = (e: any) => {
		let staffDetailsId = e
		setStaffDetailsId(staffDetailsId)
		setStaffDetailsIdForAdd(staffDetailsId);
	}

	const multiply500 = (e: any) => {
		const count500 = e.target.value;
		setFiveHundred_total(count500 * 500)
		multiply(count500 * 500, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total)
		balanceAmountCal(count500 * 500, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, five_total)
	}

	const multiply200 = (e: any) => {
		const count200 = e.target.value;
		setTwoHundred_total(count200 * 200);
		multiply(fiveHundred_total, count200 * 200, hundred_total, fifty_total, twenty_total, ten_total, five_total)
		balanceAmountCal(fiveHundred_total, count200 * 200, hundred_total, fifty_total, twenty_total, ten_total, five_total)

	}

	const multiply100 = (e: any) => {
		const count100 = e.target.value;
		setHundred_total(count100 * 100);
		multiply(fiveHundred_total, twoHundred_total, count100 * 100, fifty_total, twenty_total, ten_total, five_total)
		balanceAmountCal(fiveHundred_total, twoHundred_total, count100 * 100, fifty_total, twenty_total, ten_total, five_total)

	}

	const multiply50 = (e: any) => {
		const count50 = e.target.value;
		setFifty_total(count50 * 50);
		multiply(fiveHundred_total, twoHundred_total, hundred_total, count50 * 50, twenty_total, ten_total, five_total)
		balanceAmountCal(fiveHundred_total, twoHundred_total, hundred_total, count50 * 50, twenty_total, ten_total, five_total)

	}

	const multiply20 = (e: any) => {
		const count20 = e.target.value;
		setTwenty_total(count20 * 20);
		multiply(fiveHundred_total, twoHundred_total, hundred_total, fifty_total, count20 * 20, ten_total, five_total)
		balanceAmountCal(fiveHundred_total, twoHundred_total, hundred_total, fifty_total, count20 * 20, ten_total, five_total)

	}

	const multiply10 = (e: any) => {
		const count10 = e.target.value;
		setTen_total(count10 * 10);
		multiply(fiveHundred_total, twoHundred_total, hundred_total, fifty_total, twenty_total, count10 * 10, five_total)
		balanceAmountCal(fiveHundred_total, twoHundred_total, hundred_total, fifty_total, twenty_total, count10 * 10, five_total)
	}

	const multiply5 = (e: any) => {
		const count5 = e.target.value;
		setFive_total(count5 * 5);
		multiply(fiveHundred_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, count5 * 5)
		balanceAmountCal(fiveHundred_total, twoHundred_total, hundred_total, fifty_total, twenty_total, ten_total, count5 * 5)
	}

	const multiply = (fiveHundred_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any) => {
		setTotalAmount(Number(fiveHundred_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total));
	}

	const balanceAmountCal = (fiveHundred_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any) => {
		setBalCashAmount(Number(fiveHundred_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total) - Number(loanTotalAmount));
	}

	const balanceAmountCal1 = (fiveHundred_total: any, twoHundred_total: any, hundred_total: any, fifty_total: any, twenty_total: any, ten_total: any, five_total: any, loanTotalAmount: any) => {
		setBalCashAmount(Number(fiveHundred_total) + Number(twoHundred_total) + Number(hundred_total) + Number(fifty_total) + Number(twenty_total) + Number(ten_total) + Number(five_total) - Number(loanTotalAmount));
	}

	function formReset() {
		payLoanAmountDenominationForm.resetForm();
		setPaymentTypeId(0)
		setFiveHundred_total("")
		setTwoHundred_total("")
		setHundred_total("")
		setFifty_total("")
		setTwenty_total("")
		setTen_total("")
		setFive_total("")
		setTotalAmount("")
		setBalCashAmount("")
	}

	// Print
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper title='Loans Details'>
			<Page>
				{userTypeId != 1 ? <></> :
					<>
						<Collapse isOpen={isOpenListCard}>
							<Card stretch data-tour='list'>
								<CardHeader borderSize={1}>
									<CardLabel icon='List' iconColor='info'>
										<CardTitle tag='div' className='h5'>
											Loans List
										</CardTitle>
									</CardLabel>
									<CardActions className='d-print-none'>
										<Button
											color='primary'
											icon='Add'
											isLight
											onClick={() => setAddLoanTypeOffCanvas(true)}>
											Add New Loan Type
										</Button>
										<Button
											color='primary'
											icon='Add'
											isLight
											onClick={openAddLoanOffCanvas}>
											Add New
										</Button>
									</CardActions>
								</CardHeader>
								<CardBody>
									<div className='row'>
										<div className='col-3'>
											<FormGroup id='userTypeId' label='Staff Type' isFloating>
												<SearchableSelect isFloating
													ariaLabel='Staff Type'
													placeholder="Select Staff Type"
													onChange={selectUserType}
													value={staffTypeId}
													list={userTypeData.map((data: any) => ({ value: data.userTypeId, label: data.userType }))} />
											</FormGroup>
										</div>

										{staffTypeId?.value != 0 ?

											<div className='col-4'>
												<FormGroup id='staffDetailsId' label='Staff' isFloating>
													<SearchableSelect isFloating
														ariaLabel='Staff'
														placeholder="Select Staff"
														onChange={(e: any) => selectStaffDetailsId(e)}
														value={staffDetailsId}
														list={staffDetailsData.map((data: any) => ({ value: data.staffDetailsId, label: data.empAndStaffName }))} />
												</FormGroup>
											</div>
											: null}

										{staffTypeId?.value != undefined ?
											<div className="col-3">
												<Button className="mt-2" icon='ArrowDownward' color='primary' onClick={ViewStaffLoanList}>View</Button>
											</div>
											: null}
									</div>
								</CardBody>
							</Card>
						</Collapse>

						<OpenCardComponent
							isOpenListCard={isOpenListCard}
							setIsOpenListCard={setIsOpenListCard}
							isLoader={isLoader}
						/>
					</>}

				{dataStatus ? (
					<>
						<Card stretch data-tour='list' ref={componentRef} id='pdf'>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Loans List
									</CardTitle>
								</CardLabel>
								<CardActions className='d-print-none'>
									<ButtonGroup color='primary'>
										<Icon
											className='mt-1'
											icon='Search'
											size='2x'
											color='primary'
										/>
										<Input
											id='searchInput'
											type='search'
											placeholder='Search...'
											onChange={columnVisibleForm.handleChange}
											value={columnVisibleForm.values.searchInput}
										/>
									</ButtonGroup>
									<Dropdown
										isOpen={columnVisibilityMenu}
										setIsOpen={setColumnVisibilityMenu}
										isButtonGroup>
										<DropdownToggle>
											<Button icon='FilterAlt' color='primary' isLight>
												Filter
											</Button>
										</DropdownToggle>
										<DropdownMenu
											isAlignmentEnd
											size='lg'
											isCloseAfterLeave={false}>
											<DropdownItem>
												<div className='container py-2'>
													<form className='row g-3'>
														<div className='col-12'>
															<FormGroup>
																<h6>Select All</h6>
																<Checks
																	id='available'
																	type='switch'
																	label='Select All Columns'
																	onChange={() =>
																		getColumnsForDataTableList(
																			'getStaffLoanDetails',
																			'post',
																		)
																	}
																	checked={isChecked}
																	ariaLabel='Available status'
																/>
															</FormGroup>
														</div>
														<div className='col-12'>
															<FormGroup>
																<h6>Columns</h6>
																<ChecksGroup>
																	{allColumnsData.map(
																		(i: any) => (
																			<Checks
																				key={
																					i.columnVisibilityId
																				}
																				label={i.columnName}
																				onChange={() =>
																					updateColumnsSubmit(
																						i.columnVisibilityId,
																						i.isDisplay,
																						'',
																					)
																				}
																				checked={
																					i.isDisplay
																				}
																				disabled={
																					i.isDefault
																				}
																			/>
																		),
																	)}
																</ChecksGroup>
															</FormGroup>
														</div>
													</form>
												</div>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
									<Dropdown isButtonGroup>
										<DropdownToggle>
											<Button color='primary' isLight icon='CloudDownload'>
												{' '}
												Export{' '}
											</Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='CloudDownload'
													onClick={() =>
														downloadExcel(
															'StaffLoansList',
															staffLoanData,
															columnVisibilityData,
														)
													}>
													{' '}
													Excel{' '}
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='PictureAsPdf'
													onClick={() =>
														convertJsonToPdf(
															staffLoanData,
															columnVisibilityData,
															'StaffLoansList',
														)
													}>
													{' '}
													PDF
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='CloudDownload'
													onClick={() =>
														downloadFile(
															'StaffLoansList',
															staffLoanData,
															columnVisibilityData,
														)
													}>
													{' '}
													CSV{' '}
												</Button>
											</DropdownItem>
											<DropdownItem>
												<Button
													color='primary'
													isLight
													icon='Print'
													onClick={() => handlePrint()}>
													{' '}
													Print{' '}
												</Button>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
									{userTypeId != 1 ? <Button color='primary' icon='Add' isLight onClick={openAddLoanOffCanvas}>
										Add New
									</Button> : null}
								</CardActions>
							</CardHeader>
							<CardBody className='table-responsive' isScrollable>
								{isLoader ?
									<TableLoader /> :
									<table className='table table-modern table-hover text-nowrap'>
										<thead>
											<tr>
												<th
													scope='col'
													onClick={() => requestSort('sno')}
													className='cursor-pointer text-decoration-underline'>
													S.No{' '}
													<Icon
														size='lg'
														className={getClassNamesFor('sno')}
														icon='FilterList'
													/>
												</th>
												{columnVisibilityData.map((column: any) => (
													<th
														key={column.keyName}
														scope='col'
														onClick={() => requestSort(column.keyName)}
														className='cursor-pointer text-decoration-underline'>
														{column.columnName}
														<Icon
															size='lg'
															className={getClassNamesFor(column.keyName)}
															icon='FilterList'
														/>
													</th>
												))}
												<th scope='col' className='d-print-none'>
													Status
												</th>
												<th scope='col' className='d-print-none'>
													Action
												</th>
											</tr>
										</thead>
										<tbody>
											{filteredData != '' ? (
												<>
													{filteredData.map((rowData: any) => (
														<tr key={rowData.loansDetailsId}>
															<td width={'1%'}>{rowData.sno}</td>
															{columnVisibilityData.map((column: any) =>
																column.isDisplay &&
																	rowData[column.keyName] ? (
																	<td
																		key={`${rowData.loansDetailsId}-${column.keyName}`}>
																		{rowData[column.keyName]}
																	</td>
																) : (
																	<td
																		key={`empty-${column.keyName}`}
																	/>
																),
															)}
															<td className='d-print-none'><Button isLink icon='Circle' className='text-nowrap' color={rowData.statusId == 1 ? 'info' : rowData.statusId == 2 ? 'success' : 'danger'} onClick={() => getStaffLoanRequestHistory(rowData.loansDetailsId)}>{rowData.statusName}</Button></td>

															<td className="d-print-none">
																{rowData.isComplete == 1 ?
																	<Button isLink icon='DoneAll' isLight className='text-nowrap' color='success' onClick={() => navigate(`../loan/paySchedule/${rowData.loansDetailsId}`)} >Completed</Button>
																	: <>
																		{rowData.isDisposal == 'Yes' ?
																			<Button color='primary' isLight icon='Preview' aria-label='Edit' onClick={() => navigate(`../loan/paySchedule/${rowData.loansDetailsId}`)} >View</Button> :
																			<>
																				{rowData.statusId == 2 ?
																					<Button color='primary' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData, 'disposal') }} >Disposal</Button> :
																					<Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData, 'edit') }} />}
																			</>}
																	</>}
															</td>

														</tr>
													))}
												</>
											) : (
												<NoDataMsg
													columnsCount={columnVisibilityData.length + 3}
													msg={noDataMsg}
												/>
											)}
										</tbody>
									</table>}
							</CardBody>
							<PaginationButtons
								className='d-print-none'
								data={items}
								label='items'
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								perPage={perPage}
								setPerPage={setPerPage}
							/>
						</Card>
					</>
				) : null}
			</Page>

			<OffCanvas
				setOpen={setAddLoanOffCanvas}
				isOpen={addLoanOffCanvas}
				titleId='addLoanOffCanvas'
				isBodyScroll
				placement='end'
				isBackdrop
				tag='form'
				noValidate
				onSubmit={addLoanForm.handleSubmit}>
				<OffCanvasHeader setOpen={setAddLoanOffCanvas} onClick={closeAndResetForm}>
					<OffCanvasTitle id='addLoanOffCanvas'>Add Loan</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='row g-4'>

						<div className='col-12'>
							<FormGroup id='loanRequestTypeId' label='Loan Request Type'>
								<SearchableSelect
									ariaLabel=''
									placeholder='Loan Request Type'
									onChange={(e: any) => setLoanRequestTypeId(e)}
									value={loanRequestTypeId}
									list={loanRequestTypeData.map((data: any) => ({
										value: data.loanRequestTypeId,
										label: data.loanRequestName,
									}))}
									onBlur={addLoanForm.handleBlur}
									isValid={addLoanForm.isValid}
									isTouched={addLoanForm.touched.loanRequestTypeId}
									invalidFeedback={addLoanForm.errors.loanRequestTypeId}
								/>
							</FormGroup>
						</div>
						{userTypeId == 1 ?
							<div className='col-12'>
								<FormGroup id='staffDetailsIdForAdd' label='staff Details'>
									<SearchableSelect
										ariaLabel='Staff Details'
										placeholder="Staff Details"
										onChange={(e: any) => selectStaffForAdd(e)}
										value={staffDetailsIdForAdd}
										list={staffListData.map((data: any) => ({ value: data.staffDetailsId, label: data.empAndStaffName }))}
										isValid={addLoanForm.isValid}
										isTouched={addLoanForm.touched.staffDetailsId}
										invalidFeedback={addLoanForm.errors.staffDetailsId} />
								</FormGroup>
							</div>
							: null}
						<div className='col-12'>
							<FormGroup id='loanAmount' label='Loan Amount'>
								<Input
									placeholder='Enter Loan Amount'
									onChange={addLoanForm.handleChange}
									value={addLoanForm.values.loanAmount}
									onBlur={addLoanForm.handleBlur}
									isValid={addLoanForm.isValid}
									isTouched={addLoanForm.touched.loanAmount}
									invalidFeedback={addLoanForm.errors.loanAmount}
									onKeyDown={(e: any) => onlyAllowNumber(e)}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='disbursementDate' label='Disbursement Date'>
								<Input
									placeholder='Enter Disbursement Date'
									onChange={addLoanForm.handleChange}
									value={addLoanForm.values.disbursementDate}
									onBlur={addLoanForm.handleBlur}
									isValid={addLoanForm.isValid}
									isTouched={addLoanForm.touched.disbursementDate}
									invalidFeedback={addLoanForm.errors.disbursementDate}
									type='date'
									min={new Date().toISOString().split('T')[0]}
								/>
							</FormGroup>
						</div>

						<CardLabel icon='Reply' iconColor='info'>
							<CardTitle tag='div' className='h5'>
								Repayments
							</CardTitle>
						</CardLabel>
						<div className='col-12'>
							<FormGroup id='EMIDeductionDate' label='EMI Deduction Start Date'>
								<Input
									placeholder='EMI Deduction Start Date'
									onChange={addLoanForm.handleChange}
									value={addLoanForm.values.EMIDeductionDate}
									onBlur={addLoanForm.handleBlur}
									isValid={addLoanForm.isValid}
									isTouched={addLoanForm.touched.EMIDeductionDate}
									invalidFeedback={addLoanForm.errors.EMIDeductionDate}
									type='date'
									min={
										new Date(addLoanForm.values.disbursementDate)
											.toISOString()
											.split('T')[0]
									}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='deductionMonth' label='Deduction Month'>
								<Input
									placeholder='Enter Deduction Month'
									onChange={addLoanForm.handleChange}
									value={addLoanForm.values.deductionMonth}
									onBlur={addLoanForm.handleBlur}
									isValid={addLoanForm.isValid}
									isTouched={addLoanForm.touched.deductionMonth}
									invalidFeedback={addLoanForm.errors.deductionMonth}
									onKeyDown={(e: any) => onlyAllowNumber(e)}
								/>
							</FormGroup>
						</div>

						{addLoanForm.values.deductionMonth > 0 ? (
							<div className='col-12'>
								<Alert
									color='success'
									shadow='md'
									isLight
									icon='Recommend'
									className='flex-nowrap'>
									<p>
										This loan will be fully paid off in{' '}
										<b>{Math.round(
											Number(
												addLoanForm.values.loanAmount /
												addLoanForm.values.deductionMonth,
											),
										)}{' '}</b>
										instalment(s). The first deduction for this loan will be on{' '}
										<b>{addLoanForm.values.EMIDeductionDate}</b>.
									</p>
								</Alert>
							</div>
						) : null}

						<div className='col-12'>
							<FormGroup id='approvalStaff' label='Pending With'>
								<SearchableSelect
									ariaLabel='Staff'
									placeholder="Select Staff"
									onChange={(e: any) => setApprovalStaff(e)}
									value={approvalStaff}
									list={approvalStaffListData.map((data: any) => ({ value: data.staffDetailsId, label: data.empAndStaffName }))}
									isValid={addLoanForm.isValid}
									isTouched={addLoanForm.touched.approvalStaff}
									invalidFeedback={addLoanForm.errors.approvalStaff} />
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='reason' label='Reason'>
								<Textarea
									placeholder='Enter Reason'
									onChange={addLoanForm.handleChange}
									value={addLoanForm.values.reason}
									onBlur={addLoanForm.handleBlur}
									isValid={addLoanForm.isValid}
									isTouched={addLoanForm.touched.reason}
									invalidFeedback={addLoanForm.errors.reason}
								/>
							</FormGroup>
						</div>


						<div className='col-12'>
							<br />
							<br />
						</div>
					</div>

				</OffCanvasBody>
				<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
					<div className='row m-0'>
						<Button
							color='info'
							icon='Save'
							className='w-100'
							type='submit'
							isDisable={!addLoanForm.isValid && !!addLoanForm.submitCount}>
							Save
						</Button>
					</div>
				</div>
			</OffCanvas>

			<OffCanvas
				setOpen={setEditLoanOffCanvas}
				isOpen={editLoanOffCanvas}
				titleId='editLoanOffCanvas'
				isBodyScroll
				placement='end'
				isBackdrop
				tag='form'
				noValidate
				onSubmit={editLoanForm.handleSubmit}>
				<OffCanvasHeader setOpen={setEditLoanOffCanvas} onClick={closeAndResetForm}>
					<OffCanvasTitle id='editLoanOffCanvas'>Update Loan</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='row g-4'>

						<div className='col-12'>
							<FormGroup id='loanRequestTypeId' label='Loan Request Type'>
								<SearchableSelect
									ariaLabel=''
									placeholder='Loan Request Type'
									onChange={(e: any) => setLoanRequestTypeId(e)}
									value={loanRequestTypeId}
									list={loanRequestTypeData.map((data: any) => ({
										value: data.loanRequestTypeId,
										label: data.loanRequestName,
									}))}
									onBlur={editLoanForm.handleBlur}
									isValid={editLoanForm.isValid}
									isTouched={editLoanForm.touched.loanRequestTypeId}
									invalidFeedback={editLoanForm.errors.loanRequestTypeId}
								/>
							</FormGroup>
						</div>
						{userTypeId == 1 ?
							<div className='col-12'>
								<FormGroup id='staffDetailsIdForAdd' label='staff Details'>
									<SearchableSelect
										ariaLabel='Staff Details'
										placeholder="Staff Details"
										onChange={(e: any) => selectStaffForAdd(e)}
										value={staffDetailsIdForAdd}
										list={staffListData.map((data: any) => ({ value: data.staffDetailsId, label: data.empAndStaffName }))}
										isValid={editLoanForm.isValid}
										isTouched={editLoanForm.touched.staffDetailsId}
										invalidFeedback={editLoanForm.errors.staffDetailsId} />
								</FormGroup>
							</div> : null}
						<div className='col-12'>
							<FormGroup id='loanAmount' label='Loan Amount'>
								<Input
									placeholder='Enter Loan Amount'
									onChange={editLoanForm.handleChange}
									value={editLoanForm.values.loanAmount}
									onBlur={editLoanForm.handleBlur}
									isValid={editLoanForm.isValid}
									isTouched={editLoanForm.touched.loanAmount}
									invalidFeedback={editLoanForm.errors.loanAmount}
									onKeyDown={(e: any) => onlyAllowNumber(e)}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='disbursementDate' label='Disbursement Date'>
								<Input
									placeholder='Enter Disbursement Date'
									onChange={editLoanForm.handleChange}
									value={editLoanForm.values.disbursementDate}
									onBlur={editLoanForm.handleBlur}
									isValid={editLoanForm.isValid}
									isTouched={editLoanForm.touched.disbursementDate}
									invalidFeedback={editLoanForm.errors.disbursementDate}
									type='date'
									min={new Date().toISOString().split('T')[0]}
								/>
							</FormGroup>
						</div>
						<CardLabel icon='Reply' iconColor='info'>
							<CardTitle tag='div' className='h5'>
								Repayments
							</CardTitle>
						</CardLabel>
						<div className='col-12'>
							<FormGroup id='EMIDeductionDate' label='EMI Deduction Start Date'>
								<Input
									placeholder='EMI Deduction Start Date'
									onChange={editLoanForm.handleChange}
									value={editLoanForm.values.EMIDeductionDate}
									onBlur={editLoanForm.handleBlur}
									isValid={editLoanForm.isValid}
									isTouched={editLoanForm.touched.EMIDeductionDate}
									invalidFeedback={editLoanForm.errors.EMIDeductionDate}
									type='date'
									min={
										new Date(editLoanForm.values.disbursementDate)
											.toISOString()
											.split('T')[0]
									}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='deductionMonth' label='Instalment Amount'>
								<Input
									placeholder='Enter Instalment Amount'
									onChange={editLoanForm.handleChange}
									value={editLoanForm.values.deductionMonth}
									onBlur={editLoanForm.handleBlur}
									isValid={editLoanForm.isValid}
									isTouched={editLoanForm.touched.deductionMonth}
									invalidFeedback={editLoanForm.errors.deductionMonth}
									onKeyDown={(e: any) => onlyAllowNumber(e)}
								/>
							</FormGroup>
						</div>
						{editLoanForm.values.deductionMonth > 0 ? (
							<div className='col-12'>
								<Alert
									color='success'
									shadow='md'
									isLight
									icon='Recommend'
									className='flex-nowrap'>
									<p>
										This loan will be fully paid off in{' '}
										<b>{Math.round(
											Number(
												editLoanForm.values.loanAmount /
												editLoanForm.values.deductionMonth,
											)
										)}{' '}</b>
										instalment(s). The first deduction for this loan will be on{' '}
										<b>{editLoanForm.values.EMIDeductionDate}</b>.
									</p>
								</Alert>
							</div>
						) : null}
						<div className='col-12'>
							<FormGroup id='approvalStaff' label='Pending With'>
								<SearchableSelect
									ariaLabel='Staff'
									placeholder="Select Staff"
									onChange={(e: any) => setApprovalStaff(e)}
									value={approvalStaff}
									list={approvalStaffListData.map((data: any) => ({ value: data.staffDetailsId, label: data.empAndStaffName }))}
									isValid={editLoanForm.isValid}
									isTouched={editLoanForm.touched.approvalStaff}
									invalidFeedback={editLoanForm.errors.approvalStaff} />
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='reason' label='Reason'>
								<Textarea
									placeholder='Enter Reason'
									onChange={editLoanForm.handleChange}
									value={editLoanForm.values.reason}
									onBlur={editLoanForm.handleBlur}
									isValid={editLoanForm.isValid}
									isTouched={editLoanForm.touched.reason}
									invalidFeedback={editLoanForm.errors.reason}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='statusId' label='Status'>
								<SearchableSelect
									ariaLabel='Staff'
									placeholder="Select Status"
									onChange={(e: any) => setStatusId(e)}
									value={statusId}
									list={statusData.map((data: any) => ({ value: data.statusId, label: data.statusName }))}
									isValid={editLoanForm.isValid}
									isTouched={editLoanForm.touched.statusId}
									invalidFeedback={editLoanForm.errors.statusId} />
							</FormGroup>
						</div>
						{statusId?.value != 1 ? <div className='col-12'>
							<FormGroup id='remarks' label='Remarks'>
								<Textarea
									autoComplete=''
									onChange={editLoanForm.handleChange}
									onBlur={editLoanForm.handleBlur}
									value={editLoanForm.values.remarks}
									isValid={editLoanForm.isValid}
									isTouched={editLoanForm.touched.remarks}
									invalidFeedback={editLoanForm.errors.remarks}
								/>
							</FormGroup>
						</div> : null}

						<div className='col-12'>
							<br />
							<br />
							<br />
						</div>
						<div className='d-flex justify-content-center position-absolute top-60 start-50 translate-middle'>
							<DeleteComponents
								isDeleteOpen={isDeleteOpen}
								setIsDeleteOpen={setIsDeleteOpen}
								deleteFunction={deleteStaffLoanSubmit}
							/>
						</div>
					</div>
				</OffCanvasBody>
				<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
					<div className='row m-0'>
						<div className='col-6 p-3'>
							<Button
								color='danger'
								className='w-100'
								icon='Delete'
								onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
								Delete
							</Button>
						</div>
						<div className='col-6 p-3'>
							<Button
								color='info'
								icon='Save'
								type='submit'
								className='w-100'
								isDisable={!editLoanForm.isValid && !editLoanForm.submitCount}>
								{' '}
								Update
							</Button>
						</div>
					</div>
				</div>
			</OffCanvas>

			<OffCanvas
				setOpen={setAddLoanTypeOffCanvas}
				isOpen={addLoanTypeOffCanvas}
				titleId='addLoanTypeOffCanvas'
				isBodyScroll
				placement='end'
				isBackdrop
				tag='form'
				noValidate
				onSubmit={addLoanTypeForm.handleSubmit}>
				<OffCanvasHeader setOpen={setAddLoanTypeOffCanvas} onClick={closeAndResetForm}>
					<OffCanvasTitle id='addLoanTypeOffCanvas'>Add Loan Type</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='row g-4'>
						<div className='col-12'>
							<FormGroup id='loanRequestName' label='Loan Request Name'>
								<Input
									placeholder='Enter Loan Request Name'
									onChange={addLoanTypeForm.handleChange}
									value={addLoanTypeForm.values.loanRequestName}
									onBlur={addLoanTypeForm.handleBlur}
									isValid={addLoanTypeForm.isValid}
									isTouched={addLoanTypeForm.touched.loanRequestName}
									invalidFeedback={addLoanTypeForm.errors.loanRequestName}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='perquisiteRate' label='Perquisite Rate'>
								<Input
									placeholder='Enter Perquisite Rate'
									onChange={addLoanTypeForm.handleChange}
									value={addLoanTypeForm.values.perquisiteRate}
									onBlur={addLoanTypeForm.handleBlur}
									isValid={addLoanTypeForm.isValid}
									isTouched={addLoanTypeForm.touched.perquisiteRate}
									invalidFeedback={addLoanTypeForm.errors.perquisiteRate}
									onKeyDown={(e: any) => onlyAllowNumber(e)}
								/>
							</FormGroup>
						</div>
					</div>
				</OffCanvasBody>
				<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
					<div className='row m-0'>
						<Button color='info' icon='Save' className='w-100' type='submit'>
							Save
						</Button>
					</div>
				</div>
			</OffCanvas>

			<OffCanvas
				setOpen={setRecordPaymentOffCanvas}
				isOpen={recordPaymentOffCanvas}
				titleId='recordPaymentOffCanvas'
				placement='end'>
				<OffCanvasHeader setOpen={setRecordPaymentOffCanvas}>
					<OffCanvasTitle id='recordPaymentOffCanvas'>Record Repayment</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='row g-4'>
						<div className='col-12'>
							<FormGroup id='repaymentAmount' label='Repayment Amount'>
								<Input
									onChange={editRepaymentForm.handleChange}
									value={editRepaymentForm.values.repaymentAmount}
									isValid={editRepaymentForm.isValid}
									onBlur={editRepaymentForm.handleBlur}
									isTouched={editRepaymentForm.touched.repaymentAmount}
									invalidFeedback={editRepaymentForm.errors.repaymentAmount}
									placeholder='Enter Repayment Amount'
									required
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='repaymentDate' label='Repayment Date'>
								<Input
									onChange={editRepaymentForm.handleChange}
									value={editRepaymentForm.values.repaymentDate}
									isValid={editRepaymentForm.isValid}
									onBlur={editRepaymentForm.handleBlur}
									isTouched={editRepaymentForm.touched.repaymentDate}
									invalidFeedback={editRepaymentForm.errors.repaymentDate}
									type='date' />
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='paymentMode' label='Payment Mode'>
								<Input
									onChange={editRepaymentForm.handleChange}
									value={editRepaymentForm.values.paymentMode}
									isValid={editRepaymentForm.isValid}
									onBlur={editRepaymentForm.handleBlur}
									isTouched={editRepaymentForm.touched.paymentMode}
									invalidFeedback={editRepaymentForm.errors.paymentMode}
									placeholder='Enter Payment Mode' required />
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='remainingAmount' label='Remaining Amount'>
								<Input
									onChange={editRepaymentForm.handleChange}
									value={editRepaymentForm.values.remainingAmount}
									isValid={editRepaymentForm.isValid}
									onBlur={editRepaymentForm.handleBlur}
									isTouched={editRepaymentForm.touched.remainingAmount}
									invalidFeedback={editRepaymentForm.errors.remainingAmount}
									placeholder='Enter Remaining Amount' required />
							</FormGroup>
						</div>
					</div>
				</OffCanvasBody>
				<div className='row top-90 m-0'>
					<div className='col-12 position-absolute bottom-0 start-0 p-3'>
						<Button color='info' className='w-100'>
							Save
						</Button>
					</div>
				</div>
			</OffCanvas>

			<Modal
				setIsOpen={setStatusModal}
				isOpen={statusModal}
				titleId='statusModal'
				isStaticBackdrop
				size='lg'
			>
				<ModalHeader className="'modal-header'"
					setIsOpen={() => { setStatusModal(false) }} onClick={closeAndResetForm}>
					<ModalTitle id="statusModal"> Status</ModalTitle>
				</ModalHeader>
				<ModalBody>
					<div className='row'>
						<div className='col-12'>
							<table className='table table-modern table-hover'>
								<thead>
									<tr className='table-primary'>
										<td style={{ width: '30px' }} className='text-decoration-underline'>
											<strong>Action By</strong>
										</td>
										<td style={{ width: '25px' }} className='text-decoration-underline'>
											<strong>Staff Name</strong>
										</td>
										<td style={{ width: '25px' }} className='text-decoration-underline'>
											<strong>Action</strong>
										</td>
										<td style={{ width: '25px' }} className='text-decoration-underline'>
											<strong>Pending With</strong>
										</td>
										<td style={{ width: '10px' }} className='text-decoration-underline'>
											<strong>Status</strong>
										</td>
										<td style={{ width: '10px' }} className='text-decoration-underline'>
											<strong>Date & Time</strong>
										</td>
										<td style={{ width: '25px' }} className='text-decoration-underline'>
											<strong>Remarks</strong>
										</td>
									</tr>
								</thead>
								<tbody>
									{staffRequestHistoryData.map(
										(status: any) => (
											<tr key={status.staffRequestHistoryId}>
												<td>{status.actionStaffName}</td>
												<td>{status.staffName}</td>
												<td>{status.action}</td>
												<td>{status.assignedStaffName != '' ? status.assignedStaffName : "-"}</td>
												<td className={status.statusId == 1 ? 'text-info' : status.statusId == 2 ? 'text-success' : 'text-danger'}> <Icon icon='Circle' color={status.statusId == 1 ? 'info' : status.statusId == 2 ? 'success' : 'danger'} />{" "}{status.statusName}</td>
												<td>{dayjs(status.statusUpdatedOn).format('D MMMM, YYYY h:mm A')}</td>
												<td>{status.remarks}</td>
											</tr>))}
								</tbody>
							</table>
						</div>
					</div>
				</ModalBody>
			</Modal>

			<Modal
				setIsOpen={setDisposalModal}
				isOpen={disposalModal}
				titleId='disposalModal'
				isStaticBackdrop
				size='lg'>
				<ModalHeader className="'modal-header'"
					setIsOpen={() => { setDisposalModal(false) }} onClick={closeAndResetForm}>
					<ModalTitle id="disposalModal">Disposal</ModalTitle>
				</ModalHeader>
				<ModalBody >
					<form noValidate onSubmit={payLoanAmountDenominationForm.handleSubmit}>
						<div className='row'>
							<h5 className="main-content-label mb-1 text-danger text-center fw-bold">Loan Requested amount - ₹ {loanTotalAmount}</h5>
						</div>

						<CardBody>
							<div className='row h-100 pb-3'>
								<div className='col-lg-4 col-md-6'>
									<Card stretch>
										<CardHeader>
											<CardLabel icon='List'>
												<CardTitle tag='div' className='h5'>
													Payment Denomination
												</CardTitle>
											</CardLabel>
										</CardHeader>
										<CardBody>
											<div className='row g-3'>
												<div className='col-12'>
													<Button
														icon='PendingActions'
														color='info'
														className='w-100 p-3'
														isLight={TABS.CASH_PAYMENTS !== activeTab}
														onClick={() => { setActiveTab(TABS.CASH_PAYMENTS); formReset(); setPaymentTypeId(1); }}>
														{TABS.CASH_PAYMENTS}
													</Button>
												</div>
												<div className='col-12'>
													<Button
														icon='LocalPolice'
														color='info'
														className='w-100 p-3'
														isLight={TABS.CHEQUE !== activeTab}
														onClick={() => { setActiveTab(TABS.CHEQUE); formReset(); setPaymentTypeId(2); }}>
														{TABS.CHEQUE}
													</Button>
												</div>
												<div className='col-12'>
													<Button
														icon='Style'
														color='info'
														className='w-100 p-3'
														isLight={TABS.NEFT_UPI !== activeTab}
														onClick={() => { setActiveTab(TABS.NEFT_UPI); formReset(); setPaymentTypeId(3); }}>
														{TABS.NEFT_UPI}
													</Button>
												</div>
												<div className='col-12'>
													<Button
														icon='CreditCard'
														color='info'
														className='w-100 p-3'
														isLight={TABS.CARD_PAYMENT !== activeTab}
														onClick={() => { setActiveTab(TABS.CARD_PAYMENT); formReset(); setPaymentTypeId(4); }}>
														{TABS.CARD_PAYMENT}
													</Button>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
								<div className='col-lg-8 col-md-6'>
									{TABS.CASH_PAYMENTS === activeTab && (
										<Card stretch>
											<CardHeader>
												<CardLabel icon='PendingActions'>
													<CardTitle tag='div' className='h5'>
														Cash Denomination
													</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody>
												<div className='row'>
													<div className="d-grid gap-4">
														<div className="row justify-content-center">
															<div className="col-lg-4 align-self-center h6">
															</div>
															<div className="col-lg-4 justify-content-center">
																Count
															</div>

															<div className="col-lg-4 justify-content-center">
																Amount
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-1 align-self-center h6">
															</div>
															<div className="col-lg-3 mt-2 align-self-center h6">
																<p>500 X</p>
															</div>
															<div className="col-lg-4 justify-content-center">
																<FormGroup id="fiveHundred_count" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.fiveHundred_count} onInput={multiply500} onKeyDown={(e: any) => onlyAllowNumber(e)} />
																</FormGroup>
															</div>

															<div className="col-lg-4 justify-content-center">
																<FormGroup id="fiveHundred_total" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={fiveHundred_total} disabled />
																</FormGroup>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-1 align-self-center h6">
															</div>
															<div className="col-lg-3 mt-2 align-self-center h6">
																<p>200 X</p>
															</div>
															<div className="col-lg-4 justify-content-center">
																<FormGroup id="twoHundred_total" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.twoHundred_total} onInput={multiply200} onKeyDown={(e: any) => onlyAllowNumber(e)} />
																</FormGroup>
															</div>

															<div className="col-lg-4 justify-content-center">
																<FormGroup id="twoHundred_total" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={twoHundred_total} disabled />
																</FormGroup>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-1 align-self-center h6">
															</div>
															<div className="col-lg-3 mt-2 align-self-center h6">
																<p>100 X</p>
															</div>
															<div className="col-lg-4 justify-content-center">
																<FormGroup id="hundred_count" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.hundred_count} onInput={multiply100} onKeyDown={(e: any) => onlyAllowNumber(e)} />
																</FormGroup>
															</div>

															<div className="col-lg-4 justify-content-center">
																<FormGroup id="hundred_total" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={hundred_total} disabled />
																</FormGroup>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-1 align-self-center h6">
															</div>
															<div className="col-lg-3 mt-2 align-self-center h6">
																<p>50 X</p>
															</div>
															<div className="col-lg-4 justify-content-center">
																<FormGroup id="fifty_count" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.fifty_count} onInput={multiply50} onKeyDown={(e: any) => onlyAllowNumber(e)} />
																</FormGroup>
															</div>

															<div className="col-lg-4 justify-content-center">
																<FormGroup id="fifty_total" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={fifty_total} disabled />
																</FormGroup>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-1 align-self-center h6">
															</div>
															<div className="col-lg-3 mt-2 align-self-center h6">
																<p>20 X</p>
															</div>
															<div className="col-lg-4 justify-content-center">
																<FormGroup id="twenty_count" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.twenty_count} onInput={multiply20} onKeyDown={(e: any) => onlyAllowNumber(e)} />
																</FormGroup>
															</div>

															<div className="col-lg-4 justify-content-center">
																<FormGroup id="twenty_total" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={twenty_total} disabled />
																</FormGroup>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-1 align-self-center h6">
															</div>
															<div className="col-lg-3 mt-2 align-self-center h6">
																<p>10 X</p>
															</div>
															<div className="col-lg-4 justify-content-center">
																<FormGroup id="ten_count" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.ten_count} onInput={multiply10} onKeyDown={(e: any) => onlyAllowNumber(e)} />
																</FormGroup>
															</div>

															<div className="col-lg-4 justify-content-center">
																<FormGroup id="ten_total" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={ten_total} disabled />
																</FormGroup>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-1 align-self-center h6">
															</div>
															<div className="col-lg-3 mt-2 align-self-center h6">
																<p>5 X</p>
															</div>
															<div className="col-lg-4 justify-content-center">
																<FormGroup id="five_count" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.five_count} onInput={multiply5} onKeyDown={(e: any) => onlyAllowNumber(e)} />
																</FormGroup>
															</div>

															<div className="col-lg-4 justify-content-center">
																<FormGroup id="five_total" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={five_total} disabled />
																</FormGroup>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-1 align-self-center h6">
															</div>
															<div className="col-lg-3 mt-2 align-self-center h6">
																<p></p>
															</div>
															<div className="col-lg-4 mt-2 justify-content-center">
																<p>Total Amount</p>
															</div>
															<div className="col-lg-4 justify-content-center">
																<FormGroup id="totalAmount" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={totalAmount} disabled />
																</FormGroup>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-1 align-self-center h6">
															</div>
															<div className="col-lg-3 mt-2 align-self-center h6">
																<p></p>
															</div>
															<div className="col-lg-4 mt-2 justify-content-center">
																<p>Balance Cash Amount</p>
															</div>
															<div className="col-lg-4 justify-content-center">
																<FormGroup id="balCashAmount" label="">
																	<Input className={balCashAmount < 0 ? 'border border-danger' : ''} type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={balCashAmount} readOnly />
																	<>
																		{balCashAmount < 0 ?
																			<div className='ms-2 text-danger'>
																				Amount Not Matching !{' '}<Icon icon='Report' color='danger' className='mx-1' size='lg' />
																			</div> : null}
																	</>
																</FormGroup>
															</div>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									)}
									{TABS.CHEQUE === activeTab && (
										<Card stretch>
											<CardHeader>
												<CardLabel icon='LocalPolice'>
													<CardTitle tag='div' className='h5'>
														Cheque Details
													</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody>
												<div className='row'>
													<div className="d-grid gap-4">
														<div className="row justify-content-center">
															<div className="col-lg-4 mt-2 align-self-center h6">
																<p>Cheque Number</p>
															</div>
															<div className="col-lg-7 justify-content-center">
																<FormGroup id="chequeNumber" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.chequeNumber} />
																</FormGroup>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-4 mt-2 align-self-center h6">
																<p>Amount</p>
															</div>

															<div className="col-lg-7 justify-content-center">
																<FormGroup id="chequeAmount" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} onBlur={payLoanAmountDenominationForm.handleBlur} value={payLoanAmountDenominationForm.values.chequeAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />

																</FormGroup>
																<>
																	{loanTotalAmount == Number(payLoanAmountDenominationForm.values.chequeAmount) ? null : (
																		<div className="ms-2 mt-2 text-danger">
																			Amount Not Matching !{' '}
																			<Icon icon="Report" color="danger" className="mx-1" size="lg" />
																		</div>
																	)}
																</>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-4 mt-2 align-self-center h6">
																<p>Cheque Date</p>
															</div>
															<div className="col-lg-7 justify-content-center">
																<FormGroup id="chequeDate" label="">
																	<Input type="date" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.chequeDate} />
																</FormGroup>
															</div>

														</div>
														<div className="row justify-content-center">
															<div className="col-lg-4 mt-2 align-self-center h6">
																<p>Bank Name</p>
															</div>

															<div className="col-lg-7 justify-content-center">
																<FormGroup id="chequeBankName" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.chequeBankName} />
																</FormGroup>
															</div>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									)}
									{TABS.NEFT_UPI === activeTab && (
										<Card stretch>
											<CardHeader>
												<CardLabel icon='Style' iconColor='primary'>
													<CardTitle tag='div' className='h5'>
														NEFT / UPI Details
													</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody>
												<div className='row'>
													<div className="d-grid gap-4">
														<div className="row justify-content-center">
															<div className="col-lg-4 mt-2 align-self-center h6">
																<p>NEFT / UPI Ref Number</p>
															</div>
															<div className="col-lg-7 justify-content-center">
																<FormGroup id="neftRefNumber" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.neftRefNumber} />
																</FormGroup>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-4 mt-2 align-self-center h6">
																<p>Amount</p>
															</div>

															<div className="col-lg-7 justify-content-center">
																<FormGroup id="neftAmount" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.neftAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />
																</FormGroup>
																<>
																	{loanTotalAmount == Number(payLoanAmountDenominationForm.values.neftAmount) ? null : (
																		<div className="ms-2 mt-2 text-danger">
																			Amount Not Matching !{' '}
																			<Icon icon="Report" color="danger" className="mx-1" size="lg" />
																		</div>
																	)}
																</>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-4 mt-2 align-self-center h6">
																<p>Transaction Date</p>
															</div>

															<div className="col-lg-7 justify-content-center">
																<FormGroup id="neftTranDate" label="">
																	<Input type="date" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.neftTranDate} />
																</FormGroup>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-4 mt-2 align-self-center h6">
																<p>Bank Name</p>
															</div>

															<div className="col-lg-7 justify-content-center">
																<FormGroup id="neftBankName" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.neftBankName} />
																</FormGroup>
															</div>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									)}
									{TABS.CARD_PAYMENT === activeTab && (
										<Card stretch>
											<CardHeader>
												<CardLabel icon='CreditCard' iconColor='primary'>
													<CardTitle tag='div' className='h5'>
														Card Details
													</CardTitle>
												</CardLabel>
											</CardHeader>
											<CardBody>
												<div className='row'>
													<div className="d-grid gap-4">
														<div className="row justify-content-center">
															<div className="col-lg-4 mt-2 align-self-center h6">
																<p>Card Number</p>
															</div>
															<div className="col-lg-7 justify-content-center">
																<FormGroup id="cardNumber" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.cardNumber} />
																</FormGroup>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-4 mt-2 align-self-center h6">
																<p>Amount</p>
															</div>

															<div className="col-lg-7 justify-content-center">
																<FormGroup id="cardAmount" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.cardAmount} onKeyDown={(e: any) => onlyAllowNumber(e)} />
																</FormGroup>
																<>
																	{loanTotalAmount == Number(payLoanAmountDenominationForm.values.cardAmount) ? null : (
																		<div className="ms-2 mt-2 text-danger">
																			Amount Not Matching !{' '}
																			<Icon icon="Report" color="danger" className="mx-1" size="lg" />
																		</div>
																	)}
																</>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-4 mt-2 align-self-center h6">
																<p>Transaction Date</p>
															</div>

															<div className="col-lg-7 justify-content-center">
																<FormGroup id="cardDate" label="">
																	<Input type="date" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.cardDate} />
																</FormGroup>
															</div>
														</div>
														<div className="row justify-content-center">
															<div className="col-lg-4 mt-2 align-self-center h6">
																<p>Bank Name</p>
															</div>

															<div className="col-lg-7 justify-content-center">
																<FormGroup id="cardBankName" label="">
																	<Input type="text" placeholder="" onChange={payLoanAmountDenominationForm.handleChange} value={payLoanAmountDenominationForm.values.cardBankName} />
																</FormGroup>
															</div>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									)}
								</div>
							</div>
							<div className='row h-100 pb-3'>
								<div className="col-lg-9">
									<FormGroup id="remarks" label="Remarks" isFloating>
										<Textarea placeholder='Enter Remarks' onChange={editLoanForm.handleChange} value={editLoanForm.values.remarks} />
									</FormGroup>
								</div>
								{paymentTypeId == 1 || paymentTypeId == '' ?
									<div className="col-lg-3 mt-2">
										<Button color='info' icon='Save' type="submit" isDisable={totalAmount > 0 ? false : true} >Save</Button>
									</div>
									: null}
								{paymentTypeId == 2 ?
									<div className="col-lg-3 mt-2">
										<Button color='info' icon='Save' type="submit" isDisable={Number(loanTotalAmount == Number(payLoanAmountDenominationForm.values.chequeAmount)) ? false : true}>Save</Button>
									</div>
									: null}

								{paymentTypeId == 3 ?
									<div className="col-lg-3 mt-2">
										<Button color='info' icon='Save' type="submit" isDisable={Number(loanTotalAmount == Number(payLoanAmountDenominationForm.values.neftAmount)) ? false : true} >Save</Button>
									</div>
									: null}

								{paymentTypeId == 4 ?
									<div className="col-lg-3 mt-2">
										<Button color='info' icon='Save' type="submit" isDisable={Number(loanTotalAmount == Number(payLoanAmountDenominationForm.values.cardAmount)) ? false : true}>Save</Button>
									</div>
									: null}
							</div>
						</CardBody>
					</form>
				</ModalBody>
			</Modal>

			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
}

export default LoansList;
