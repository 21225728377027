import React, { forwardRef, HTMLAttributes, ReactNode } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { IOptionsProps } from '../../components/bootstrap/Option';
import ReactSelect from 'react-select';
import Validation from '../../components/bootstrap/forms/Validation';
import './select.css'
import useDarkMode from '../../hooks/useDarkMode';
import { customStyles, customStylesForCanvas } from '../../services/searchableSelect.service';

interface ISelectProps extends Partial<IOptionsProps>, HTMLAttributes<HTMLSelectElement> {
	id?: string;
	className?: string;
	name?: string;
	children?: ReactNode;
	ariaLabel: string;
	placeholder?: string;
	size?: 'lg' | 'sm';
	multiple?: boolean;
	disabled?: boolean;
	required?: boolean;
	isFloating?: boolean;
	ariaDescribedby?: string;
	ariaLabelledby?: string;
	title?: string;
	//value?: string | string[];
	value?: any | string[];
	defaultValue?: string | string[];
	isTouched?: boolean;
	isValid?: boolean;
	invalidFeedback?: string;
	validFeedback?: string;
	isValidMessage?: boolean;
	isTooltipFeedback?: boolean;
	onBlur?(...args: unknown[]): unknown;
	onChange?(...args: unknown[]): unknown;
	onFocus?(...args: unknown[]): unknown;
	onInput?(...args: unknown[]): unknown;
	onInvalid?(...args: unknown[]): unknown;
	onSelect?(...args: unknown[]): unknown;
}

const customNoOptionsMessage = () => "No Data available";

const SearchableSelect = forwardRef<HTMLSelectElement, ISelectProps>(
	(
		{
			id,
			name,
			className,
			children,
			required,
			placeholder,
			ariaDescribedby,
			ariaLabelledby,
			ariaLabel,
			list,
			multiple,
			title,
			size,
			disabled,
			value,
			defaultValue,
			isValid,
			isTouched,
			invalidFeedback,
			validFeedback,
			isValidMessage,
			isTooltipFeedback,
			isFloating,
			onBlur,
			onChange,
			onFocus,
			onInput,
			onInvalid,
			onSelect,
			...props
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		ref,
	) => {
		const { darkModeStatus } = useDarkMode()

		return (
			<>
				{isFloating ?
					<ReactSelect id={'selectSize'}
						isClearable
						className={classNames(
							'form-select',
							{
								[`form-select-${size}`]: size,
								'text-muted': value === '' && placeholder,
								'is-invalid': !isValid && isTouched && invalidFeedback,
								'is-valid': !isValid && isTouched && !invalidFeedback,
							},
							className,
						)}

						placeholder={placeholder ? placeholder : ariaLabel}
						aria-label={ariaLabel}
						value={value}
						onChange={onChange}
						options={list}
						onBlur={onBlur}
						isMulti={multiple}
						styles={customStyles(darkModeStatus)}
						onFocus={onFocus}
						noOptionsMessage={customNoOptionsMessage}
						isDisabled={disabled}
					/>
					:
					<ReactSelect isClearable id={'selectSizeForCanvas'}
						className={classNames(
							'form-select',
							{
								[`form-select-${size}`]: size,
								'text-muted': value === '' && placeholder,
								'is-invalid': !isValid && isTouched && invalidFeedback,
								'is-valid': !isValid && isTouched && !invalidFeedback,
							},
							className,
						)}

						placeholder={placeholder ? placeholder : ariaLabel}
						aria-label={ariaLabel}
						value={value}
						onChange={onChange}
						options={list}
						onBlur={onBlur}
						styles={customStylesForCanvas(darkModeStatus)}
						onFocus={onFocus}
						isMulti={multiple}
						noOptionsMessage={customNoOptionsMessage}
						isDisabled={disabled}
					/>}
				{isValidMessage && (
					<Validation
						isTouched={isTouched}
						invalidFeedback={invalidFeedback}
						validFeedback={validFeedback}
						isTooltip={isTooltipFeedback}
					/>
				)}
			</>
		);
	},
);
SearchableSelect.displayName = 'Select';
SearchableSelect.propTypes = {
	id: PropTypes.string,
	className: PropTypes.string,
	/**
	 * If the name value is left blank, the id value is assigned.
	 */
	name: PropTypes.string,
	children: PropTypes.node,
	ariaLabel: PropTypes.string.isRequired,
	placeholder: PropTypes.string,
	size: PropTypes.oneOf(['lg', 'sm']),
	multiple: PropTypes.bool,
	/**
	 * A *disabled* element isn't editable and isn't sent on submit.
	 */
	disabled: PropTypes.bool,
	required: PropTypes.bool,
	ariaDescribedby: PropTypes.string,
	ariaLabelledby: PropTypes.string,
	title: PropTypes.string,
	/**
	 * For formik ***`formik.values.ID_OR_NAME`***
	 */
	// @ts-ignore
	value: PropTypes.oneOfType([PropTypes.any, PropTypes.arrayOf(PropTypes.any)]),
	// @ts-ignore
	defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
	// @ts-ignore
	list: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
			label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
		}),
	),
	/**
	 * For formik ***`formik.touched.ID_OR_NAME`***
	 */
	isTouched: PropTypes.bool,
	/**
	 * For formik ***`formik.isValid`***
	 */
	isValid: PropTypes.bool,
	/**
	 * For formik ***`formik.errors.ID_OR_NAME`***
	 */
	invalidFeedback: PropTypes.string,
	validFeedback: PropTypes.string,
	isValidMessage: PropTypes.bool,
	isTooltipFeedback: PropTypes.bool,
	/**
	 * Fires the moment that the element loses focus. For formik ***`formik.handleBlur`***
	 */
	onBlur: PropTypes.func,
	/**
	 * Fires the moment when the value of the element is changed. For formik ***`formik.handleChange`***
	 */
	onChange: PropTypes.func,
	/**
	 * Fires the moment when the element gets focus
	 */
	onFocus: PropTypes.func,
	/**
	 * Script to be run when an element gets user input
	 */
	onInput: PropTypes.func,
	/**
	 * Script to be run when an element is invalid
	 */
	onInvalid: PropTypes.func,
	/**
	 * Fires after some text has been selected in an element
	 */
	onSelect: PropTypes.func,
	/**
	 * More information, [react-input-mask](https://github.com/sanniassin/react-input-mask#react-input-mask).
	 */
};
SearchableSelect.defaultProps = {
	id: undefined,
	className: undefined,
	name: undefined,
	children: null,
	placeholder: undefined,
	multiple: false,
	size: undefined,
	disabled: false,
	required: false,
	ariaDescribedby: undefined,
	ariaLabelledby: undefined,
	title: undefined,
	value: undefined,
	defaultValue: undefined,
	list: undefined,
	isTouched: false,
	isValid: false,
	invalidFeedback: undefined,
	validFeedback: undefined,
	isValidMessage: true,
	isTooltipFeedback: false,
	onBlur: undefined,
	onChange: undefined,
	onFocus: undefined,
	onInput: undefined,
	onInvalid: undefined,
	onSelect: undefined,
};

export default SearchableSelect;
