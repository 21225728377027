import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()


export const getStaffLeaveDetails = (userTypeId: any, staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffLeaveDetails/${getLicenseKey}/${userTypeId}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffLeaveDetails - " + error.message)
            console.log('error caught in service : getStaffLeaveDetails')
        },
    );
export const addStaffLeave = (leavePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffLeave`, leavePostData,
        response => {
            success(response)
        },
        error => {
            failure("addStaffLeave- " + error.message)
            console.log('error caught in service : addStaffLeave')
        },
    );
export const updateStaffLeave = (leavePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffLeave`, leavePostData,
        response => {
            success(response)
        },
        error => {
            failure("updateStaffLeave - " + error.message)
            console.log('error caught in service : updateStaffLeave')
        },
    );
export const deleteStaffLeave = (leavePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStaffLeave`, leavePostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteStaffLeave - " + error.message)
            console.log('error caught in service : deleteStaffLeave')
        },
    );

    export const getStaffLeaveDetailsRequestHistory = (requestTableId: number,success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

        api.get(getAPIURL() + `getStaffRequestHistory/${getLicenseKey}/${requestTableId}/13`,
            response => {
                success(response)
            },
            error => {
                failure("getStaffRequestHistory - " + error.message)
                console.log('error caught in service : getStaffRequestHistory')
            },
        );
    