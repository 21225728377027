import { AxiosResponse } from 'axios';
import service from '../api/api.services';
import { getAPIURL, getLicenseKey } from './application.settings';

const api = service();

export const getDashboardDataByUserTypeId = (userTypeId : any,  userAccountId : any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,
) => api.get(
	getAPIURL() + `getDashboardDataByUserTypeId/${getLicenseKey}/${userTypeId}/${userAccountId}`,
	(response) => {
		success(response);
	},
	(error) => {
		failure('getDashboardDataByUserTypeId - ' + error.message);
		console.log('error caught in service : getDashboardDataByUserTypeId');
	},
);