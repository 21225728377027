import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';
import Button from '../../components/bootstrap/Button';
import Card, { CardBody, CardLabel, CardTitle } from '../../components/bootstrap/Card';
import Avatar from '../../components/Avatar';
import Icon from '../../components/icon/Icon';
import useDarkMode from '../../hooks/useDarkMode';
import UserImage2Webp from '../../assets/img/wanna/wanna1.png';
import UserImage2 from '../../assets/img/wanna/wanna1.png';
import { getEmployeeProfile } from '../../services/employee.service';
import AuthContext from '../../contexts/authContext';
import { toasts } from '../../services/toast.service';
import { useParams } from 'react-router-dom';
import { pictNotLoading, profilePic } from '../../services/common.service';

const EmployeeProfile = () => {

	let { employeeDetailsId } = useParams()

	useEffect(() => {
		getEmployeeProfileList(employeeDetailsId);
	}, []);

	const { darkModeStatus } = useDarkMode();
	const [employeeProfileData, setEmployeeProfileData] = useState<any>([]);
	const [dataSuccess, setDataSuccess] = useState(false);
	const { userAccountId, userTypeId} = useContext(AuthContext);

	function getEmployeeProfileList(employeeDetailsId: any) {
		getEmployeeProfile(employeeDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.employeeProfile;
					if (data != undefined) {
						setEmployeeProfileData(data);
						setDataSuccess(false);
					} else {
						toasts('Undefined Data', 'Error');
						setDataSuccess(false);
					}
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	return (
		<>
			{employeeProfileData.map((employee: any) => (
				<PageWrapper title={`${employee.employeeFullName}`} key={employee.employeeDetailsId}>

					<SubHeader>
						<SubHeaderLeft>
							<Button
								color='info'
								isLink
								icon='ArrowBack'
								tag='a'
								to={`../employee/employeeList`}>
								Back to List
							</Button>
							<SubheaderSeparator />
						</SubHeaderLeft>
						{userTypeId == 1 ?
							<SubHeaderRight>
								<Button
									color='primary'
									isLight
									icon='Edit'
									tag='a'
									to={`../academic/updateStaff/${employee.employeeDetailsId}`}>
									Edit
								</Button>
							</SubHeaderRight>
							: <></>}
					</SubHeader>
					<Page>
						<div className='row'>
							<div className='col-lg-4'>
								<Card className='shadow-3d-info'>
									<CardBody>
										<div className='row g-3'>
											<div className='col-12 d-flex justify-content-center'>
												{employee.profilePath != null ?
													<img style={{ borderRadius: '50%' }} src={employee.profilePath}
														width="120" height="120" onError={(e: any) => pictNotLoading(e, employee.genderId)} />
													: <img src={profilePic(employee.genderId)}
														width="120" height="120" />}
											</div>
											<div className='col-12'>
												<div className='row g-2'>
													<span className='fs-4 fw-bold me-2  d-flex justify-content-center text-center text-uppercase'>{`${employee.employeeFullName}`}</span>
													<div className='h5 text-muted text-lowercase opacity-50 d-flex justify-content-center text-uppercase'>
														{`${employee.roleName}`}
													</div>

													<div className='col-6'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon icon='ViewList' size='3x' color='info' />
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{employee.employeeId ? employee.employeeId : <Icon icon='Splitscreen' className='mb-0 text-dark fw-bold h2' />}
																</div>
																<div className='text-muted'>
																	Employee Id
																</div>
															</div>
														</div>
													</div>
													<div className='col-6'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon icon='PhoneIphone' size='3x' color='info' />
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{employee.mobileNumber ? employee.mobileNumber : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
																</div>
																<div className='text-muted'>
																	Mobile
																</div>
															</div>
														</div>
													</div>
													<div className='col-6'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon icon={employee.genderId == 1 ? 'Male' : 'Female'} size='3x' color='info' />
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{employee.gender ? employee.gender : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}</div>
																<div className='text-muted'>
																	Gender
																</div>
															</div>
														</div>
													</div>
													<div className='col-6'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon icon='DateRange' size='3x' color='info' />
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{employee.age ? employee.age : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}                                                                     </div>
																<div className='text-muted'>
																	Age
																</div>
															</div>
														</div>
													</div>

													<div className='col-12'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon icon='Mail' size='3x' color='info' />
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{employee.fatherName ? employee.fatherName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
																</div>
																<div className='text-muted'>
																	Father Name
																</div>
															</div>
														</div>
													</div>
													<div className='col-12'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon icon='Mail' size='3x' color='info' />
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{employee.emailAddress ? employee.emailAddress : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
																</div>
																<div className='text-muted'>
																	Personal Email
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>

							{/* Other Info */}
							<div className='col-lg-8'>
								<Card className='shadow-3d-primary'>
									<div className='ms-4 mt-4'>
										<CardLabel icon='Summarize' iconColor='success'>
											<CardTitle tag='div' className='h5'>
												Other Info
											</CardTitle>
										</CardLabel>
									</div>
									<CardBody>
										<div className='row g-3'>
											<div className='col-md-6'>
												<Card
													className={`bg-l${darkModeStatus ? 'o25' : '25'
														}-warning bg-l${darkModeStatus ? 'o50' : '10'
														}-warning-hover transition-base rounded-2 mb-4`}
													shadow='sm'>
													<div className='ms-4 mt-4'>
														<CardLabel className='bg-transparent'>
															<CardTitle tag='div' className='h5'>
																Work Details
															</CardTitle>
														</CardLabel>
													</div>
													<CardBody>
														<div className='row g-3'>
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='Mail'
																			size='2x'
																			color='info'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold fs-6 mb-0'>
																			{employee.workEmail ? (
																				employee.workEmail
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			workEmail
																		</div>
																	</div>
																</div>
															</div>

															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='DateRange'
																			size='2x'
																			color='info'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold fs-6 mb-0'>
																			{employee.dateOfJoining ? (
																				employee.dateOfJoining
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			date Of Joining
																		</div>
																	</div>
																</div>
															</div>

															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='HomeWork'
																			size='2x'
																			color='info'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold fs-6 mb-0'>
																			{employee.departmentName ? (
																				employee.departmentName
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Department Name
																		</div>
																	</div>
																</div>
															</div>
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='Category'
																			size='2x'
																			color='info'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold fs-6 mb-0'>
																			{employee.designationName ? (
																				employee.designationName
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Designation Name
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</CardBody>
												</Card>
												<Card
													className={`bg-l${darkModeStatus ? 'o25' : '25'
														}-secondary bg-l${darkModeStatus ? 'o50' : '10'
														}-secondary-hover transition-base rounded-2 mb-4`}
													shadow='sm'>
													<div className='ms-4 mt-4'>
														<CardLabel className='bg-transparent'>
															<CardTitle tag='div' className='h5'>
																Work Locations
															</CardTitle>
														</CardLabel>
													</div>
													<CardBody>
														<div className='d-flex align-items-center pb-3'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='House'
																	size='3x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold mb-0'>
																	{employee.workLocationsName ? (
																		employee.workLocationsName
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}
																</div>
															</div>
														</div>
													</CardBody>
												</Card>
												<Card
													className={`bg-l${darkModeStatus ? 'o25' : '25'
														}-primary bg-l${darkModeStatus ? 'o50' : '10'
														}-primary-hover transition-base rounded-2 mb-4`}
													shadow='sm'>
													<div className='ms-4 mt-4'>
														<CardLabel className='bg-transparent'>
															<CardTitle tag='div' className='h5'>
																Address
															</CardTitle>
														</CardLabel>
													</div>
													<CardBody>
														<div className='d-flex align-items-center pb-3'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='House'
																	size='3x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold mb-0'>
																	{employee.fullAddress ? (
																		employee.fullAddress
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}
																</div>
															</div>
														</div>
													</CardBody>
												</Card>
											</div>

											<div className='col-md-6'>
												{/* Account Information */}
												<Card
													className={`bg-l${darkModeStatus ? 'o25' : '25'
														}-success bg-l${darkModeStatus ? 'o50' : '10'
														}-success-hover transition-base rounded-2 mb-0`}
													shadow='sm'>
													<div className='ms-4 mt-4'>
														<CardLabel className='bg-transparent'>
															<CardTitle tag='div' className='h5'>
																Account Information
															</CardTitle>
														</CardLabel>
													</div>
													<CardBody className='pt-0'>
														<div className='row g-2 d-flex align-items-center'>
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='CommentBank'
																			size='2x'
																			color='primary'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold fs-6 mb-0 mt-3'>
																			{employee.accountHolderName ? (
																				employee.accountHolderName
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Account Holder Name
																		</div>
																	</div>
																</div>
															</div>
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='AccountBalance'
																			size='2x'
																			color='primary'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold fs-6 mb-0'>
																			{employee.bankName ? (
																				employee.bankName
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Bank Name
																		</div>
																	</div>
																</div>
															</div>
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='EditNote'
																			size='2x'
																			color='primary'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold fs-6 mb-0'>
																			{employee.accountNumber ? (
																				employee.accountNumber
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Account Number
																		</div>
																	</div>
																</div>
															</div>

															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='QrCode'
																			size='2x'
																			color='primary'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold fs-6 mb-0'>
																			{employee.ifsc ? (
																				employee.ifsc
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			IFSC
																		</div>
																	</div>
																</div>
															</div>
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='SwitchAccount'
																			size='2x'
																			color='primary'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold fs-6 mb-0'>
																			{employee.accountType ? (
																				employee.accountType
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Account Type
																		</div>
																	</div>
																</div>
															</div>
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='Article'
																			size='2x'
																			color='primary'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold fs-6 mb-0'>
																			{employee.panNumber ? (
																				employee.panNumber
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Pan Number
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</CardBody>
												</Card>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					</Page>
				</PageWrapper>
			))}
		</>
	);
};

export default EmployeeProfile;
