import React, { useContext, useEffect, useRef, useState } from 'react'
import Button from '../../bootstrap/Button';
import Page from '../../../layout/Page/Page';
import Card, { CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../../bootstrap/Card';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Checks from '../../bootstrap/forms/Checks';
import AlertService from '../../../services/AlertService';
import Textarea from '../../bootstrap/forms/Textarea';
import Input from "../../bootstrap/forms/Input";
import { useFormik } from "formik";
import { useNavigate } from 'react-router-dom';
import { showLoader } from '../../../services/loader.services';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../../layout/SubHeader/SubHeader';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import { toasts } from '../../../services/toast.service';
import { getAllScreens } from '../../../services/screen.service';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import { addUserType } from '../../../services/userType.service';

const AddUserRole = () => {

    useEffect(() => {
        getAllScreensList()
    }, [])

    const navigate = useNavigate();
    const { userTypeId, userAccountId } = useContext(AuthContext);
    const [allScreensData, setAllScreensData] = useState<any>([])
    const [screenArray, setScreenArray] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);

    const userTypeForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            userType: '',
            description: ''
        },
        validate: (values) => {
            const errors: {
                userType?: string;
                description?: string;

            } = {};
            if (!values.userType) {
                errors.userType = 'Required';
            }
            if (!values.description) {
                errors.description = 'Required';
            }
            return errors;
        },
        validateOnChange: false,
        onSubmit: () => { addUserTypeSubmit() },
    });

    function onSelect(event: any, screenId: any) {
        allScreensData.forEach((screenGroup: any) => {
            let screenGroupData = screenGroup.screens
            const newData: any = [];
            screenGroupData.forEach((item: any) => {
                if (item.screenId == screenId) {
                    item.isChecked = event.target.checked
                }
                newData.push(item)
            })
            setData(newData)
        });
        if (event.target.checked == true) {
            screenArray.push(screenId);
        }
        else if (event.target.checked == false) {
            let unCheckArrayId = screenArray.indexOf(Number(screenId))
            screenArray.splice(unCheckArrayId, 1);
        }
        generateMenu(allScreensData)
    }

    function selectAllScreenGroupMain(allScreens: any, e: any) {
        if (e == true) {

            let screenGroupData = allScreens.screens

            const newData: any = [];

            screenGroupData.forEach((item: any) => {

                if (item.screenId == item.screenId) {
                    item.isChecked = true
                    allScreens.isScreenGroupChecked = true
                }
                newData.push(item.screenId)
                screenArray.push(item.screenId);
            })
            setData(newData)
        }
        else {
            let screenGroupData = allScreens.screens

            const newData: any = [];

            screenGroupData.forEach((item: any) => {

                if (item.screenId == item.screenId) {
                    item.isChecked = false
                    allScreens.isScreenGroupChecked = false
                }
                newData.push(item.screenId)
                let unCheckArrayId = screenArray.indexOf(Number(item.screenId))
                screenArray.splice(unCheckArrayId, 1);
            })
            setData(newData)
        }
    }

    function setScreenDetails() {
        return {
            userType: userTypeForm.values.userType,
            description: userTypeForm.values.description,
            userAccountId: userAccountId,
            screenDetails: screenArray,
            licenseKey: getLicenseKey
        }
    }

    function addUserTypeSubmit() {
        showLoader(true);
        if (userTypeForm.isValid) {
            if (screenArray.length > 0) {
                let screenPostData = setScreenDetails()
                addUserType(screenPostData,
                    (response) => {
                        const data = response.data;
                        if (data.success == true) {
                            showLoader(false);
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            setIsNavigate(`../userManagement/userAndRolesList`)
                            setScreenArray([])
                        }
                        else if (data.success == false) {
                            showLoader(false);
                            setAlertStatus({ message: data.message, type: "error" });
                            setIsOpen(true);
                            setIsNavigate(null)
                        }
                        else {
                            showLoader(false);
                            let errorCode = response.data.error[0].error_code;
                            let errorDescription = response.data.error[0].error_description;
                            setAlertStatus({ message: errorDescription, type: "error" });
                            setIsOpen(true);
                            setIsNavigate(null)
                        }
                    }
                    , (error) => {
                        showLoader(false);
                        setAlertStatus({ message: error, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                )
            } else if (screenArray.length == 0) {
                showLoader(false);
                setAlertStatus({ message: 'Please Select Screens!', type: "error" });
                setIsOpen(true);
                setIsNavigate(null)
            }

        } else if (userTypeForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    function getAllScreensList() {
        getAllScreens(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.allScreens;
                    if (data != undefined) {
                        setAllScreensData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function generateMenu(screenData: any) {

        for (let i = 0; i < screenData.length; i++) {

            let screenGroup = screenData[i].screens
            let screenGroup1 = screenData[i]

            let checkedArray = screenGroup.filter((item: any) => item.isChecked).length
            let allScreensCount = screenGroup.length
            if (checkedArray == allScreensCount) {
                screenData.forEach((item: any) => {
                    if (item.screenGroupId == screenGroup1.screenGroupId) {
                        item.isScreenGroupChecked = true
                    }
                })
            }
            else {
                screenData.forEach((item: any) => {
                    if (item.screenGroupId == screenGroup1.screenGroupId) {
                        item.isScreenGroupChecked = false
                    }
                })
            }
        }
    }

    return (
        <PageWrapper>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(`../userManagement/userAndRolesList`)}>
                        Back to List
                    </Button>
                    <SubheaderSeparator />
                    <CardLabel icon='PersonSearch' iconColor='info'>
                        <CardTitle tag='div' className='h5'> New Role</CardTitle>
                    </CardLabel>
                </SubHeaderLeft>
            </SubHeader>

            <Page>
                <form noValidate onSubmit={userTypeForm.handleSubmit}>
                    <div className='row h-100 align-content-start'>
                        <div className='col-12'>
                            <Card>
                                <CardHeader>
                                    <CardLabel icon='Phonelink' iconColor='danger'>
                                        <CardTitle tag='div' className='h5'>
                                            Role Information
                                        </CardTitle>
                                        <CardSubTitle tag='div' className='h6'>
                                            Assign Screes For User Roll
                                        </CardSubTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody>
                                    <div className='row g-4'>
                                        <div className='col-md-4'>
                                            <FormGroup id='userType' label='Role Name' isFloating>
                                                <Input
                                                    aria-placeholder='Role Name'
                                                    autoComplete=''
                                                    onChange={userTypeForm.handleChange}
                                                    onBlur={userTypeForm.handleBlur}
                                                    value={userTypeForm.values.userType}
                                                    isValid={userTypeForm.isValid}
                                                    isTouched={userTypeForm.touched.userType}
                                                    invalidFeedback={userTypeForm.errors.userType}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="col-md-5">
                                            <FormGroup id='description' label='Description' isFloating>
                                                <Textarea
                                                    placeholder="Enter description"
                                                    onChange={userTypeForm.handleChange}
                                                    value={userTypeForm.values.description}
                                                    onBlur={userTypeForm.handleBlur}
                                                    isValid={userTypeForm.isValid}
                                                    invalidFeedback={userTypeForm.errors.description}
                                                    isTouched={userTypeForm.touched.description}
                                                />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>

                        <div className='row'>
                            {allScreensData?.map((allScreens: any) => (
                                <div className="col-6" key={allScreens.screenGroupId}>
                                    <Card stretch>
                                        <CardHeader borderSize={1}>
                                            <CardLabel className="col-lg-5">
                                                <CardTitle tag='div' className='h5 text-primary'>
                                                    <Checks className='border border-primary mt-1'
                                                        label={allScreens.screenGroupDisplayName}
                                                        onChange={(e: any) => selectAllScreenGroupMain(allScreens, e.target.checked)}
                                                        checked={allScreens.isScreenGroupChecked} />
                                                </CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row">
                                                {allScreens?.screens.map((screens: any) => {
                                                    return (
                                                        <div className="col-md-6 mt-2" key={screens.screenId}>
                                                            <Checks
                                                                label={screens.displayName}
                                                                onChange={(e) => onSelect(e, screens.screenId)}
                                                                checked={screens.isChecked}
                                                            />
                                                        </div>
                                                    )
                                                })}
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                        </div>

                        <SubHeader>
                            <SubHeaderLeft><></></SubHeaderLeft>
                            <SubHeaderRight>
                                <SubheaderSeparator />
                                <Button icon='Save' color='info' type='submit'>Save</Button>
                            </SubHeaderRight>
                        </SubHeader>
                    </div>
                </form>
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />
            </Page>
        </PageWrapper>
    );
}

export default AddUserRole