import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useDarkMode from "../../../hooks/useDarkMode";
import AuthContext from "../../../contexts/authContext";
import { getProfileForStaff } from "../../../services/staff.service";
import { toasts } from "../../../services/toast.service";
import Icon from "../../icon/Icon";
import dayjs from "dayjs";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import SubHeader, { SubHeaderLeft, SubHeaderRight } from "../../../layout/SubHeader/SubHeader";
import { profilePic, pictNotLoading } from "../../../services/common.service";
import { getSalaryAdvanceScheduleDetails } from "../../../services/salary.service";
import Button from "../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardSubTitle } from "../../bootstrap/Card";
import { TableLoader } from "../../../services/loader.services";
import Modal, { ModalHeader, ModalTitle, ModalBody, ModalFooter } from "../../bootstrap/Modal";
import { getAmountCollectionDetails } from "../../../services/collection.service";


const salaryAdvancePaySchedule = () => {

    const { salaryAdvanceId } = useParams();

    useEffect(() => {
        setDataSuccess(false);
        getSalaryAdvanceSchedule(salaryAdvanceId)
    }, []);

    const { darkModeStatus } = useDarkMode();
    const [staffProfileData, setStaffProfileData] = useState<any>([]);
    const { userAccountId } = useContext(AuthContext);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [salaryAdvanceData, setSalaryAdvanceData] = useState<any>([]);
    const [salaryAdvancePayScheduleData, setSalaryAdvancePayScheduleData] = useState<any>([]);
    const [paidStatus, setPaidStatus] = useState<any>(true);
    const [salaryAdvanceModal, setSalaryAdvanceModal] = useState(false)
    const [amountCollectionDetails, setAmountCollectionDetails] = useState<any>([]);
    const [dateOfDeduction, setDateOfDeduction] = useState<any>('');

    function getStaffProfile(staffDetailsId: any) {
        getProfileForStaff(
            staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.profileForStaff;
                    if (data != undefined) {
                        setStaffProfileData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getSalaryAdvanceSchedule(salaryAdvanceId: any) {
        getSalaryAdvanceScheduleDetails(salaryAdvanceId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.salaryAdvanceList;
                    let data1 = data[0].salaryAdvancePaySchedule;
                    if (data != undefined) {
                        setSalaryAdvanceData(data);
                        setSalaryAdvancePayScheduleData(data1);
                        getStaffProfile(data[0].staffDetailsId);
                        let totalBalanceAmount1 = 0;
                        for (let data of data1) {
                            let balanceAmount = Number(data.balanceAmount)
                            totalBalanceAmount1 += balanceAmount;
                        }

                        setPaidStatus(totalBalanceAmount1 == 0 ? false : true);

                        for (let i = 0; i < data1.length; i++) {
                            data1[i].sno = i + 1;
                        }
                        setDataSuccess(true);
                    } else {
                        toasts('Undefined Data', 'Error');
                        setDataSuccess(true);

                    }
                } else if (response.data.success === false) {
                    setSalaryAdvanceData([]);
                    setDataSuccess(true);
                }
            },
            (error) => {
                toasts(error, 'Error');
                setDataSuccess(true);
            },
        );
    }

    function getAmountCollection(screenId: any, paymentScheduleId: number) {
        getAmountCollectionDetails(screenId, paymentScheduleId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.amountCollectionDetails;
                    if (data != undefined) {
                        setAmountCollectionDetails(data);
                        setSalaryAdvanceModal(true)
                        setDateOfDeduction(data[0].dateOfDeduction);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedPaymentCollection = i;
            getAmountCollection(22, selectedPaymentCollection.salaryAdvancePayScheduleId);
        }
    }

    return (
        <>
            <PageWrapper title='Pay Schedule'>
                <SubHeader>
                    <SubHeaderLeft>
                        <Button
                            color='info'
                            isLink
                            icon='ArrowBack'
                            tag='a'
                            to={`../salary/salaryAdvance`}>
                            Back to List
                        </Button>
                        {staffProfileData.map((staff: any) => (
                            <div key={staff.staffUserAccountId}>
                                <img style={{ borderRadius: '50%' }}
                                    src={staff.profilePath != null ? staff.profilePath : profilePic(staff.genderId)}
                                    height='40' width='40' onError={(e: any) => pictNotLoading(e, staff.genderId)}
                                />
                                <span>
                                    <strong className='ms-1'>{staff.fullname}{" "} ,</strong>
                                </span>
                                <span className='border border-success border-2 text-success fw-bold px-3 ms-1 py-2 rounded'>
                                    {staff.empNumber}
                                </span>
                            </div>))}
                    </SubHeaderLeft>
                    {paidStatus ?
                        <SubHeaderRight>
                            <Button
                                color='success'
                                icon='Payment'
                                isLight
                                tag='a'
                                to={`../salaryAdvance/amountCollection/${salaryAdvanceId}`}>
                                Pay
                            </Button>
                        </SubHeaderRight> : null}
                </SubHeader>
                <Page>
                    <div className='row'>
                        {!dataSuccess ?
                            <div className='col-lg-12 text-center'>
                                <TableLoader />
                            </div> :
                            <div className='col-lg-12'>
                                <div className='row'>
                                    <div className='col-md-8'>
                                        <Card>
                                            <CardHeader>
                                                <CardLabel icon='Task' iconColor='primary'>
                                                    <CardTitle tag='div' className='h5'>
                                                        Advance Salary Summary
                                                    </CardTitle>
                                                </CardLabel>
                                            </CardHeader>
                                            <CardBody>
                                                <table className='table table-modern table-hover text-center text-nowrap'>
                                                    <thead className='text-uppercase align-middle text-decoration-underline'>
                                                        <tr className='table-primary'>
                                                            <th>
                                                                DATE OF DEDUCTION
                                                            </th>
                                                            <th>TOTAL AMOUNT</th>
                                                            <th>
                                                                PAID AMOUNT
                                                            </th>
                                                            <th>
                                                                BALANCE AMOUNT
                                                            </th>
                                                            <th>
                                                                view
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {salaryAdvancePayScheduleData.map((salaryAdvance: any) => (
                                                            <tr key={salaryAdvance.salaryAdvancePayScheduleId}>
                                                                <td>{salaryAdvance.dateOfDeductionForView}</td>
                                                                <td>{salaryAdvance.totalAmount}</td>
                                                                <td className='text-success'>{salaryAdvance.paidAmount}</td>
                                                                <td className='text-danger'>{salaryAdvance.balanceAmount}</td>
                                                                <td>
                                                                    {salaryAdvance.balanceAmount == 0 ?
                                                                        <Button color='primary' isLight icon='ArrowForwardIos' aria-label='Edit' onClick={() => onActivate(salaryAdvance)} />
                                                                        : "-"}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </CardBody>
                                        </Card>
                                    </div>
                                    <div className='col-md-4'>
                                        <Card
                                            className={`bg-l${darkModeStatus ? 'o25' : '25'
                                                }-primary bg-l${darkModeStatus ? 'o50' : '10'
                                                }-primary-hover transition-base rounded-2 mb-4`}
                                            shadow='sm'>
                                            <div className='ms-4 mt-4'>
                                                <CardLabel className='bg-transparent'>
                                                    <CardTitle tag='div' className='h5'>
                                                        Salary Advance
                                                    </CardTitle>
                                                </CardLabel>
                                            </div>
                                            {salaryAdvanceData.map((advanceSalary: any) => (
                                                <CardBody key={advanceSalary.salaryAdvanceId}>
                                                    <div className='row g-3'>
                                                        <div className='col-12'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon
                                                                        icon='PersonPinCircle'
                                                                        size='2x'
                                                                        color='info'
                                                                    />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-6 mb-0'>
                                                                        {advanceSalary.empAndStaffName}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        Staff Name
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className='col-12'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon
                                                                        icon='Money'
                                                                        size='2x'
                                                                        color='info'
                                                                    />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-6 mb-0'>
                                                                        ₹ {advanceSalary.requestedAmount}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        Requested Amount
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon
                                                                        icon='DateRange'
                                                                        size='2x'
                                                                        color='info'
                                                                    />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-6 mb-0'>
                                                                        {advanceSalary.requestedDateForView}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        Requested Date
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon
                                                                        icon='CalendarViewMonth'
                                                                        size='2x'
                                                                        color='info'
                                                                    />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-6 mb-0'>
                                                                        {advanceSalary.deductionMonth} Months
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        Deduction Month
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon
                                                                        icon='DateRange'
                                                                        size='2x'
                                                                        color='info'
                                                                    />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-6 mb-0'>
                                                                        {advanceSalary.deductionDateForView}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        Deduction Date
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-12'>
                                                            <div className='d-flex align-items-center'>
                                                                <div className='flex-shrink-0'>
                                                                    <Icon
                                                                        icon='Segment'
                                                                        size='2x'
                                                                        color='info'
                                                                    />
                                                                </div>
                                                                <div className='flex-grow-1 ms-3'>
                                                                    <div className='fw-bold fs-6 mb-0'>
                                                                        ₹ {advanceSalary.currentSalaryAmount}
                                                                    </div>
                                                                    <div className='text-muted'>
                                                                        Current Salary Amount
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            ))}
                                        </Card>
                                    </div>
                                </div>
                            </div>}
                    </div>

                    <Modal
                        setIsOpen={setSalaryAdvanceModal}
                        isOpen={salaryAdvanceModal}
                        titleId='salaryAdvanceModal'
                        isStaticBackdrop
                        size='lg'
                    >
                        <ModalHeader className="'modal-header'"
                            setIsOpen={() => { setSalaryAdvanceModal(false) }} >
                            <ModalTitle id="salaryAdvanceModal">Collection Details</ModalTitle>

                        </ModalHeader>
                        <ModalBody>
                            {/* <div className='row g-2'>
                                <div className='col-12 ms-4'>
                                    {staffProfileData.map((staff: any) => (
                                        <div key={staff.staffUserAccountId}>
                                            <div className='d-flex align-items-center'>
                                                <div className='flex-shrink-0'>
                                                    <img style={{ borderRadius: '50%' }}
                                                        src={staff.profilePath != null ? staff.profilePath : profilePic(staff.genderId)}
                                                        height='60' width='60' onError={(e: any) => pictNotLoading(e, staff.genderId)}
                                                    />
                                                </div>
                                                <div className='flex-grow-1 ms-3'>
                                                    <div className='h4 fw-bold'>
                                                        {staff.staffName} - {staff.empNumber}
                                                    </div>
                                                    <div className='h6 text-muted'>Staff Name</div>
                                                </div>
                                                <div className='flex-grow-1 ms-3 '>
                                                    <div className='fw-bold fs-6 mb-0'>
                                                        {dateOfDeduction}
                                                    </div>
                                                    <div className='text-muted'>
                                                        Deduction Date
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className='col-12'>
                                    <table className='table table-modern table-hover'>
                                        <thead>
                                            <tr className='table-primary'>
                                                <td style={{ width: '30px' }} className='text-decoration-underline'>
                                                    <strong>Payment Type</strong>
                                                </td>
                                                <td style={{ width: '25px' }} className='text-decoration-underline'>
                                                    <strong>Total Amount</strong>
                                                </td>
                                                <td style={{ width: '25px' }} className='text-decoration-underline'>
                                                    <strong>bank Name</strong>
                                                </td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {amountCollectionDetails.map(
                                                (details: any) => (<tr key={details.advanceSalaryOrLoanDetailsId}>{details.paymentTypeId == 1 ? <><td>{details.paymentType}</td><td>{details.totalAmount}</td><td>{details.cardBankName != null ? details.cardBankName : "-"}</td> </> : null}{details.paymentTypeId == 2 ? <> <td>{details.paymentType}</td><td>{details.chequeAmount}</td><td>{details.chequeBankName != null ? details.chequeBankName : "-"}</td> </> : null}{details.paymentTypeId == 3 ? <><td>{details.paymentType}</td><td>{details.neftAmount}</td><td>{details.neftBankName != null ? details.neftBankName : "-"}</td> </> : null}{details.paymentTypeId == 4 ? <><td>{details.paymentType}</td><td>{details.cardAmount}</td><td>{details.cardBankName != null ? details.cardBankName : "-"}</td> </> : null}{details.paymentTypeId == 5 ?
                                                    <><td>{details.paymentType}</td><td>{details.amount}</td><td>{details.cardBankName != null ? details.cardBankName : "-"}</td> </> : null}</tr>))}</tbody></table>
                                </div>
                            </div> */}

                            <div className='row px-4'>
                                <div className='col-xl-4 '>
                                    <Card stretch>
                                        {/* <CardHeader borderSize={1} size='sm'>
                                            <CardLabel icon='Person' iconColor='info'>
                                                <CardTitle>
                                                    Profile
                                                </CardTitle>
                                            </CardLabel>
                                        </CardHeader> */}
                                        <CardBody>
                                            {staffProfileData.map((staff: any) => (
                                                <div className="row" key={staff.staffUserAccountId}>
                                                    <div className="col-12">
                                                        <div className='d-flex justify-content-center'>
                                                            <img style={{ borderRadius: '50%' }}
                                                                src={staff.profilePath != null ? staff.profilePath : profilePic(staff.genderId)}
                                                                height='100' width='100' onError={(e: any) => pictNotLoading(e, staff.genderId)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 text-center">
                                                        <h5 className='fs-5 fw-bold me-2 text-uppercase mt-3'>{staff.staffName}</h5>
                                                        <div><span className="text-success">{staff.empNumber} </span></div>
                                                    </div>
                                                </div>
                                            ))}
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-xl-8'>
                                    <Card stretch>
                                        <CardHeader borderSize={1} size='sm'>
                                            <CardLabel icon='PendingActions' iconColor='info'>
                                                <CardTitle>
                                                    Paid Details
                                                </CardTitle>
                                                <CardSubTitle tag='div' className='h6'>Deduction Date -{' '}
                                                    <small className='ms-2 text-danger'>
                                                        {dateOfDeduction}
                                                    </small>
                                                </CardSubTitle>
                                            </CardLabel>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <table className="table table-modern table-hover text-nowrap text-md-nowrap mg-b-0">
                                                        <thead>
                                                            <tr className='table-primary'>
                                                                <td style={{ width: '30px' }} className='text-decoration-underline'>
                                                                    <strong>Payment Type</strong>
                                                                </td>
                                                                <td style={{ width: '25px' }} className='text-decoration-underline'>
                                                                    <strong>Total Amount</strong>
                                                                </td>
                                                                <td style={{ width: '25px' }} className='text-decoration-underline'>
                                                                    <strong>bank Name</strong>
                                                                </td>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{amountCollectionDetails.map((details: any) => (<tr key={details.advanceSalaryOrLoanDetailsId}>{details.paymentTypeId == 1 ? <><td>{details.paymentType}</td><td>{details.totalAmount}</td><td>{details.cardBankName != null ? details.cardBankName : "-"}</td></> : null}{details.paymentTypeId == 2 ? <> <td>{details.paymentType}</td><td>{details.chequeAmount}</td><td>{details.chequeBankName != null ? details.chequeBankName : "-"}</td> </> : null}{details.paymentTypeId == 3 ? <><td>{details.paymentType}</td><td>{details.neftAmount}</td><td>{details.neftBankName != null ? details.neftBankName : "-"}</td> </> : null}{details.paymentTypeId == 4 ? <><td>{details.paymentType}</td><td>{details.cardAmount}</td><td>{details.cardBankName != null ? details.cardBankName : "-"}</td> </> : null}{details.paymentTypeId == 5 ? <><td>{details.paymentType}</td><td>{details.amount}</td><td>{details.cardBankName != null ? details.cardBankName : "-"}</td> </> : null}</tr>))}</tbody></table>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            </div>
                        </ModalBody>
                    </Modal>
                </Page>
            </PageWrapper>
        </>
    );
};

export default salaryAdvancePaySchedule;
