import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()


export const getUserType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getUserType/${getLicenseKey}/0`,
        response => {
            success(response)
        },
        error => {
            failure("getUserType - " + error.message)
            console.log('error caught in service : getUserType')
        },
    );

    export const addUserType = (screenPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addUserType`, screenPostData,
        response => {
            success(response)
        },
        error => {
            failure("addUserType - " + error.message)
            console.log('error caught in service : addUserType')
        },
    );

export const updateUserType = (userTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateUserType`, userTypePostData,
        response => {
            success(response)
        },
        error => {
            failure("updateUserType - " + error.message)
            console.log('error caught in service : updateUserType')
        },
    );

export const deleteUserType = (userTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteUserType`, userTypePostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteUserType - " + error.message)
            console.log('error caught in service : deleteUserType')
        },
    );


export const getUserTypeByUserTypeId = (userTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getUserType/${getLicenseKey}/${userTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getUserType - " + error.message)
            console.log('error caught in service : getUserType')
        },
    );
