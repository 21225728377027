import { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import classNames from "classnames";
import useDarkMode from "../../hooks/useDarkMode";
import useSortableData from "../../hooks/useSortableData";
import Button, { ButtonGroup } from "../bootstrap/Button";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../bootstrap/Card";
import Icon from "../icon/Icon";
import Input from "../bootstrap/forms/Input";
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../bootstrap/Dropdown";
import FormGroup from "../bootstrap/forms/FormGroup";
import Checks, { ChecksGroup } from "../bootstrap/forms/Checks";
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from "../bootstrap/OffCanvas";
import { useFormik } from "formik";
import DeleteComponents from "../../common/components/DeleteComponents";
import Textarea from "../bootstrap/forms/Textarea";
import Download from "../Download/download";
import AlertService from "../../services/AlertService";
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../services/export.service";
import { TableLoader, showLoader } from "../../services/loader.services";
import { addDepartment, addDepartmentForCampus, deleteDepartment, deleteDepartmentForCampus, getDepartmentList, updateDepartment, updateDepartmentForCampus } from "../../services/setting.services";
import AuthContext from "../../contexts/authContext";
import PaginationButtons, { dataPagination } from "../PaginationButtons";
import { getColumnsForDataTable, updateColumnsForDataTable, updateDisplayOrder, updateFilter } from "../../services/common.service";
import NoDataMsg from "../../common/components/NoDataMsg";
import { getLicenseKey } from "../../services/application.settings";
import { toasts } from "../../services/toast.service";
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, DropResult, Droppable, DroppableProvided, DroppableStateSnapshot } from "@hello-pangea/dnd";
import { move, reorder } from "../../pages/presentation/project-management/helper/helper";
import Board from "../../pages/presentation/project-management/component/Board";


function DepartmentList() {

    useEffect(() => {
        getDepartment()
    }, []);

    const departmentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            departmentName: '',
            departmentCode: "",
            description: '',
        },
        validate: (values) => {
            const errors: {
                departmentName?: string;
                departmentCode?: string;
                // description?: string;
            } = {};

            if (!values.departmentName) {
                errors.departmentName = 'Required';
            }
            if (!values.departmentCode) {
                errors.departmentCode = 'Required';
            }
            // if (!values.description) {
            //     errors.description = 'Required';
            // }
            return errors;
        },
        validateOnChange: false,
        onSubmit: () => { addDepartmentSubmit() },
    });

    const departmentEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            departmentId: '',
            departmentName: '',
            departmentCode: '',
            description: '',
        },
        validate: (values) => {
            const errors: {
                departmentCode?: string;
                departmentName?: string;
                // description?: string;
            } = {};
            if (!values.departmentCode) {
                errors.departmentCode = '';
            }
            if (!values.departmentName) {
                errors.departmentName = 'Required';
            }
            // if (!values.description) {
            //     errors.description = '';
            // }
            return errors;
        },
        validateOnChange: false,
        onSubmit: () => { updateDepartmentSubmit() },
    });

    //search function
    const columnVisibleForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        validateOnChange: false,
        onSubmit: () => { },
    });

    const [state, setState] = useState<any>({ column1: [] });

    const [isDownload, setIsDownload] = useState(false);
    const { userAccountId, userTypeId, isPayrollConnected } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [departmentList, setDepartmentList] = useState<any>([]);

    // For Table and offCanvas
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(5000);
    const { items, requestSort, getClassNamesFor } = useSortableData(state['column1']);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addDepartmentOffCanvas, setAddDepartmentOffCanvas] = useState(false)
    const [editDepartmentOffCanvas, setEditDepartmentOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);
    const [dataStatus, setDataStatus] = useState(false)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [columnDataById, setColumnDataById] = useState([])
    const [displayOrder, setDisplayOrder] = useState<any>([])

    const [noDataMsg, setNoDataMsg] = useState('')


    function onActivate(i: any) {
        if (i != undefined) {
            let selectedDepartment = i
            setEditDepartmentOffCanvas(true)
            departmentEditForm.setValues({
                departmentId: selectedDepartment.departmentId,
                departmentName: selectedDepartment.departmentName,
                departmentCode: selectedDepartment.departmentCode,
                description: selectedDepartment.description,

            });
        }
    }

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibleForm);

    function getDepartment() {
        setDataStatus(false)
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.departmentList;
                    if (data != undefined) {
                        setDataStatus(true)
                        setDepartmentList(data);
                        getColumnsForDataTableList('getDepartmentList', 'get')

                        let itemData = { 'column1': data }
                        setState(itemData);

                        let newData: any = []
                        data.forEach((item: any, index: any) => {

                            let obj = {
                                departmentId: item['departmentId'],
                                displayOrder: index + 1
                            }
                            newData.push(obj);
                        });

                        setDisplayOrder(newData)
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    getColumnsForDataTableList('getDepartmentList', 'get');
                    setDepartmentList([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true)
                    toasts(errorDescription, "Error")
                }

            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function setDepartmentDetails() {
        return ({
            departmentName: departmentForm.values.departmentName,
            departmentCode: departmentForm.values.departmentCode,
            description: departmentForm.values.departmentName,
            licenseKey: getLicenseKey,
            createdBy: userAccountId
        })
    }

    function addDepartmentSubmit() {
        showLoader(true);
        if (departmentForm.isValid) {
            let departmentPostData = setDepartmentDetails()
            addDepartment(departmentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        if (isPayrollConnected) {
                            addDepartmentForCampus(departmentPostData,
                                (response) => {
                                    const data1 = response.data;
                                    if (data1.success == true) {
                                        showLoader(false);
                                        setAlertStatus({ message: data.message, type: "success" });
                                        setIsOpen(true);
                                        getDepartment()
                                        closeAndResetForm()
                                    } else if (data1.success == false) {
                                        showLoader(false);
                                        setAlertStatus({ message: data1.message, type: "warning" });
                                        setIsOpen(true);
                                    }
                                    else {
                                        showLoader(false);
                                        let errorCode = response.data.error[0].error_code;
                                        let errorDescription = response.data.error[0].error_description;
                                        setAlertStatus({ message: errorDescription, type: "error" });
                                        setIsOpen(true);
                                    }
                                }
                                , (error) => {
                                    showLoader(false);
                                    setAlertStatus({ message: error, type: "error" });
                                    setIsOpen(true);
                                }
                            )

                        } else {
                            showLoader(false);
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            getDepartment()
                            closeAndResetForm()
                        }
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "warning" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )

        } else if (departmentForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
            setIsOpen(true);
        }

    }

    function setUpdateDesignationDetails() {

        return ({
            departmentId: departmentEditForm.values.departmentId,
            departmentName: departmentEditForm.values.departmentName,
            departmentCode: departmentEditForm.values.departmentCode,
            description: departmentEditForm.values.departmentName,
            licenseKey: getLicenseKey,
            isActive: 1,
            userAccountId: userAccountId
        })
    }

    function updateDepartmentSubmit() {
        showLoader(true);
        if (departmentEditForm.isValid) {
            let departmentPostData = setUpdateDesignationDetails()
            updateDepartment(departmentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        if (isPayrollConnected) {
                            updateDepartmentForCampus(departmentPostData,
                                (response) => {
                                    const data1 = response.data;
                                    if (data1.success == true) {
                                        showLoader(false);
                                        setAlertStatus({ message: data.message, type: "success" });
                                        setIsOpen(true);
                                        getDepartment()
                                        closeAndResetForm()
                                    } else if (data1.success == false) {
                                        showLoader(false);
                                        setAlertStatus({ message: data1.message, type: "warning" });
                                        setIsOpen(true);
                                    }
                                    else {
                                        showLoader(false);
                                        let errorCode = response.data.error[0].error_code;
                                        let errorDescription = response.data.error[0].error_description;
                                        setAlertStatus({ message: errorDescription, type: "error" });
                                        setIsOpen(true);
                                    }
                                }
                                , (error) => {
                                    showLoader(false);
                                    setAlertStatus({ message: error, type: "error" });
                                    setIsOpen(true);
                                }
                            )

                        } else {
                            showLoader(false);
                            setAlertStatus({ message: data.message, type: "success" });
                            setIsOpen(true);
                            getDepartment()
                            closeAndResetForm()
                        }
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "warning" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )

        } else if (departmentEditForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
            setIsOpen(true);
        }

    }

    function setDeleteDesignationDetails() {

        return ({
            departmentId: departmentEditForm.values.departmentId,
            departmentName: departmentEditForm.values.departmentName,
            departmentCode: departmentEditForm.values.departmentCode,
            description: departmentEditForm.values.description,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function deleteDepartmentSubmit() {
        showLoader(true);
        let departmentPostData = setDeleteDesignationDetails()
        deleteDepartment(departmentPostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    if (isPayrollConnected) {
                        deleteDepartmentForCampus(departmentPostData,
                            (response) => {
                                const data1 = response.data;
                                if (data1.success == true) {
                                    showLoader(false);
                                    setAlertStatus({ message: data.message, type: "success" });
                                    setIsOpen(true);
                                    getDepartment()
                                    closeAndResetForm()
                                } else if (data1.success == false) {
                                    showLoader(false);
                                    setAlertStatus({ message: data1.message, type: "warning" });
                                    setIsOpen(true);
                                }
                                else {
                                    showLoader(false);
                                    let errorCode = response.data.error[0].error_code;
                                    let errorDescription = response.data.error[0].error_description;
                                    setAlertStatus({ message: errorDescription, type: "error" });
                                    setIsOpen(true);
                                }
                            }
                            , (error) => {
                                showLoader(false);
                                setAlertStatus({ message: error, type: "error" });
                                setIsOpen(true);
                            }
                        )
                    } else {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getDepartment()
                        closeAndResetForm()
                    }
                }
                else if (data.success == false) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "warning" });
                    setIsOpen(true);
                }
                else {
                    showLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false);
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
    }

    function getColumnsForDataTableList(apiName: any, type: any) {
        getColumnsForDataTable(userAccountId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        let keyData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => !item.isDefault)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const updateColumnsSubmit = (columnVisibilityId: any, isDisplay: any, type: any) => {

        if (columnVisibleForm.isValid) {

            setColumnDataById(isDisplay)
            const postData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(postData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForDataTableList('getDepartmentList', 'get')
                    }
                    else if (data.success == false) {
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibleForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndResetForm() {
        setAddDepartmentOffCanvas(false)
        departmentForm.resetForm()
        setEditDepartmentOffCanvas(false);
        departmentEditForm.resetForm();
        setIsDeleteOpen(false);
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    const onDragEnd = (result: DropResult) => {
        const { source, destination }: any = result;

        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const ITEMS = reorder(state[source.droppableId], source.index, destination.index);

            const sourceList = source.droppableId;

            let final = { ...state, [sourceList]: ITEMS }

            for (let i = 0; i < final['column1'].length; i++) {
                final['column1'][i].sno = i + 1;
            }

            setState(final);
        } else {
            const RESULT: any = move(
                state[source.droppableId],
                state[destination.droppableId],
                source,
                destination,
            );

            let final = { ...state, ...RESULT, }

            for (let i = 0; i < final['column1'].length; i++) {
                final['column1'][i].sno = i + 1;
            }
            setState(final);
        }

        updateDisplayOrderSubmit()
    };

    const updateDisplayOrderSubmit = () => {

        displayOrder.forEach((item1: any) => {
            state['column1'].forEach((item: any) => {

                if (item['departmentId'] == item1['departmentId']) {
                    item1['departmentId'] = item['departmentId'],
                        item1['displayOrder'] = item['sno']
                }
            })
        })
        setDisplayOrder(displayOrder)
        const postData = {
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            displayOrder: displayOrder,
            screenType: 1,
        }

        updateDisplayOrder(postData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    getDepartment()
                }
            }
            , (error) => {
                toasts(error, "Error")
            }
        )
    }

    return (
        <PageWrapper title="Department List">
            <Page>
                <DragDropContext onDragEnd={onDragEnd}>
                    <Board>
                        <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info'>
                                    <CardTitle tag='div' className='h5'>Department List</CardTitle>
                                </CardLabel>
                                <CardActions className="d-print-none">
                                    <ButtonGroup color='primary'>
                                        <Icon
                                            className="mt-1"
                                            icon='Search'
                                            size='2x'
                                            color='primary'
                                        />
                                        <Input
                                            id='searchInput'
                                            type='search'
                                            placeholder='Search...'
                                            onChange={columnVisibleForm.handleChange}
                                            value={columnVisibleForm.values.searchInput}
                                        />
                                    </ButtonGroup>
                                    <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                        <DropdownToggle>
                                            <Button
                                                icon='FilterAlt'
                                                color='primary'
                                                isLight>Filter
                                            </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks
                                                                    id='available'
                                                                    type='switch'
                                                                    label='Select All Columns'
                                                                    onChange={() => getColumnsForDataTableList('getDepartmentList', 'post')}
                                                                    checked={isChecked}
                                                                    ariaLabel='Available status'
                                                                />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map((i: any) => (
                                                                        <Checks
                                                                            key={i.columnVisibilityId}
                                                                            label={i.columnName}
                                                                            onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')}
                                                                            checked={i.isDisplay}
                                                                            disabled={i.isDefault}
                                                                        />
                                                                    ))}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("DepartmentList", departmentList, columnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(departmentList, columnVisibilityData, "DepartmentList")}> PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("DepartmentList", departmentList, columnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print'
                                                    onClick={() => handlePrint()}> Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Button
                                        color='primary'
                                        icon='Add'
                                        isLight
                                        onClick={() => setAddDepartmentOffCanvas(true)}
                                    >
                                        Add New
                                    </Button>
                                </CardActions>
                            </CardHeader>
                            <CardBody className='table-responsive'>
                                <Droppable droppableId={'column1'}>
                                    {(
                                        providedDroppable: DroppableProvided,
                                        snapshotDroppable: DroppableStateSnapshot,
                                    ) => (
                                        <div className="row">
                                            {!dataStatus ? <TableLoader /> :
                                                <table className='table table-modern table-hover' {...providedDroppable.droppableProps}
                                                    ref={providedDroppable.innerRef}>
                                                    <thead>
                                                        <tr>
                                                            <th scope='col' onClick={() => requestSort('sno')}
                                                                className='cursor-pointer text-decoration-underline'>
                                                                S.No{' '}
                                                                <Icon size='lg' className={getClassNamesFor('sno')}
                                                                    icon='FilterList' />
                                                            </th>
                                                            {columnVisibilityData.map((column: any) => (
                                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                                    className='cursor-pointer text-decoration-underline'>
                                                                    {column.columnName}
                                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                                </th>
                                                            ))}
                                                            <th scope='col' className='d-print-none'>
                                                                Action
                                                            </th>
                                                        </tr>
                                                    </thead>

                                                    <tbody>{filteredData != "" ? <>{filteredData.map((rowData: any, index: any) => (
                                                        <Draggable key={rowData.departmentName} draggableId={rowData.departmentName} index={index}>
                                                            {(
                                                                providedDraggable: DraggableProvided,
                                                                snapshotDraggable: DraggableStateSnapshot,
                                                            ) => (
                                                                <tr
                                                                    className={classNames({ 'border-info': snapshotDraggable.isDragging })}
                                                                    ref={providedDraggable.innerRef}
                                                                    {...providedDraggable.draggableProps}
                                                                    {...providedDraggable.dragHandleProps}
                                                                    key={rowData.departmentId}
                                                                >
                                                                    <td>{index + 1}</td>

                                                                    {columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.departmentId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}
                                                                    <td className="d-print-none">
                                                                        <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} />
                                                                    </td>
                                                                </tr>
                                                            )}
                                                        </Draggable>))} </> : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                                    </tbody>
                                                </table>}
                                        </div>
                                    )}
                                </Droppable>
                            </CardBody>
                            {/* <PaginationButtons className="d-print-none"
                                data={items}
                                label='items'
                                setCurrentPage={setCurrentPage}
                                currentPage={currentPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                            /> */}
                        </Card>
                    </Board>
                </DragDropContext>
            </Page>

            <OffCanvas
                setOpen={setAddDepartmentOffCanvas}
                isOpen={addDepartmentOffCanvas}
                titleId='addDepartmentOffCanvas'
                isBodyScroll
                placement='end'
                isBackdrop>
                <OffCanvasHeader setOpen={setAddDepartmentOffCanvas} onClick={closeAndResetForm}>
                    <OffCanvasTitle id="addDepartmentOffCanvas">Add Department</OffCanvasTitle>
                </OffCanvasHeader>
                <form noValidate onSubmit={departmentForm.handleSubmit}>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className='col-12'>
                                <FormGroup id='departmentName' label='Department Name'>
                                    <Input
                                        placeholder="Enter Department Name"
                                        onChange={departmentForm.handleChange}
                                        value={departmentForm.values.departmentName}
                                        onBlur={departmentForm.handleBlur}
                                        isValid={departmentForm.isValid}
                                        isTouched={departmentForm.touched.departmentName}
                                        invalidFeedback={departmentForm.errors.departmentName}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='departmentCode' label='Department Code'>
                                    <Input
                                        placeholder="Enter Department Code"
                                        onChange={departmentForm.handleChange}
                                        value={departmentForm.values.departmentCode}
                                        onBlur={departmentForm.handleBlur}
                                        isValid={departmentForm.isValid}
                                        isTouched={departmentForm.touched.departmentCode}
                                        invalidFeedback={departmentForm.errors.departmentCode}
                                    />
                                </FormGroup>
                            </div>
                            {/* <div className='col-12'>
                                <FormGroup id='description' label='Description'>
                                    <Textarea
                                        placeholder="Max 250 characters"
                                        onChange={departmentForm.handleChange}
                                        value={departmentForm.values.description}
                                        onBlur={departmentForm.handleBlur}
                                    // isValid={departmentForm.isValid}
                                    // isTouched={departmentForm.touched.description}
                                    // invalidFeedback={departmentForm.errors.description}
                                    />
                                </FormGroup>
                            </div> */}
                        </div>
                    </OffCanvasBody>
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <div className='row m-0'>
                            <Button
                                color='info'
                                icon="Save"
                                className='w-100'
                                type="submit">
                                Save
                            </Button>
                        </div>
                    </div>
                </form>
            </OffCanvas>

            <OffCanvas
                setOpen={setEditDepartmentOffCanvas}
                isOpen={editDepartmentOffCanvas}
                titleId='editDepartmentOffCanvas'
                isBodyScroll
                placement='end'
                isBackdrop>
                <OffCanvasHeader setOpen={setEditDepartmentOffCanvas} onClick={close}>
                    <OffCanvasTitle id="editDepartmentOffCanvas">Update Department</OffCanvasTitle>
                </OffCanvasHeader>
                <form noValidate onSubmit={departmentEditForm.handleSubmit}>
                    <OffCanvasBody>
                        <div className="row g-4">
                            <div className='col-12'>
                                <FormGroup id='departmentName' label='Department Name'>
                                    <Input
                                        placeholder="Enter Department Name"
                                        onChange={departmentEditForm.handleChange}
                                        value={departmentEditForm.values.departmentName}
                                        onBlur={departmentEditForm.handleBlur}
                                        isValid={departmentEditForm.isValid}
                                        isTouched={departmentEditForm.touched.departmentName}
                                        invalidFeedback={departmentEditForm.errors.departmentName}
                                    />
                                </FormGroup>
                            </div>
                            <div className='col-12'>
                                <FormGroup id='departmentCode' label='Department Code'>
                                    <Input
                                        placeholder="Enter Department Code"
                                        onChange={departmentEditForm.handleChange}
                                        value={departmentEditForm.values.departmentCode}
                                        onBlur={departmentEditForm.handleBlur}
                                        isValid={departmentEditForm.isValid}
                                        isTouched={departmentEditForm.touched.departmentCode}
                                        invalidFeedback={departmentEditForm.errors.departmentCode}
                                    />
                                </FormGroup>
                            </div>
                            {/* <div className='col-12'>
                                <FormGroup id='description' label='Description'>
                                    <Textarea
                                        placeholder="Enter description"
                                        onChange={departmentEditForm.handleChange}
                                        value={departmentEditForm.values.description}
                                        onBlur={departmentEditForm.handleBlur}
                                    // isValid={departmentEditForm.isValid}
                                    // isTouched={departmentEditForm.touched.description}
                                    // invalidFeedback={departmentEditForm.errors.description}
                                    />
                                </FormGroup>
                            </div> */}
                            <div className="d-flex justify-content-center position-absolute top-60 start-50 translate-middle">
                                <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteDepartmentSubmit} />
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <div className='row m-0'>
                            <div className='col-6 p-3'>
                                <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                    Delete
                                </Button>
                            </div>
                            <div className='col-6 p-3'>
                                <Button color='info' icon="Save" type="submit" className='w-100' isDisable={!departmentEditForm.isValid && !departmentEditForm.submitCount} > Update</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </OffCanvas>
            <Download setIsDownload={setIsDownload} isDownload={isDownload} title={'Department'} />
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
}
export default DepartmentList;