import React, { useContext, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Icon from '../icon/Icon';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import FormGroup from '../bootstrap/forms/FormGroup';
import { useReactToPrint } from 'react-to-print';
import Button from '../bootstrap/Button';
import { useFormik } from 'formik';
import Input from '../bootstrap/forms/Input';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import useSortableData from '../../hooks/useSortableData';
import { dataPagination } from '../PaginationButtons';
import Collapse from '../bootstrap/Collapse';
import OpenCardComponent from '../../common/components/OpenCardComponents';
import { convertNumToMonth, getColumnsForDataTable, getPaymentType, onlyAllowNumber } from '../../services/common.service';
import NoDataMsg from '../../common/components/NoDataMsg';
import { toasts } from '../../services/toast.service';
import { getLicenseKey } from '../../services/application.settings';
import AlertService from '../../services/AlertService';
import useMinimizeAside from '../../hooks/useMinimizeAside';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../services/export.service';
import '../../common/components/toPrint.css'
import useDarkMode from '../../hooks/useDarkMode';
import { getCumulativeSalaryReport } from '../../services/report.service';

const CumulativeSalaryReport = () => {

    useMinimizeAside();
    useEffect(() => {
    }, []);

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const { items, requestSort, getClassNamesFor } = useSortableData([]);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [isLoader, setIsLoader] = useState(false);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [cumulativeSalaryData, setCumulativeSalaryData] = useState<any>([]);
    const [noDataMsg, setNoDataMsg] = useState('');
    const [fromMonth, setFromMonth] = useState('');
    const [fromYear, setFromYear] = useState('');
    const [toMonth, setToMonth] = useState('');
    const [toYear, setToYear] = useState('');
    const componentRef = useRef(null);
    const navigate = useNavigate();

    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [columnDataById, setColumnDataById] = useState([]);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const [totalSalaryAmount, setTotalSalaryAmount] = useState<any>(0);
    const [totalPaidSalaryAmount, setTotalPaidSalaryAmount] = useState<any>(0);
    const [totalUpPaidSalaryAmount, setTotalUpPaidSalaryAmount] = useState<any>(0);
    const [totalStaffCount, setTotalStaffCount] = useState<any>(0);

    const payRunForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            fromMonthAndYear: '',
            toMonthAndYear: '',
        },
        validate: (values) => {
            const errors: {
                fromMonthAndYear?: string;
                toMonthAndYear?: string;
                paymentTypeId?: string;
                paymentStatusId?: string;
            } = {};

            if (!values.fromMonthAndYear) {
                errors.fromMonthAndYear = 'Required';
            }
            if (!values.toMonthAndYear) {
                errors.toMonthAndYear = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { viewSalaryDetails() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    function viewSalaryDetails() {
        setDataSuccess(false);
        setIsLoader(true);
        let from = payRunForm.values.fromMonthAndYear;
        let to = payRunForm.values.toMonthAndYear;
        const fromSplitDate = from.split('-');
        const toSplitDate = to.split('-');
        const fromYear = fromSplitDate[0];
        const fromMonth = fromSplitDate[1];
        const toYear = toSplitDate[0];
        const toMonth = toSplitDate[1];

        setFromYear(fromYear);
        setFromMonth(fromMonth);
        setToYear(toYear);
        setToMonth(toMonth);
        CumulativeSalaryList(fromYear, fromMonth, toYear, toMonth);
    }

    function CumulativeSalaryList(fromYear: any, fromMonth: any, toYear: any, toMonth: any) {
        setIsLoader(true);
        getCumulativeSalaryReport(fromYear, fromMonth, toYear, toMonth,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.cumulativeSalaryReport;
                    if (data != undefined) {
                        setCumulativeSalaryData(data);
                        getColumnsForTable('getCumulativeSalaryReport', 'get');
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }

                        setTotalStaffCount(data.length)
                        let totalAmount1 = 0;
                        let totalSalaryAmount1 = 0;
                        let totalPending1 = 0;
                        for (let data1 of data) {
                            let totalSalaryAmount = Number(data1.totalSalaryAmount)
                            let collectionAmount = Number(data1.paidAmount)
                            let pendingAmount = Number(data1.pendingAmount)
                            totalSalaryAmount1 += totalSalaryAmount;
                            totalAmount1 += collectionAmount;
                            totalPending1 += pendingAmount;
                        }
                        setTotalSalaryAmount(totalSalaryAmount1);
                        setTotalPaidSalaryAmount(totalAmount1);
                        setTotalUpPaidSalaryAmount(totalPending1);
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        setIsOpenListCard(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    getColumnsForTable('getCumulativeSalaryReport', 'get');
                    setCumulativeSalaryData([]);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            //   updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title='Cumulative Salary Report'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card
                        stretch
                        data-tour='list'
                        tag='form'
                        noValidate
                        onSubmit={payRunForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Cumulative Salary Report
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-2'>
                                    <FormGroup id='fromMonthAndYear' label='From Month' isFloating>
                                        <Input
                                            onChange={payRunForm.handleChange}
                                            value={payRunForm.values.fromMonthAndYear}
                                            onBlur={payRunForm.handleBlur}
                                            isValid={payRunForm.isValid}
                                            isTouched={payRunForm.touched.fromMonthAndYear}
                                            invalidFeedback={payRunForm.errors.fromMonthAndYear}
                                            type='month'
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-2'>
                                    <FormGroup id='toMonthAndYear' label='To Month' isFloating>
                                        <Input
                                            onChange={payRunForm.handleChange}
                                            value={payRunForm.values.toMonthAndYear}
                                            onBlur={payRunForm.handleBlur}
                                            isValid={payRunForm.isValid}
                                            isTouched={payRunForm.touched.toMonthAndYear}
                                            invalidFeedback={payRunForm.errors.toMonthAndYear}
                                            type='month'
                                        />
                                    </FormGroup>
                                </div>

                                <div className='col-2'>
                                    <Button
                                        className='mt-2'
                                        icon='ArrowDownward'
                                        color='primary'
                                        type='submit'
                                        isDisable={!payRunForm.isValid && !!payRunForm.submitCount}>
                                        View
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent
                    isOpenListCard={isOpenListCard}
                    setIsOpenListCard={setIsOpenListCard}
                    isLoader={isLoader}
                />

                {dataSuccess ? (
                    <Card stretch ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className='col-lg-6'>
                                <CardTitle tag='div' className='h5'>
                                    <span className='text-primary'>{convertNumToMonth(fromMonth)} - {fromYear}</span>  To  <span className='text-primary'>{convertNumToMonth(toMonth)} - {toYear}</span> Cumulative Salary Details
                                </CardTitle>
                            </CardLabel>
                            <CardActions className='d-print-none'>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'>
                                            Export
                                        </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel('CumulativeSalaryReport', cumulativeSalaryData, columnVisibilityData)}>
                                                Excel
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(cumulativeSalaryData, columnVisibilityData, 'CumulativeSalaryReport')}>
                                                PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile('CumulativeSalaryReport', cumulativeSalaryData, columnVisibilityData)}>
                                                CSV
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={() => handlePrint()}>
                                                Print
                                            </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardActions>
                        </CardHeader>

                        <CardBody>
                            {/* <div className='row g-4 align-items-center justify-content-center'>
                                <div className='col-xl-3'>
                                    <div
                                        className={classNames(
                                            'd-flex align-items-center rounded-2 p-3',
                                            {
                                                'bg-l10-secondary': !darkModeStatus,
                                                'bg-lo25-secondary': darkModeStatus,
                                            },
                                        )}>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Group' size='3x' color='secondary' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>{totalStaffCount}</div>
                                            <div className='text-muted mt-n1 truncate-line-1'>
                                                Total Staff
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3'>
                                    <div
                                        className={classNames(
                                            'd-flex align-items-center rounded-2 p-3',
                                            {
                                                'bg-l10-success': !darkModeStatus,
                                                'bg-lo25-success': darkModeStatus,
                                            },
                                        )}>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Checklist' size='3x' color='success' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>{priceFormat(totalSalaryAmount)}</div>
                                            <div className='text-muted mt-n1 truncate-line-1'>
                                                Total Salary
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3'>
                                    <div
                                        className={classNames(
                                            'd-flex align-items-center rounded-2 p-3',
                                            {
                                                'bg-l10-primary': !darkModeStatus,
                                                'bg-lo25-primary': darkModeStatus,
                                            },
                                        )}>
                                        <div className='flex-shrink-0'>
                                            <Icon
                                                icon='Money'
                                                size='3x'
                                                color='primary'
                                            />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'> {priceFormat(totalPaidSalaryAmount)}</div>
                                            <div className='text-muted mt-n1 truncate-line-1'>
                                                Total Paid Salary Amount
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-xl-3'>
                                    <div
                                        className={classNames(
                                            'd-flex align-items-center rounded-2 p-3',
                                            {
                                                'bg-l10-danger': !darkModeStatus,
                                                'bg-lo25-danger': darkModeStatus,
                                            },
                                        )}>
                                        <div className='flex-shrink-0'>
                                            <Icon icon='Report' size='3x' color='danger' />
                                        </div>
                                        <div className='flex-grow-1 ms-3'>
                                            <div className='fw-bold fs-5 mb-0'>{priceFormat(totalUpPaidSalaryAmount)}</div>
                                            <div className='text-muted mt-n1 truncate-line-1'>
                                                Total Un Payable Amount
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className='row g-4'>
                                <table className='table table-modern table-hover'>
                                    <thead>
                                        <tr className='table-primary'>
                                            <td className='text-decoration-underline'>
                                                <strong>S.No</strong>
                                            </td>
                                            <td className='text-decoration-underline'>
                                                <strong>Year</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Month</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>
                                                    Total Salary Amount
                                                </strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Total Paid Amount</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Total Pending Amount</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Total Staff</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Total Paid Staff</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Total Pending Staff</strong>
                                            </td>
                                            <td className='text-center text-decoration-underline'>
                                                <strong>Total Partial Paid Staff</strong>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {cumulativeSalaryData != '' ? (
                                            <>
                                                {cumulativeSalaryData.map(
                                                    (salary: any, index: any) => (
                                                        <tr key={index}>
                                                            <td>{salary.sno}</td>
                                                            <td>{salary.year}</td>
                                                            <td>{salary.month}</td>
                                                            <td className='fw-bold'>{salary.totalSalaryAmount}</td>
                                                            <td className='fw-bold text-success'>{salary.totalPaidAmount}</td>
                                                            <td className='fw-bold text-danger'>{salary.totalPendingAmount}</td>
                                                            <td className='fw-bold'>{salary.totalStaff}</td>
                                                            <td className='fw-bold text-success'>{salary.totalPaidStaff}</td>
                                                            <td className='fw-bold text-danger'>{salary.totalPendingStaff}</td>
                                                            <td className='fw-bold text-danger'>{salary.totalPartialPaidStaff}</td>
                                                        </tr>
                                                    )
                                                )}
                                            </>
                                        ) : (
                                            <NoDataMsg columnsCount={12} msg={noDataMsg} />
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                ) : null}
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
};

export default CumulativeSalaryReport;
