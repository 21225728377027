import React, { useContext, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import NoDataMsg from '../../common/components/NoDataMsg';
import OpenCardComponent from '../../common/components/OpenCardComponents';
import SearchableSelect from '../../common/components/SearchableSelect';
import Page from '../../layout/Page/Page';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../services/export.service';
import PaginationButtons, { dataPagination } from '../PaginationButtons';
import Button, { ButtonGroup } from '../bootstrap/Button';
import Card, { CardHeader, CardLabel, CardTitle, CardBody, CardActions } from '../bootstrap/Card';
import Collapse from '../bootstrap/Collapse';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import FormGroup from '../bootstrap/forms/FormGroup';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from '../bootstrap/OffCanvas';
import { getStaffListByUserTypeId } from '../../services/staff.service';
import { toasts } from '../../services/toast.service';
import { useFormik } from 'formik';
import useSortableData from '../../hooks/useSortableData';
import {
	convertDateToEpoch,
	getColumnsForDataTable,
	getCurrentDateAndTime,
	updateColumnsForDataTable,
	updateFilter,
} from '../../services/common.service';
import { getLicenseKey } from '../../services/application.settings';
import AuthContext from '../../contexts/authContext';
import { useReactToPrint } from 'react-to-print';
import {
	addStaffExtraSalaryDetails,
	deleteStaffExtraSalaryDetails,
	getSalaryType,
	getStaffExtraSalaryDetails,
	updateStaffExtraSalaryDetails,
} from '../../services/salary.service';
import DeleteComponents from '../../common/components/DeleteComponents';
import AlertService from '../../services/AlertService';
import Select from '../bootstrap/forms/Select';
import { showLoader } from '../../services/loader.services';

const AddOverTimeStaff = () => {

	useEffect(() => {
		getAllStaff(0);
		getSalary();
	}, []);

	const { userAccountId, userTypeId } = useContext(AuthContext);
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [staffData, setStaffData] = useState<any>([]);
	const [salaryTypeData, setSalaryTypeData] = useState<any>([]);

	const [selectedOption, setSelectedOption] = useState<any>([]);
	const [salaryTypeId, setSalaryTypeId] = useState<any>([]);
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const [staffExtraSalaryData, setStaffExtraSalaryData] = useState<any>([]);
	const { items, requestSort, getClassNamesFor } = useSortableData(staffExtraSalaryData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnDataById, setColumnDataById] = useState([]);
	const componentRef = useRef(null);
	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [staffExtraSalaryEditOffCanvas, setStaffExtraSalaryEditOffCanvas] = useState(false);
	const [staffExtraSalaryAddOffCanvas, setStaffExtraSalaryAddOffCanvas] = useState(false);
	const [title, setTitle] = useState<any>([]);
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [staffDetailsData, setStaffDetailsData] = useState<any>([]);
	const [timeDifference, setTimeDifference] = useState<any>("-");
	const [fromTime, setFromTime] = useState<any>('');
	const [toTime, setToTime] = useState<any>('');
	const [dayOrTimeId, setDayOrTimeId] = useState<any>('');
	const [deductFromSalary, setDeductFromSalary] = useState<any>(false);

	const addExtraSalaryDetails = useFormik({
		enableReinitialize: true,
		initialValues: {
			staffDetailsId: '',
			salaryTypeId: '',
			fromTime: '',
			toTime: '',
			dateOfWorking: getCurrentDateAndTime('date'),
			amount: '',
			staffDetails: '',
			dayOrTimeId: '',
		},
		validate: (values) => {
			const errors: {
				staffDetailsId?: string;
				salaryTypeId?: string;
				fromTime?: string;
				toTime?: string;
				amount?: string;
				staffDetails?: string;
				dateOfWorking?: string;
				dayOrTimeId?: string;
			} = {};

			if (dayOrTimeId?.value == 3) {
				if (!fromTime) {
					errors.fromTime = 'Required';
				}
				if (!toTime) {
					errors.toTime = 'Required';
				}
			}
			if (!values.amount) {
				errors.amount = 'Required';
			}
			if (!dayOrTimeId) {
				errors.dayOrTimeId = 'Required';
			}
			if (staffDetailsData.length == 0) {
				errors.staffDetailsId = 'Required';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: () => {
			addExtraSalarySubmit();
		},
	});

	const editExtraSalaryDetails = useFormik({
		enableReinitialize: true,
		initialValues: {
			staffDetailsId: '',
			fromTime: '',
			toTime: '',
			amount: '',
			staffExtraSalaryDetailsId: '',
			dateOfWorking: '',
			salaryTypeId: '',
			dayOrTimeId: '',
		},
		validate: (values) => {
			const errors: {
				staffDetailsId?: string;
				fromTime?: string;
				toTime?: string;
				amount?: string;
				staffExtraSalaryDetailsId?: string;
				dateOfWorking?: string;
				dayOrTimeId?: string;
			} = {};
			if (dayOrTimeId?.value == 3) {
				if (!fromTime) {
					errors.fromTime = 'Required';
				}
				if (!toTime) {
					errors.toTime = 'Required';
				}
			}
			if (!dayOrTimeId) {
				errors.dayOrTimeId = 'Required';
			}
			if (!values.amount) {
				errors.amount = 'Required';
			}
			if (!values.staffExtraSalaryDetailsId) {
				errors.staffExtraSalaryDetailsId = 'Required';
			}
			if (!values.dateOfWorking) {
				errors.dateOfWorking = 'Required';
			}
			return errors;
		},

		onSubmit: () => {
			updateExtraSalarySubmit();
		},
	});

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		onSubmit: () => { },
	});

	const filteredData = updateFilter(
		onCurrentPageData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedExtraSalaryDetails = i;
			setStaffExtraSalaryEditOffCanvas(true);
			setFromTime(selectedExtraSalaryDetails.fromTime)
			setToTime(selectedExtraSalaryDetails.toTime)
			setDeductFromSalary(selectedExtraSalaryDetails.deductFromSalary)
			setDayOrTimeId({ value: selectedExtraSalaryDetails.dayOrTimeId, label: selectedExtraSalaryDetails.dayOrTime })
			editExtraSalaryDetails.setValues({
				salaryTypeId: selectedExtraSalaryDetails.salaryTypeId,
				staffDetailsId: selectedExtraSalaryDetails.staffDetailsId,
				fromTime: selectedExtraSalaryDetails.fromTime != null ? selectedExtraSalaryDetails.fromTime.substring(0, 5) : '',
				toTime: selectedExtraSalaryDetails.toTime != null ? selectedExtraSalaryDetails.toTime.substring(0, 5) : '',
				amount: selectedExtraSalaryDetails.amount,
				staffExtraSalaryDetailsId: selectedExtraSalaryDetails.staffExtraSalaryDetailsId,
				dateOfWorking: selectedExtraSalaryDetails.workingDate,
				dayOrTimeId: selectedExtraSalaryDetails.dayOrTimeId,
			}); true

			if (selectedExtraSalaryDetails.fromTime != null && selectedExtraSalaryDetails.toTime != null) {
				const fromTime1 = convertTo24HourFormat(selectedExtraSalaryDetails.fromTime);
				const toTime1 = convertTo24HourFormat(selectedExtraSalaryDetails.toTime);

				// Parse the start and end times
				const start = new Date(`1970-01-01T${fromTime1}:00`);
				const end = new Date(`1970-01-01T${toTime1}:00`);

				// Calculate the difference in milliseconds
				let diff = Number(end) - Number(start);

				// If the difference is negative, it means the end time is on the next day
				if (diff < 0) {
					diff += 24 * 60 * 60 * 1000; // Add 24 hours in milliseconds
				}

				// Convert milliseconds to hours and minutes
				const hours = Math.floor(diff / (1000 * 60 * 60));
				const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

				setTimeDifference(`${hours} hours and ${minutes} minutes`);
			}
		}
	}

	function openCanvas() {
		resetForm();
		setStaffExtraSalaryAddOffCanvas(true);
	}

	function convertTo24HourFormat(time: any) {
		let [hours, minutes] = time.split(/[: ]/);
		const period = time.slice(-2);

		hours = parseInt(hours, 10);

		if (period === "PM" && hours !== 12) {
			hours += 12;
		} else if (period === "AM" && hours === 12) {
			hours = 0;
		}

		return `${String(hours).padStart(2, "0")}:${minutes}`;
	}

	function calculateFromTimeDifference(e: any) {
		setFromTime(e)
		if (toTime != '') {

			const fromTime1 = convertTo24HourFormat(e);
			const toTime1 = convertTo24HourFormat(toTime);

			// Parse the start and end times
			const start = new Date(`1970-01-01T${fromTime1}:00`);
			const end = new Date(`1970-01-01T${toTime1}:00`);

			// Calculate the difference in milliseconds
			let diff = Number(end) - Number(start);

			// If the difference is negative, it means the end time is on the next day
			if (diff < 0) {
				diff += 24 * 60 * 60 * 1000; // Add 24 hours in milliseconds
			}

			// Convert milliseconds to hours and minutes
			const hours = Math.floor(diff / (1000 * 60 * 60));
			const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

			setTimeDifference(`${hours} hours and ${minutes} minutes`);
		}

	};

	function calculateToTimeDifference(e: any) {
		setToTime(e)
		if (fromTime != '') {


			const fromTime1 = convertTo24HourFormat(fromTime);
			const toTime1 = convertTo24HourFormat(e);

			// Parse the start and end times
			const start = new Date(`1970-01-01T${fromTime1}:00`);
			const end = new Date(`1970-01-01T${toTime1}:00`);

			// Calculate the difference in milliseconds
			let diff = Number(end) - Number(start);

			// If the difference is negative, it means the end time is on the next day
			if (diff < 0) {
				diff += 24 * 60 * 60 * 1000; // Add 24 hours in milliseconds
			}

			// Convert milliseconds to hours and minutes
			const hours = Math.floor(diff / (1000 * 60 * 60));
			const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));

			setTimeDifference(`${hours} hours and ${minutes} minutes`);
		}
	};

	function setAddExtraSalary() {
		return {
			licenseKey: getLicenseKey,
			addedBy: userAccountId,
			salaryTypeId: salaryTypeId?.value,
			staffDetails: staffDetailsData,
			fromTime: fromTime != '' ? fromTime : '00:00:00',
			toTime: toTime != '' ? toTime : '00:00:00',
			dayOrTimeId: dayOrTimeId?.value,
			amount: addExtraSalaryDetails.values.amount,
			dateOfWorking: addExtraSalaryDetails.values.dateOfWorking,
		};
	}

	function setEditExtraSalary() {
		return {
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
			salaryTypeId: editExtraSalaryDetails.values.salaryTypeId,
			staffDetailsId: editExtraSalaryDetails.values.staffDetailsId,
			staffExtraSalaryDetailsId: editExtraSalaryDetails.values.staffExtraSalaryDetailsId,
			dateOfWorking: editExtraSalaryDetails.values.dateOfWorking,
			fromTime: fromTime != '' ? fromTime : '00:00:00',
			toTime: toTime != '' ? toTime : '00:00:00',
			dayOrTimeId: dayOrTimeId?.value,
			amount: editExtraSalaryDetails.values.amount,
			deductFromSalary: deductFromSalary == true ? 1 : 0,
		};
	}

	function addExtraSalarySubmit() {
		showLoader(true);
		if (addExtraSalaryDetails.isValid) {
			let staffExtraDetailsPostData = setAddExtraSalary();
			addStaffExtraSalaryDetails(
				staffExtraDetailsPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						viewStaffExtraSalaryDetails();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (addExtraSalaryDetails.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
			setIsOpen(true);
		}
	}

	function updateExtraSalarySubmit() {
		showLoader(true);
		if (editExtraSalaryDetails.isValid) {
			let staffExtraDetailsPostData = setEditExtraSalary();
			updateStaffExtraSalaryDetails(
				staffExtraDetailsPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						closeAndReset();
						viewStaffExtraSalaryDetails();
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					showLoader(false);
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (editExtraSalaryDetails.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
			setIsOpen(true);
		}
	}

	function deleteExtraSalarySubmit() {
		showLoader(true);
		let staffExtraDetailsPostData = setEditExtraSalary();
		deleteStaffExtraSalaryDetails(
			staffExtraDetailsPostData,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'success' });
					setIsOpen(true);
					closeAndReset();
					viewStaffExtraSalaryDetails();
				} else if (data.success == false) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'warning' });
					setIsOpen(true);
				} else {
					showLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setAlertStatus({ message: errorDescription, type: 'error' });
					setIsOpen(true);
				}
			},
			(error) => {
				showLoader(false);
				setAlertStatus({ message: error, type: 'error' });
				setIsOpen(true);
			},
		);
	}

	function getStaffExtraSalary(staffDetailsId: any, salaryTypeId: any, workingDate: any) {
		getStaffExtraSalaryDetails(
			staffDetailsId,
			salaryTypeId,
			workingDate,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffExtraSalaryDetails;
					if (data != undefined) {
						getColumnsForTable('getStaffExtraSalaryDetails', 'get');
						setStaffExtraSalaryData(data);
						setIsLoader(false);
						setIsOpenListCard(false);
						setDataSuccess(true);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
					} else {
						setDataSuccess(false);
						setIsOpenListCard(true);
						setIsLoader(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataSuccess(true);
					getColumnsForTable('getStaffExtraSalaryDetails', 'get');
					setStaffExtraSalaryData([]);
					setIsOpenListCard(false);
					setIsLoader(false);
					setNoDataMsg(response.data.message);
					//toasts(response.data.message, "Error")
				} else {
					setDataSuccess(false);
					setIsOpenListCard(true);
					setIsLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				toasts(error, 'Error');
			},
		);
	}

	function getAllStaff(userTypeId: any) {
		getStaffListByUserTypeId(
			userTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffListByUserTypeId;
					if (data != undefined) {
						setStaffData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setStaffData([]);
					//toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getSalary() {
		getSalaryType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.salaryType;
					if (data != undefined) {
						setSalaryTypeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setSalaryTypeData([]);
					//toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => item.isDefault == false,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibilityForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};

			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getStaffExtraSalaryDetails', 'get');
					} else if (data.success == false) {
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibilityForm.isValid == false) {
			toasts('Please fill all the details!', 'warning');
		}
	}

	// Export Configuration
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	function closeAndReset() {
		setStaffExtraSalaryAddOffCanvas(false);
		setStaffExtraSalaryEditOffCanvas(false);
		setIsDeleteOpen(false);
		resetForm();
	}

	function resetForm() {
		addExtraSalaryDetails.resetForm();
		editExtraSalaryDetails.resetForm();
		setFromTime('')
		setToTime('')
		setDayOrTimeId('')
		setTimeDifference('-')
		setSelectedOption([]);
		setStaffDetailsData([]);
	}

	function selectStaff(e: any) {
		let staffDetailsId = e;
		setSelectedOption(staffDetailsId.value);
		const staffArrayId = staffDetailsId.map((e: any) => e.value);
		setStaffDetailsData(staffArrayId);
	}

	const selectSalaryType = (e: any) => {
		setDataSuccess(false);
		setIsOpenListCard(true);
		let salaryTypeId = e;
		setSalaryTypeId(salaryTypeId);
		if (salaryTypeId?.value == 1) {
			setTitle('Over Time');
		} else if (salaryTypeId?.value == 2) {
			setTitle('Late Come');
		} else {
			setTitle('Lunch Late Come');
		}
	};

	function viewStaffExtraSalaryDetails() {
		resetForm();
		setDataSuccess(false);
		setIsLoader(true);
		columnVisibilityForm.resetForm();
		getStaffExtraSalary(
			userAccountId,
			salaryTypeId?.value,
			convertDateToEpoch(addExtraSalaryDetails.values.dateOfWorking),
		);
	}

	const dayOrTimeData = [{ dayOrTimeId: 1, dayOrTime: 'Full Day' }, { dayOrTimeId: 2, dayOrTime: 'Half Day' }, { dayOrTimeId: 3, dayOrTime: 'Time' }]

	function selectDayOrTime(e: any) {
		if (e?.value != 3) {
			setToTime('')
			setFromTime('')
			setTimeDifference('-')
		}
		setDayOrTimeId(e)
	}

	return (
		<PageWrapper title='Staff Extra Salary Details'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card>
						<CardHeader borderSize={1}>
							<CardLabel icon='list' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Staff Extra Salary Details
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-lg-3'>
									<FormGroup id='salaryTypeId' label='salary Type' isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='salary Type'
											onChange={selectSalaryType}
											value={salaryTypeId}
											list={salaryTypeData.map((data: any) => ({
												value: data.salaryTypeId,
												label: data.salaryTypeName,
											}))}
											placeholder='salary Type'
											isValid={addExtraSalaryDetails.isValid}
											onBlur={addExtraSalaryDetails.handleBlur}
											isTouched={addExtraSalaryDetails.touched.salaryTypeId}
											invalidFeedback={
												addExtraSalaryDetails.errors.salaryTypeId
											}
										/>
									</FormGroup>
								</div>
								{salaryTypeId?.value != undefined ? (
									<div className='col-lg-3'>
										<FormGroup
											id='dateOfWorking'
											label='Date'
											isFloating>
											<Input
												onChange={addExtraSalaryDetails.handleChange}
												value={addExtraSalaryDetails.values.dateOfWorking}
												onBlur={addExtraSalaryDetails.handleBlur}
												isValid={addExtraSalaryDetails.isValid}
												isTouched={
													addExtraSalaryDetails.touched.dateOfWorking
												}
												invalidFeedback={
													addExtraSalaryDetails.errors.dateOfWorking
												}
												type='date'
												min={new Date().toISOString().split('T')[0]}
											/>
										</FormGroup>
									</div>
								) : null}

								{salaryTypeId?.value != undefined ? (
									<div className='col-lg-3 align-self-center'>
										<FormGroup>
											<Button
												icon='South'
												color='primary'
												onClick={viewStaffExtraSalaryDetails}>
												View
											</Button>
										</FormGroup>
									</div>
								) : null}
							</div>
						</CardBody>
					</Card>
				</Collapse>

				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>

				{dataSuccess ? (
					<Card stretch data-tour='list' ref={componentRef}>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info' className='col-lg-5'>
								<CardTitle tag='div' className='h5'>
									{title} List
								</CardTitle>
							</CardLabel>
							&nbsp;&nbsp;
							<ButtonGroup className='col-lg-3 d-print-none' color='primary'>
								<Icon className='mt-1' icon='Search' size='2x' color='primary' />
								<Input
									id='searchInput'
									type='search'
									placeholder='Search...'
									onChange={columnVisibilityForm.handleChange}
									value={columnVisibilityForm.values.searchInput}
								/>
							</ButtonGroup>
							<CardActions className='d-print-none'>
								<Dropdown
									isOpen={columnVisibilityMenu}
									setIsOpen={setColumnVisibilityMenu}
									isButtonGroup>
									<DropdownToggle>
										<Button icon='FilterAlt' color='primary' isLight>
											Filter
										</Button>
									</DropdownToggle>
									<DropdownMenu
										isAlignmentEnd
										size='lg'
										isCloseAfterLeave={false}>
										<DropdownItem>
											<div className='container py-2'>
												<form className='row g-3'>
													<div className='col-12'>
														<FormGroup>
															<h6>Select All</h6>
															<Checks
																id='available'
																type='switch'
																label='Select All Columns'
																onChange={() =>
																	getColumnsForTable(
																		'getStaffExtraSalaryDetails',
																		'post',
																	)
																}
																checked={isChecked}
																ariaLabel='Available status'
															/>
														</FormGroup>
													</div>
													<div
														className='col-12'
														style={{
															maxHeight: '200px',
															overflowY: 'scroll',
														}}>
														<FormGroup>
															<h6>Columns</h6>
															<ChecksGroup>
																{allColumnsData.map((i: any) => (
																	<Checks
																		key={i.columnVisibilityId}
																		id='{i.columnVisibilityId}'
																		label={i.columnName}
																		onChange={() =>
																			updateColumnsSubmit(
																				i.columnVisibilityId,
																				i.isDisplay,
																				'',
																			)
																		}
																		checked={i.isDisplay}
																		disabled={
																			i.isDefault == true
																		}
																	/>
																))}
															</ChecksGroup>
														</FormGroup>
													</div>
												</form>
											</div>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'>
											{' '}
											Export{' '}
										</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadExcel(
														'StaffExtraSalaryList',
														staffExtraSalaryData,
														columnVisibilityData,
													)
												}>
												{' '}
												Excel{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='PictureAsPdf'
												onClick={() =>
													convertJsonToPdf(
														staffExtraSalaryData,
														columnVisibilityData,
														'StaffExtraSalaryList',
													)
												}>
												{' '}
												PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadFile(
														'StaffExtraSalaryList',
														staffExtraSalaryData,
														columnVisibilityData,
													)
												}>
												{' '}
												CSV{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='Print'
												onClick={handlePrint}>
												{' '}
												Print{' '}
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Button
									color='primary'
									icon='Add'
									isLight
									onClick={openCanvas}>
									Add New
								</Button>
							</CardActions>
						</CardHeader>

						<CardBody className='table-responsive' isScrollable>
							<table className='table table-modern table-hover text-nowrap'>
								<thead>
									<tr>
										<th
											scope='col'
											onClick={() => requestSort('sno')}
											className='cursor-pointer text-decoration-underline'>
											S.No{''}
											<Icon
												size='lg'
												className={getClassNamesFor('sno')}
												icon='FilterList'
											/>
										</th>
										{columnVisibilityData.map((column: any) => (
											<th
												key={column.keyName}
												scope='col'
												onClick={() => requestSort(column.keyName)}
												className='cursor-pointer text-decoration-underline'>
												{column.columnName}
												<Icon
													size='lg'
													className={getClassNamesFor(column.keyName)}
													icon='FilterList'
												/>
											</th>
										))}
										<th className='d-print-none'>Action</th>
									</tr>
								</thead>
								<tbody>
									{filteredData != '' ? (
										<>
											{filteredData.map((rowData: any) => (
												<tr key={rowData.staffExtraSalaryDetailsId}>
													<td>{rowData.sno}</td>
													{columnVisibilityData.map((column: any) =>
														column.isDisplay &&
															rowData[column.keyName] ? (
															<td
																key={`${rowData.staffExtraSalaryDetailsId}-${column.keyName}`}>
																{rowData[column.keyName]}
															</td>
														) : (
															<td key={`empty-${column.keyName}`} />
														),
													)}
													<td className='d-print-none'>
														<Button
															color='dark'
															isLight
															icon='Edit'
															aria-label='Edit'
															onClick={() => {
																onActivate(rowData);
															}}
														/>
													</td>
												</tr>
											))}
										</>
									) : (
										<NoDataMsg
											columnsCount={columnVisibilityData.length + 2}
											msg={noDataMsg}
										/>
									)}
								</tbody>
							</table>
						</CardBody>
						<PaginationButtons
							className='d-print-none'
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>
				) : null}

				<OffCanvas
					setOpen={setStaffExtraSalaryAddOffCanvas}
					isOpen={staffExtraSalaryAddOffCanvas}
					titleId='staffExtraSalaryAddOffCanvas'
					isBodyScroll
					placement='end'
					isNotClose
					tag='form'
					noValidate
					onSubmit={addExtraSalaryDetails.handleSubmit}>
					<OffCanvasHeader
						setOpen={setStaffExtraSalaryAddOffCanvas}
						onClick={closeAndReset}>
						<OffCanvasTitle id='staffExtraSalaryAddOffCanvas'>
							Add {title} Staffs{' '}
						</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							<div className='col-12'>
								<FormGroup id='staffDetailsId' label='Staff'>
									<SearchableSelect
										ariaLabel='Staff'
										onChange={selectStaff}
										value={selectedOption}
										multiple
										list={staffData.map((data: any) => ({
											value: data.staffDetailsId,
											label: data.empAndStaffName,
										}))}
										placeholder='Select Staff'
										isValid={addExtraSalaryDetails.isValid}
										onBlur={addExtraSalaryDetails.handleBlur}
										isTouched={addExtraSalaryDetails.touched.staffDetailsId}
										invalidFeedback={
											addExtraSalaryDetails.errors.staffDetailsId
										}
									/>
								</FormGroup>
							</div>

							<div className='col-12'>
								<FormGroup
									id='dayOrTime'
									label='Day Or Time'>
									<SearchableSelect
										ariaLabel='Day Or Time'
										placeholder='Day Or Time'
										onChange={(e: any) => selectDayOrTime(e)}
										value={dayOrTimeId}
										isValid={addExtraSalaryDetails.isValid}
										onBlur={addExtraSalaryDetails.handleBlur}
										isTouched={addExtraSalaryDetails.touched.dayOrTimeId}
										invalidFeedback={addExtraSalaryDetails.errors.dayOrTimeId}
										list={dayOrTimeData.map((data: any) => (
											{ value: data.dayOrTimeId, label: data.dayOrTime }
										))}
									/>
								</FormGroup>
							</div>

							{dayOrTimeId?.value == 3 ?
								<>
									<div className='col-12'>
										<FormGroup id='fromTime' label='From Time'>
											<Input
												onChange={(e: any) => calculateFromTimeDifference(e.target.value)}
												value={fromTime}
												onBlur={addExtraSalaryDetails.handleBlur}
												isValid={addExtraSalaryDetails.isValid}
												isTouched={addExtraSalaryDetails.touched.fromTime}
												invalidFeedback={addExtraSalaryDetails.errors.fromTime}
												type='time'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='toTime' label='To Time'>
											<Input
												onChange={(e: any) => calculateToTimeDifference(e.target.value)}
												value={toTime}
												onBlur={addExtraSalaryDetails.handleBlur}
												isValid={addExtraSalaryDetails.isValid}
												isTouched={addExtraSalaryDetails.touched.toTime}
												invalidFeedback={addExtraSalaryDetails.errors.toTime}
												type='time'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='toTime' label='Time Duration'>
											<Input
												value={timeDifference}
												disabled
											/>
										</FormGroup>
									</div>
								</> :
								null}

							<div className='col-12'>
								<FormGroup id='amount' label='Amount'>
									<Input
										onChange={addExtraSalaryDetails.handleChange}
										value={addExtraSalaryDetails.values.amount}
										isValid={addExtraSalaryDetails.isValid}
										onBlur={addExtraSalaryDetails.handleBlur}
										isTouched={addExtraSalaryDetails.touched.amount}
										invalidFeedback={addExtraSalaryDetails.errors.amount}
										placeholder={
											salaryTypeId?.value == 1
												? 'Enter OT amount'
												: salaryTypeId?.value == 2
													? 'Enter Late Come Amount'
													: 'Enter Lunch Late Come Amount'
										}
										required
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>
					<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
						<div className='row m-0'>
							<Button
								color='info'
								icon='Save'
								className='w-100'
								type='submit'
								isDisable={
									!addExtraSalaryDetails.isValid &&
									!!addExtraSalaryDetails.submitCount
								}>
								Save
							</Button>
						</div>
					</div>
				</OffCanvas>

				<OffCanvas
					setOpen={setStaffExtraSalaryEditOffCanvas}
					isOpen={staffExtraSalaryEditOffCanvas}
					titleId='staffExtraSalaryEditOffCanvas'
					isBodyScroll
					tag='form'
					noValidate
					onSubmit={editExtraSalaryDetails.handleSubmit}>
					<OffCanvasHeader
						setOpen={setStaffExtraSalaryEditOffCanvas}
						onClick={closeAndReset}>
						<OffCanvasTitle id='staffExtraSalaryEditOffCanvas'>
							Edit {title} Staffs Details
						</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row g-4'>
							<div className='col-12'>
								<FormGroup id='staffDetailsId' label='Staff'>
									<Select
										ariaLabel='Staff'
										disabled
										onChange={editExtraSalaryDetails.handleChange}
										value={editExtraSalaryDetails.values.staffDetailsId.toString()}
										list={staffData.map((data: any) => ({
											value: data.staffDetailsId,
											label: data.empAndStaffName,
										}))}
									/>
								</FormGroup>
							</div>


							<div className='col-12'>
								<FormGroup
									id='dayOrTime'
									label='Day Or Time'>
									<SearchableSelect
										ariaLabel='Day Or Time'
										placeholder='Day Or Time'
										onChange={(e: any) => selectDayOrTime(e)}
										value={dayOrTimeId}
										isValid={editExtraSalaryDetails.isValid}
										onBlur={editExtraSalaryDetails.handleBlur}
										isTouched={editExtraSalaryDetails.touched.dayOrTimeId}
										invalidFeedback={editExtraSalaryDetails.errors.dayOrTimeId}
										list={dayOrTimeData.map((data: any) => (
											{ value: data.dayOrTimeId, label: data.dayOrTime }
										))}
									/>
								</FormGroup>
							</div>

							{dayOrTimeId?.value == 3 ?
								<>
									<div className='col-12'>
										<FormGroup id='fromTime' label='From Time'>
											<Input
												onChange={(e: any) => calculateFromTimeDifference(e.target.value)}
												value={fromTime}
												onBlur={editExtraSalaryDetails.handleBlur}
												isValid={editExtraSalaryDetails.isValid}
												isTouched={editExtraSalaryDetails.touched.fromTime}
												invalidFeedback={editExtraSalaryDetails.errors.fromTime}
												type='time'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='toTime' label='End Time'>
											<Input
												onChange={(e: any) => calculateToTimeDifference(e.target.value)}
												value={toTime}
												onBlur={editExtraSalaryDetails.handleBlur}
												isValid={editExtraSalaryDetails.isValid}
												isTouched={editExtraSalaryDetails.touched.toTime}
												invalidFeedback={editExtraSalaryDetails.errors.toTime}
												type='time'
											/>
										</FormGroup>
									</div>
									<div className='col-12'>
										<FormGroup id='toTime' label='Time Duration'>
											<Input
												value={timeDifference}
												disabled
											/>
										</FormGroup>
									</div>
								</> : null}
							<div className='col-12'>
								<FormGroup id='amount' label='Amount'>
									<Input
										onChange={editExtraSalaryDetails.handleChange}
										value={editExtraSalaryDetails.values.amount}
										isValid={editExtraSalaryDetails.isValid}
										onBlur={editExtraSalaryDetails.handleBlur}
										isTouched={editExtraSalaryDetails.touched.amount}
										invalidFeedback={editExtraSalaryDetails.errors.amount}
										placeholder={salaryTypeId?.value == 1 ? "Enter OT amount" : salaryTypeId?.value == 2 ? "Enter Late Come Amount" : "Enter Lunch Late Come Amount"}
										required
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<Checks
									className='border border-primary fw-bold'
									type='checkbox'
									id='isFillingAddress'
									label={'Deduct from salary'}
									onChange={() => setDeductFromSalary(!deductFromSalary)}
									checked={deductFromSalary}
								/>
							</div>
						</div>
					</OffCanvasBody>
					<div className='row'>
						<div className='d-flex justify-content-center position-absolute top-65 start-50 translate-middle'>
							<DeleteComponents
								isDeleteOpen={isDeleteOpen}
								setIsDeleteOpen={setIsDeleteOpen}
								deleteFunction={deleteExtraSalarySubmit}
							/>
						</div>
						<div className='col-6 position-absolute bottom-0 start-0 p-3'>
							<Button
								color='danger'
								className='w-100'
								icon='Delete'
								onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
								Delete
							</Button>
						</div>
						<div className='col-6 position-absolute bottom-0 end-0 p-3'>
							<Button
								color='info'
								icon='Save'
								className='w-100'
								type='submit'
								isDisable={!editExtraSalaryDetails.isValid && !!editExtraSalaryDetails.submitCount}>
								Update
							</Button>
						</div>
					</div>
				</OffCanvas>
			</Page>
			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
		</PageWrapper>
	);
};

export default AddOverTimeStaff;
