import { AxiosResponse } from 'axios';
import service from '../api/api.services';
import dayjs from 'dayjs';
import { getAPIURL, getApiUrlForCampus, getLicenseKey } from './application.settings';
import profileForMale from '../assets/img/male.png';
import profileForFemale from '../assets/img/female.png';

const api = service();

export const checkDataAvailableOrNot = (
	organisationDetailsId: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getDataAvailableOrNot/${getLicenseKey}/${organisationDetailsId}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getDataAvailableOrNot - ' + error.message);
			console.log('error caught in service : getDataAvailableOrNot');
		},
	);

export const getGenderDetailsList = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getGenderDetails/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getGenderDetails - ' + error.message);
			console.log('error caught in service : getGenderDetails');
		},
	);

export const getStates = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getStatesAndCity/${getLicenseKey}/0`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getStatesAndCity - ' + error.message);
			console.log('error caught in service : getStatesAndCity');
		},
	);

export const getStatesAndCity = (
	stateId: number,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getStatesAndCity/${getLicenseKey}/${stateId}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getStatesAndCity - ' + error.message);
			console.log('error caught in service : getStatesAndCity');
		},
	);

export const getColumnsForDataTable = (
	userTypeId: any,
	apiName: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getColumnsForDataTable/${getLicenseKey}/${userTypeId}/${apiName}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getColumnsForDataTable - ' + error.message);
			console.log('error caught in service : getColumnsForDataTable');
		},
	);

export const updateColumnsForDataTable = (
	postData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `updateColumnsForDataTable`,
		postData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateColumnsForDataTable');
		},
	);

export const getPaymentType = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getPaymentType/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getPaymentType - ' + error.message);
			console.log('error caught in service : getPaymentType');
		},
	);

export const profilePicUpload = (
	fileData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `profilePicUpload`,
		fileData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : profilePicUpload');
		},
	);

export const profilePicUploadForCampus = (
	fileData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getApiUrlForCampus() + `profilePicUpload`,
		fileData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : profilePicUpload');
		},
	);


export const uploadLogo = (
	fileData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `uploadLogo`,
		fileData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : uploadLogo');
		},
	);

export const getBlood = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getBlood/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getBlood - ' + error.message);
			console.log('error caught in service : getBlood');
		},
	);

export const getCategory = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getCategory/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getCategory - ' + error.message);
			console.log('error caught in service : getCategory');
		},
	);

export const getTransportAreaMaster = (
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(
		getAPIURL() + `getTransportAreaMaster/${getLicenseKey}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getTransportAreaMaster - ' + error.message);
			console.log('error caught in service : getTransportAreaMaster');
		},
	);


export const getStatus = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

	api.get(getAPIURL() + `getStatus/${getLicenseKey}`,
		response => {
			success(response)
		},
		error => {
			failure("getStatus - " + error.message)
			console.log('error caught in service : getStatus')
		},
	);


export const getBankDetails = (ifscCode: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.get(`https://ifsc.razorpay.com/${ifscCode}`,
		(response) => {
			success(response);
		},
		(error) => {
			failure('getBankDetails - ' + error.message);
			console.log('error caught in service : getBankDetails');
		},
	);

export const updateDisplayOrder = (
	postData: any,
	success: (arg0: AxiosResponse<any, any>) => any,
	failure: (arg0: any) => void,
) =>
	api.post(
		getAPIURL() + `updateDisplayOrder`,
		postData,
		(response) => {
			success(response);
		},
		(error) => {
			failure(error.message);
			console.log('error caught in service : updateDisplayOrder');
		},
	);




// Filter
export function updateFilter(data: any, columnVisibilityData: any, form: any) {
	const filteredData = data.filter((rowData: any) => {
		for (const column of columnVisibilityData) {
			const columnValue = rowData[column.keyName];
			if (
				columnValue &&
				columnValue !== null &&
				columnValue.toString().toLowerCase().includes(form.values.searchInput.toLowerCase())
			) {
				return true;
			}
		}
		return false;
	});

	return filteredData;
}

// Get Current Time
export function getCurrentDateAndTime(type: any) {
	const today =
		type == 'date'
			? dayjs(new Date()).format('YYYY-MM-DD')
			: dayjs(new Date()).format('D MMMM, YYYY h:mm A');
	return today;
}

// Convert Date To Time
export function convertDateToEpoch(fromDate: any) {
	let newFromDate = new Date(fromDate).getTime();
	return newFromDate;
}

// Date Range
export function convertDateRangeToEpoch(fromDate: any, toDate: any) {
	return Array(new Date(fromDate).getTime(), new Date(toDate).getTime());
}

// get Month Name
export function convertNumToMonth(monthNumber: any) {
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];

	return months[monthNumber - 1];
}

export function numConvertToOrdinal(n: any) {
	let ord = 'th';

	if (n % 10 == 1 && n % 100 != 11) {
		ord = 'st';
	} else if (n % 10 == 2 && n % 100 != 12) {
		ord = 'nd';
	} else if (n % 10 == 3 && n % 100 != 13) {
		ord = 'rd';
	}
	return ord;
}
//// default img
export function pictNotLoading(event: any, genderId: any) {
	event.target.src = genderId == 2 || genderId == 3 ? profileForFemale : profileForMale;
}

//// Profile img
export function profilePic(genderId: any) {
	let src = genderId == 2 || genderId == 3 ? profileForFemale : profileForMale;
	return src;
}

// only Allow Number
export function onlyAllowNumber(e: any) {
	if (e.key === 'Backspace') {
		return;
	}
	if (!/[0-9]/.test(e.key)) {
		e.preventDefault();
	}
}

// Amount to word
export function amountToWords(num: any) {
	const ones = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
	const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
	const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];

	if (num === 0) return "Zero";

	function getHundreds(n: any) {
		let str = "";
		if (Math.floor(n / 100) > 0) {
			str += ones[Math.floor(n / 100)] + " Hundred ";
			n %= 100;
		}
		if (n > 0) {
			if (n < 10) str += ones[n];
			else if (n < 20) str += teens[n - 10];
			else {
				str += tens[Math.floor(n / 10)];
				if (n % 10 > 0) str += "-" + ones[n % 10];
			}
		}
		return str.trim();
	}

	function convertIntegerToWords(n: any) {
		let words = "";
		if (Math.floor(n / 1000) > 0) {
			words += getHundreds(Math.floor(n / 1000)) + " Thousand ";
			n %= 1000;
		}
		words += getHundreds(n);
		return words.trim();
	}

	function convertDecimalToWords(n: any) {
		const tensPart = Math.floor(n / 10);
		const onesPart = n % 10;

		let words = "";
		if (tensPart > 0) {
			words += tens[tensPart] + " ";
		}
		if (onesPart > 0) {
			words += ones[onesPart];
		}
		return words.trim();
	}

	const [integerPart, decimalPart] = num.toString().split('.').map(Number);

	let result = convertIntegerToWords(integerPart) + " Rupees";
	if (decimalPart) {
		result += " and " + convertDecimalToWords(decimalPart) + " Paise";
	} else {
		result += " Only";
	}

	return result.trim();
}