import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import OffCanvas, { OffCanvasBody } from '../../../components/bootstrap/OffCanvas';
import Avatar from '../../../components/Avatar';
import USERS from '../../../common/data/userDummyData';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle, } from '../../../components/bootstrap/Dropdown';
import Button from '../../../components/bootstrap/Button';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import useDarkMode from '../../../hooks/useDarkMode';

//TASK AND DEADLINE
interface IUserAppointment {
	[key: string]: 'Work on' | 'Pending' | 'Completed';
}
interface ICommonRightPanel {
	setOpen(...args: unknown[]): unknown;
	isOpen: boolean;
}
const CommonRightPanel: FC<ICommonRightPanel> = ({ setOpen, isOpen }) => {
	const { themeStatus, darkModeStatus } = useDarkMode();

	const EVENTS: IUserAppointment = {
		WORKON: 'Work on',
		PENDING: 'Pending',
		COMPLETED: 'Completed',
	};
	const [activeUserAppointmentTab, setActiveUserAppointmentTab] = useState<
		IUserAppointment['key']
	>(EVENTS.APPROVED);
	const handleActiveUserAppointmentTab = (tabName: IUserAppointment['key']) => {
		setActiveUserAppointmentTab(tabName);
	};


	const taskReport = [
		{
			id: 1,
			date: '05-Apr-2024',
			text: 'Staff Dashboard',
			status: EVENTS.WORKON,
		},
		{
			id: 2,
			date: '01-Apr-2024',
			text: 'Leave Report',
			status: EVENTS.COMPLETED,
		},
		{
			id: 3,
			date: '15-Apr-2024',
			text: 'Loan & salary',
			status: EVENTS.PENDING,
		},
		{
			id: 4,
			date: '15-Apr-2024',
			text: 'Staff Profile',
			status: EVENTS.PENDING,
		},
		{
			id: 5,
			date: '10-Apr-2024',
			text: 'StaffCreation',
			status: EVENTS.WORKON,
		},

	];

	return (
		<OffCanvas
			setOpen={setOpen}
			isOpen={isOpen}
			isRightPanel
			isBodyScroll
			placement='end'>
			<OffCanvasBody className='p-4'>
				<div className='row mb-5'>
					<div className='col'>
						<div className='d-flex align-items-center'>
						</div>
					</div>
					<div className='col-auto'>
						<Dropdown>
							<DropdownToggle hasIcon={false}>
								<Button
									icon='MoreHoriz'
									color={themeStatus}
									hoverShadow='default'
									isLight={darkModeStatus}
								/>
							</DropdownToggle>
							<DropdownMenu isAlignmentEnd>
								<DropdownItem>
									<Button
										color='link'
										icon='Close'
										onClick={() => {
											setOpen(false);
										}}>
										Close
									</Button>
								</DropdownItem>
							</DropdownMenu>
						</Dropdown>
					</div>
				</div>
				<div className='d-flex justify-content-center mb-3'>
					<Avatar
						srcSet={USERS.JOHN.srcSet}
						src={USERS.JOHN.src}
						color={USERS.JOHN.color}
						shadow='default'
					/>
				</div>
				<div className='d-flex flex-column align-items-center mb-5'>
					<div className='h2 fw-bold'>
						{/* {`${USERS.JOHN.name} ${USERS.JOHN.surname}`} */}
						Lingeshwaran P
					</div>
					<div className='h5 text-muted'>
						{/* {`@${USERS.JOHN.name}${USERS.JOHN.surname}`} */}
						B0001
					</div>
				</div>
				<div
					className={classNames('rounded-3', {
						'shadow-3d-dark': !darkModeStatus,
						'shadow-3d-light': darkModeStatus,
						'bg-l10-dark': !darkModeStatus,
						'bg-lo50-info': darkModeStatus,
					})}>
					<div className='row row-cols-3 g-3 pb-3 px-3 mt-0'>
						{Object.keys(EVENTS).map((key) => (
							<div
								key={EVENTS[key]}
								className='col d-flex flex-column align-items-center'>
								<Button
									color={
										(darkModeStatus &&
											activeUserAppointmentTab === EVENTS[key]) ||
											!darkModeStatus
											? 'dark'
											: undefined
									}
									className='w-100 text-capitalize'
									rounded={1}
									onClick={() =>
										handleActiveUserAppointmentTab(EVENTS[key])
									}
									isLight={activeUserAppointmentTab !== EVENTS[key]}>
									<div className='h6 mb-3 text-muted opacity-80'>
										{EVENTS[key]}
									</div>
									<div
										className={classNames('h2', {
											'text-light': darkModeStatus,
										})}>
										{
											taskReport.filter(
												(f) => f.status === EVENTS[key],
											).length
										}
									</div>
								</Button>
							</div>
						))}
					</div>
				</div>
				<Card>
					<CardBody>
						<div className="row">
							<div className="col-8 text-success fw-bold h5">
								TASK
							</div>
							<div className="col-4 text-success fw-bold h5 ">
								DEADLINE
							</div>
						</div>
						{taskReport
							.filter((f) => f.status === activeUserAppointmentTab)
							.map((item) => (

								<div className='row'>
									<div className='col d-flex align-items-center'>
										<div className='flex-grow-1 d-flex justify-content-between align-items-center'>
											<div className='h5 mt-4'>
												<small>
													<span className='text-dark fw-bold'>
														{item.text}
													</span>
												</small>
											</div>
											<div className='h5 mt-4'>
												<small>
													<span className='text-info fw-bold'>
														{item.date}
													</span>
												</small>
											</div>
										</div>
									</div>
								</div>
							))}
					</CardBody>
				</Card>
			</OffCanvasBody>
		</OffCanvas>
	);
};
CommonRightPanel.propTypes = {
	setOpen: PropTypes.func.isRequired,
	isOpen: PropTypes.bool.isRequired,
};

export default CommonRightPanel;
