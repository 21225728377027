import { useContext, useEffect, useRef, useState } from "react";
import AuthContext from "../../contexts/authContext";
import { getLicenseKey } from "../../services/application.settings";
import AlertService from "../../services/AlertService";
import OpenCardComponent from "../../common/components/OpenCardComponents";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Collapse from "../bootstrap/Collapse";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../bootstrap/Card";
import FormGroup from "../bootstrap/forms/FormGroup";
import SearchableSelect from "../../common/components/SearchableSelect";
import Button from "../bootstrap/Button";
import { toasts } from "../../services/toast.service";
import { assignScreenForStaff, getAllScreensByUserAccountId, getAllScreensByUserTypeId } from "../../services/screen.service";
import Checks from "../bootstrap/forms/Checks";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import { showLoader } from "../../services/loader.services";
import { getStaffListByUserTypeId } from "../../services/staff.service";
import { getUserType, getUserTypeByUserTypeId, updateUserType } from "../../services/userType.service";
import { useFormik } from "formik";

const AssignScreensForUser = () => {

    useEffect(() => {
        getUserTypeList()
    }, [])

    const { userAccountId } = useContext(AuthContext);
    const [userTypeId, setUserTypeId] = useState<any>('')
    const [staffDetailsId, setStaffDetailsId] = useState<any>('')

    const [userRolesData, setUserRolesData] = useState<any>([]);
    const [staffData, setStaffData] = useState<any>([])
    const [assignScreenData, setAssignScreenData] = useState([])
    const [checkArray, setCheckArray] = useState<any>([])
    const [data, setData] = useState<any>([])
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const componentRef = useRef(null);
    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [dataStatus, setDataStatus] = useState(false);
    const [isLoader, setIsLoader] = useState(false)


    const editUserTypeForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            userTypeId: '',
            userType: '',
            description: ''
        },

        validate: (values) => {
            const errors: {
                userType?: string;
                description?: string;

            } = {};
            if (!values.userType) {
                errors.userType = 'Required';
            }
            if (!values.description) {
                errors.description = 'Required';
            }
            return errors;
        },
        validateOnChange: false,
        onSubmit: () => { },
    });


    const selectUserType = (e: any) => {
        setStaffDetailsId('')
        setIsLoader(false);
        setDataStatus(false)
        setCheckArray([])
        let userTypeId = e
        setUserTypeId(userTypeId)
        if (userTypeId?.value != undefined) {
            getStaffListByUserType(userTypeId?.value)
            getUserTypeByUserType(userTypeId?.value)
        }
    }

    const selectStaff = (e: any) => {
        setIsLoader(false);
        setDataStatus(false)
        setCheckArray([])
        let staffDetailsId = e
        setStaffDetailsId(staffDetailsId)
    }

    const viewScreenList = () => {
        setIsLoader(true);
        setDataStatus(false)
        setCheckArray([])
        getScreenListByUserAccountId(userTypeId?.value, staffDetailsId?.value)
    }

    const viewAssignScreenByUserTypeList = () => {
        setIsLoader(true);
        setDataStatus(false)
        setCheckArray([])
        getAllScreensByUserType(userTypeId?.value)
    }

    function getUserTypeByUserType(userTypeId: any) {
        getUserTypeByUserTypeId(userTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userType[0];
                    if (data != undefined) {
                        editUserTypeForm.setValues({
                            userType: data.userType,
                            userTypeId: data.userTypeId,
                            description: data.description,
                        });
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function getAllScreensByUserType(userTypeId: any) {
        getAllScreensByUserTypeId(userTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.allScreens;
                    if (data != undefined) {
                        setAssignScreenData(data);

                        for (let i = 0; i < data.length; i++) {
                            let screenGroup = data[i].screens
                            for (let j = 0; j < screenGroup.length; j++) {
                                if (screenGroup[j].isChecked == true) {
                                    let dup = checkArray.filter((item: any) => item == screenGroup[j].screenId)

                                    if (dup == '') {
                                        checkArray.push(screenGroup[j].screenId)
                                    }
                                }
                            }
                        }
                        generateMenu(data);
                        setIsOpenListCard(false);
                        setIsLoader(false);
                        setDataStatus(true)
                        setCheckArray(checkArray)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                    setIsOpenListCard(false);
                    setIsLoader(false);
                    setDataStatus(true)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setScreenDetails() {
        return {
            userTypeId: editUserTypeForm.values.userTypeId,
            userType: editUserTypeForm.values.userType,
            description: editUserTypeForm.values.description,
            userAccountId: userAccountId,
            screenDetails: checkArray,
            licenseKey: getLicenseKey
        }
    }

    function updateUserTypeSubmit() {
        showLoader(true);
        if (checkArray.length > 0) {
            let screenPostData = setScreenDetails()
            updateUserType(screenPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset()
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (checkArray.length == 0) {
            showLoader(false);
            setAlertStatus({ message: 'Please Select Screens!', type: "error" });
            setIsOpen(true);
        }
    }

    function onSelect(event: any, screenId: any) {
        assignScreenData.forEach((screenGroup: any) => {
            let screenGroupData = screenGroup.screens
            const newData: any = [];
            screenGroupData.forEach((item: any) => {
                if (item.screenId == screenId) {
                    item.isChecked = event.target.checked
                }
                newData.push(item)
            })
            setData(newData)
        });
        if (event.target.checked == true) {
            checkArray.push(screenId);
        }
        else if (event.target.checked == false) {
            let unCheckArrayId = checkArray.indexOf(Number(screenId))
            checkArray.splice(unCheckArrayId, 1);
        }
        generateMenu(assignScreenData)
    }

    function selectAllScreenGroupMain(allScreens: any, e: any) {
        if (e == true) {

            let screenGroupData = allScreens.screens

            const newData: any = [];

            screenGroupData.forEach((item: any) => {

                if (item.screenId == item.screenId) {
                    item.isChecked = true
                    allScreens.isScreenGroupChecked = true
                }
                newData.push(item.screenId)

                let dup = checkArray.filter((item1: any) => item1 == item.screenId)

                if (dup == '') {
                    checkArray.push(item.screenId);
                }

            })
            setData(newData)
        }
        else {
            let screenGroupData = allScreens.screens

            const newData: any = [];

            screenGroupData.forEach((item: any) => {

                if (item.screenId == item.screenId) {
                    item.isChecked = false
                    allScreens.isScreenGroupChecked = false
                }
                newData.push(item.screenId)
                let unCheckArrayId = checkArray.indexOf(Number(item.screenId))
                checkArray.splice(unCheckArrayId, 1);
            })
            setData(newData)
        }
    }

    function getUserTypeList() {
        getUserType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.userType;
                    if (data != undefined) {
                        setUserRolesData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setUserRolesData([]);
                }
            },
            (error) => {
                setDataStatus(true);
                toasts(error, 'Error');
            },
        );
    }

    function setScreenData() {
        return {
            userTypeId: userTypeId?.value,
            userAccountId: userAccountId,
            staffDetailsId: staffDetailsId?.value,
            screenId: checkArray,
            licenseKey: getLicenseKey
        }
    }

    function assignScreenForStaffSubmit() {
        showLoader(true)
        if (checkArray.length > 0) {
            let screenPostData = setScreenData();
            assignScreenForStaff(screenPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (checkArray.length == 0) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getStaffListByUserType(staffTypeId: any) {
        getStaffListByUserTypeId(staffTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListByUserTypeId;
                    if (data != undefined) {
                        setStaffData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setStaffData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getScreenListByUserAccountId(userTypeId: number, userAccountId: any) {
        getAllScreensByUserAccountId(userTypeId, userAccountId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.allScreens;
                    if (data != undefined) {
                        setAssignScreenData(data)
                        for (let i = 0; i < data.length; i++) {
                            let screenGroup = data[i].screens
                            for (let j = 0; j < screenGroup.length; j++) {
                                if (screenGroup[j].isChecked == true) {
                                    let dup = checkArray.filter((item: any) => item == screenGroup[j].screenId)

                                    if (dup == '') {
                                        checkArray.push(screenGroup[j].screenId)
                                    }
                                }
                            }
                        }
                        generateMenu(data);
                        setIsOpenListCard(false);
                        setIsLoader(false);
                        setDataStatus(true)
                        setCheckArray(checkArray)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsOpenListCard(false);
                    setIsLoader(false);
                    setDataStatus(true)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function generateMenu(screenData: any) {

        for (let i = 0; i < screenData.length; i++) {

            let screenGroup = screenData[i].screens
            let screenGroup1 = screenData[i]

            let checkedArray = screenGroup.filter((item: any) => item.isChecked).length

            let allScreensCount = screenGroup.length
            if (checkedArray == allScreensCount) {
                screenData.forEach((item: any) => {
                    if (item.screenGroupId == screenGroup1.screenGroupId) {
                        item.isScreenGroupChecked = true
                    }
                })
            }
            else {
                screenData.forEach((item: any) => {
                    if (item.screenGroupId == screenGroup1.screenGroupId) {
                        item.isScreenGroupChecked = false
                    }
                })
            }
        }
    }

    function closeAndReset() {
        setIsOpenListCard(true);
        setDataStatus(false);
        setUserTypeId('');
        setStaffDetailsId('');
        setCheckArray([]);
    }

    const [isUserAndUserType, setIsUserAndUserType] = useState(true)

    function selectUserAndUserTypeCard() {
        setIsUserAndUserType(!isUserAndUserType)
        closeAndReset()
    }

    return (
        <PageWrapper title='Assign Screen'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    {isUserAndUserType == true ? "Screen Assign" : "Screen Assign For User Type"}
                                </CardTitle>
                            </CardLabel>
                            <CardActions>
                                <Button
                                    icon='PersonSearch'
                                    color='info'
                                    isLight={isUserAndUserType ? false : true}
                                    onClick={selectUserAndUserTypeCard}>
                                    User Wise
                                </Button>

                                <Button
                                    icon='PeopleAlt'
                                    color='info'
                                    isLight={isUserAndUserType ? true : false}
                                    onClick={selectUserAndUserTypeCard}>
                                    Role Wise
                                </Button>
                            </CardActions>
                        </CardHeader>
                        <CardBody>

                            {isUserAndUserType == true ?
                                <div className='row'>
                                    <div className='col-3'>
                                        <FormGroup id='userTypeId' label='User Type' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='User Type'
                                                onChange={selectUserType}
                                                value={userTypeId}
                                                list={userRolesData.map((data: any) => (
                                                    { value: data.userTypeId, label: data.userType }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>

                                    {userTypeId ?
                                        <div className='col-4'>
                                            <FormGroup id='semesterTypeId' label='Staff' isFloating>
                                                <SearchableSelect isFloating
                                                    ariaLabel='Staff'
                                                    onChange={selectStaff}
                                                    value={staffDetailsId}
                                                    list={staffData.map((data: any) => (
                                                        { value: data.staffDetailsId, label: data.empAndStaffName }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div> : null
                                    }
                                    {staffDetailsId ?
                                        <div className='col-3 mt-2'>
                                            <Button
                                                icon='ArrowDownward'
                                                color='primary'
                                                onClick={viewScreenList}
                                            >
                                                View
                                            </Button>
                                        </div> : null
                                    }
                                </div>
                                :
                                <div className='row'>
                                    <div className='col-3'>
                                        <FormGroup id='userTypeId' label='User Type' isFloating>
                                            <SearchableSelect isFloating
                                                ariaLabel='User Type'
                                                onChange={selectUserType}
                                                value={userTypeId}
                                                list={userRolesData.map((data: any) => (
                                                    { value: data.userTypeId, label: data.userType }
                                                ))}
                                            />
                                        </FormGroup>
                                    </div>

                                    {userTypeId ?
                                        <div className='col-3 mt-2'>
                                            <Button
                                                icon='ArrowDownward'
                                                color='primary'
                                                onClick={viewAssignScreenByUserTypeList}
                                            >
                                                View
                                            </Button>
                                        </div> : null
                                    }
                                </div>
                            }
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataStatus ?
                    <>

                        {isUserAndUserType == true ?
                            <>
                                <Card ref={componentRef}>
                                    <CardHeader borderSize={1}>
                                        <div className='d-flex col-5 justify-content-start'>
                                            <CardLabel icon='List' iconColor='info'>
                                                <CardTitle tag='div' className='h5'>Assigned Screens For <span className="text-primary">{staffDetailsId?.label}</span></CardTitle>
                                            </CardLabel>
                                        </div>
                                    </CardHeader>

                                    <CardBody>
                                        <div className='row'>
                                            {assignScreenData?.map((allScreens: any) => (
                                                <div className="col-6" key={allScreens.screenGroupId}>
                                                    <Card stretch>
                                                        <CardHeader borderSize={1}>
                                                            <CardLabel className="col-lg-5">
                                                                <CardTitle tag='div' className='h5 text-primary'>
                                                                    <Checks className='border border-primary mt-1'
                                                                        label={allScreens.screenGroupDisplayName}
                                                                        onChange={(e: any) => selectAllScreenGroupMain(allScreens, e.target.checked)}
                                                                        checked={allScreens.isScreenGroupChecked} />
                                                                </CardTitle>
                                                            </CardLabel>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <div className="row">
                                                                {allScreens?.screens.map((screens: any) => {
                                                                    return (
                                                                        <div className="col-md-6 mt-2" key={screens.screenId}>
                                                                            <Checks
                                                                                label={screens.displayName}
                                                                                onChange={(e) => onSelect(e, screens.screenId)}
                                                                                checked={screens.isChecked}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            ))}
                                        </div>
                                    </CardBody>
                                </Card>
                                <SubHeader>
                                    <SubHeaderLeft><></></SubHeaderLeft>
                                    <SubHeaderRight>
                                        <SubheaderSeparator />
                                        <Button icon='Save' color='info' isDisable={checkArray.length == 0} onClick={assignScreenForStaffSubmit}>Save</Button>
                                    </SubHeaderRight>
                                </SubHeader>
                            </>
                            : <>
                                <Card ref={componentRef}>
                                    <CardHeader borderSize={1}>
                                        <div className='d-flex col-5 justify-content-start'>
                                            <CardLabel icon='List' iconColor='info'>
                                                <CardTitle tag='div' className='h5'>Assigned Screens For <span className="text-primary">{userTypeId?.label}</span></CardTitle>
                                            </CardLabel>
                                        </div>
                                    </CardHeader>

                                    <CardBody>
                                        <div className='row'>
                                            {assignScreenData?.map((allScreens: any) => (
                                                <div className="col-6" key={allScreens.screenGroupId}>
                                                    <Card stretch>
                                                        <CardHeader borderSize={1}>
                                                            <CardLabel className="col-lg-5">
                                                                <CardTitle tag='div' className='h5 text-primary'>
                                                                    <Checks className='border border-primary mt-1'
                                                                        label={allScreens.screenGroupDisplayName}
                                                                        onChange={(e: any) => selectAllScreenGroupMain(allScreens, e.target.checked)}
                                                                        checked={allScreens.isScreenGroupChecked} />
                                                                </CardTitle>
                                                            </CardLabel>
                                                        </CardHeader>
                                                        <CardBody>
                                                            <div className="row">
                                                                {allScreens?.screens.map((screens: any) => {
                                                                    return (
                                                                        <div className="col-md-6 mt-2" key={screens.screenId}>
                                                                            <Checks
                                                                                label={screens.displayName}
                                                                                onChange={(e) => onSelect(e, screens.screenId)}
                                                                                checked={screens.isChecked}
                                                                            />
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </CardBody>
                                                    </Card>
                                                </div>
                                            ))}
                                        </div>
                                    </CardBody>
                                </Card>
                                <SubHeader>
                                    <SubHeaderLeft><></></SubHeaderLeft>
                                    <SubHeaderRight>
                                        <SubheaderSeparator />
                                        <Button icon='Save' color='info' isDisable={checkArray.length == 0} onClick={updateUserTypeSubmit}>Save</Button>
                                    </SubHeaderRight>
                                </SubHeader>
                            </>}
                    </> : null
                }
                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            </Page>
        </PageWrapper>
    );
}

export default AssignScreensForUser