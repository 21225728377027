import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const validateUserFromEmail = (validateUserPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `validateUserFromEmail`, validateUserPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : validateUserFromEmail')
        }
    );

export const login = (loginData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `login`, loginData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : login')
        }
    );

    export const loginForStaff = (loginData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `loginForStaff`, loginData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : loginForStaff')
        }
    );