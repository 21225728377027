import React, { useRef, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Card, { CardBody, CardFooter, CardFooterLeft, CardFooterRight, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import FormGroup from '../bootstrap/forms/FormGroup'
import Input from "../bootstrap/forms/Input";
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks'
import Alert from '../bootstrap/Alert'
import Button from '../bootstrap/Button'
import { useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../layout/SubHeader/SubHeader'
import AlertService from '../../services/AlertService'

const newPostTexDeduction = () => {

    const componentRef = useRef(null);
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [doChecked, setDoChecked] = useState(false);


    const earningDetailsForm = useFormik({

        enableReinitialize: true,
        initialValues: {
            payslipName: '',
        },
        validate: (values) => {
            const errors: {
                payslipName?: string;
            } = {};
            if (!values.payslipName) {
                errors.payslipName = 'Required';
            }
            return errors;
        },
        validateOnChange: false,
        onSubmit: () => { },
    });

    function doCheck() {
        setDoChecked(!doChecked)
    }

    return (
        <PageWrapper>
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color='info'
                        isLink
                        icon='ArrowBack'
                        tag='a'
                        to={`../salary/salaryComponents`}>
                        Back to List
                    </Button>
                    <SubheaderSeparator />
                </SubHeaderLeft>
            </SubHeader>
            <Page>
                <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                    <CardHeader borderSize={1}>
                        <CardLabel>
                            <CardTitle tag='div' className='h5'>New Post-Tax Deduction</CardTitle>
                        </CardLabel>
                    </CardHeader>
                    <CardBody>
                        <div className="row g-3 ms-2">
                            <div className="col-7">
                                <FormGroup id='earningDetailsId' label='Name in Payslip' >
                                    <Input
                                        onChange={earningDetailsForm.handleChange}
                                        value={earningDetailsForm.values.payslipName}
                                        isValid={earningDetailsForm.isValid}
                                        onBlur={earningDetailsForm.handleBlur}
                                        isTouched={earningDetailsForm.touched.payslipName}
                                        invalidFeedback={earningDetailsForm.errors.payslipName}
                                        placeholder='Name in Payslip'
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="h6 mt-3 fw-bold mt-4 ms-4">Select the deduction frequency
                            <span className='text-danger'> *</span>
                        </div>
                        <div className="mt-3">
                            <ChecksGroup>
                                <div className="col-4 mt-2 ms-4">
                                    <Checks
                                        type='radio'
                                        name='One-time deduction'
                                        id='One-time deduction'
                                        label='One-time deduction'
                                        value='1'
                                    // onChange={addEmployeeForm.handleChange}
                                    // checked={addEmployeeForm.values.accountType}
                                    />
                                </div>
                                <div className="col-4 mt-2 ms-4">
                                    <Checks
                                        type='radio'
                                        name='Recurring deduction for subsequent Payrolls'
                                        id='Recurring deduction for subsequent Payrolls'
                                        label='Recurring deduction for subsequent Payrolls'
                                        value='2'
                                    // onChange={addEmployeeForm.handleChange}
                                    // checked={addEmployeeForm.values.accountType}
                                    />
                                </div>
                            </ChecksGroup>
                        </div>
                        <div className="col-8 mt-2 ms-4">
                            <Checks
                                id='flexCheckDefault'
                                label='Mark this as Active'
                                name='defaultCheck'
                                onChange={doCheck}
                                checked={doChecked}
                            />
                        </div>
                        <br />
                        <div className='col-8 mt-2 ms-4'>
                            <Alert isLight className='border-0' shadow='lg' color='danger' rounded={2}>
                                <span>
                                    <strong>Note:</strong>
                                    <small> Once you associate this deduction with an employee, you will only be able to edit the Name in Payslip. The change will be reflected in both new and existing employees. </small>
                                </span>
                            </Alert>
                        </div>
                        <br /><br />
                        <CardFooter>
                            <CardFooterRight>
                                <Button
                                    color='info'
                                    type="submit"
                                    isDisable={!earningDetailsForm.isValid && !earningDetailsForm.submitCount}
                                >
                                    Save
                                </Button>
                                <Button
                                    isOutline
                                    color='info'
                                    isLight
                                    onClick={() => navigate(-1)}>
                                    Cancel
                                </Button>
                            </CardFooterRight>
                        </CardFooter>
                    </CardBody>
                </Card>
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    )
}

export default newPostTexDeduction