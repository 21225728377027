import { AxiosResponse } from "axios";
import { getAPIURL, getLicenseKey } from "./application.settings";
import service from "../api/api.services";

const api = service()

export const addStaffAttendance = (attendancePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffAttendance`, attendancePostData,
        response => {
            success(response)
        },
        error => {
            failure("addStaffAttendance - " + error.message)
            console.log('error caught in service : addStaffAttendance')
        },
    );

export const getStaffAttendanceReportList = (dateOfAttendance: any, userTypeId: any, isReport: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffAttendanceReportList/${getLicenseKey}/${dateOfAttendance}/${userTypeId}/${isReport}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffAttendanceReportList - " + error.message)
            console.log('error caught in service : getStaffAttendanceReportList')
        },
    );


export const getStaffMonthlyAttendanceList = (year: any, month: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffMonthlyAttendanceList/${getLicenseKey}/${year}/${month}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffMonthlyAttendanceList - " + error.message)
            console.log('error caught in service : getStaffMonthlyAttendanceList')
        },
    );

export const updateStaffMonthlyAttendance = (attendancePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffMonthlyAttendance`, attendancePostData,
        response => {
            success(response)
        },
        error => {
            failure("updateStaffMonthlyAttendance - " + error.message)
            console.log('error caught in service : updateStaffMonthlyAttendance')
        },
    );
