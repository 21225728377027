import React, { useContext, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Icon from '../icon/Icon';
import FormGroup from '../bootstrap/forms/FormGroup';
import Checks from '../bootstrap/forms/Checks';
import { useReactToPrint } from 'react-to-print';
import Button from '../bootstrap/Button';
import { useFormik } from 'formik';
import Input from '../bootstrap/forms/Input';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import useSortableData from '../../hooks/useSortableData';
import { dataPagination } from '../PaginationButtons';
import Collapse from '../bootstrap/Collapse';
import OpenCardComponent from '../../common/components/OpenCardComponents';
import { convertNumToMonth, onlyAllowNumber } from '../../services/common.service';
import NoDataMsg from '../../common/components/NoDataMsg';
import { addStaffSalaryPaidDetails, getStaffListForPayRun } from '../../services/salary.service';
import { toasts } from '../../services/toast.service';
import { showLoader } from '../../services/loader.services';
import { getLicenseKey } from '../../services/application.settings';
import AlertService from '../../services/AlertService';
import useMinimizeAside from '../../hooks/useMinimizeAside';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';
import useDarkMode from '../../hooks/useDarkMode';

const AddStaffSalary = () => {

    useMinimizeAside();
    // useEffect(() => {
    // }, []);

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const { items, requestSort, getClassNamesFor } = useSortableData([]);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [isLoader, setIsLoader] = useState(false);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [salaryDetails, setSalaryDetails] = useState<any>([]);
    const [noDataMsg, setNoDataMsg] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const componentRef = useRef(null);
    const navigate = useNavigate();
    const { themeStatus, darkModeStatus } = useDarkMode();

    const [salaryDetailsArray, setSalaryDetailsArray] = useState<any>([])
    const [data, setData] = useState<any>([])

    const [isCheckAll, setIsCheckAll] = useState(false);

    const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([]);
    const [totalWorkingDays, setTotalWorkingDays] = useState<any>(0);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const payRunForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            monthAndYear: '',
        },
        validate: (values) => {
            const errors: {
                monthAndYear?: string;
            } = {};

            if (!values.monthAndYear) {
                errors.monthAndYear = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    function viewSalaryDetails() {
        closeAndReset()
        setDataSuccess(false);
        setIsLoader(true);
        let monthAndYear = payRunForm.values.monthAndYear;
        const splitDate = monthAndYear.split('-');
        const year = splitDate[0];
        const month = splitDate[1];

        setYear(year);
        setMonth(month);
        getStaffSalaryList(year, month);
    }

    function getStaffSalaryList(year: any, month: any) {
        setIsLoader(true);
        getStaffListForPayRun(year, month, 0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListForPayRun;
                    if (data != undefined) {
                        setSalaryDetails(data);
                        setFilteredDataToRefresh(data)
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        setTotalWorkingDays(data[0].totalWorkingDays)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            data[i].isChecked = false;
                            data[i].paidAmount1 = '';
                        }
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        setIsOpenListCard(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    setSalaryDetails([]);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function onSelect(e: any, salary: any, index: any) {
        const newData: any = [];

        salaryDetails.forEach((item: any) => {

            if (item.staffDetailsId == salary.staffDetailsId) {
                item.isChecked = e
            }
        })
        newData.push(salary)
        setData(newData)

        if (e == true) {

            var obj
            obj = {
                staffDetailsId: salary.staffDetailsId,
                totalSalaryAmount: salary.totalSalaryAmount,
                monthlyAmount: salary.getGrossPayableAmount,
                pendingAmount: salary.pendingAmount,
                totalWorkingDays: salary.totalWorkingDays,
                totalPaidDays: salary.totalPaidDays,
                totalLeaveDays: salary.totalLeaveDays,
                totalOtWorkingTime: salary.totalOtWorkingTime,
                totalOtAmount: salary.totalOtAmount,
                totalLateCome: salary.totalLateCome,
                lateComeDeduction: salary.lateComeDeduction,
                totalLunchLateCome: salary.totalLateCome,
                lunchLateComeDeduction: salary.lunchLateComeDeduction,
                salaryAdvanceAmount: salary.salaryAdvanceAmount,
                loanAmount: salary.loanAmount,
                pfAmountDeduction: salary.getTotalPfAmount,
                grossPayableAmount: salary.getGrossPayableAmount,
                basicSalaryAmount: salary.getBasicSalaryAmount,
                lopAmount : salary.getLopAmount
            };
            salaryDetailsArray.push(obj);
        } else {

            salaryDetailsArray.splice(salaryDetailsArray.indexOf(index), 1);
        }

    }

    function updateAmountValue(paidAmount: any, staffDetailsId: any, pfAmountDeduction: any, totalOtAmount: any, getLopAmount: any, loanAmount: any, salaryAdvanceAmount: any) {
        const newData: any = [];
        salaryDetails.forEach((item: any) => {

            if (item['staffDetailsId'] == staffDetailsId) {

                item['getBasicSalaryAmount'] = paidAmount
                item['totalSalaryAmount'] = (Number(paidAmount) + Number(totalOtAmount)) - (Number(pfAmountDeduction) + Number(getLopAmount) + Number(loanAmount) + Number(salaryAdvanceAmount))
                // item['pendingAmount'] = Number(paidAmount) - Number(pfAmountDeduction)
            }
            newData.push(item)
        })
        setData(newData)

        if (salaryDetailsArray.length == 0) {
            const obj = {
                basicSalaryAmount: paidAmount,
                totalSalaryAmount: (Number(paidAmount) + Number(totalOtAmount)) - (Number(pfAmountDeduction) + Number(getLopAmount) + Number(loanAmount) + Number(salaryAdvanceAmount)),
                pendingAmount: (Number(paidAmount) + Number(totalOtAmount)) - (Number(pfAmountDeduction) + Number(getLopAmount) + Number(loanAmount) + Number(salaryAdvanceAmount)),
            };
            salaryDetailsArray.push(obj);
        } else {
            let selectedItem: any;
            salaryDetailsArray.forEach((item: any, index: any) => {
                if (item['staffDetailsId'] == staffDetailsId) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['basicSalaryAmount'] = paidAmount
                selectedItem['totalSalaryAmount'] = (Number(paidAmount) + Number(totalOtAmount)) - (Number(pfAmountDeduction) + Number(getLopAmount) + Number(loanAmount) + Number(salaryAdvanceAmount))
                selectedItem['pendingAmount'] = (Number(paidAmount) + Number(totalOtAmount)) - (Number(pfAmountDeduction) + Number(getLopAmount) + Number(loanAmount) + Number(salaryAdvanceAmount))
            } else {
                var obj
                obj = {
                    basicSalaryAmount: paidAmount,
                    totalSalaryAmount: (Number(paidAmount) + Number(totalOtAmount)) - (Number(pfAmountDeduction) + Number(getLopAmount) + Number(loanAmount) + Number(salaryAdvanceAmount)),
                    pendingAmount: (Number(paidAmount) + Number(totalOtAmount)) - (Number(pfAmountDeduction) + Number(getLopAmount) + Number(loanAmount) + Number(salaryAdvanceAmount)),
                };

                salaryDetailsArray.push(obj);
            }
        }
    }

    function updatePfAmountValue(pfAmount: any, staffDetailsId: any, basicSalaryAmount: any) {
        const newData: any = [];

        salaryDetails.forEach((item: any) => {

            if (item['staffDetailsId'] == staffDetailsId) {
                item['getTotalPfAmount'] = pfAmount
                item['totalSalaryAmount'] = Number(basicSalaryAmount) - Number(pfAmount)
                // item['pendingAmount'] = Number(basicSalaryAmount) - Number(pfAmount)
            }
            newData.push(item)
        })
        setData(newData)

        if (salaryDetailsArray.length == 0) {
            const obj = {
                pfAmountDeduction: pfAmount,
                totalSalaryAmount: Number(basicSalaryAmount) - Number(pfAmount),
                pendingAmount: Number(basicSalaryAmount) - Number(pfAmount),
            };
            salaryDetailsArray.push(obj);
        } else {
            let selectedItem: any;
            salaryDetailsArray.forEach((item: any, index: any) => {

                if (item['staffDetailsId'] == staffDetailsId) {
                    selectedItem = item
                }
            });
            if (selectedItem != undefined) {
                selectedItem['pfAmountDeduction'] = pfAmount
                selectedItem['totalSalaryAmount'] = Number(basicSalaryAmount) - Number(pfAmount)
                selectedItem['pendingAmount'] = Number(basicSalaryAmount) - Number(pfAmount)
            } else {
                var obj
                obj = {
                    pfAmountDeduction: pfAmount,
                    totalSalaryAmount: Number(basicSalaryAmount) - Number(pfAmount),
                    pendingAmount: Number(basicSalaryAmount) - Number(pfAmount),
                };

                salaryDetailsArray.push(obj);
            }
        }
    }

    function setStaffSalaryDetails() {
        return ({
            userAccountId: userAccountId,
            licenseKey: getLicenseKey,
            year: year,
            month: month,
            salaryDetails: salaryDetailsArray,
        })
    }

    function addUpdateStaffSalaryPaidDetailSubmit() {
        showLoader(true);
        if (salaryDetailsArray.length > 0) {
            let salaryDetailsPostData = setStaffSalaryDetails()
            addStaffSalaryPaidDetails(salaryDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        setDataSuccess(false);
                        setIsOpenListCard(true);
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false)
                        setIsOpen(true);
                        setAlertStatus({ message: errorDescription, type: "error" });
                    }
                }, (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )

        } else if (salaryDetailsArray.length == 0) {
            showLoader(false);
            setAlertStatus({ message: 'Please select a staff!', type: "error" });
            setIsOpen(true);
        }
    }

    const selectAllStaffs = (e: any) => {
        setIsCheckAll(e)
        const newData: any = [];
        if (e == true) {

            filteredDataToRefresh.forEach((item: any, index: any) => {
                if (item.paymentStatusId == 0) {
                    item.isSelect = true
                    item.isEditable = false
                    onSelect(true, item, index)
                }
            });
        } else {

            filteredDataToRefresh.forEach((item: any) => {
                item.isSelect = false
                item.isEditable = true
                item.isChecked = false
                newData.push(item);
            });

            setSalaryDetailsArray([])
        }
    }

    function closeAndReset() {
        setSalaryDetailsArray([]);
        setSalaryDetails([]);
        setIsCheckAll(false)
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (

        <PageWrapper title='Salary Details List'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card
                        stretch
                        data-tour='list'
                        tag='form'
                        noValidate
                        onSubmit={payRunForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Add Salary Details
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-3'>
                                    <FormGroup id='monthAndYear' label='Month' isFloating>
                                        <Input
                                            onChange={payRunForm.handleChange}
                                            value={payRunForm.values.monthAndYear}
                                            onBlur={payRunForm.handleBlur}
                                            isValid={payRunForm.isValid}
                                            isTouched={payRunForm.touched.monthAndYear}
                                            invalidFeedback={payRunForm.errors.monthAndYear}
                                            type='month'
                                        />
                                    </FormGroup>
                                </div>

                                <div className='col-6'>
                                    <Button
                                        className='mt-2'
                                        icon='ArrowDownward'
                                        color='primary'
                                        onClick={viewSalaryDetails}
                                        isDisable={payRunForm.values.monthAndYear != '' ? false : true}>
                                        View
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ? (
                    <>
                        <form noValidate onSubmit={payRunForm.handleSubmit}>

                            <Card stretch ref={componentRef}>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='List' iconColor='info' className='col-lg-6'>
                                        <CardTitle tag='div' className='h5'>
                                            <span className='text-primary'>{convertNumToMonth(month)} - {year}</span> Salary Details - <span className='text-danger'>{totalWorkingDays}</span> Days
                                        </CardTitle>
                                    </CardLabel>
                                </CardHeader>

                                <CardBody className='table-responsive'>
                                    <table className='table table-modern table-hover mt-3'>
                                        <thead>
                                            <tr className='table-primary'>
                                                <td className='text-decoration-underline'>
                                                    <Checks
                                                        className='border border-primary'
                                                        onChange={(e: any) => { selectAllStaffs(e.target.checked) }}
                                                        checked={isCheckAll}
                                                    />
                                                </td>
                                                <td className='text-decoration-underline'>
                                                    <strong>S.No</strong>
                                                </td>
                                                <td className='text-decoration-underline'>
                                                    <strong>Staff Name</strong>
                                                </td>
                                                <td className='text-center text-decoration-underline'>
                                                    <strong> Paid<br /> Days</strong>
                                                </td>
                                                <td className='text-center text-decoration-underline'>
                                                    <strong>Gross <br />Salary</strong>
                                                </td>
                                                <td className='text-decoration-underline'>
                                                    <strong>Basic Salary</strong>
                                                </td>
                                                <td className='text-decoration-underline'>
                                                    <strong>PF Amount</strong>
                                                </td>
                                                <td className='text-center text-decoration-underline'>
                                                    <strong>LOP  <br /> Amount</strong>
                                                </td>

                                                {/* <td className='text-center text-decoration-underline'>
                                                    <strong>OT <br /> (Timing)</strong>
                                                </td> */}
                                                {/* <td className='text-center text-decoration-underline'>
                                                    <strong>Late Come <br /> (Timing)</strong>
                                                </td>
                                                <td className='text-center text-decoration-underline'>
                                                    <strong>Lunch Late Come <br /> (Timing)</strong>
                                                </td> */}
                                                <td className='text-center text-decoration-underline'>
                                                    <strong>Part<br /> Salary</strong>
                                                </td>
                                                {/* <td className='text-center text-decoration-underline'>
                                                    <strong>Loan</strong>
                                                </td> */}
                                                <td className='text-center text-decoration-underline'>
                                                    <strong>Total<br /> Salary</strong>
                                                </td>
                                                {/* <td className='text-center text-decoration-underline'>
                                                    <strong>Pending<br /> Amount</strong>
                                                </td> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {salaryDetails != '' ? (
                                                <>
                                                    {salaryDetails.map(
                                                        (salary: any, index: any) => (
                                                            <tr key={index}>
                                                                <td>
                                                                    {salary.paymentStatusId > 0 ? <Icon
                                                                        size='lg'
                                                                        color='success'
                                                                        icon='CheckBox'
                                                                    /> :
                                                                        <Checks
                                                                            className='border border-primary'
                                                                            onChange={(e: any) => onSelect(e.target.checked, salary, index)}
                                                                            checked={salary.isChecked}
                                                                        />
                                                                    }
                                                                </td>
                                                                <td>{salary.sno}</td>
                                                                <td>{salary.staffName} <br /> <span className='text-center text-info'>{salary.empNumber}</span> - <span className='text-center text-info'>{salary.designationName}</span> </td>
                                                                <td className='text-center fw-bold'>{salary.totalPaidDays}</td>
                                                                <td className='text-center fw-bold'>{salary.getGrossPayableAmount}</td>
                                                                <td className='text-center text-success fw-bold'>
                                                                    <div className="col-12" style={{ width: '100px'}}>
                                                                        <FormGroup id='remarks'>
                                                                            <Input placeholder="" value={salary.getBasicSalaryAmount} onInput={(e: any) => updateAmountValue(e.target.value, salary.staffDetailsId, salary.getTotalPfAmount, salary.totalOtAmount, salary.getLopAmount, salary.loanAmount, salary.salaryAdvanceAmount)} onKeyDown={(e: any) => onlyAllowNumber(e)} disabled={salary.isChecked == true ? false : true} />
                                                                        </FormGroup>
                                                                    </div></td>

                                                                <td className='text-center text-success fw-bold'>
                                                                    <div className="col-12" style={{ width: '100px'}}>
                                                                        <FormGroup id='remarks'>
                                                                            <Input placeholder="" value={salary.getTotalPfAmount} onInput={(e: any) => updatePfAmountValue(e.target.value, salary.staffDetailsId, salary.pendingAmount)} onKeyDown={(e: any) => onlyAllowNumber(e)} disabled={salary.isChecked == true ? false : true} />
                                                                        </FormGroup>
                                                                    </div></td>
                                                                <td className='text-center text-danger'>{salary.getLopAmount}</td>

                                                                {/* <td className='text-center'><span className='text-success'>{salary.totalOtAmount}</span> <br />{salary.totalOtWorkingTimeForView != '' && salary.totalOtWorkingTimeForView != null ? <>(<span className='text-info'>{salary.totalOtWorkingTimeForView}</span>) </> : null}</td> */}

                                                                {/* <td className='text-center'><span className='text-danger'>{salary.lateComeDeduction}</span> <br />{salary.totalLateComeForView != '' && salary.totalLateComeForView != null ? <>(<span className='text-info'>{salary.totalLateComeForView}</span>)</> : null}</td>

                                                                <td className='text-center'><span className='text-danger'>{salary.lunchLateComeDeduction}</span> <br />{salary.totalLunchLateComeForView != '' && salary.totalLunchLateComeForView != null ? <>(<span className='text-info'>{salary.totalLunchLateComeForView}</span>)</> : null}</td> */}

                                                                <td className='text-center text-danger'>{salary.salaryAdvanceAmount}</td>
                                                                {/* <td className='text-center text-danger'>{salary.loanAmount}</td> */}
                                                                <td className='text-center text-success fw-bold'>{salary.totalSalaryAmount}</td>

                                                            </tr>
                                                        )
                                                    )}
                                                </>
                                            ) : (
                                                <NoDataMsg columnsCount={7} msg={noDataMsg} />
                                            )}
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                            <SubHeader>
                                <SubHeaderLeft><></></SubHeaderLeft>
                                <SubHeaderRight>
                                    <SubheaderSeparator />
                                    <Button color='primary' type='submit' icon='Save' isLight onClick={() => addUpdateStaffSalaryPaidDetailSubmit()}>
                                        Submit
                                    </Button>
                                </SubHeaderRight>
                            </SubHeader>
                        </form>
                        <br />
                        <br />
                        <br />
                    </>
                ) : null}
            </Page>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
};

export default AddStaffSalary;