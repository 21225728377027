import React, { FC, forwardRef, HTMLAttributes, ReactElement, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { CardFooter, CardFooterLeft, CardFooterRight } from './bootstrap/Card';
import Select from './bootstrap/forms/Select';
import Option from './bootstrap/Option';
import classNames from 'classnames';
import Icon from './icon/Icon';


interface IPaginationItemProps extends HTMLAttributes<HTMLLIElement> {
	className?: string;
	isDisabled?: boolean;
	isActive?: boolean;
	isPrev?: boolean;
	isFirst?: boolean;
	isNext?: boolean;
	isLast?: boolean;
	children?: ReactNode;
	onClick?(...args: unknown[]): unknown | undefined;
}
export const PaginationItem = forwardRef<HTMLLIElement, IPaginationItemProps>(
	(
		{
			className,
			isDisabled,
			isActive,
			isPrev,
			isFirst,
			isNext,
			isLast,
			children,
			onClick,
			...props
		},
		ref,
	) => {
		return (
			<li
				ref={ref}
				className={classNames(
					'page-item',
					{
						disabled: isDisabled,
						active: isActive,
					},
					className,
				)}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...props}>
				<span
					role='button'
					onClick={onClick}
					onKeyDown={onClick}
					className='page-link'
					tabIndex={isDisabled ? -1 : undefined}
					aria-disabled={isDisabled ? 'true' : undefined}
					aria-label={
						(isPrev && 'First Page') || (isNext && 'Last Page') || `${children} page`
					}>
					{isPrev && <Icon icon='ChevronLeft' />}
					{isFirst && <Icon icon='FirstPage' />}
					{isNext && <Icon icon='ChevronRight' />}
					{isLast && <Icon icon='LastPage' />}
					{children}
				</span>
			</li>
		);
	},
);

PaginationItem.displayName = 'PaginationItem';
PaginationItem.propTypes = {
	className: PropTypes.string,
	isDisabled: PropTypes.bool,
	isActive: PropTypes.bool,
	isPrev: PropTypes.bool,
	isFirst: PropTypes.bool,
	isNext: PropTypes.bool,
	isLast: PropTypes.bool,
	children: PropTypes.node,
	onClick: PropTypes.func,
};
PaginationItem.defaultProps = {
	className: undefined,
	isDisabled: false,
	isActive: false,
	isPrev: false,
	isFirst: false,
	isNext: false,
	isLast: false,
	children: null,
	onClick: undefined,
};
interface IPaginationProps extends HTMLAttributes<HTMLElement> {
	ariaLabel: string;
	children:
		| ReactElement<IPaginationItemProps>
		| ReactElement<IPaginationItemProps>[]
		| ReactNode
		| ReactNode[];
	className?: string | undefined;
	size?: 'sm' | 'lg' | null;
}
const Pagination = forwardRef<HTMLDivElement, IPaginationProps>(
	({ ariaLabel, className, children, size, ...props }, ref) => {
		return (
			// eslint-disable-next-line react/jsx-props-no-spreading
			<nav ref={ref} aria-label={ariaLabel} className={className} {...props}>
				<ul className={classNames('pagination', { [`pagination-${size}`]: size }, 'm-0')}>
					{children}
				</ul>
			</nav>
		);
	},
);

Pagination.displayName = 'Pagination';
Pagination.propTypes = {
	ariaLabel: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	size: PropTypes.oneOf(['sm', 'lg']),
};
Pagination.defaultProps = {
	className: undefined,
	size: null,
};


export const PER_COUNT = {
	3: 3,
	5: 5,
	10: 10,
	25: 25,
	50: 50,
};

export const dataPagination = (data: any[], currentPage: number, perPage: number) =>
	data.filter(
		(i, index) => index + 1 > (currentPage - 1) * perPage && index + 1 <= currentPage * perPage,
	);

interface IPaginationButtonsProps {
	setCurrentPage(...args: unknown[]): unknown;
	currentPage: number;
	perPage: number;
	setPerPage(...args: unknown[]): unknown;
	data: unknown[];
	label?: string;
	className?: string;
}
const PaginationButtons: FC<IPaginationButtonsProps> = ({
	setCurrentPage,
	currentPage,
	perPage,
	setPerPage,
	data,
	label,
	className
}) => {
	const totalItems = data.length;
	const totalPage = Math.ceil(totalItems / perPage);

	const pagination = () => {
		let items = [];

		let i = currentPage - 1;
		while (i >= currentPage - 1 && i > 0) {
			items.push(
				<PaginationItem key={i} onClick={() => setCurrentPage(currentPage - 1)}>
					{i}
				</PaginationItem>,
			);

			i -= 1;
		}

		items = items.reverse();

		items.push(
			<PaginationItem key={currentPage} isActive onClick={() => setCurrentPage(currentPage)}>
				{currentPage}
			</PaginationItem>,
		);

		i = currentPage + 1;
		while (i <= currentPage + 1 && i <= totalPage) {
			items.push(
				<PaginationItem key={i} onClick={() => setCurrentPage(currentPage + 1)}>
					{i}
				</PaginationItem>,
			);

			i += 1;
		}

		return items;
	};

	const getInfo = () => {
		const start = perPage * (currentPage - 1) + 1;

		const end = perPage * currentPage;

		return (
			<span className='pagination__desc'>
				Showing {start} to {end > totalItems ? totalItems : end} of {totalItems} {label}
			</span>
		);
	};

	return (
		<CardFooter className={className}>
			<CardFooterLeft>
				<span className='text-muted'>{getInfo()}</span>
			</CardFooterLeft>

			<CardFooterRight className='d-flex'>
				{totalPage > 1 && (
					// @ts-ignore
					<Pagination ariaLabel={label}>
						<PaginationItem
							isFirst
							isDisabled={!(currentPage - 1 > 0)}
							onClick={() => setCurrentPage(1)}
						/>
						<PaginationItem
							isPrev
							isDisabled={!(currentPage - 1 > 0)}
							onClick={() => setCurrentPage(currentPage - 1)}
						/>
						{currentPage - 1 > 1 && (
							<PaginationItem onClick={() => setCurrentPage(currentPage - 2)}>
								...
							</PaginationItem>
						)}
						{pagination()}
						{currentPage + 1 < totalPage && (
							<PaginationItem onClick={() => setCurrentPage(currentPage + 2)}>
								...
							</PaginationItem>
						)}
						<PaginationItem
							isNext
							isDisabled={!(currentPage + 1 <= totalPage)}
							onClick={() => setCurrentPage(currentPage + 1)}
						/>
						<PaginationItem
							isLast
							isDisabled={!(currentPage + 1 <= totalPage)}
							onClick={() => setCurrentPage(totalPage)}
						/>
					</Pagination>
				)}

				<Select					
					size='sm'
					ariaLabel='Per'
					onChange={(e: { target: { value: string } }) => {
						setPerPage(parseInt(e.target.value, 10));
						setCurrentPage(1);
					}}
					value={perPage.toString()}>
					{Object.keys(PER_COUNT).map((i) => (
						<Option key={i} value={i}>
							{i}
						</Option>
					))}
				</Select>
			</CardFooterRight>
		</CardFooter>
	);
};
PaginationButtons.propTypes = {
	setCurrentPage: PropTypes.func.isRequired,
	currentPage: PropTypes.number.isRequired,
	perPage: PropTypes.number.isRequired,
	setPerPage: PropTypes.func.isRequired,
	// eslint-disable-next-line react/forbid-prop-types
	data: PropTypes.array.isRequired,
	label: PropTypes.string,
};
PaginationButtons.defaultProps = {
	label: 'items',
};

export default PaginationButtons;
