import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useDarkMode from '../../hooks/useDarkMode';
import AuthContext from '../../contexts/authContext';
import { getProfileForStaff } from '../../services/staff.service';
import { toasts } from '../../services/toast.service';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import SubHeader, {
	SubHeaderLeft,
	SubHeaderRight,
	SubheaderSeparator,
} from '../../layout/SubHeader/SubHeader';
import Button from '../bootstrap/Button';
import Page from '../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import { getCurrentDateAndTime, pictNotLoading, profilePic } from '../../services/common.service';
import Icon from '../icon/Icon';
import { getStaffListForPayRun } from '../../services/salary.service';
import classNames from 'classnames';

const StaffProfile = () => {
	const navigate = useNavigate();

	const { darkModeStatus } = useDarkMode();

	const { staffDetailsId } = useParams();

	useEffect(() => {
		const [year, month] = getCurrentDateAndTime('date').split('-');
		getStaffProfile(staffDetailsId, year, month);
	}, []);

	const [profileForStaffData, setProfileForStaffData] = useState<any>([]);
	const [staffPayRunData, setStaffPayRunData] = useState<any>([]);
	const { userAccountId, userTypeId } = useContext(AuthContext);

	function getStaffProfile(staffDetailsId: any, year: any, month: any) {
		getProfileForStaff(
			staffDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.profileForStaff;
					if (data != undefined) {
						setProfileForStaffData(data);
						getStaffForPayRun(year, month, staffDetailsId);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStaffForPayRun(year: any, month: any, staffDetailsId: any) {
		getStaffListForPayRun(
			year,
			month,
			staffDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffListForPayRun;
					if (data != undefined) {
						setStaffPayRunData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	return (
		<>
			{profileForStaffData.map((staff: any) => (
				<PageWrapper title={`${staff.fullname}`} key={staff.staffUserAccountId}>
					<SubHeader>
						<SubHeaderLeft>
							<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
								Back to List
							</Button>
							<SubheaderSeparator />
						</SubHeaderLeft>
						{userTypeId == 1 ? (
							<SubHeaderRight>
								<Button
									color='primary'
									isLight
									icon='Edit'
									tag='a'
									to={`../userManagement/updateStaff/${staffDetailsId}`}>
									Edit
								</Button>
							</SubHeaderRight>
						) : (
							<></>
						)}
					</SubHeader>
					<Page container='fluid'>
						<div className='row'>
							<div className='col-lg-4'>
								<Card className='shadow-3d-info'>
									<CardBody>
										<div className='row g-3'>
											<div className='col-12 d-flex justify-content-center'>
												{staff.profilePath != null ? (
													<img
														style={{ borderRadius: '50%' }}
														src={staff.profilePath}
														width='120'
														height='120'
														onError={(e: any) =>
															pictNotLoading(e, staff.genderId)
														}
													/>
												) : (
													<img
														src={profilePic(staff.genderId)}
														width='120'
														height='120'
													/>
												)}
											</div>
											<div className='col-12'>
												<div className='row g-2'>
													<span className='fs-4 fw-bold me-2  d-flex justify-content-center text-center text-uppercase'>{`${staff.fullname}`}</span>
													<div className='h5 text-muted text-lowercase opacity-50 d-flex justify-content-center text-uppercase'>
														{`${staff.designationName != null
															? staff.designationName
															: staff.userType
															}`}
													</div>

													<div className='col-6'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='ViewList'
																	size='3x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{staff.empNumber ? (
																		staff.empNumber
																	) : (
																		<Icon
																			icon='Splitscreen'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}
																</div>
																<div className='text-muted'>
																	Emp number
																</div>
															</div>
														</div>
													</div>
													<div className='col-6'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='PhoneIphone'
																	size='3x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{staff.contactNumber ? (
																		staff.contactNumber
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}
																</div>
																<div className='text-muted'>
																	Mobile
																</div>
															</div>
														</div>
													</div>
													<div className='col-6'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon={
																		staff.genderId == 1
																			? 'Male'
																			: 'Female'
																	}
																	size='3x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{staff.gender ? (
																		staff.gender
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}
																</div>
																<div className='text-muted'>
																	Gender
																</div>
															</div>
														</div>
													</div>
													<div className='col-6'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='DateRange'
																	size='3x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{staff.dateOfBirth ? (
																		staff.dateOfBirth
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}{' '}
																</div>
																<div className='text-muted'>
																	DOB
																</div>
															</div>
														</div>
													</div>

													<div className='col-12'>
														<div className='d-flex align-items-center'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='Mail'
																	size='3x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold fs-5 mb-0'>
																	{staff.email ? (
																		staff.email
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}
																</div>
																<div className='text-muted'>
																	Personal Email
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
								<Card>
									<CardHeader>
										<CardLabel icon='ShowChart' iconColor='secondary'>
											<CardTitle tag='div' className='h5'>
												Staff Pay Run Details
											</CardTitle>
											<CardActions>Till Now</CardActions>
										</CardLabel>
									</CardHeader>
									<CardBody>
										<div className='col-12'>
											{staffPayRunData.map((staffSalary: any) => (
												<div
													className='row g-2 align-items-center'
													key={staffSalary.staffDetailsId}>
													<div className='col-xl-6'>
														<div
															className={classNames(
																'd-flex align-items-center rounded-2 p-3',
																{
																	'bg-l10-danger':
																		!darkModeStatus,
																	'bg-lo25-danger':
																		darkModeStatus,
																},
															)}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='AttachMoney'
																	size='2x'
																	color='danger'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold text-danger mb-0'>
																	{
																		staffSalary.monthlySalaryAmount
																	}
																</div>
																<div className='text-muted'>
																	<b>SALARY AMOUNT</b>
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-6'>
														<div
															className={classNames(
																'd-flex align-items-center rounded-2 p-3',
																{
																	'bg-l10-secondary':
																		!darkModeStatus,
																	'bg-lo25-secondary':
																		darkModeStatus,
																},
															)}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='AttachMoney'
																	size='2x'
																	color='secondary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold text-secondary mb-0'>
																	{staffSalary.totalSalaryAmount}
																</div>
																<div className='text-muted'>
																	<b>TOTAL SALARY </b>
																</div>
															</div>
														</div>
													</div>

													<div className='col-xl-6'>
														<div
															className={classNames(
																'd-flex align-items-center rounded-2 p-3',
																{
																	'bg-l10-info': !darkModeStatus,
																	'bg-lo25-info': darkModeStatus,
																},
															)}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='DateRange'
																	size='2x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold text-info mb-0'>
																	{staffSalary.totalWorkingDays}
																</div>
																<div className='text-muted'>
																	<b>WORKING DAYS</b>
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-6'>
														<div
															className={classNames(
																'd-flex align-items-center rounded-2 p-3',
																{
																	'bg-l10-success':
																		!darkModeStatus,
																	'bg-lo25-success':
																		darkModeStatus,
																},
															)}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='EventBusy'
																	size='2x'
																	color='success'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold text-success mb-0'>
																	{staffSalary.totalAbsentCount}
																</div>
																<div className='text-muted'>
																	<b>TOTAL ABSENT</b>
																</div>
															</div>
														</div>
													</div>

													<div className='col-xl-6'>
														<div
															className={classNames(
																'd-flex align-items-center rounded-2 p-3',
																{
																	'bg-l10-info': !darkModeStatus,
																	'bg-lo25-info': darkModeStatus,
																},
															)}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='MoreTime'
																	size='2x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold text-info mb-0'>
																	{staffSalary.totalOtAmount != 0 ? <>{staffSalary.totalOtAmount}({staffSalary.totalOtWorkingTimeForView})</> : 0}
																</div>
																<div className='text-muted'>
																	<b>OT </b>
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-6'>
														<div
															className={classNames(
																'd-flex align-items-center rounded-2 p-3',
																{
																	'bg-l10-danger':
																		!darkModeStatus,
																	'bg-lo25-danger':
																		darkModeStatus,
																},
															)}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='info'
																	size='2x'
																	color='danger'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold text-danger mb-0'>
																	{staffSalary.lateComeDeduction != 0 ? <>{staffSalary.lateComeDeduction}{' '}
																		({staffSalary.totalLateComeForView})</> : 0}
																</div>
																<div className='text-muted'>
																	<b>LATE COME</b>
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-6'>
														<div
															className={classNames(
																'd-flex align-items-center rounded-2 p-3',
																{
																	'bg-l10-danger':
																		!darkModeStatus,
																	'bg-lo25-danger':
																		darkModeStatus,
																},
															)}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='TimerOff'
																	size='2x'
																	color='danger'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold text-danger mb-0'>
																	{staffSalary.lunchLateComeDeduction != 0 ? <>{staffSalary.lunchLateComeDeduction}({staffSalary.totalLunchLateComeForView})</> : 0}
																</div>
																<div className='text-muted'>
																	<b>LUNCH LATE COME</b>
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-6'>
														<div
															className={classNames(
																'd-flex align-items-center rounded-2 p-3',
																{
																	'bg-l10-info': !darkModeStatus,
																	'bg-lo25-info': darkModeStatus,
																},
															)}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='Add'
																	size='2x'
																	color='info'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold text-info mb-0'>
																	{staffSalary.salaryAdvanceAmount}
																</div>
																<div className='text-muted'>
																	<b>SALARY ADVANCE </b>
																</div>
															</div>
														</div>
													</div>
													<div className='col-xl-6'>
														<div
															className={classNames(
																'd-flex align-items-center rounded-2 p-3',
																{
																	'bg-l10-success':
																		!darkModeStatus,
																	'bg-lo25-success':
																		darkModeStatus,
																},
															)}>
															<div className='flex-shrink-0'>
																<Icon
																	icon='Savings'
																	size='2x'
																	color='success'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold text-success mb-0'>
																	{staffSalary.loanAmount}
																</div>
																<div className='text-muted'>
																	<b>LOAN AMOUNT</b>
																</div>
															</div>
														</div>
													</div>
												</div>
											))}
										</div>
									</CardBody>
								</Card>
							</div>

							{/* Other Info */}
							<div className='col-lg-8'>
								<Card className='shadow-3d-primary'>
									<CardBody>
										<div className='row g-4'>
											<div className='col-md-6'>
												<Card
													className={`bg-l${darkModeStatus ? 'o25' : '25'
														}-warning bg-l${darkModeStatus ? 'o50' : '10'
														}-warning-hover transition-base rounded-2 mb-4`}
													shadow='sm'>
													<div className='ms-4 mt-4'>
														<CardLabel className='bg-transparent'>
															<CardTitle tag='div' className='h5'>
																Work Details
															</CardTitle>
														</CardLabel>
													</div>
													<CardBody>
														<div className='row g-3'>
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='DateRange'
																			size='2x'
																			color='info'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold fs-6 mb-0'>
																			{staff.dateOfJoining ? (
																				staff.dateOfJoining
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			date Of Joining
																		</div>
																	</div>
																</div>
															</div>

															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='HomeWork'
																			size='2x'
																			color='info'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold fs-6 mb-0'>
																			{staff.departmentName ? (
																				staff.departmentName
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Department Name
																		</div>
																	</div>
																</div>
															</div>
															<div className='col-12'>
																<div className='d-flex align-items-center'>
																	<div className='flex-shrink-0'>
																		<Icon
																			icon='Category'
																			size='2x'
																			color='info'
																		/>
																	</div>
																	<div className='flex-grow-1 ms-3'>
																		<div className='fw-bold fs-6 mb-0'>
																			{staff.designationName ? (
																				staff.designationName
																			) : (
																				<Icon
																					icon='Remove'
																					className='mb-0 text-dark fw-bold h2'
																				/>
																			)}
																		</div>
																		<div className='text-muted'>
																			Designation Name
																		</div>
																	</div>
																</div>
															</div>
														</div>
													</CardBody>
												</Card>
												<Card
													className={`bg-l${darkModeStatus ? 'o25' : '25'
														}-secondary bg-l${darkModeStatus ? 'o50' : '10'
														}-secondary-hover transition-base rounded-2 mb-4`}
													shadow='sm'>
													<div className='ms-4 mt-4'>
														<CardLabel className='bg-transparent'>
															<CardTitle tag='div' className='h5'>
																Work Locations
															</CardTitle>
														</CardLabel>
													</div>
													<CardBody>
														<div className='d-flex align-items-center pb-3'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='House'
																	size='3x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold mb-0'>
																	{staff.workLocationsId != 0 ? (
																		staff.workLocationsName
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}
																</div>
															</div>
														</div>
													</CardBody>
												</Card>
												<Card
													className={`bg-l${darkModeStatus ? 'o25' : '25'
														}-primary bg-l${darkModeStatus ? 'o50' : '10'
														}-primary-hover transition-base rounded-2 mb-4`}
													shadow='sm'>
													<div className='ms-4 mt-4'>
														<CardLabel className='bg-transparent'>
															<CardTitle tag='div' className='h5'>
																Address
															</CardTitle>
														</CardLabel>
													</div>
													<CardBody>
														<div className='d-flex align-items-center pb-3'>
															<div className='flex-shrink-0'>
																<Icon
																	icon='House'
																	size='3x'
																	color='primary'
																/>
															</div>
															<div className='flex-grow-1 ms-3'>
																<div className='fw-bold mb-0'>
																	{staff.address ? (
																		staff.address
																	) : (
																		<Icon
																			icon='Remove'
																			className='mb-0 text-dark fw-bold h2'
																		/>
																	)}
																</div>
															</div>
														</div>
													</CardBody>
												</Card>
											</div>

											<div className='col-md-6'>
												<div className='row g-2'>
													{/* Account Information */}
													<Card
														className={`bg-l${darkModeStatus ? 'o25' : '25'
															}-success bg-l${darkModeStatus ? 'o50' : '10'
															}-success-hover transition-base rounded-2 mb-0 ms-2`}
														shadow='sm'>
														<div className='ms-4 mt-4'>
															<CardLabel className='bg-transparent'>
																<CardTitle tag='div' className='h5'>
																	Account Information
																</CardTitle>
															</CardLabel>
														</div>
														<CardBody className='pt-0'>
															<div className='row g-2 d-flex align-items-center'>
																<div className='col-12'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='CommentBank'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold fs-6 mb-0 mt-3'>
																				{staff.accountHolderName ? (
																					staff.accountHolderName
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}
																			</div>
																			<div className='text-muted'>
																				Account Holder Name
																			</div>
																		</div>
																	</div>
																</div>
																<div className='col-12'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='AccountBalance'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold fs-6 mb-0'>
																				{staff.bankName ? (
																					staff.bankName
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}
																			</div>
																			<div className='text-muted'>
																				Bank Name
																			</div>
																		</div>
																	</div>
																</div>
																<div className='col-12'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='EditNote'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold fs-6 mb-0'>
																				{staff.accountNumber ? (
																					staff.accountNumber
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}
																			</div>
																			<div className='text-muted'>
																				Account Number
																			</div>
																		</div>
																	</div>
																</div>

																<div className='col-12'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='QrCode'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold fs-6 mb-0'>
																				{staff.ifscCode ? (
																					staff.ifscCode
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}
																			</div>
																			<div className='text-muted'>
																				IFSC Code
																			</div>
																		</div>
																	</div>
																</div>
																<div className='col-12'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='SwitchAccount'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold fs-6 mb-0'>
																				{staff.accountType ? (
																					staff.accountType
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}
																			</div>
																			<div className='text-muted'>
																				Account Type
																			</div>
																		</div>
																	</div>
																</div>
																<div className='col-12'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='Article'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold fs-6 mb-0'>
																				{staff.panNumber ? (
																					staff.panNumber
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}
																			</div>
																			<div className='text-muted'>
																				Pan Number
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</CardBody>
													</Card>

													<Card
														className={`bg-l${darkModeStatus ? 'o25' : '25'
															}-danger bg-l${darkModeStatus ? 'o50' : '10'
															}-danger-hover transition-base rounded-2 mb-0 row g-3 ms-2`}
														shadow='sm'>
														<div className='ms-4 mt-4'>
															<CardLabel className='bg-transparent'>
																<CardTitle tag='div' className='h5'>
																	Other info
																</CardTitle>
															</CardLabel>
														</div>
														<CardBody className='pt-0'>
															<div className='row g-2 d-flex align-items-center'>
																<div className='col-12'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='PermIdentity'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold fs-6 mb-0 mt-3'>
																				{staff.fatherName ? (
																					staff.fatherName
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}
																			</div>
																			<div className='text-muted'>
																				Father Name
																			</div>
																		</div>
																	</div>
																</div>
																<div className='col-12'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='Bloodtype'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold fs-6 mb-0'>
																				{staff.groupName ? (
																					staff.groupName
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}
																			</div>
																			<div className='text-muted'>
																				Blood Group
																			</div>
																		</div>
																	</div>
																</div>
																<div className='col-12'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='EditNote'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold fs-6 mb-0'>
																				{staff.aadharCardNumber ? (
																					staff.aadharCardNumber
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}
																			</div>
																			<div className='text-muted'>
																				Aadhar Number
																			</div>
																		</div>
																	</div>
																</div>

																<div className='col-12'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='Group'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold fs-6 mb-0'>
																				{staff.martialStatus ? (
																					staff.martialStatus
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}
																			</div>
																			<div className='text-muted'>
																				Martial Status
																			</div>
																		</div>
																	</div>
																</div>
																<div className='col-12'>
																	<div className='d-flex align-items-center'>
																		<div className='flex-shrink-0'>
																			<Icon
																				icon='Phone'
																				size='2x'
																				color='primary'
																			/>
																		</div>
																		<div className='flex-grow-1 ms-3'>
																			<div className='fw-bold fs-6 mb-0'>
																				{staff.emergencyContactPhone ? (
																					staff.emergencyContactPhone
																				) : (
																					<Icon
																						icon='Remove'
																						className='mb-0 text-dark fw-bold h2'
																					/>
																				)}
																			</div>
																			<div className='text-muted'>
																				Emergency Contact
																				Person Number
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</CardBody>
													</Card>
												</div>
											</div>
										</div>
									</CardBody>
								</Card>
							</div>
						</div>
					</Page>
				</PageWrapper>
			))}
		</>
	);
};

export default StaffProfile;
