import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getEmployeeLoanDetails = (employeeDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getEmployeeLoanDetails/${getLicenseKey}/${employeeDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getEmployeeLoanDetails - " + error.message)
            console.log('error caught in service : getEmployeeLoanDetails')
        },
    );

export const addStaffLoanDetails = (loanPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addStaffLoanDetails`, loanPostData,
        response => {
            success(response)
        },
        error => {
            failure("addStaffLoanDetails - " + error.message)
            console.log('error caught in service : addStaffLoanDetails')
        },
    );

export const updateStaffLoanDetails = (loanPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateStaffLoanDetails`, loanPostData,
        response => {
            success(response)
        },
        error => {
            failure("updateStaffLoanDetails - " + error.message)
            console.log('error caught in service : updateStaffLoanDetails')
        },
    );

export const deleteStaffLoansDetails = (loanPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteStaffLoansDetails`, loanPostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteStaffLoansDetails - " + error.message)
            console.log('error caught in service : deleteStaffLoansDetails')
        },
    );

export const getLoanRequestType = (loanRequestTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getLoanRequestType/${getLicenseKey}/${loanRequestTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getLoanRequestType - " + error.message)
            console.log('error caught in service : getLoanRequestType')
        },
    );


export const addLoanRequestType = (loanPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addLoanRequestType`, loanPostData,
        response => {
            success(response)
        },
        error => {
            failure("addLoanRequestType - " + error.message)
            console.log('error caught in service : addLoanRequestType')
        },
    );

export const updateLoanRequestType = (loanTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateLoanRequestType`, loanTypePostData,
        response => {
            success(response)
        },
        error => {
            failure("updateLoanRequestType - " + error.message)
            console.log('error caught in service : updateLoanRequestType')
        },
    );

export const deleteLoanRequestType = (loanTypePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteLoanRequestType`, loanTypePostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteLoanRequestType - " + error.message)
            console.log('error caught in service : deleteLoanRequestType')
        },
    );

export const getStaffLoanDetails = (userTypeId: any, staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffLoanDetails/${getLicenseKey}/${userTypeId}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffLoanDetails - " + error.message)
            console.log('error caught in service : getStaffLoanDetails')
        },
    );


export const getLoanRequestHistory = (requestTableId: number, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffRequestHistory/${getLicenseKey}/${requestTableId}/12`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffRequestHistory - " + error.message)
            console.log('error caught in service : getStaffRequestHistory')
        },
    );

export const getLoanScheduleDetails = (loansDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffLoanDetails/${getLicenseKey}/0/0/${loansDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffLoanDetails - " + error.message)
            console.log('error caught in service : getStaffLoanDetails')
        },
    );