import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getAllScreensByUserTypeId = (userTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getAllScreens/${getLicenseKey}/${userTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAllScreens - " + error.message)
            console.log('error caught in service : getAllScreens')
        },
    );

export const getAllScreens = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getAllScreens/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getAllScreens - " + error.message)
            console.log('error caught in service : getAllScreens')
        },
    );


export const getAllScreensByUserAccountId = (userTypeId: any, userAccountId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getAllScreens/${getLicenseKey}/${userTypeId}/${userAccountId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAllScreens - " + error.message)
            console.log('error caught in service : getAllScreens')
        },
    );

export const assignScreenForStaff = (screenPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `assignScreenForStaff`, screenPostData,
        response => {
            success(response)
        },
        error => {
            failure(error.message)
            console.log('error caught in service : assignScreenForStaff')
        }
    );

    export const getAssignedScreenByUserAccountId = (userAccountId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>
    api.get(getAPIURL() + `getAssignedScreenByUserAccountId/${getLicenseKey}/${userAccountId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAssignedScreenByUserAccountId - " + error.message)
            console.log('error caught in service : getAssignedScreenByUserAccountId')
        },
    );