import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Brand from '../../../layout/Brand/Brand';
import Navigation from '../../../layout/Navigation/Navigation';
import ThemeContext from '../../../contexts/themeContext';
import useDarkMode from '../../../hooks/useDarkMode';
import Aside, { AsideBody, AsideFoot, AsideHead } from '../../../layout/Aside/Aside';
import AuthContext from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import User from '../../../layout/User/User';
import { toasts } from '../../../services/toast.service';
import { getAssignedScreenByUserAccountId } from '../../../services/screen.service';
import { checkDataAvailableOrNot } from '../../../services/common.service';

const DefaultAside = () => {
	const { asideStatus, setAsideStatus } = useContext(ThemeContext);

	const [doc, setDoc] = useState(
		localStorage.getItem('facit_asideDocStatus') === 'true' || false,
	);

	const { t } = useTranslation(['translation', 'menu']);
	const { darkModeStatus } = useDarkMode();
	const { userAccountId, userTypeId, organisationDetailsId } = useContext(AuthContext);
	const navigate = useNavigate();
	const [getMenu, setGetMenu] = useState<any>({});
	const [tableDataCount, setTableDataCount] = useState<any>('');

	useEffect(() => {
		if (userAccountId == '') {
			navigate(`../login`);
		} else {
			getAssignedScreenByUser(userAccountId);
		}
	}, []);

	function getAssignedScreenByUser(userAccountId: number) {
		getAssignedScreenByUserAccountId(userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.assignedScreenByUserAccountId;
					if (data != undefined) {
						generateMenu(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function generateMenu(screenData: any) {
		let menu: any = {};
		for (let i = 0; i < screenData.length; i++) {

			let screenGroupMain = screenData[i]
			let submenu: any = {}
			menu[screenGroupMain.screenGroupDisplayName] = {
				id: screenGroupMain.screenGroupDisplayName,
				text: screenGroupMain.screenGroupDisplayName,
				path: screenGroupMain.screenGroupDisplayName,
				icon: screenGroupMain.screenGroupIcon,
			}
			for (let k = 0; k < screenGroupMain.screens.length; k++) {
				let screen = screenGroupMain.screens[k]
				submenu[screen.displayName] = {
					id: screen.displayName,
					text: screen.displayName,
					path: screen.screenPath,
					icon: screen.screenIcon,
				}
			}
			menu[screenGroupMain.screenGroupDisplayName]['subMenu'] = submenu
		}

		setGetMenu(menu)
		localStorage.setItem("screenMenus", menu)
	}

	function checkDataAvailable() {
		checkDataAvailableOrNot(organisationDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.dataAvailableOrNot[0];
					if (data != undefined) {
						setTableDataCount(data.tableDataCount)
						if (data.tableDataCount == false) {
							navigate(`/followingStepsToHave`);
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}


	return (
		<Aside>
			<AsideHead>
				<Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus} />
			</AsideHead>
			<AsideBody>
				{!doc && (
					<>
						<span onClick={() => { checkDataAvailable() }}>
							<Navigation menu={getMenu} id='aside-menu' />
							{/* <NavigationLine /> */}
						</span>
					</>
				)}
			</AsideBody>
			<AsideFoot>
				<User />
			</AsideFoot>
		</Aside>
	);
};

export default DefaultAside;
