import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../../layout/PageWrapper/PageWrapper';
import Card, {
    CardActions,
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../../components/bootstrap/Card';
import Page from '../../../../layout/Page/Page';
import classNames from 'classnames';
import Icon from '../../../../components/icon/Icon';
import dayjs from 'dayjs';
import useDarkMode from '../../../../hooks/useDarkMode';
import { toasts } from '../../../../services/toast.service';
import AuthContext from '../../../../contexts/authContext';
import { getDashboardDataByUserTypeId } from '../../../../services/dashboard.service';
import { TableLoader } from '../../../../services/loader.services';
import { priceFormat } from '../../../../helpers/helpers';
import { getStaffListForPayRun } from '../../../../services/salary.service';
import { getCurrentDateAndTime } from '../../../../services/common.service';

const TotalSalaryDetails = () => {
    const { userAccountId, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        if (userTypeId != '' && userTypeId != '') {
            getDashboardDataByUserTypeIdList(userTypeId, userAccountId);
        }
    }, []);


    const { themeStatus, darkModeStatus } = useDarkMode();
    const [dataStatus, setDataStatus] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [dashboardDataByUserTypeData, setDashboardDataByUserTypeData] = useState<any>([])

	function getDashboardDataByUserTypeIdList(userTypeId: any, userAccountId: any) {
		setDataStatus(false);
		getDashboardDataByUserTypeId(userTypeId, userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.dashboardDataByUserTypeId[0];
					if (data != undefined) {
						setDataStatus(true);
						setDashboardDataByUserTypeData(data);
					}
					else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}


    return (
        <Card>
            <CardHeader>
                <CardLabel icon='ShowChart' iconColor='secondary'>
                    <CardTitle tag='div' className='h5'>
                        Salary Details
                    </CardTitle>
                </CardLabel>
            </CardHeader>
            <CardBody>
                {!dataStatus ?
                    <TableLoader /> :
                    <div className='row g-4 align-items-center'>
                        <div className='col-xl-6'>
                            <div
                                className={classNames(
                                    'd-flex align-items-center rounded-2 p-3',
                                    {
                                        'bg-l10-success': !darkModeStatus,
                                        'bg-lo25-success': darkModeStatus,
                                    },
                                )}>
                                <div className='flex-shrink-0'>
                                    <Icon icon='Checklist' size='3x' color='success' />
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                    <div className='fw-bold fs-5 mb-0'>{priceFormat(dashboardDataByUserTypeData.getCurrentMonthTotalSalary)}</div>
                                    <div className='text-muted mt-n1 truncate-line-1'>
                                        Total Salary
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div
                                className={classNames(
                                    'd-flex align-items-center rounded-2 p-3',
                                    {
                                        'bg-l10-primary': !darkModeStatus,
                                        'bg-lo25-primary': darkModeStatus,
                                    },
                                )}>
                                <div className='flex-shrink-0'>
                                    <Icon
                                        icon='Money'
                                        size='3x'
                                        color='primary'
                                    />
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                    <div className='fw-bold fs-5 mb-0'> {priceFormat(dashboardDataByUserTypeData.getCurrentMonthPaidSalary)}</div>
                                    <div className='text-muted mt-n1 truncate-line-1'>
                                    Total Paid Salary Amount
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-xl-6'>
                            <div
                                className={classNames(
                                    'd-flex align-items-center rounded-2 p-3',
                                    {
                                        'bg-l10-danger': !darkModeStatus,
                                        'bg-lo25-danger': darkModeStatus,
                                    },
                                )}>
                                <div className='flex-shrink-0'>
                                    <Icon icon='Report' size='3x' color='danger' />
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                    <div className='fw-bold fs-5 mb-0'>{priceFormat(dashboardDataByUserTypeData.getCurrentMonthPendingSalary)}</div>
                                    <div className='text-muted mt-n1 truncate-line-1'>
                                        Total Un Paid Amount
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className='col-xl-6'>
                            <div
                                className={classNames(
                                    'd-flex align-items-center rounded-2 p-3',
                                    {
                                        'bg-l10-warning': !darkModeStatus,
                                        'bg-lo25-warning': darkModeStatus,
                                    },
                                )}>
                                <div className='flex-shrink-0'>
                                    <Icon icon='GridView' size='3x' color='info' />
                                </div>
                                <div className='flex-grow-1 ms-3'>
                                    <div className='fw-bold fs-5 mb-0'>{dashboardDataByUserTypeData.getTodayReceivedAmountByOthers != undefined ? priceFormat(dashboardDataByUserTypeData.getTodayReceivedAmountByOthers) : 0}</div>
                                    <div className='text-muted mt-n1'>Others</div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                }
            </CardBody>
        </Card>
    );
};

export default TotalSalaryDetails;
