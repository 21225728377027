import React, { useContext } from 'react';
import Header, { HeaderLeft } from '../../../layout/Header/Header';
import Navigation from '../../../layout/Navigation/Navigation';
import { componentPagesMenu, pageLayoutTypesPagesMenu } from '../../../menu';
import useDeviceScreen from '../../../hooks/useDeviceScreen';
import CommonHeaderRight from './CommonHeaderRight';
import Search from '../../../components/Search';
import AuthContext from '../../../contexts/authContext';
import useDarkMode from '../../../hooks/useDarkMode';
import classNames from 'classnames';

const DefaultHeader = () => {

	const { darkModeStatus } = useDarkMode();
	const { width } = useDeviceScreen();
	const { userTypeId, userAccountId, userData } = useContext(AuthContext);
	return (
		<Header>
			<HeaderLeft>
				{/* <Navigation
					menu={{ ...pageLayoutTypesPagesMenu, ...componentPagesMenu }}
					id='header-top-menu'
					horizontal={
						!!width && width >= Number(process.env.REACT_APP_MOBILE_BREAKPOINT_SIZE)
					}
				/> */}
				{userTypeId != 10 ? <Search /> :
					<div className='d-flex align-items-center'>
						<div className='row g-4'>
							<div className='col-md-auto'>
								<div
									className={classNames('fs-3', 'fw-bold', {
										'text-dark': !darkModeStatus,
									})}>
									Hi, {userData.employeeName}!
								</div>
							</div>
						</div>
					</div>
				}
			</HeaderLeft>
			<CommonHeaderRight />
		</Header>
	);
};

export default DefaultHeader;
