import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Icon from '../icon/Icon';
import Card, { CardBody, CardFooter, CardFooterLeft, CardFooterRight, CardHeader, CardLabel, CardTitle, } from '../bootstrap/Card';
import Button, { ButtonGroup } from '../bootstrap/Button';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Alert, { AlertHeading } from '../bootstrap/Alert';
import { useFormik } from 'formik';
import { getPfCalculation, updatePfCalculation } from '../../services/setting.services';
import { toasts } from '../../services/toast.service';
import { getLicenseKey } from '../../services/application.settings';
import AuthContext from '../../contexts/authContext';
import { showLoader } from '../../services/loader.services';
import AlertService from '../../services/AlertService';

const EmployeesProvidentFund = () => {

  useEffect(() => {
    getPfCalculationDetails()
  }, []);

  const { userAccountId, userTypeId } = useContext(AuthContext);

  const [dataStatus, setDataStatus] = useState(false)
  const [pfCalculationData, setPfCalculationData] = useState<any>([]);
  const [noDataMsg, setNoDataMsg] = useState('')

  // For Alert
  const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
  const [isOpen, setIsOpen] = useState(false);

  const employeesProvidentFundForm = useFormik({
    initialValues: {
      deductionCycle: 'Monthly',
      employeeContribution: '',
      employerContribution: '',
      adminCharges: '',
      pfCalculationId: '',
    },

    validate: (values) => {
      const errors: {
        employeeContribution?: string;
        employerContribution?: string;
        adminCharges?: string;

      } = {};

      if (!values.adminCharges) {
        errors.adminCharges = 'Required';
      }
      if (!values.employeeContribution) {
        errors.employeeContribution = 'Required';
      }
      if (!values.employerContribution) {
        errors.employerContribution = 'Required';
      }
      return errors;
    },

    onSubmit: () => { updatePfCalculationSubmit() },

  });

  function getPfCalculationDetails() {
    setDataStatus(false)
    getPfCalculation(
      (response) => {
        if (response.data.success) {
          let data = response.data.data.pfCalculation;
          if (data != undefined) {
            setDataStatus(true)
            setPfCalculationData(data);
            employeesProvidentFundForm.setValues({
              pfCalculationId: data[0].pfCalculationId,
              deductionCycle: data[0].deductionCycle,
              employeeContribution: data[0].employeeContribution,
              employerContribution: data[0].employerContribution,
              adminCharges: data[0].adminCharges,

            });
          } else {
            setDataStatus(true)
            toasts("Undefined Data", "Error")
          }
        }
        else if (response.data.success === false) {
          setPfCalculationData([]);
          setNoDataMsg(response.data.message)
        } else {
          let errorCode = response.data.error[0].error_code;
          let errorDescription = response.data.error[0].error_description;
          setDataStatus(true)
          toasts(errorDescription, "Error")
        }

      }, error => {
        setDataStatus(true)
        toasts(error, "Error")
      }
    )
  }

  function setUpdatePfCalculationDetails() {

    return ({
      pfCalculationId: employeesProvidentFundForm.values.pfCalculationId,
      deductionCycle: employeesProvidentFundForm.values.deductionCycle,
      employeeContribution: employeesProvidentFundForm.values.employeeContribution,
      employerContribution: employeesProvidentFundForm.values.employerContribution,
      adminCharges: employeesProvidentFundForm.values.adminCharges,
      licenseKey: getLicenseKey,
      userAccountId: userAccountId
    })
  }

  function updatePfCalculationSubmit() {
    showLoader(true);
    if (employeesProvidentFundForm.isValid) {
      let pfCalculationPostData = setUpdatePfCalculationDetails()
      updatePfCalculation(pfCalculationPostData,
        (response) => {
          const data = response.data;
          if (data.success == true) {
            showLoader(false);
            setAlertStatus({ message: data.message, type: "success" });
            setIsOpen(true);
            getPfCalculationDetails()
          }
          else if (data.success == false) {
            showLoader(false);
            setAlertStatus({ message: data.message, type: "warning" });
            setIsOpen(true);
          }
          else {
            showLoader(false);
            let errorCode = response.data.error[0].error_code;
            let errorDescription = response.data.error[0].error_description;
            setAlertStatus({ message: errorDescription, type: "error" });
            setIsOpen(true);
          }
        }
        , (error) => {
          setAlertStatus({ message: error, type: "error" });
          setIsOpen(true);
        }
      )

    } else if (employeesProvidentFundForm.isValid == false) {
      showLoader(false);
      setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
      setIsOpen(true);
    }

  }

  return (
    <PageWrapper title='Provident Fund'>
      <Page container='fluid'>
        <form noValidate onSubmit={employeesProvidentFundForm.handleSubmit}>
          <div className='row px-4'>
            <div className='col-xl-5'>
              <Card stretch>
                <CardHeader borderSize={1}>
                  <CardLabel icon='list' iconColor='primary'>
                    <CardTitle>
                      Employee's Provident Fund
                    </CardTitle>
                  </CardLabel>
                </CardHeader>
                <CardBody>
                  <div className='row g-4'>
                    <div className='col-12'>
                      <FormGroup id='deductionCycle' label='Deduction Cycle' isFloating>
                        <Input
                          disabled
                          onChange={employeesProvidentFundForm.handleChange}
                          onBlur={employeesProvidentFundForm.handleBlur}
                          value={employeesProvidentFundForm.values.deductionCycle}
                          isValid={employeesProvidentFundForm.isValid}
                          isTouched={employeesProvidentFundForm.touched.deductionCycle}
                          invalidFeedback={employeesProvidentFundForm.errors.deductionCycle}
                        />
                      </FormGroup>
                    </div>
                    <div className='col-12'>
                      <FormGroup id='employeeContribution' label='Employee Contribution' isFloating>
                        <Input
                          placeholder='0'
                          onChange={employeesProvidentFundForm.handleChange}
                          onBlur={employeesProvidentFundForm.handleBlur}
                          value={employeesProvidentFundForm.values.employeeContribution}
                          isValid={employeesProvidentFundForm.isValid}
                          isTouched={employeesProvidentFundForm.touched.employeeContribution}
                          invalidFeedback={employeesProvidentFundForm.errors.employeeContribution}
                        />
                      </FormGroup>
                    </div>
                    <div className='col-12'>
                      <FormGroup id='employerContribution' label='Employer Contribution' isFloating>
                        <Input
                          placeholder='0'
                          onChange={employeesProvidentFundForm.handleChange}
                          onBlur={employeesProvidentFundForm.handleBlur}
                          value={employeesProvidentFundForm.values.employerContribution}
                          isValid={employeesProvidentFundForm.isValid}
                          isTouched={employeesProvidentFundForm.touched.employerContribution}
                          invalidFeedback={employeesProvidentFundForm.errors.employerContribution}
                        />
                      </FormGroup>
                    </div>
                    <div className='col-12'>
                      <FormGroup id='adminCharges' label='Admin Charges' isFloating>
                        <Input
                          placeholder='0'
                          onChange={employeesProvidentFundForm.handleChange}
                          onBlur={employeesProvidentFundForm.handleBlur}
                          value={employeesProvidentFundForm.values.adminCharges}
                          isValid={employeesProvidentFundForm.isValid}
                          isTouched={employeesProvidentFundForm.touched.adminCharges}
                          invalidFeedback={employeesProvidentFundForm.errors.adminCharges}
                        />
                      </FormGroup>
                    </div>
                  </div>
                </CardBody>
                <CardFooter>
                  <CardFooterRight>
                    <Button icon="Save" color="info" type="submit" isDisable={!employeesProvidentFundForm.isValid && !!employeesProvidentFundForm.submitCount}>
                      Save
                    </Button>
                  </CardFooterRight>
                </CardFooter>
              </Card>
            </div>
            <div className='col-xl-7'>
              <Card stretch>
                <CardBody>
                  <div className="row">
                    <div className="col-lg-12">
                      <Alert color='primary' isLight>
                        <div className='row'>
                          <ButtonGroup>
                            <AlertHeading className='col-lg-12 fs-5 mt-2'>
                              <span className='text-primary fw-bold'>Sample EPF Calculation</span><br />
                              <div className='text-muted fs-6 mt-2'> Let's assume the PF wage is ₹ 15,000. The breakup of contribution will be :</div>
                            </AlertHeading>
                          </ButtonGroup>
                        </div>
                        <div className='col-12'>
                          <Card stretch className='mt-2'>
                            <CardBody>
                              <div className='row g-1'>
                                <div className='col-md-12'>
                                  <div className='h6 fw-bold'>
                                    Employee's Contribution
                                  </div>
                                </div>
                                <br />
                                <div className='col-md-10'>
                                  <p className='text-muted h6'>
                                    (<span className='text-primary fw-bold'>{employeesProvidentFundForm.values.employeeContribution == "" ? 0 : employeesProvidentFundForm.values.employeeContribution}</span> % of 15,000)
                                  </p>
                                </div>

                                <div className='col-md-2'>
                                  <p className='text-muted text-danger h6'>₹ <span className='text-danger'>{employeesProvidentFundForm.values.employeeContribution != "" ? ((Number(employeesProvidentFundForm.values.employeeContribution) * Number(15000)) / 100) : 0}</span></p>
                                </div>
                                <hr />
                                <div className='col-md-12'>
                                  <div className='h6 fw-bold'>
                                    Employer Contribution
                                  </div>
                                </div>
                                <div className='col-md-10'>
                                  <p className='text-muted h6'>
                                    (<span className='text-primary fw-bold'>{employeesProvidentFundForm.values.employerContribution == "" ? 0 : employeesProvidentFundForm.values.employerContribution}</span> % of 15,000 (Max of ₹ 15,000))	</p>
                                </div>

                                <div className='col-md-2'>
                                  <p className='text-muted text-danger h6'>₹ <span className='text-danger'>{employeesProvidentFundForm.values.employerContribution != "" ? ((Number(employeesProvidentFundForm.values.employerContribution) * Number(15000)) / 100) : 0}</span></p>
                                </div>
                                <hr />
                                <div className='col-md-12'>
                                  <div className='h6 fw-bold'>
                                    Admin Charges
                                  </div>
                                </div>
                                <div className='col-md-10'>
                                  <p className='text-muted h6'>(<span className='text-primary fw-bold'>{employeesProvidentFundForm.values.adminCharges == "" ? 0 : employeesProvidentFundForm.values.adminCharges}</span> % of 15,000)</p>
                                </div>
                                <div className='col-md-2'>
                                  <p className='h6'>₹ <span className='text-danger'>{employeesProvidentFundForm.values.adminCharges != "" ? ((Number(employeesProvidentFundForm.values.adminCharges) * Number(15000)) / 100) : 0}</span></p>
                                </div>
                                <hr />
                                <div className='col-md-10'>
                                  <p className='h6 fw-bold'>Total</p>
                                </div>
                                <div className='col-md-2'>
                                  <p className='h6 fw-bold'>₹ <span className='text-success'>{employeesProvidentFundForm.values.employeeContribution != "" ? ((Number(employeesProvidentFundForm.values.employeeContribution) + Number(employeesProvidentFundForm.values.employerContribution) + Number(employeesProvidentFundForm.values.adminCharges)) * Number(15000) / 100) : 0}</span></p>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        </div>
                        <div className='col-12 mt-1'>
                          <Icon icon='EmojiObjects' size={'lg'} /> Do you want to preview EPF calculation for multiple cases, based on the preferences you have configured ?
                        </div>
                      </Alert>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        </form>

      </Page>

      <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

    </PageWrapper>
  );
};
export default EmployeesProvidentFund;