import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Icon from '../../icon/Icon';
import Card, {
	CardActions,
	CardBody,
	CardFooter,
	CardHeader,
	CardLabel,
	CardSubTitle,
	CardTitle,
} from '../../bootstrap/Card';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Input from '../../bootstrap/forms/Input';
import Checks from '../../bootstrap/forms/Checks';
import Select from '../../bootstrap/forms/Select';
import Alert, { AlertHeading } from '../../bootstrap/Alert';
import Badge from '../../bootstrap/Badge';
import classNames from 'classnames';
import OffCanvas, {
	OffCanvasBody,
	OffCanvasHeader,
	OffCanvasTitle,
} from '../../bootstrap/OffCanvas';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import AlertService from '../../../services/AlertService';
import { showLoader } from '../../../services/loader.services';
import {
	getDateFormats,
	getIndustry,
	getOrganisationDetailsByOrganisationDetailsId,
	getWorkLocationsList,
	getWorkLocationsListByWorkLocationId,
	updateOrganisationDetails,
} from '../../../services/setting.services';
import {
	getStates,
	getStatesAndCity,
	onlyAllowNumber,
	pictNotLoading,
	profilePic,
	uploadLogo,
} from '../../../services/common.service';
import Modal, { ModalBody } from '../../bootstrap/Modal';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import { toasts } from '../../../services/toast.service';
import SearchableSelect from '../../../common/components/SearchableSelect';
import useDarkMode from '../../../hooks/useDarkMode';

const UpdateOrganisation = () => {

	const { userAccountId, organisationDetailsId, userTypeId } = useContext(AuthContext);

	//TABS
	const ProfileTabs = {
		organizationProfile: 'Profile',
		organisationAddress: 'Address',
		contact: 'Settings',
		fillingAddress: 'Filling Address',
	};

	useEffect(() => {

		getOrganisation(organisationDetailsId);
		getStatesList();
		getIndustryList();
		getDateFormatsList();
		getWorkLocations();

	}, []);

	const navigate = useNavigate();

	const [profileActiveTab, setProfileActiveTab] = useState(ProfileTabs.organizationProfile);
	const [emailSentInfoOffCanvas, setEmailSentInfoOffCanvas] = useState(false);

	const [industryData, setIndustryData] = useState([]);
	const [industryId, setIndustryId] = useState<any>([]);
	const [organisationData, setOrganisationData] = useState<any>([]);
	const [dateFormatData, setDateFormatData] = useState([]);
	const [dateFormateId, setDateFormateId] = useState<any>([]);
	const [statesData, setStatesData] = useState([]);
	const [cityData, setCityData] = useState([]);
	const [cityId, setCityId] = useState<any>(Number);
	const [stateId, setStateId] = useState<any>(Number);
	const [workLocationData, setWorkLocationData] = useState([]);
	const [workLocationsId, setWorkLocationsId] = useState<any>([]);
	const [workLocationDataByWorkLocation, setWorkLocationDataByWorkLocation] = useState<any>([]);

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [isNavigate, setIsNavigate] = useState<any>(null);

	const [isDeleteOpen, setIsDeleteOpen] = useState(false);
	const [selectedFile, setSelectedFile] = useState<any>('');
	const [flexRadio, setFlexRadio] = useState('');
	const { darkModeStatus } = useDarkMode();

	const flexChecks = useFormik({
		initialValues: {
			defaultCheck: false,
			checkedCheck: true,
		},
		onSubmit: () => { },
	});

	const organisationDetailsForm = useFormik({
		initialValues: {
			organisationDetailsId: '',
			organisationId: '',
			organisationName: '',
			businessLocation: '',
			industryId: '',
			dateFormat: '',
			fieldSeparator: '',
			dateFormateId: '',
			address1: '',
			address2: '',
			cityId: '',
			stateId: '',
			pincode: '',
			filingAddress: '',
			workLocationsId: '',
		},
		validate: (values) => {
			const errors: {
				organisationDetailsId?: string;
				organisationName?: string;
				businessLocation?: string;
				industryId?: string;
				fieldSeparator?: string;
				dateFormateId?: string;
				address1?: string;
				address2?: string;
				cityId?: string;
				stateId?: string;
				pincode?: string;
				filingAddress?: string;
				workLocationsId?: string;
			} = {};

			if (!values.organisationName) {
				errors.organisationName = 'Required';
			}
			if (!values.businessLocation) {
				errors.businessLocation = 'Required';
			}
			if (!industryId) {
				errors.industryId = 'Required';
			}
			if (!values.fieldSeparator) {
				errors.fieldSeparator = 'Required';
			}
			if (!dateFormateId) {
				errors.dateFormateId = 'Required';
			}
			if (!values.address1) {
				errors.address1 = 'Required';
			}
			if (!values.address2) {
				errors.address2 = 'Required';
			}
			if (!cityId) {
				errors.cityId = 'Required';
			}
			if (!stateId) {
				errors.stateId = 'Required';
			}
			if (!workLocationsId) {
				errors.workLocationsId = 'Required';
			}
			if (!values.pincode) {
				errors.pincode = 'Required';
			} else if (!/^\d{6}$/.test(values.pincode)) {
				errors.pincode = 'Must be 6 Numbers';
			}
			return errors;
		},
		onSubmit: () => {
			updateOrganisationSubmit();
		},
	});

	const handleFileChange = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setSelectedFile(e.target.files[0]);
		}
	};

	function onUpload(organisationDetailsId: any) {
		showLoader(true);
		const fileData = new FormData();
		fileData.append('logoPic', selectedFile, selectedFile.name);
		fileData.append('licenseKey', getLicenseKey.toString());
		fileData.append('organisationId', organisationDetailsId.toString());
		fileData.append('userAccountId', userAccountId);
		fileData.append('userTypeId', userTypeId);
		uploadLogo(
			fileData,
			(response) => {
				showLoader(false);
				setAlertStatus({ message: response.data.message, type: 'success' });
				setIsOpen(true);
				getOrganisation(organisationDetailsId);
				window.location.reload();
			},
			(error) => {
				showLoader(false);
				setAlertStatus({ message: 'Profile pic not upload', type: 'error' });
				setIsOpen(true);
			},
		);
	}

	function getOrganisation(organisationDetailsId: any) {
		getOrganisationDetailsByOrganisationDetailsId(
			organisationDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.organisationDetails[0];
					if (data != undefined) {
						setOrganisationData(data);
						if (data.stateId != null) {
							getCityList(data.stateId);
						}
						setStateId(
							data.stateId != null
								? { value: data.stateId, label: data.stateName }
								: '',
						);
						setCityId(
							data.cityId != null
								? { value: data.cityId, label: data.cityName }
								: '',
						);
						setDateFormateId(
							data.dateFormatId != null
								? { value: data.dateFormatId, label: data.dateFormatForView }
								: '',
						);
						setIndustryId(
							data.industryId != null
								? { value: data.industryId, label: data.industryName }
								: '',
						);
						setWorkLocationsId(
							data.workLocationsId != null
								? { value: data.workLocationsId, label: data.locationName }
								: '',
						);
						getWorkLocationsListByWorkLocation(
							data.organisationDetailsId,
							data.workLocationsId,
						);
						organisationDetailsForm.setValues({
							organisationDetailsId: data.organisationDetailsId,
							organisationName: data.organisationName,
							businessLocation: data.businessLocation,
							organisationId: data.organisationId,
							industryId: data.industryId,
							fieldSeparator: data.fieldSeparator,
							dateFormateId: data.dateFormatId,
							address1: data.address1,
							address2: data.address2,
							cityId: data.cityId,
							stateId: data.stateId,
							pincode: data.pincode,
							filingAddress: data.filingAddress,
							dateFormat: data.dateFormat,
							workLocationsId: data.workLocationsId,
						});
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'warning');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function organisationDetails() {
		return {
			organisationDetailsId: organisationDetailsForm.values.organisationDetailsId,
			organisationId: organisationDetailsForm.values.organisationId,
			workLocationsId: workLocationsId?.value,
			organisationName: organisationDetailsForm.values.organisationName,
			businessLocation: organisationDetailsForm.values.businessLocation,
			industryId: industryId?.value,
			dateFormatId: dateFormateId?.value,
			address1: organisationDetailsForm.values.address1,
			address2: organisationDetailsForm.values.address2,
			cityId: cityId?.value,
			stateId: stateId?.value,
			pincode: organisationDetailsForm.values.pincode,
			fieldSeparator: organisationDetailsForm.values.fieldSeparator,
			updatedBy: userAccountId,
			licenseKey: getLicenseKey,
		};
	}

	function updateOrganisationSubmit() {
		showLoader(true);
		if (organisationDetailsForm.isValid) {
			let organisationPostData = organisationDetails();
			updateOrganisationDetails(
				organisationPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'success' });
						setIsOpen(true);
						getOrganisation(organisationDetailsId);
					} else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: 'warning' });
						setIsOpen(true);
					} else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: 'error' });
						setIsOpen(true);
					}
				},
				(error) => {
					setAlertStatus({ message: error, type: 'error' });
					setIsOpen(true);
				},
			);
		} else if (organisationDetailsForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'error' });
			setIsOpen(true);
		}
	}

	const selectWorkLocation = (e: any) => {
		let workLocationsId = e;
		setWorkLocationsId(workLocationsId);
		if (workLocationsId?.value != undefined) {
			getWorkLocationsListByWorkLocation(organisationDetailsId, workLocationsId?.value);
		}
	};

	function getWorkLocations() {
		getWorkLocationsList(
			organisationDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.workLocationsList;
					if (data != undefined) {
						setWorkLocationData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setWorkLocationData([]);
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getWorkLocationsListByWorkLocation(organisationDetailsId: any, workLocationId: any) {
		getWorkLocationsListByWorkLocationId(
			organisationDetailsId,
			workLocationId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.workLocationsList;
					if (data != undefined) {
						setWorkLocationDataByWorkLocation(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getIndustryList() {
		getIndustry(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.industry;
					if (data != undefined) {
						setIndustryData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setIndustryData([]);
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getDateFormatsList() {
		getDateFormats(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.dateFormats;
					if (data != undefined) {
						setDateFormatData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const selectState = (e: any) => {
		let stateId = e;
		setStateId(stateId);
		if (stateId?.value != undefined) {
			getCityList(stateId?.value);
		}
	};

	function getStatesList() {
		getStates(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.statesAndCity;
					if (data != undefined) {
						setStatesData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const selectCity = (e: any) => {
		let cityId = e;
		setCityId(cityId);
	};

	function getCityList(stateId: number) {
		getStatesAndCity(
			stateId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.statesAndCity[0]['city'];
					if (data != undefined) {
						setCityData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'warning');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	return (

		<PageWrapper title='Organisation Details'>
			<Page>
				<div className='row h-100 pb-3'>
					<div className='col-lg-3 col-md-6'>
						<Card stretch>
							<CardHeader borderSize={1}>
								<CardLabel icon='AccountBalance' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Update Organisation
									</CardTitle>
									<CardSubTitle tag='div' className='h6'>
										Information
									</CardSubTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row g-3'>
									<div className='col-12'>
										<Button
											icon='Contacts'
											color='info'
											className='w-100 p-3'
											isLight={
												ProfileTabs.organizationProfile !== profileActiveTab
											}
											onClick={() =>
												setProfileActiveTab(ProfileTabs.organizationProfile)
											}>
											{ProfileTabs.organizationProfile}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Home'
											color='info'
											className='w-100 p-3'
											isLight={
												ProfileTabs.organisationAddress !== profileActiveTab
											}
											onClick={() =>
												setProfileActiveTab(ProfileTabs.organisationAddress)
											}>
											{ProfileTabs.organisationAddress}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='PostAdd'
											color='info'
											className='w-100 p-3'
											isLight={
												ProfileTabs.fillingAddress !== profileActiveTab
											}
											onClick={() =>
												setProfileActiveTab(ProfileTabs.fillingAddress)
											}>
											{ProfileTabs.fillingAddress}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Settings'
											color='info'
											className='w-100 p-3'
											isLight={ProfileTabs.contact !== profileActiveTab}
											onClick={() =>
												setProfileActiveTab(ProfileTabs.contact)
											}>
											{ProfileTabs.contact}
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-xl-9 col-lg-8 col-md-6'>
						{ProfileTabs.organizationProfile === profileActiveTab && (
							<Card
								stretch
								tag='form'
								noValidate
								onSubmit={organisationDetailsForm.handleSubmit}>
								<CardHeader>
									<CardLabel icon='Article' iconColor='info'>
										<CardTitle tag='div' className='h5'>
											Basic Info
										</CardTitle>
									</CardLabel>
									<CardActions>
										<CardTitle tag='div' className='h5 mt-2'>
											Organisation Id -{' '}
											<span className='border border-success border-2 text-success  px-3 py-1 rounded'>
												{organisationData.organisationId}
											</span>
										</CardTitle>
									</CardActions>
								</CardHeader>
								<CardBody>
									<div className='col-12 mt-2'>
										<div className='row g-4 align-items-center'>
											<div className='col-lg-2'>
												{selectedFile ? (
													<img
														style={{
															borderRadius: '50%',
														}}
														src={URL.createObjectURL(
															selectedFile,
														)}
														height='120'
														width='120'
													/>
												) : (
													<img
														style={{ borderRadius: '50%' }}
														src={
															organisationData.logoPath != null
																? organisationData.logoPath
																: profilePic(1)
														}
														height='130'
														width='130'
														onError={(e: any) => pictNotLoading(e, 1)}
													/>
												)}
											</div>
											<div className='col-lg-9'>
												<div className='row g-4'>
													<div className='col-12'>
														<p className='lead text-muted fs-6'>
															This logo will be displayed on documents
															such as Payslip and TDS Worksheet.
														</p>
														<div className='text-muted'>
															Preferred Image Size: 240 x 240 pixels @
															72 DPI, Maximum size of 1MB.
														</div>
													</div>
													<div className='col-auto'>
														<Input
															type='file'
															autoComplete='photo'
															ariaLabel='Upload image file'
															onChange={handleFileChange}
														/>
													</div>
													<div className='col-3'>
														<Button
															color='info'
															icon='Upload'
															isDisable={
																selectedFile != '' ? false : true
															}
															onClick={() =>
																onUpload(organisationDetailsId)
															}>
															{' '}
															Upload
														</Button>
													</div>
												</div>
											</div>
										</div>
									</div>
									<div className='row g-3 mt-2 '>
										<div className='lead text-muted fs-6 ms-2'>
											This is your registered business name which will appear
											in all the forms and payslips.
										</div>
										<div className='col-md-12'>
											<FormGroup
												id='organisationName'
												isFloating
												label='Organisation Name'>
												<Input
													aria-placeholder='Organisation Name'
													autoComplete='-name'
													onChange={organisationDetailsForm.handleChange}
													onBlur={organisationDetailsForm.handleBlur}
													value={
														organisationDetailsForm.values
															.organisationName
													}
													isValid={organisationDetailsForm.isValid}
													isTouched={
														organisationDetailsForm.touched
															.organisationName
													}
													invalidFeedback={
														organisationDetailsForm.errors
															.organisationName
													}
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup
												id='businessLocation'
												label='Business Location'
												isFloating>
												<Input
													aria-placeholder='Business Location'
													autoComplete='-name'
													onChange={organisationDetailsForm.handleChange}
													onBlur={organisationDetailsForm.handleBlur}
													value={
														organisationDetailsForm.values
															.businessLocation
													}
													isValid={organisationDetailsForm.isValid}
													isTouched={
														organisationDetailsForm.touched
															.businessLocation
													}
													invalidFeedback={
														organisationDetailsForm.errors
															.businessLocation
													}
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup id='industryId' label='Industry' isFloating>
												<SearchableSelect
													isFloating
													placeholder='Industry'
													ariaLabel='Industry'
													list={industryData.map((data: any) => ({
														value: data.industryId,
														label: data.industryName,
													}))}
													onChange={(e: any) => setIndustryId(e)}
													value={industryId}
													onBlur={organisationDetailsForm.handleBlur}
													isValid={organisationDetailsForm.isValid}
													isTouched={
														organisationDetailsForm.touched.industryId
													}
													invalidFeedback={
														organisationDetailsForm.errors.industryId
													}
												/>
											</FormGroup>
										</div>
									</div>
									<div className='col-lg-12 mt-3'>
										<FormGroup className='d-flex align-items-center justify-content-end'>
											<Button
												type='submit'
												icon='Save'
												color='info'
												isOutline>
												Save
											</Button>
										</FormGroup>
									</div>
								</CardBody>
							</Card>
						)}

						{ProfileTabs.organisationAddress === profileActiveTab && (
							<Card
								stretch
								tag='form'
								noValidate
								onSubmit={organisationDetailsForm.handleSubmit}>
								<CardHeader>
									<CardLabel icon='Home' iconColor='info'>
										<CardTitle tag='div' className='h5'>
											Address Info
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row g-4'>
										<div className='col-12'>
											<FormGroup id='address1' label='Address1' isFloating>
												<Input
													placeholder='Address1'
													onChange={organisationDetailsForm.handleChange}
													onBlur={organisationDetailsForm.handleBlur}
													value={organisationDetailsForm.values.address1}
													isValid={organisationDetailsForm.isValid}
													isTouched={
														organisationDetailsForm.touched.address1
													}
													invalidFeedback={
														organisationDetailsForm.errors.address1
													}
												/>
											</FormGroup>
										</div>
										<div className='col-12'>
											<FormGroup id='address2' label='Address2' isFloating>
												<Input
													placeholder='Address2'
													onChange={organisationDetailsForm.handleChange}
													onBlur={organisationDetailsForm.handleBlur}
													value={organisationDetailsForm.values.address2}
													isValid={organisationDetailsForm.isValid}
													isTouched={
														organisationDetailsForm.touched.address2
													}
													invalidFeedback={
														organisationDetailsForm.errors.address2
													}
												/>
											</FormGroup>
										</div>
										<div className='col-4'>
											<FormGroup id='stateId' label='State' isFloating>
												<SearchableSelect
													isFloating
													placeholder='State'
													ariaLabel='State'
													onChange={selectState}
													value={stateId}
													list={statesData.map((data: any) => ({
														value: data.stateId,
														label: data.stateName,
													}))}
													onBlur={organisationDetailsForm.handleBlur}
													isValid={organisationDetailsForm.isValid}
													isTouched={
														organisationDetailsForm.touched.stateId
													}
													invalidFeedback={
														organisationDetailsForm.errors.stateId
													}
												/>
											</FormGroup>
										</div>
										<div className='col-4'>
											<FormGroup id='cityId' label='City' isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='City'
													placeholder='City'
													onChange={selectCity}
													value={cityId}
													list={cityData.map((data: any) => ({
														value: data.cityId,
														label: data.cityName,
													}))}
													onBlur={organisationDetailsForm.handleBlur}
													isValid={organisationDetailsForm.isValid}
													isTouched={
														organisationDetailsForm.touched.cityId
													}
													invalidFeedback={
														organisationDetailsForm.errors.cityId
													}
												/>
											</FormGroup>
										</div>
										<div className='col-4'>
											<FormGroup id='pincode' label='PIN Code' isFloating>
												<Input
													placeholder='PIN Code'
													onChange={organisationDetailsForm.handleChange}
													onBlur={organisationDetailsForm.handleBlur}
													value={organisationDetailsForm.values.pincode}
													isValid={organisationDetailsForm.isValid}
													isTouched={
														organisationDetailsForm.touched.pincode
													}
													invalidFeedback={
														organisationDetailsForm.errors.pincode
													}
													onKeyDown={(e: any) => onlyAllowNumber(e)}
												/>
											</FormGroup>
										</div>
										{/* <div className='col-12 mt-3'>
											<Alert color='warning' isLight>
												<div className='row'>
													<ButtonGroup>
														<Checks
															id='flexCheckDefault'
															name='defaultCheck'
															onChange={flexChecks.handleChange}
															checked={flexChecks.values.defaultCheck}
														/>
														<AlertHeading className='col-lg-12 fs-6 '>
															<p className='mt-1'>
																Update the address in all previous
																Transactions.
															</p>
															<p className='text-muted fs-6 mt-1'>
																{' '}
																This option would update the new
																address in all previous
																transactions.
															</p>
															<p className='text-muted mt-1'>
																This will be considered as the
																address of your primary work
																location.
															</p>
														</AlertHeading>
													</ButtonGroup>
												</div>
											</Alert>
										</div> */}
									</div>
									<div className='col-lg-12 mt-4'>
										<FormGroup className='d-flex align-items-center justify-content-end'>
											<Button
												type='submit'
												icon='Save'
												color='info'
												isOutline>
												Save
											</Button>
										</FormGroup>
									</div>
								</CardBody>
							</Card>
						)}

						{ProfileTabs.fillingAddress === profileActiveTab && (
							<Card
								stretch
								tag='form'
								noValidate
								onSubmit={organisationDetailsForm.handleSubmit}>
								<CardHeader>
									<CardLabel icon='PostAdd' iconColor='info'>
										<CardTitle tag='div' className='h5'>
											Filling Address Info
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row'>
										<div className='col-8'>
											<FormGroup
												id='workLocationsId'
												label='Filling Address'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel=''
													placeholder='Filling Address*'
													value={workLocationsId}
													onChange={selectWorkLocation}
													list={workLocationData.map((data: any) => ({
														value: data.workLocationsId,
														label: data.locationName,
													}))}
													onBlur={organisationDetailsForm.handleBlur}
													isValid={organisationDetailsForm.isValid}
													isTouched={
														organisationDetailsForm.touched
															.workLocationsId
													}
													invalidFeedback={
														organisationDetailsForm.errors
															.workLocationsId
													}
												/>
											</FormGroup>
										</div>

										<div className='col-8 mt-3'>
											{workLocationDataByWorkLocation.map((data: any) => (
												<div key={data.workLocationsId}>
													<Card
														className={`bg-l${darkModeStatus ? 'o25' : '25'
															}-primary bg-l${darkModeStatus ? 'o50' : '10'
															}-primary-hover transition-base rounded-2 mb-4`}
														shadow='sm'>
														<CardHeader className='bg-transparent'>
															<CardLabel>
																<CardTitle tag='div' className='h5'>
																	{data.locationName}
																</CardTitle>
															</CardLabel>
														</CardHeader>
														<CardBody>
															<div className='d-flex align-items-center pb-3'>
																<div className='flex-shrink-0'>
																	<Icon
																		icon='House'
																		size='4x'
																		color='primary'
																	/>
																</div>
																<div className='flex-grow-1 ms-3'>
																	<div className='fw-bold mb-0 col-8'>
																		{data.workLocationsName}
																	</div>
																</div>
															</div>
														</CardBody>
													</Card>
												</div>
											))}
										</div>
									</div>
									<div className='col-12'>
										<p className='text-muted'>
											<Icon
												icon='PushPin'
												size='lg'
												color='danger'
											/>
											{''} If you would like to add another work
											location
											as filing address, please {''}
											<Icon
												icon='East'
												color='danger'
												className='mx-1'
												size='lg'
											/><Button
												color='link'
												onClick={() =>
													navigate(
														`../organisation/workLocations`,
													)
												}>Click Here.
											</Button>
										</p>
									</div>
									<div className='col-lg-12'>
										<FormGroup className='d-flex align-items-center justify-content-end'>
											<Button
												type='submit'
												icon='Save'
												color='info'
												isOutline>
												Save
											</Button>
										</FormGroup>
									</div>
								</CardBody>
							</Card>
						)}

						{ProfileTabs.contact === profileActiveTab && (
							<Card
								stretch
								tag='form'
								noValidate
								onSubmit={organisationDetailsForm.handleSubmit}>
								<CardHeader>
									<CardLabel icon='Article' iconColor='info'>
										<CardTitle tag='div' className='h5'>
											Settings Info
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row g-3 mt-2 '>
										<div className='col-md-6'>
											<FormGroup
												id='Date Format'
												label='Date Format'
												isFloating>
												<SearchableSelect
													isFloating
													ariaLabel='Choose...'
													placeholder='Date Format'
													list={dateFormatData.map((data: any) => ({
														value: data.dateFormatsId,
														label: data.dateFormatForView,
													}))}
													onChange={(e: any) => setDateFormateId(e)}
													value={dateFormateId}
													onBlur={organisationDetailsForm.handleBlur}
													isValid={organisationDetailsForm.isValid}
													isTouched={
														organisationDetailsForm.touched
															.dateFormateId
													}
													invalidFeedback={
														organisationDetailsForm.errors.dateFormateId
													}
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup
												id='fieldSeparator'
												label='Field Separator'
												isFloating>
												<Select
													ariaLabel='fieldSeparator'
													placeholder='Field Separator'
													list={[
														{ value: '.', text: '.' },
														{ value: '/', text: '/' },
														{ value: '-', text: '-' },
													]}
													onChange={organisationDetailsForm.handleChange}
													onBlur={organisationDetailsForm.handleBlur}
													value={
														organisationDetailsForm.values
															.fieldSeparator
													}
													isValid={organisationDetailsForm.isValid}
													isTouched={
														organisationDetailsForm.touched
															.fieldSeparator
													}
													invalidFeedback={
														organisationDetailsForm.errors
															.fieldSeparator
													}
												/>
											</FormGroup>
										</div>
									</div>
									<div className='col-lg-12 mt-3'>
										<FormGroup className='d-flex align-items-center justify-content-end'>
											<Button
												type='submit'
												icon='Save'
												color='info'
												isOutline>
												Save
											</Button>
										</FormGroup>
									</div>
								</CardBody>
							</Card>
						)}
					</div>
				</div>

				<OffCanvas
					setOpen={setEmailSentInfoOffCanvas}
					isOpen={emailSentInfoOffCanvas}
					titleId='emailSentInfoOffCanvas'
					isBodyScroll
					placement='end'
					isBackdrop>
					<OffCanvasHeader setOpen={setEmailSentInfoOffCanvas} onClick={close}>
						<OffCanvasTitle id='emailSentInfoOffCanvas'>
							Emails are Sent Through
						</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className='row'>
							<Card
								borderColor={flexRadio == 'first' ? 'primary' : null}
								borderSize={flexRadio == 'first' ? 1 : 0}>
								<CardBody>
									<div className='col-lg-12'>
										<ButtonGroup>
											<Checks
												isTouched
												type='radio'
												name='flexRadioDefault'
												id='flexRadioDefault1'
												value='first'
												onChange={() => setFlexRadio('first')}
												checked={flexRadio}
											/>
											<div
												className={classNames(
													'rounded-2',
													'd-flex align-items-stretch justify-content-start',
												)}>
												<h6>
													{' '}
													Email address of Zoho Payroll
													<br />
													<p>(message-service@mail.zohopayroll.in)</p>
													<p>
														All the emails sent to customers will have
														message-service@mail.zohopayroll.in in the
														From field. But the reply-to email address
														will be primary contact's email address,
														snehajoe16291@gmail.com.
													</p>
												</h6>
												<span className='fw-bold'>
													<Badge
														color='warning'
														isLight
														className='justify-content-end'>
														<Icon icon='Star' color='warning' />
														Recommended
													</Badge>
												</span>
											</div>
										</ButtonGroup>
									</div>
								</CardBody>
							</Card>
							<Card
								borderColor={flexRadio == 'second' ? 'primary' : null}
								borderSize={flexRadio == 'second' ? 1 : 0}>
								<CardBody>
									<div className='col-lg-12'>
										<ButtonGroup>
											<Checks
												type='radio'
												name='flexRadioDefault'
												id='flexRadioDefault1'
												value='second'
												onChange={() => setFlexRadio('second')}
												checked={flexRadio}
											/>
											<div
												className={classNames(
													'rounded-2',
													'd-flex align-items-stretch justify-content-start',
												)}>
												<h6>
													{' '}
													Email address of Sender
													<p>
														All the emails sent to customers will have
														sender's email address in the From field.{' '}
													</p>
													<p>
														To prevent emails from landing in the Spam
														folder, we strongly recommend you to use the
														email address of Zoho Payroll.
													</p>
												</h6>
											</div>
										</ButtonGroup>
									</div>
								</CardBody>
							</Card>
						</div>
					</OffCanvasBody>
					<CardFooter borderSize={2}>
						<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
							<div className='row m-0'>
								<div className='col-12'>
									<Button
										color='info'
										icon='Save'
										type='submit'
										className='w-100'>
										Save
									</Button>
								</div>
							</div>
						</div>
					</CardFooter>
				</OffCanvas>

				<Modal
					isOpen={isDeleteOpen}
					setIsOpen={setIsDeleteOpen}
					titleId='exampleModalLabel'
					isCentered
					size={'sm'}>
					<></>
					<ModalBody>
						<div className='row g-4 mt-2'>
							<div className='col-12 d-flex justify-content-center'>
								<h5>
									<strong>Are you sure want to delete ?</strong>
								</h5>
							</div>
							<div className='col-10'>
								<div className='d-flex gap-2 justify-content-end'>
									<Button color='danger' size={'sm'}>
										Yes
									</Button>
									<Button
										color='info'
										size={'sm'}
										onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
										No
									</Button>
								</div>
							</div>
							<div className='col-10'></div>
						</div>
					</ModalBody>
				</Modal>
			</Page>
			<AlertService
				setIsOpen={setIsOpen}
				isOpen={isOpen}
				alertStatus={alertStatus}
				isNavigate={isNavigate}
			/>
		</PageWrapper>
	);
};

export default UpdateOrganisation;
