import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const addAmountCollection = (amountPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addAmountCollection`, amountPostData,
        response => {
            success(response)
        },
        error => {
            failure("addAmountCollection - " + error.message)
            console.log('error caught in service : addAmountCollection')
        },
    );

export const getAmountCollectionDetails = (screenId: any, paymentScheduleId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getAmountCollectionDetails/${getLicenseKey}/${screenId}/${paymentScheduleId}`,
        response => {
            success(response)
        },
        error => {
            failure("getAmountCollectionDetails - " + error.message)
            console.log('error caught in service : getAmountCollectionDetails')
        },
    );