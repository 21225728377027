import { useContext, useEffect, useRef, useState } from 'react';
import useDarkMode from '../../../hooks/useDarkMode';
import useSortableData from '../../../hooks/useSortableData';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import Input from '../../bootstrap/forms/Input';
import FormGroup from '../../bootstrap/forms/FormGroup';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../bootstrap/OffCanvas';
import { useFormik } from 'formik';
import AlertService from '../../../services/AlertService';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import { useNavigate, useParams } from 'react-router-dom';
import Icon from '../../icon/Icon';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import { useReactToPrint } from 'react-to-print';
import Popovers from '../../bootstrap/Popovers';
import { TableLoader, showLoader } from '../../../services/loader.services';
import AuthContext from '../../../contexts/authContext';
import {
	getColumnsForDataTable,
	updateColumnsForDataTable,
	updateDisplayOrder,
	updateFilter,
} from '../../../services/common.service';
import { getLicenseKey } from '../../../services/application.settings';
import { toasts } from '../../../services/toast.service';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/export.service';
import NoDataMsg from '../../../common/components/NoDataMsg';
import SearchableSelect from '../../../common/components/SearchableSelect';
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from '../../bootstrap/Modal';
import { getEmployeeList } from '../../../services/employee.service';
import { deleteUserType, getUserType } from '../../../services/userType.service';
import { DragDropContext, Draggable, DraggableProvided, DraggableStateSnapshot, DropResult, Droppable, DroppableProvided, DroppableStateSnapshot } from '@hello-pangea/dnd';
import { move, reorder } from '../../../pages/presentation/project-management/helper/helper';
import Board from '../../../pages/presentation/project-management/component/Board';
import classNames from 'classnames';

function UserRolesList() {
	const { userAccountId, userTypeId } = useContext(AuthContext);

	useEffect(() => {
		getUserTypeList();
	}, []);

	const userEditForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			employeeDetailsId: '',
			firstName: '',
			middleName: '',
			lastName: '',
			workEmail: '',
			userRoleId: '',
		},
		validate: (values) => {
			const errors: {
				employeeDetailsId?: string;
				firstName?: string;
				middleName?: string;
				lastName?: string;
				workEmail?: string;
				userRoleId?: string;
			} = {};
			if (!values.employeeDetailsId) {
				errors.employeeDetailsId = 'Required';
			}
			if (!values.firstName) {
				errors.firstName = 'Required';
			}
			if (!values.workEmail) {
				errors.workEmail = 'Required';
			}
			if (!values.userRoleId) {
				errors.userRoleId = 'Required';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: () => { updateUserSubmit() },
	});

	const columnVisibleForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		validateOnChange: false,
		onSubmit: () => { },
	});

	const deleteUserTypeForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			userTypeId: '',
		},
		validate: (values) => {
			const errors: {
				userTypeId?: string;
			} = {};
			if (!values.userTypeId) {
				errors.userTypeId = 'Required';
			}
			return errors;
		},
		validateOnChange: false,
		onSubmit: () => { },
	});

	const navigate = useNavigate();
	const componentRef = useRef(null);

	// For Table and offCanvas
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(1000);

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	// ColumnVisibility
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [allColumnsDataForRole, setAllColumnsDataForRole] = useState([]);
	const [columnDataById, setColumnDataById] = useState([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [columnVisibilityDataForRole, setColumnVisibilityDataForRole] = useState([]);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const { themeStatus, darkModeStatus } = useDarkMode();

	const [addEmployeeOffCanvas, setAddEmployeeOffCanvas] = useState(false);
	const [selectedRole, setSelectedRole] = useState<any>([]);
	const [userDetailsData, setUserDetailsData] = useState<any>([]);

	const [userTypeData, setUserTypeData] = useState<any>([]);
	const [deleteUserTypeOffCanvas, setDeleteUserTypeOffCanvas] = useState(false);
	const [dataStatus, setDataStatus] = useState(false);
	const [noDataMsg, setNoDataMsg] = useState('');

	function onActivate(i: any) {
		if (i != undefined) {
			let selectedEmployee = i;
			setAddEmployeeOffCanvas(true);

			userEditForm.setValues({
				employeeDetailsId: selectedEmployee.employeeDetailsId,
				firstName: selectedEmployee.firstName,
				middleName: selectedEmployee.middleName,
				lastName: selectedEmployee.lastName,
				workEmail: selectedEmployee.workEmail,
				userRoleId: selectedEmployee.userRoleId,
			});
			getUserTypeList();
		}
	}

	function onActivateForUserType(i: any) {
		if (i != undefined) {
			let selectedRole = i;
			setDeleteUserTypeOffCanvas(true)
			deleteUserTypeForm.setValues({
				userTypeId: selectedRole.userTypeId,
			});
		}
	}

	function selectedUserRole(e: any) {
		let selectedRole = userTypeData.filter((item: any) => item.userRoleId == e.value);
		setSelectedRole(selectedRole[0]);
	}

	const [state, setState] = useState<any>({ column1: [] });
	const [displayOrder, setDisplayOrder] = useState<any>([])

	const { items: items1, requestSort: requestSort1, getClassNamesFor: getClassNamesFor1 } = useSortableData(state['column1']);
	const { items: items, requestSort: requestSort, getClassNamesFor: getClassNamesFor } = useSortableData(userDetailsData);

	const currentPageData1 = dataPagination(items1, currentPage, perPage);
	const currentPageData = dataPagination(items, currentPage, perPage);

	const filteredData = updateFilter(currentPageData, columnVisibilityData, columnVisibleForm);

	const filteredData1 = updateFilter(currentPageData1, columnVisibilityDataForRole, columnVisibleForm);

	function getEmployee(organisationDetailsId: any, employeeStatusId: any) {
		setDataStatus(false)
		getEmployeeList(organisationDetailsId, employeeStatusId, 0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.employeeList;
					if (data != undefined) {
						getColumnsForDataTableList('getUserList', 'get');
						setUserDetailsData(data.filter((item: any) => item.userRoleId == 1));
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
							data[i].employeeFullName = (
								<>
									<Popovers desc='Active' trigger='hover'>
										<Icon color='success' icon='Circle' className='text-nowrap' />
									</Popovers>
									&nbsp;&nbsp;
									<span>{`${data[i].employeeFullName}`}</span>
								</>
							)
						};
						setDataStatus(true);
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success == false) {
					setUserDetailsData([]);
					getColumnsForDataTableList('getUserList', 'get');
					setNoDataMsg(response.data.message);
					setDataStatus(true);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(false);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function setUpdateUserDetails() {
		return {
			employeeDetailsId: userEditForm.values.employeeDetailsId,
			firstName: userEditForm.values.firstName,
			middleName: userEditForm.values.middleName,
			lastName: userEditForm.values.lastName,
			userRoleId: selectedRole.userRoleId,
			workEmail: userEditForm.values.workEmail,
			userAccountId: userAccountId,
			licenseKey: getLicenseKey,
		};
	}

	function updateUserSubmit() {
		showLoader(true);
		if (userEditForm.isValid) {
			let employeePostData = setUpdateUserDetails();
			// updateUser(employeePostData,
			// 	(response) => {
			// 		const data = response.data;
			// 		if (data.success == true) {
			// 			showLoader(false);
			// 			setAlertStatus({ message: data.message, type: 'success' });
			// 			setIsOpen(true);
			// 		} else if (data.success == false) {
			// 			showLoader(false);
			// 			setAlertStatus({ message: data.message, type: 'error' });
			// 			setIsOpen(true);
			// 		} else {
			// 			showLoader(false);
			// 			let errorCode = response.data.error[0].error_code;
			// 			let errorDescription = response.data.error[0].error_description;
			// 			setAlertStatus({ message: errorDescription, type: 'error' });
			// 			setIsOpen(true);
			// 		}
			// 	},
			// 	(error) => {
			// 		setAlertStatus({ message: error, type: 'error' });
			// 		setIsOpen(true);
			// 	},
			// );
		} else if (userEditForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
			setIsOpen(true);
		}
	}

	function getUserTypeList() {
		setDataStatus(false);
		getUserType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.userType;
					if (data != undefined) {
						getColumnsForDataTableListForRole('getUserType', 'get');
						setUserTypeData(data);
						let itemData = { 'column1': data }
						setState(itemData);

						let newData: any = []
						data.forEach((item: any, index: any) => {

							let obj = {
								userTypeId: item['userTypeId'],
								displayOrder: index + 1
							}
							newData.push(obj);
						});

						setDisplayOrder(newData)
						setDataStatus(true);
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setUserTypeData([]);
					getColumnsForDataTableListForRole('getUserType', 'get');
					setNoDataMsg(response.data.message);
					setDataStatus(true);
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function setDeleteUserRoleDetails() {
		return {
			userTypeId: deleteUserTypeForm.values.userTypeId,
			userAccountId: userAccountId,
			licenseKey: getLicenseKey,
		};
	}

	function deleteUserRoleSubmit() {
		showLoader(true);
		let userTypePostData = setDeleteUserRoleDetails();
		deleteUserType(
			userTypePostData,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'success' });
					setIsOpen(true);
					getUserTypeList();
					closeModal();
				} else if (data.success == false) {
					showLoader(false);
					setAlertStatus({ message: data.message, type: 'warning' });
					setIsOpen(true);
				} else {
					showLoader(false);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setAlertStatus({ message: errorDescription, type: 'error' });
					setIsOpen(true);
				}
			},
			(error) => {
				setAlertStatus({ message: error, type: 'error' });
				setIsOpen(true);
			},
		);
	}

	function getColumnsForDataTableList(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData)
						let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						let keyData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData)

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						}
						else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter((item: any) => !item.isDefault)

							for (let i = 0; i < nonDefaultValue.length; i++) {

								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
								}
								all.push(obj);
							}
							updateColumnsSubmit('', '', all)
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "warning")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	const updateColumnsSubmit = (columnVisibilityId: any, isDisplay: any, type: any) => {

		if (columnVisibleForm.isValid) {

			setColumnDataById(isDisplay)
			const postData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type ? type : [
					{
						columnVisibilityId: columnVisibilityId,
						isDisplay: isDisplay ? 0 : 1
					}
				]
			}

			updateColumnsForDataTable(postData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForDataTableList('getDepartmentList', 'get')
					}
					else if (data.success == false) {
					}
					else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, "Error")
					}
				}
				, (error) => {
					toasts(error, "Error")
				}
			)
		} else if (columnVisibleForm.isValid == false) {
			toasts("Please fill all the details!", "Error")
		}
	}

	function getColumnsForDataTableListForRole(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsDataForRole(allColumnsData)
						let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						let keyData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityDataForRole(columnVisibilityData)

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						}
						else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter((item: any) => !item.isDefault)

							for (let i = 0; i < nonDefaultValue.length; i++) {

								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
								}
								all.push(obj);
							}
							updateColumnsSubmitForRole('', '', all)
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "warning")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	const updateColumnsSubmitForRole = (columnVisibilityId: any, isDisplay: any, type: any) => {

		if (columnVisibleForm.isValid) {

			setColumnDataById(isDisplay)
			const postData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type ? type : [
					{
						columnVisibilityId: columnVisibilityId,
						isDisplay: isDisplay ? 0 : 1
					}
				]
			}

			updateColumnsForDataTable(postData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForDataTableListForRole('getUserType', 'get')
					}
					else if (data.success == false) {
					}
					else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, "Error")
					}
				}
				, (error) => {
					toasts(error, "Error")
				}
			)
		} else if (columnVisibleForm.isValid == false) {
			toasts("Please fill all the details!", "Error")
		}
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	function closeModal() {
		deleteUserTypeForm.resetForm();
		setDeleteUserTypeOffCanvas(false)
	}

	const onDragEnd = (result: DropResult) => {
		const { source, destination }: any = result;

		if (!destination) {
			return;
		}

		if (source.droppableId === destination.droppableId) {
			const ITEMS = reorder(state[source.droppableId], source.index, destination.index);

			const sourceList = source.droppableId;

			let final = { ...state, [sourceList]: ITEMS }

			for (let i = 0; i < final['column1'].length; i++) {
				final['column1'][i].sno = i + 1;
			}

			setState(final);
		} else {
			const RESULT: any = move(
				state[source.droppableId],
				state[destination.droppableId],
				source,
				destination,
			);

			let final = { ...state, ...RESULT, }

			for (let i = 0; i < final['column1'].length; i++) {
				final['column1'][i].sno = i + 1;
			}
			setState(final);
		}

		updateDisplayOrderSubmit()
	};

	const updateDisplayOrderSubmit = () => {

		displayOrder.forEach((item1: any) => {
			state['column1'].forEach((item: any) => {

				if (item['userTypeId'] == item1['userTypeId']) {
					item1['userTypeId'] = item['userTypeId'],
						item1['displayOrder'] = item['sno']
				}
			})
		})
		setDisplayOrder(displayOrder)
		const postData = {
			licenseKey: getLicenseKey,
			userAccountId: userAccountId,
			displayOrder: displayOrder,
			screenType: 3,
		}

		updateDisplayOrder(postData,
			(response) => {
				const data = response.data;
				if (data.success == true) {
					getUserTypeList();
				}
			}
			, (error) => {
				toasts(error, "Error")
			}
		)
	}

	return (
		<>
			<PageWrapper title='Users & Roles'>
				<Page>
					<DragDropContext onDragEnd={onDragEnd}>
						<Board>
							<Card stretch data-tour='list' ref={componentRef} id='pdf'>
								<CardHeader borderSize={1}>

									<CardLabel icon='List' iconColor='info'>
										<CardTitle tag='div' className='h5'>User Type List</CardTitle>
									</CardLabel>
									<CardActions className='float-end d-print-none'>
										<ButtonGroup color='primary'>
											<Icon className='mt-1' icon='Search' size='2x' color='primary' />
											<Input
												id='searchInput'
												type='search'
												placeholder='Search...'
												onChange={columnVisibleForm.handleChange}
												value={columnVisibleForm.values.searchInput}
											/>
										</ButtonGroup>

										<Dropdown
											isOpen={columnVisibilityMenu}
											setIsOpen={setColumnVisibilityMenu}
											isButtonGroup>
											<DropdownToggle>
												<Button icon='FilterAlt' color='primary' isLight>
													Filter
												</Button>
											</DropdownToggle>
											<DropdownMenu
												isAlignmentEnd
												size='lg'
												isCloseAfterLeave={false}>
												<DropdownItem>
													<div className='container py-2'>
														<form className='row g-3'>
															<div className='col-12'>
																<FormGroup>
																	<h6>Select All</h6>
																	<Checks type='switch' label='Select All Columns' onChange={() => getColumnsForDataTableListForRole('getUserType', 'post')} checked={isChecked} ariaLabel='Available status'
																	/>
																</FormGroup>
															</div>
															<div className='col-12'>
																<FormGroup>
																	<h6>Columns</h6>
																	<ChecksGroup>
																		{allColumnsDataForRole.map((i: any) => (<Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmitForRole(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay}
																			disabled={i.isDefault}
																		/>))}
																	</ChecksGroup>
																</FormGroup>
															</div>
														</form>
													</div>
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>
										<Dropdown isButtonGroup>
											<DropdownToggle>
												<Button color='primary' isLight icon='CloudDownload'>
													Export
												</Button>
											</DropdownToggle>
											<DropdownMenu isAlignmentEnd>
												<DropdownItem>
													<Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel('UserRolesList', userTypeData, columnVisibilityDataForRole)}>
														Excel
													</Button>
												</DropdownItem>
												<DropdownItem>
													<Button color='primary' isLight icon='PictureAsPdf' onClick={() =>
														convertJsonToPdf(userTypeData, columnVisibilityDataForRole, 'UserRolesList')}>
														PDF
													</Button>
												</DropdownItem>
												<DropdownItem>
													<Button color='primary' isLight
														icon='CloudDownload' onClick={() => downloadFile('UserRolesList', userTypeData, columnVisibilityDataForRole)}>
														CSV
													</Button>
												</DropdownItem>
												<DropdownItem>
													<Button color='primary' isLight icon='Print' onClick={handlePrint}>
														Print
													</Button>
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>

										<Button
											icon='Add'
											color='primary'
											isLight
											tag='a'
											onClick={() => navigate(`../userRoles/addUserRole`)}>
											New Role
										</Button>
									</CardActions>
								</CardHeader>
								<CardBody className='table-responsive'>
									<Droppable droppableId={'column1'}>
										{(
											providedDroppable: DroppableProvided,
											snapshotDroppable: DroppableStateSnapshot,
										) => (
											<div className="row">
												{!dataStatus ? (
													<TableLoader />
												) : (
													<table className='table table-modern table-hover' {...providedDroppable.droppableProps}
                                                    ref={providedDroppable.innerRef}>
														<thead>
															<tr>
																<th scope='col' onClick={() => requestSort1('sno')} className='cursor-pointer text-decoration-underline'>	S.No{' '}	<Icon size='lg' className={getClassNamesFor1('sno')} icon='FilterList' />
																</th>
																{columnVisibilityDataForRole.map((column: any) => (
																	<th key={column.keyName} scope='col' onClick={() => requestSort1(column.keyName)} className='cursor-pointer text-decoration-underline'>	{column.columnName}	<Icon size='lg' className={getClassNamesFor1(column.keyName)} icon='FilterList'
																	/>
																	</th>
																))}
																<th scope='col' className='d-print-none'>
																	Action
																</th>
															</tr>
														</thead>
														{/* <tbody>
												{filteredData1 != '' ?
													<>
														{filteredData1.map((rowData: any) => (
															<tr key={rowData.userTypeId}>
																<td>{rowData.sno}</td>
																{columnVisibilityDataForRole.map((column: any) => column.isDisplay && rowData[column.keyName] ? (<td key={`${rowData.userTypeId}-${column.keyName}`}>{rowData[column.keyName]}</td>) : (<td key={`empty-${column.keyName}`} />))}
																<td className='d-print-none'>
																	<Button color='dark' isLight icon='Edit' aria-label='Edit' tag='a' onClick={() => navigate(`../userRoles/updateUserRole/${rowData.userTypeId}`)}
																	/>
																	<Button className='ms-2' color='danger' isLight icon='Delete' aria-label='Edit' tag='a' onClick={() => onActivateForUserType(rowData)}
																	/>
																</td>
															</tr>
														))}
													</> :
													<NoDataMsg columnsCount={columnVisibilityDataForRole.length + 2} msg={noDataMsg}
													/>
												}
											</tbody> */}

														<tbody>{filteredData1 != "" ? <>{filteredData1.map((rowData: any, index: any) => (
															<Draggable key={rowData.userType} draggableId={rowData.userType} index={index}>
																{(
																	providedDraggable: DraggableProvided,
																	snapshotDraggable: DraggableStateSnapshot,
																) => (
																	<tr
																		className={classNames({ 'border-info': snapshotDraggable.isDragging })}
																		ref={providedDraggable.innerRef}
																		{...providedDraggable.draggableProps}
																		{...providedDraggable.dragHandleProps}
																		key={rowData.userTypeId}
																	>
																		<td>{index + 1}</td>

																		{columnVisibilityDataForRole.map((column: any) => column.isDisplay && rowData[column.keyName] ? (<td key={`${rowData.userTypeId}-${column.keyName}`}>{rowData[column.keyName]}</td>) : (<td key={`empty-${column.keyName}`} />))}
																		<td className='d-print-none'>
																			<Button color='dark' isLight icon='Edit' aria-label='Edit' tag='a' onClick={() => navigate(`../userRoles/updateUserRole/${rowData.userTypeId}`)}
																			/>
																			<Button className='ms-2' color='danger' isLight icon='Delete' aria-label='Edit' tag='a' onClick={() => onActivateForUserType(rowData)}
																			/>
																		</td>
																	</tr>
																)}
															</Draggable>))} </> : <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
														</tbody>
													</table>
												)}
											</div>
										)}
									</Droppable>
								</CardBody>
								{/* <PaginationButtons
									className='d-print-none'
									data={items1}
									label='items'
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									perPage={perPage}
									setPerPage={setPerPage}
								/> */}
							</Card>
						</Board>
					</DragDropContext>
				</Page>

				<OffCanvas
					setOpen={setAddEmployeeOffCanvas}
					isOpen={addEmployeeOffCanvas}
					titleId='upcomingDetails'
					isBodyScroll
					placement='end'
					isBackdrop
					tag='form'
					noValidate
					onSubmit={userEditForm.handleSubmit}>
					<OffCanvasHeader setOpen={setAddEmployeeOffCanvas} onClick={close}>
						<OffCanvasTitle id='upcomingDetails'>Edit User</OffCanvasTitle>
					</OffCanvasHeader>

					<OffCanvasBody>
						<div className='row g-3'>
							<div className='col-12'>
								<FormGroup id='firstName' label='Employee Name'>
									<Input
										placeholder='employeeName'
										onChange={userEditForm.handleChange}
										value={userEditForm.values.firstName}
										onBlur={userEditForm.handleBlur}
										isValid={userEditForm.isValid}
										isTouched={userEditForm.touched.firstName}
										invalidFeedback={userEditForm.errors.firstName}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='middleName' label='Middle Name'>
									<Input
										placeholder='middleName'
										onChange={userEditForm.handleChange}
										value={userEditForm.values.middleName}
										onBlur={userEditForm.handleBlur}
									// isValid={userEditForm.isValid}
									// isTouched={userEditForm.touched.middleName}
									// invalidFeedback={userEditForm.errors.middleName}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='lastName' label='Last Name'>
									<Input
										placeholder='lastName'
										onChange={userEditForm.handleChange}
										value={userEditForm.values.lastName}
										onBlur={userEditForm.handleBlur}
									// isValid={userEditForm.isValid}
									// isTouched={userEditForm.touched.lastName}
									// invalidFeedback={userEditForm.errors.lastName}
									/>
								</FormGroup>
							</div>

							<div className='col-12'>
								<FormGroup id='workEmail' label='Work Email'>
									<Input
										placeholder='workEmail'
										onChange={userEditForm.handleChange}
										value={userEditForm.values.workEmail}
										onBlur={userEditForm.handleBlur}
										isValid={userEditForm.isValid}
										isTouched={userEditForm.touched.workEmail}
										invalidFeedback={userEditForm.errors.workEmail}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='userRoleId' label='Role'>
									<SearchableSelect
										ariaLabel='Role'
										onChange={(e: any) => selectedUserRole(e)}
										value={{ value: selectedRole.userRoleId, label: selectedRole.roleName }}
										list={userTypeData.map((data: any) => ({
											value: data.userRoleId,
											label: data.roleName,
										}))}
									/>
								</FormGroup>
								<div className='mt-2'>
									<p>{selectedRole.description}</p>
								</div>
							</div>
						</div>
					</OffCanvasBody>

					<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
						<div className='row m-0'>
							<Button color='info' icon='Save' className='w-100' type='submit'>
								Save
							</Button>
						</div>
					</div>
				</OffCanvas>

				<Modal
					setIsOpen={setDeleteUserTypeOffCanvas}
					isOpen={deleteUserTypeOffCanvas}
					titleId='deleteUserTypeOffCanvas'
					isStaticBackdrop
					size='sm'>
					<ModalHeader className="'modal-header'"
						setIsOpen={() => { setDeleteUserTypeOffCanvas(false) }} onClick={closeModal}>
						<ModalTitle id="deleteUserTypeOffCanvas">Delete</ModalTitle>
					</ModalHeader>
					<ModalBody>
						<div className='row align-middle'>
							<h5 className="main-content-label mb-1 text-center text-danger">Are you sure want to delete ?</h5>
						</div>
					</ModalBody>
					<ModalFooter>
						<Button color='info' isOutline className='border-0' onClick={closeModal}>No</Button>
						<Button color='info' icon='Save' onClick={deleteUserRoleSubmit}>Yes </Button>
					</ModalFooter>
				</Modal>

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</PageWrapper>
		</>
	);
}
export default UserRolesList;