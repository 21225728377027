import React, { useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import showNotification from '../extras/showNotification';
import Icon from '../icon/Icon';
import Card, { CardBody } from '../bootstrap/Card';
import Button, { ButtonGroup } from '../bootstrap/Button';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import Checks from '../bootstrap/forms/Checks';
import Alert, { AlertHeading } from '../bootstrap/Alert';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Tooltips from '../bootstrap/Tooltips';

const employeesStateInsurance = () => {

  const employeesStateInsuranceForm = useFormik({
    initialValues: {
      esiNumber: '',
      deductionCycle: 'Monthly',
      employeesContribution: '0.75%',
      employersContribution: '3.25%',
      checkOne: true,
      checkTwo: false,
      checkThree: true,
    },
    validate: (values) => {
      const errors: {
        esiNumber?: string;
        deductionCycle?: string;
        employeesContribution?: string;
        employersContribution?: string;
      } = {};
      if (!values.esiNumber) {
        errors.esiNumber = '';
      }
      if (!values.deductionCycle) {
        errors.deductionCycle = 'Required';
      }
      if (!values.employeesContribution) {
        errors.employeesContribution = 'Required';
      }
      if (!values.employersContribution) {
        errors.employersContribution = 'Required';
      }
      return errors;
    },
    onSubmit: () => {
      setIsLoading(true);
      setTimeout(handleSave, 2000);
    },
  });

  const navigate = useNavigate();
  const flexChecks = useFormik({
    initialValues: {
      defaultCheck: false,
      checkedCheck: true,
    },
    onSubmit: () => { },
  });
  const flexRadios = useFormik({
    initialValues: {
      flexRadioDefault: 'first',
      flexRadioDisabled: 'third',
    },
    onSubmit: () => { },
  });

  const [lastSave, setLastSave] = useState<Dayjs | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSave = () => {
    setLastSave(dayjs());
    setIsLoading(false);
    showNotification(
      <span className='d-flex align-items-center'>
        <Icon icon='Info' size='lg' className='me-1' />
        <span>Updated Successfully</span>
      </span>,
      "The user's account details have been successfully updated.",
    );
  };

  return (
    <PageWrapper>
      <Page>
        <div className='row h-100 align-content-space-between'>
          <div className='col-md-12'>
            <Card>
              <CardBody>
                <div className="row">
                  <h5 className='h5 text-start fs-5 col-lg-6'><b>Employees' State Insurance</b></h5>
                </div>
                <hr />
                <div className='row g-2 mt-2'>
                  <div className='col-md-5'>
                    <h6 className='h6 text-start col-lg-6'><b>ESI Number</b></h6>
                    <FormGroup id='esiNumber' >
                      <Input
                        type='number'
                        placeholder='00-00-000000-000-0000'
                        className='align-items-center'
                        autoComplete='additional-name'
                        onChange={employeesStateInsuranceForm.handleChange}
                        onBlur={employeesStateInsuranceForm.handleBlur}
                        value={employeesStateInsuranceForm.values.esiNumber}
                        isValid={employeesStateInsuranceForm.isValid}
                        isTouched={employeesStateInsuranceForm.touched.esiNumber}
                        invalidFeedback={employeesStateInsuranceForm.errors.esiNumber}

                      />
                    </FormGroup>
                  </div>&nbsp;&nbsp;
                  <div className='col-md-4 mt-2'>
                    <h6 className='h6 text-start col-lg-6'><b>Deduction Cycle <Tooltips title='Employee State Insurance(ESI) contribution for each month shoud be deposited to Employee State Insurance Corporation(ESIC) within the 21st of the following month.' placement='right'><Icon icon='info'></Icon></Tooltips></b></h6>
                    <FormGroup id='deductionCycle' >
                      <Input
                        disabled
                        onChange={employeesStateInsuranceForm.handleChange}
                        onBlur={employeesStateInsuranceForm.handleBlur}
                        value={employeesStateInsuranceForm.values.deductionCycle}
                        isValid={employeesStateInsuranceForm.isValid}
                        isTouched={employeesStateInsuranceForm.touched.deductionCycle}
                        invalidFeedback={employeesStateInsuranceForm.errors.deductionCycle}
                      />
                    </FormGroup>
                  </div>
                  <div className='col-md-4 mt-3'>
                    <FormGroup id='employeesContribution' label='Employees Contribution' isFloating>
                      <Input
                        disabled
                        onChange={employeesStateInsuranceForm.handleChange}
                        onBlur={employeesStateInsuranceForm.handleBlur}
                        value={employeesStateInsuranceForm.values.employeesContribution}
                        isValid={employeesStateInsuranceForm.isValid}
                        isTouched={employeesStateInsuranceForm.touched.employeesContribution}
                        invalidFeedback={employeesStateInsuranceForm.errors.employeesContribution}
                      />
                    </FormGroup>
                  </div>
                  <div className='col-md-7 mt-4 '>
                    <p className='text-muted'>of Gross Pay</p>
                  </div>
                  <div className='col-md-4 mt-3'>
                    <FormGroup id='employersContribution' isFloating label='Employers Contribution'>
                      <Input
                        disabled
                        onChange={employeesStateInsuranceForm.handleChange}
                        onBlur={employeesStateInsuranceForm.handleBlur}
                        value={employeesStateInsuranceForm.values.employersContribution}
                        isValid={employeesStateInsuranceForm.isValid}
                        isTouched={employeesStateInsuranceForm.touched.employersContribution}
                        invalidFeedback={employeesStateInsuranceForm.errors.employersContribution}
                      />
                    </FormGroup>
                  </div>
                  <div className='col-md-7 mt-4 '>
                    <p className='text-muted '>of Gross Pay</p>
                  </div>
                  <div className='col-lg-12 mt-2'>
                    <ButtonGroup>
                      <Checks
                        id='flexCheckDefault'
                        name='defaultCheck'
                        onChange={flexChecks.handleChange}
                        checked={flexChecks.values.defaultCheck}
                      />
                      <p>Include employer's contribution in the CTC</p>
                    </ButtonGroup>
                  </div>
                  <div className='col-lg-12'>
                    <Alert color='warning' isLight>
                      <AlertHeading className='col-lg-12 fs-5'>
                        <small className='text-muted fs-6'> <b>Note:</b> ESI deductions will be made only if the employee’s monthly salary is less than or equal to ₹21,000. If the employee gets a salary revision which increases their monthly salary above ₹21,000, they would have to continue making ESI contributions till the end of the contribution period in which the salary was revised (April-September or October-March).</small>
                      </AlertHeading>
                    </Alert>
                  </div>
                </div>
                <hr />
              </CardBody>
              <div className="row">
                <div className='col-md-1 ms-2'>
                  <Button color='info' type="submit" isDisable={
                    !employeesStateInsuranceForm.isValid &&
                    !!employeesStateInsuranceForm.submitCount
                  }>Enable</Button>
                </div>
                <div className='col-md-1'>
                  <Button isOutline color='info' isLight onClick={() => navigate(-1)} > Cancel</Button>
                </div>
                <br /><br /><br />
              </div>
            </Card>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};
export default employeesStateInsurance;