import { useEffect, useRef, useState } from "react";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import Page from "../../layout/Page/Page";
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from "../bootstrap/Card";
import Icon from "../icon/Icon";
import Alert from "../bootstrap/Alert";
import { useNavigate } from "react-router-dom";
import Badge from "../bootstrap/Badge";
import Popovers from "../bootstrap/Popovers";
import { getPayScheduleDetails, getUpcomingPayrolls } from "../../services/setting.services";
import { TableLoader } from "../../services/loader.services";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from "../../layout/SubHeader/SubHeader";
import { toasts } from "../../services/toast.service";
import { numConvertToOrdinal } from "../../services/common.service";

function Pay() {

    useEffect(() => {
        getPaySchedule()
        getUpcomingPayrollsDetails()
    }, [])

    const navigate = useNavigate();
    const componentRef = useRef(null);

    // For Table and offCanvas
    const [payScheduleData, setPayScheduleData] = useState<any>([])
    const [weekDays, setWeekDays] = useState<any>([])
    const [upcomingPayrolls, setUpcomingPayrolls] = useState<any>([])
    const [dataStatus, setDataStatus] = useState(false)

    function getPaySchedule() {
        setDataStatus(false)
        getPayScheduleDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.payScheduleDetails;
                    if (data != undefined) {
                        setDataStatus(true)
                        setPayScheduleData(data[0]);
                        for (let i = 0; i < data.length; i++) {
                            data[i].weekDay = [
                                {
                                    dayId: 1,
                                    weekDay: 'SUN',
                                    isSelect: data[0].workWeek.substring(1, 0),
                                },
                                {
                                    dayId: 2,
                                    weekDay: 'MON',
                                    isSelect: data[0].workWeek.substring(2, 1),
                                },
                                {
                                    dayId: 3,
                                    weekDay: 'TUE',
                                    isSelect: data[0].workWeek.substring(3, 2),
                                },
                                {
                                    dayId: 4,
                                    weekDay: 'WED',
                                    isSelect: data[0].workWeek.substring(4, 3),
                                },
                                {
                                    dayId: 5,
                                    weekDay: 'THU',
                                    isSelect: data[0].workWeek.substring(5, 4),
                                },
                                {
                                    dayId: 6,
                                    weekDay: 'FRI',
                                    isSelect: data[0].workWeek.substring(6, 5),
                                },
                                {
                                    dayId: 7,
                                    weekDay: 'SAT',
                                    isSelect: data[0].workWeek.substring(7, 6),
                                }
                            ]
                        }
                        let weekDay = data[0].weekDay.filter((item: any) => item.isSelect == 1)
                        let workingWeek: any = ''
                        weekDay.forEach((item: any, index: any) => {
                            workingWeek += item['weekDay'] + ' ,'
                        })
                        setWeekDays(workingWeek)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true)
                    toasts(response.data.message, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function getUpcomingPayrollsDetails() {
        getUpcomingPayrolls(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.upcomingPayrolls;
                    if (data != undefined) {
                        setUpcomingPayrolls(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    return (
        <PageWrapper>
            <SubHeader>
                <SubHeaderLeft>
                    <CardLabel icon='MonetizationOn' iconColor='info'>
                        <CardTitle tag='div' className='h5'>Pay Schedule</CardTitle>
                    </CardLabel>
                </SubHeaderLeft>
                <SubHeaderRight>
                    <SubheaderSeparator />
                    <Popovers
                        desc={
                            <>
                                <Alert color='warning' isLight>
                                    Pay Schedule cannot be edited once you process the first pay run.
                                </Alert >
                            </>
                        }
                        trigger='hover'
                    >
                        <Icon size={'2x'} icon='Lightbulb' className="cursor-pointer" color="danger" />
                    </Popovers>
                </SubHeaderRight>
            </SubHeader>
            <Page>
                {!dataStatus ? <TableLoader /> :
                    <div className='row'>
                        <div className='col-lg-6'>
                            <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                                <CardHeader borderSize={1}>
                                    <CardLabel className="col-lg-11">
                                        <CardTitle tag='div' className='h6'>This Organisation's payroll runs on this schedule. </CardTitle>
                                    </CardLabel>
                                    <CardActions>
                                        <Icon
                                            color="primary"
                                            icon="Edit"
                                            size={'lg'}
                                            onClick={() => navigate(`../Payschedule/edit/${payScheduleData.payScheduleId}`)}
                                            className="ms-1 cursor-pointer"
                                        />
                                    </CardActions>
                                </CardHeader>
                                <CardBody className='table-responsive'>
                                    <table className='table table-hover table-borderless'>
                                        <thead></thead>
                                        <tbody>
                                            <tr>
                                                <td>Pay Freuency</td>
                                                <td>Every month (Monthly)</td>
                                            </tr>
                                            <tr>
                                                <td>Working Days</td>
                                                <td>{weekDays}</td>
                                            </tr>
                                            <tr>
                                                <td>Pay Day</td>
                                                <td>{payScheduleData.dayOfEveryMonth > 0 && (payScheduleData.dayOfEveryMonth + numConvertToOrdinal(payScheduleData.dayOfEveryMonth))} {payScheduleData.dayOfEveryMonth == 0 ? 'Last day' : ''} of every month</td>
                                            </tr>
                                            <tr>
                                                <td>First Pay Period</td>
                                                <td>{payScheduleData.firstPayrollFrom}</td>
                                            </tr>
                                            <tr>
                                                <td>First Pay Date</td>
                                                <td>{payScheduleData.dateOfSalary}</td>
                                            </tr>
                                            {payScheduleData.organisationWorkingDays > 0 ?
                                                <tr>
                                                    <td>No of Working Days</td>
                                                    <td>{payScheduleData.organisationWorkingDays}</td>
                                                </tr> : null
                                            }
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </div>
                        <div className='col-lg-6'>
                            <Card>
                                <CardHeader borderSize={1}>
                                    <CardLabel className="col-lg-5">
                                        <CardTitle tag='div' className='h6'>Upcoming Payrolls</CardTitle>
                                    </CardLabel>
                                </CardHeader>
                                <CardBody className='table-responsive'>
                                    <table className='table table-hover table-borderless'>
                                        <thead>
                                            <tr>
                                                <th>Pay Period</th>
                                                <th>Pay Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {upcomingPayrolls.slice(0, 5).map((i: any) => (
                                                <tr key={i.payPeriod} >
                                                    <td>{i.payPeriod}{i.payPeriod == payScheduleData.firstPayrollFrom ? <Badge className="ms-2" rounded={0} color="success">NEXT PAYRUN</Badge> : null}</td>
                                                    <td>{i.payDate}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                }
            </Page >
        </PageWrapper >
    );
}
export default Pay;
