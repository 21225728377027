import React, { useEffect, useRef, useState } from 'react'
import Card, { CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import FormGroup from '../bootstrap/forms/FormGroup'
import Select from "../bootstrap/forms/Select";
import Input from "../bootstrap/forms/Input";
import Alert from '../bootstrap/Alert'
import Icon from '../icon/Icon';
import Checks from '../bootstrap/forms/Checks'
import Button from '../bootstrap/Button'
import { addEarningDetails, getEarningTypeList } from '../../services/salary.service'
import { useNavigate } from 'react-router-dom'
import { toasts } from '../../services/toast.service'
import SearchableSelect from '../../common/components/SearchableSelect'
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../layout/SubHeader/SubHeader'
import AlertService from '../../services/AlertService'
import { useFormik } from 'formik'
import { getLicenseKey } from '../../services/application.settings'
import { showLoader } from '../../services/loader.services'

const correction = () => {

    useEffect(() => {
        getEarningType()
    }, []);

    const earningForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            earningName: '',
            payType: '',
            fromCTc: '',

        },
        validate: (values) => {
            const errors: {

                earningName?: string;
                calculationTypeId?: string;

            } = {};
            if (!values.earningName) {
                errors.earningName = 'Required';
            }
            // if (!values.earningDetailsId) {
            //     errors.earningDetailsId = 'Required';
            // }
            return errors;
        },
        validateOnChange: false,
        onSubmit: () => { AddEarning() },
    });

    const navigate = useNavigate();
    const [earningData, setEarningData] = useState([]);
    const [earingId, setEarningId] = useState([]);
    const componentRef = useRef(null);
    const [dataSuccess, setDataSuccess] = useState(false)
    const [isEpfContribution, setEpfContribution] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [earningTypeId, setEarningTypeId] = useState<any>('');
    const [calculationTypeId, setCalculationTypeId] = useState('1');

    function setAddEarningDetails() {

        return {
            earningName: earningForm.values.earningName,
            earningTypeId: earningTypeId?.value == '' ? 0 : earningTypeId?.value,
            createdBy: '1',
            payType: '1',
            calculationType: calculationTypeId,
            fromCTc: earningTypeId == '1' && calculationTypeId == '2' ? 1 : 0,

            licenseKey: getLicenseKey
        }
    }

    function getEarningType() {
        getEarningTypeList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.earningTypeList;
                    if (data != undefined) {
                        setEarningData(data);
                        setDataSuccess(true)
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            data[i].id = data[i].earningTypeId.toString();
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function AddEarning() {
        showLoader(true)
        if (earningForm.isValid) {
            let earningsData = setAddEarningDetails()
            addEarningDetails(earningsData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )

        } else if (earningForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    const [doChecked, setDoChecked] = useState(false);
    function doCheck() {
        setDoChecked(!doChecked)
    }
    const [taxable, setTaxable] = useState(false);
    function taxableEarning() {
        setTaxable(!taxable)
    }
    const [contribution, setContribution] = useState(false);
    function esiContribution() {
        setContribution(!contribution)
    }
    const [payslip, setPaySlip] = useState(false);
    function showPaySlip() {
        setPaySlip(!payslip)
    }
    const [active, setActive] = useState(false);
    function asActive() {
        setActive(!active)
    }
    const [always, setAlways] = useState(false);
    function getAlways() {
        setAlways(!always)
    }
    const [asWage, setPfWage] = useState(false);
    function Wage() {
        setPfWage(!asWage)
    }

    const selectEaring = (e: any) => {
        let earningTypeId = e
        setEarningTypeId(earningTypeId)
    }

    return (
        <PageWrapper>
            <SubHeader>
                <SubHeaderLeft>
                    <Button
                        color='info'
                        isLink
                        icon='ArrowBack'
                        tag='a'
                        to={`../salary/salaryComponents`}>
                        Back to List
                    </Button>
                    <SubheaderSeparator />
                </SubHeaderLeft>
            </SubHeader>
            <Page>
                <form noValidate onSubmit={earningForm.handleSubmit}>
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel >
                                <CardTitle tag='div' className='h5'>New Correction Component</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody >
                            <div className="ms-3">
                                <div className="col-6">
                                    <FormGroup id='earningDetailsId' label='Select Correction' >
                                        <SearchableSelect
                                            placeholder='Earning Type'
                                            ariaLabel='Earning Type'
                                            list={earningData.map((data: any) => (
                                                { value: data.earningTypeId, label: data.typeName }
                                            ))}
                                            onChange={selectEaring}
                                            value={earningTypeId}
                                        />
                                    </FormGroup>
                                </div>
                                <div className='col-7 mt-3 ms-3'>
                                    <Alert isLight className='border-0' shadow='md' color='success' rounded={2}>
                                        <span>
                                            <Icon icon='Info' color='danger' size="lg" />
                                            &nbsp;
                                            <small>You can use this correction component to make corrections to Basic in a pay run. it will have the same configuration as Basic.</small>
                                        </span>
                                    </Alert>
                                </div>
                                <div className="col-6 mt-2">
                                    <FormGroup id='earningName' label='Earning Name'>
                                        <Input
                                            placeholder="earningName"
                                            onChange={earningForm.handleChange}
                                            value={earningForm.values.earningName}
                                            onBlur={earningForm.handleBlur}
                                            isValid={earningForm.isValid}
                                            isTouched={earningForm.touched.earningName}
                                            invalidFeedback={earningForm.errors.earningName}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="col-7 mt-3 ms-3">
                                    <Alert isLight className='border-0' shadow='md' color='danger' rounded={2}>
                                        <span>
                                            <strong>Note:</strong>&nbsp;
                                            <small>You will only be able to edit the Earning Name once the correction component is created. <br />The change will be reflected in both new and existing employees.
                                            </small>
                                        </span>
                                    </Alert>
                                </div>
                            </div>
                            <br />
                            <div className="ms-3 mt-2">
                                <div className='col-12 mt-2'>
                                    <Checks
                                        id='flexCheckDefault'
                                        label='This is a taxable earning'
                                        name='defaultCheck'
                                        onChange={taxableEarning}
                                        checked={taxable}
                                    />
                                </div>
                                <div className="col-12 mt-2">
                                    <Checks
                                        id='isConsiderForEpfContribution'
                                        label='Consider for EPF Contribution'
                                        name='isConsiderForEpfContribution'
                                        onChange={() => setEpfContribution(!isEpfContribution)}
                                        checked={isEpfContribution}
                                    />
                                    {isEpfContribution ?
                                        <>
                                            <div className="col-12 ms-2 mt-2">
                                                <Checks
                                                    type='radio'
                                                    id='Always'
                                                    label='Always'
                                                    name='isCalculateOnProRataBasis'
                                                    onChange={getAlways}
                                                    checked={always}
                                                />
                                                <div className='col-12 ms-2 mt-2'>
                                                    <Checks
                                                        type='radio'
                                                        id='isCalculateOnProRataBasis'
                                                        label='Only when PF Wage is less than ₹ 15,000.'
                                                        name='isCalculateOnProRataBasis'
                                                        onChange={Wage}
                                                        checked={asWage}
                                                    />
                                                </div>

                                            </div>
                                        </> : null}
                                    <div className='col-6 mt-2'>
                                        <Checks
                                            id='Consider for ESI Contribution'
                                            label='Consider for ESI Contribution'
                                            name='defaultCheck'
                                            onChange={esiContribution}
                                            checked={contribution}
                                        />
                                    </div>
                                    <div className='col-6 mt-2'>
                                        <Checks
                                            id='Show this component in payslip'
                                            label='Show this component in payslip'
                                            name='defaultCheck'
                                            onChange={showPaySlip}
                                            checked={payslip}
                                        />
                                    </div>
                                    <div className="col-6 mt-2">
                                        <Checks
                                            id='Mark this as Active'
                                            label='Mark this as Active'
                                            name='defaultCheck'
                                            onChange={asActive}
                                            checked={active}
                                        />
                                    </div>
                                </div>
                            </div>
                            <CardFooter>
                                <CardFooterRight>
                                    <Button
                                        color='info'
                                        type="submit"
                                        isDisable={!earningForm.isValid && !earningForm.submitCount}
                                    >
                                        Save
                                    </Button>
                                    <Button
                                        isOutline
                                        color='info'
                                        isLight
                                        onClick={() => navigate(-1)}>
                                        Cancel
                                    </Button>
                                </CardFooterRight>
                            </CardFooter>
                        </CardBody>
                    </Card>
                </form>
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    )
}
export default correction 