import { useFormik } from "formik";
import { useContext, useEffect, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import DeleteComponents from "../../common/components/DeleteComponents";
import NoDataMsg from "../../common/components/NoDataMsg";
import useSortableData from "../../hooks/useSortableData";
import Page from "../../layout/Page/Page";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import AlertService from "../../services/AlertService";
import { getLicenseKey } from "../../services/application.settings";
import { updateFilter, getColumnsForDataTable, updateColumnsForDataTable, onlyAllowNumber } from "../../services/common.service";
import { downloadExcel, convertJsonToPdf, downloadFile } from "../../services/export.service";
import { showLoader, TableLoader } from "../../services/loader.services";
import { toasts } from "../../services/toast.service";
import PaginationButtons, { dataPagination } from "../PaginationButtons";
import Button, { ButtonGroup } from "../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from "../bootstrap/Card";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../bootstrap/Dropdown";
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../bootstrap/OffCanvas";
import Checks, { ChecksGroup } from "../bootstrap/forms/Checks";
import FormGroup from "../bootstrap/forms/FormGroup";
import Icon from "../icon/Icon";
import Input from "../bootstrap/forms/Input";
import AuthContext from "../../contexts/authContext";
import { addLoanRequestType, deleteLoanRequestType, getLoanRequestType, updateLoanRequestType } from "../../services/loans.service";

const LoanType = () => {

    useEffect(() => {
        getLoanRequest()
    }, [])

    const { userAccountId, userTypeId} = useContext(AuthContext);
    const [loanTypeData, setLoanTypeData] = useState<any>([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    // For Table and offCanvas
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(loanTypeData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addLoanTypeOffCanvas, setAddLoanTypeOffCanvas] = useState(false)
    const [editLoanTypeOffCanvas, setEditLoanTypeOffCanvas] = useState(false)
    const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const addLoanTypeForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            loanRequestName: '',
            perquisiteRate: '',
        },
        validate: (values) => {
            const errors: {
                loanRequestName?: string;
                perquisiteRate?: string;
            } = {};
            if (!values.loanRequestName) {
                errors.loanRequestName = 'Required';
            }
            if (!values.perquisiteRate) {
                errors.perquisiteRate = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { addLoanType() },
    });

    const editLoanTypeForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            loanRequestName: '',
            perquisiteRate: '',
            loanRequestTypeId: '',
        },
        validate: (values) => {
            const errors: {
                loanRequestName?: string;
                perquisiteRate?: string;
                loanRequestTypeId?: string;
            } = {};
            if (!values.loanRequestName) {
                errors.loanRequestName = 'Required';
            }
            if (!values.perquisiteRate) {
                errors.perquisiteRate = 'Required';
            }
            if (!values.loanRequestTypeId) {
                errors.loanRequestTypeId = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { updateLoanType() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function getLoanRequest() {
        setDataStatus(false)
        getLoanRequestType(0,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.loanRequestType;
                    if (data != undefined) {
                        getColumnsForTable('getLoanRequestType', 'get');
                        setLoanTypeData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        setDataStatus(true)
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDataStatus(true)
                    getColumnsForTable('getLoanRequestType', 'get');
                    setLoanTypeData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    setDataStatus(true)
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setDataStatus(true)
                toasts(error, "Error")
            }
        )
    }

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedLoanType = i
            setEditLoanTypeOffCanvas(true)
            editLoanTypeForm.setValues({
                loanRequestName: selectedLoanType.loanRequestName,
                perquisiteRate: selectedLoanType.perquisiteRate,
                loanRequestTypeId: selectedLoanType.loanRequestTypeId,
            });
        }
    }

    function setLoanTypeDetails() {
        return ({
            licenseKey: getLicenseKey,
            userAccountId: userAccountId,
            loanRequestName: addLoanTypeForm.values.loanRequestName,
            perquisiteRate: addLoanTypeForm.values.perquisiteRate,
        })
    }

    function setEditLoanTypeDetails() {
        return ({
            loanRequestName: editLoanTypeForm.values.loanRequestName,
            perquisiteRate: editLoanTypeForm.values.perquisiteRate,
            loanRequestTypeId: editLoanTypeForm.values.loanRequestTypeId,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function addLoanType() {
        showLoader(true);
        if (addLoanTypeForm.isValid) {
            let loanTypePostData = setLoanTypeDetails()
            addLoanRequestType(loanTypePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getLoanRequest();
                        closeAndFormReset();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "warning" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (addLoanTypeForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
            setIsOpen(true);
        }
    }

    function updateLoanType() {
        showLoader(true);
        if (editLoanTypeForm.isValid) {
            let loanTypePostData = setEditLoanTypeDetails()
            updateLoanRequestType(loanTypePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getLoanRequest();
                        closeAndFormReset();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "warning" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (editLoanTypeForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "warning" });
            setIsOpen(true);
        }
    }

    function deleteLoanType() {
        showLoader(true);
        // if (editLoanTypeForm.isValid) {
        let loanTypePostData = setEditLoanTypeDetails()
        deleteLoanRequestType(loanTypePostData,
            (response) => {
                const data = response.data;
                if (data.success == true) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "success" });
                    setIsOpen(true);
                    getLoanRequest();
                    closeAndFormReset();
                }
                else if (data.success == false) {
                    showLoader(false);
                    setAlertStatus({ message: data.message, type: "warning" });
                    setIsOpen(true);
                }
                else {
                    showLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setAlertStatus({ message: errorDescription, type: "error" });
                    setIsOpen(true);
                }
            }
            , (error) => {
                showLoader(false);
                setAlertStatus({ message: error, type: "error" });
                setIsOpen(true);
            }
        )
        // } else if (editLoanTypeForm.isValid == false) {
        //     setAlertStatus({ message: 'Please fill all the details!', type: "error" });
        //     setIsOpen(true);
        // }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getLoanRequestType', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndFormReset() {
        setAddLoanTypeOffCanvas(false);
        setEditLoanTypeOffCanvas(false);
        setIsDeleteOpen(false);
        addLoanTypeForm.resetForm();
        editLoanTypeForm.resetForm();
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            <PageWrapper title={`Loan Request Type List`}>
                <Page container='fluid'>
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-5">
                                <CardTitle tag='div' className='h5'>Loan Request Type List</CardTitle>
                            </CardLabel>
                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>
                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getLoanRequestType', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("LoanRequestTypeList", loanTypeData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(loanTypeData, columnVisibilityData, "LoanRequestTypeList")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("LoanRequestTypeList", loanTypeData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Button color='primary' icon='Add' isLight onClick={() => setAddLoanTypeOffCanvas(true)}>
                                    Add New
                                </Button>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>

                            {!dataStatus ?
                                <TableLoader /> :

                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort(`sno`)}
                                                className='cursor-pointer text-decoration-underline'>S.No
                                                <Icon size='lg' className={getClassNamesFor(`sno`)} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ?
                                            <>
                                                {filteredData.map((rowData: any) => (
                                                    <tr key={rowData.loanRequestTypeId}>
                                                        <td>{rowData.sno}</td>
                                                        {columnVisibilityData.map((column: any) => (
                                                            (column.isDisplay && rowData[column.keyName]) ?
                                                                <td key={`${rowData.loanRequestTypeId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                        ))}
                                                        <td className="d-print-none">
                                                            <Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} />
                                                        </td>
                                                    </tr>
                                                ))
                                                }</>
                                            :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons className='d-print-none' data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                    </Card>
                </Page>

                <OffCanvas setOpen={setAddLoanTypeOffCanvas} isOpen={addLoanTypeOffCanvas} titleId='addLoanTypeOffCanvas' placement='end'>
                    <OffCanvasHeader setOpen={setAddLoanTypeOffCanvas} onClick={closeAndFormReset}>
                        <OffCanvasTitle id="addLoanTypeOffCanvas"> Add Loan Request Type</OffCanvasTitle>
                    </OffCanvasHeader>
                    <form noValidate onSubmit={addLoanTypeForm.handleSubmit}>
                        <OffCanvasBody>
                            <div className="row g-4">
                                <div className='col-12'>
                                    <FormGroup id='loanRequestName' label='Loan Request Name'>
                                        <Input onChange={addLoanTypeForm.handleChange} value={addLoanTypeForm.values.loanRequestName} isValid={addLoanTypeForm.isValid} onBlur={addLoanTypeForm.handleBlur} isTouched={addLoanTypeForm.touched.loanRequestName} invalidFeedback={addLoanTypeForm.errors.loanRequestName} placeholder="Enter Loan Request Name" required />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='perquisiteRate' label='Perquisite Rate'>
                                        <Input onChange={addLoanTypeForm.handleChange} value={addLoanTypeForm.values.perquisiteRate} isValid={addLoanTypeForm.isValid} onBlur={addLoanTypeForm.handleBlur} isTouched={addLoanTypeForm.touched.perquisiteRate} invalidFeedback={addLoanTypeForm.errors.perquisiteRate} placeholder="Enter Perquisite Rate" required onKeyDown={(e: any) => onlyAllowNumber(e)}/>
                                    </FormGroup>
                                </div>
                            </div>
                        </OffCanvasBody>
                        <div className='row m-0'>
                            <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                                <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!addLoanTypeForm.isValid && !!addLoanTypeForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </form>
                </OffCanvas>

                <OffCanvas setOpen={setEditLoanTypeOffCanvas} isOpen={editLoanTypeOffCanvas} titleId='editLoanTypeOffCanvas' placement='end'>
                    <OffCanvasHeader setOpen={setEditLoanTypeOffCanvas} onClick={closeAndFormReset}>
                        <OffCanvasTitle id="editLoanTypeOffCanvas">Update Loan Request Type</OffCanvasTitle>
                    </OffCanvasHeader>
                    <form noValidate onSubmit={editLoanTypeForm.handleSubmit}>
                        <OffCanvasBody>
                            <div className="row g-4">
                                <div className='col-12'>
                                    <FormGroup id='loanRequestName' label='Loan Request Name'>
                                        <Input onChange={editLoanTypeForm.handleChange} value={editLoanTypeForm.values.loanRequestName} isValid={editLoanTypeForm.isValid} onBlur={editLoanTypeForm.handleBlur} isTouched={editLoanTypeForm.touched.loanRequestName} invalidFeedback={editLoanTypeForm.errors.loanRequestName} placeholder="Enter Loan Request Name" />
                                    </FormGroup>
                                </div>
                                <div className='col-12'>
                                    <FormGroup id='perquisiteRate' label='Perquisite Rate'>
                                        <Input onChange={editLoanTypeForm.handleChange} value={editLoanTypeForm.values.perquisiteRate} isValid={editLoanTypeForm.isValid} onBlur={editLoanTypeForm.handleBlur} isTouched={editLoanTypeForm.touched.perquisiteRate} invalidFeedback={editLoanTypeForm.errors.perquisiteRate} placeholder="Enter Perquisite Rate" onKeyDown={(e: any) => onlyAllowNumber(e)}/>
                                    </FormGroup>
                                </div>
                                <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                                    <DeleteComponents isDeleteOpen={isDeleteOpen} setIsDeleteOpen={setIsDeleteOpen} deleteFunction={deleteLoanType} />
                                </div>
                            </div>
                            <div className='row top-90 m-0'>
                                <div className='col-6 position-absolute bottom-0 start-0 p-3'>
                                    <Button color='danger' className='w-100' icon="Delete" onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                        Delete
                                    </Button>
                                </div>
                                <div className='col-6 position-absolute bottom-0 end-0 p-3'>
                                    <Button color='info' icon="Save" className='w-100' type="submit" isDisable={!editLoanTypeForm.isValid && !!editLoanTypeForm.submitCount}>Update</Button>
                                </div>
                            </div>
                        </OffCanvasBody>
                    </form>
                </OffCanvas>

                <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

            </PageWrapper>
        </>
    );
}
export default LoanType;