import React, { FC, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { toasts } from '../services/toast.service';
import { getOrganisationDetails } from '../services/setting.services';

interface ILogoProps {
	width?: number;
	height?: number;
}

const Logo: FC<ILogoProps> = ({ width, height }) => {

	const [logoPath, setLogoPath] = useState<any>('');

	useEffect(() => {
		getOrganisationList();
	}, []);

	function getOrganisationList() {
		getOrganisationDetails(1,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.organisationDetails[0];
					if (data != undefined) {
						setLogoPath(data.logoPath);						
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}

			}, error => {
				toasts(error, "Error")
			}
		)
	}

	return (

		<img src={logoPath}
			width={width}
			height={height} 
		/>
	);
};
Logo.propTypes = {
	width: PropTypes.number,
	height: PropTypes.number,
};
Logo.defaultProps = {
	width: 2155,
	height: 854,
};

export default Logo;
