import React, { useContext, useEffect, useRef, useState } from 'react'
import Page from '../../layout/Page/Page'
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardSubTitle, CardTitle } from '../bootstrap/Card'
import useDarkMode from '../../hooks/useDarkMode';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Button from '../bootstrap/Button';
import { useReactToPrint } from 'react-to-print';
import '../pay-schedule/payslip.css'
import Logo from '../Logo';
import AuthContext from '../../contexts/authContext';
import { getOrganisationDetails } from '../../services/setting.services';
import { toasts } from '../../services/toast.service';
import { useNavigate, useParams } from 'react-router-dom';
import { getStaffListForPayRun } from '../../services/salary.service';
import { amountToWords, pictNotLoading, profilePic } from '../../services/common.service';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';

const Payslip = () => {

    const { monthAndYear, staffDetailsId } = useParams();

    const { darkModeStatus } = useDarkMode();
    const componentRef = useRef(null);
    const { organisationDetailsId } = useContext(AuthContext);


    useEffect(() => {
        getOrganisationList(organisationDetailsId);
        viewSalaryDetails()
    }, []);

    const [organisationData, setOrganisationData] = useState<any>([]);
    const [profileForStaffData, setProfileForStaffData] = useState<any>([]);
    const [salaryDetails, setSalaryDetails] = useState<any>([]);
    const [totalSalaryAmount, setTotalSalaryAmount] = useState<any>('');
    const navigate = useNavigate();

    function viewSalaryDetails() {
        if (monthAndYear != undefined) {
            const splitDate = monthAndYear.split('-');
            const year = splitDate[0];
            const month = splitDate[1];
            // setYear(year);
            // setMonth(month);
            getStaffSalaryList(year, month, staffDetailsId);
        }

    }

    function getOrganisationList(organisationDetailsId: any) {
        getOrganisationDetails(organisationDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.organisationDetails[0];
                    if (data != undefined) {
                        setOrganisationData(data);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }

            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStaffSalaryList(year: any, month: any, staffDetailsId: any) {
        getStaffListForPayRun(year, month, staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffListForPayRun;
                    if (data != undefined) {
                        setSalaryDetails(data);
                        setTotalSalaryAmount(data[0].totalSalaryAmount)
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setSalaryDetails([]);
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <>
            {salaryDetails.map((staffSalary: any) => (
                <PageWrapper title={`${staffSalary.staffName} - Payslip`} key={staffSalary.staffDetailsId}>
                    <Page container='fluid'>
                        <Card>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='StickyNote2' iconColor='info' className="col-lg-6">
                                    <CardTitle tag='div' className='h5'>Payslip</CardTitle>
                                </CardLabel>
                                <CardActions className="d-print-none">
                                    <Button icon="Print" isLight color="primary" onClick={handlePrint}></Button>
                                </CardActions>
                            </CardHeader>
                            <CardBody ref={componentRef}>
                                <div className="row">
                                    <div className="col-8 d-flex justify-content-start">
                                        <Logo width={100} height={100} />
                                        <CardHeader>
                                            <CardLabel>
                                                <CardTitle tag='div' className='h4'>{organisationData.organisationName}</CardTitle>
                                                <CardSubTitle className='text-muted'>{organisationData.address}</CardSubTitle>
                                            </CardLabel>
                                        </CardHeader>
                                    </div>
                                    <div className="col-4 d-flex justify-content-end">
                                        <CardHeader>
                                            <CardLabel>
                                                <CardSubTitle className='text-muted'>Payslip For the Month </CardSubTitle>
                                                <CardTitle tag='div' className='h4 text-end'> {staffSalary.salaryForTheMonth}</CardTitle>
                                            </CardLabel>
                                        </CardHeader>
                                    </div>
                                    <div className='col-12'><hr /></div>
                                    <div className='row'>
                                        <div className='col-6 mt-1'>
                                            <div className="row g-3 flex-grow-1 ms-3 ">
                                                <div className='fw-bold h5'>EMPLOYEE SUMMARY</div>
                                                <div className='col-4'>Employee ID</div>
                                                <div className='col-1'>:</div>
                                                <div className='col-6 fw-bold'>{staffSalary.empNumber}</div>
                                                <div className='col-4'>Employee Name</div>
                                                <div className='col-1'>:</div>
                                                <div className='col-6 fw-bold'>{staffSalary.staffName}</div>
                                                <div className='col-4'>Designation</div>
                                                <div className='col-1'>:</div>
                                                <div className='col-6 fw-bold'>{staffSalary.designationName}</div>
                                                <div className='col-4'>Department</div>
                                                <div className='col-1'>:</div>
                                                <div className='col-6 fw-bold'>{staffSalary.departmentName != null ? staffSalary.departmentName : "-"}</div>
                                                <div className='col-4'>Month</div>
                                                <div className='col-1'>:</div>
                                                <div className='col-6 fw-bold'>{staffSalary.salaryForTheMonth}</div>
                                                <div className='col-4'>Pay Date</div>
                                                <div className='col-1'>:</div>
                                                <div className='col-6 fw-bold'>{staffSalary.dateOfSalaryView}</div>
                                            </div>
                                        </div>
                                        <div className='col-6 mt-1'>
                                            <div className="row justify-content-center">
                                                <Card
                                                    shadow='none'
                                                    borderSize={1}
                                                    borderColor='light'
                                                    stretch
                                                    className='mb-0 col-7 mt-4'>
                                                    <CardHeader borderSize={3} borderColor='success' >
                                                        <CardLabel>
                                                            <CardTitle className='h4'>
                                                                ₹ {totalSalaryAmount}
                                                            </CardTitle>
                                                            <CardSubTitle>Employee Net Pay</CardSubTitle>
                                                        </CardLabel>
                                                    </CardHeader>
                                                    <CardBody>
                                                        <div className="row g-2 flex-grow-1">
                                                            <div className='col-6'>Paid Days</div>
                                                            <div className='col-1'>:</div>
                                                            <div className='col-5 fw-bold'>{staffSalary.totalPaidDays}</div>
                                                            <div className='col-6'>LOP Days</div>
                                                            <div className='col-1'>:</div>
                                                            <div className='col-5 fw-bold'>{staffSalary.totalLopDays}</div>
                                                        </div>
                                                    </CardBody>
                                                </Card>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <hr style={{ borderTop: '1px dotted black' }} />
                                        </div>
                                        <div className='col-6 mt-1'>
                                            <div className="row g-3 flex-grow-1 ms-3 ">
                                                <div className='col-4'>A/C Number</div>
                                                <div className='col-1'>:</div>
                                                <div className='col-6 fw-bold'>{staffSalary.accountNumber ? staffSalary.accountNumber : "-"}</div>
                                            </div>
                                        </div>
                                        <div className='col-6 mt-1'>
                                            <div className="row g-3 flex-grow-1 ms-3 ">
                                                <div className='col-4'>Bank Name</div>
                                                <div className='col-1'>:</div>
                                                <div className='col-6 fw-bold'>{staffSalary.bankName ? staffSalary.bankName : "-"}</div>
                                            </div>
                                        </div>
                                        <div className='col-6 mt-1'>
                                            <div className="row g-3 flex-grow-1 ms-3 ">
                                                <div className='col-4'>Pan No</div>
                                                <div className='col-1'>:</div>
                                                <div className='col-6 fw-bold'>{staffSalary.panNumber ? staffSalary.panNumber : "-"}</div>
                                            </div>
                                        </div>
                                        <div className='col-6 mt-1'>
                                            <div className="row g-3 flex-grow-1 ms-3 ">
                                                <div className='col-4'>PF No</div>
                                                <div className='col-1'>:</div>
                                                <div className='col-6 fw-bold'>{staffSalary.epfNumber ? staffSalary.epfNumber : "-"}</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className='col-12'>
                                        <hr style={{ borderTop: '1px dotted black' }} />
                                    </div>

                                    <div className="row">
                                        <div className='col-6 mt-2'>
                                            <section className="earnings-deductions">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>EARNINGS</th>
                                                            <th>EARNED</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Basic pay</td>
                                                            <td>₹ {staffSalary.getBasicSalaryAmount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>House Allowance</td>
                                                            <td>₹ 0</td>
                                                        </tr>
                                                        <tr>
                                                            <td>OT Amount</td>
                                                            <td>₹ {staffSalary.totalOtAmount}</td>
                                                        </tr>
                                                        <tr>
                                                            <td><b>Gross Earnings</b></td>
                                                            <td><b>₹ {Number(staffSalary.getBasicSalaryAmount) + Number(staffSalary.totalOtAmount)}</b></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </section>
                                        </div>
                                        <div className='col-6 mt-2'>
                                           
                                            <section className="earnings-deductions">
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>DEDUCTIONS</th>
                                                            <th>AMOUNT</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>Provident Fund</td>
                                                            <td>₹ {staffSalary.getTotalPfAmount}</td>
                                                        </tr>

                                                        {/* {staffSalary.lateComeDeduction != 0 ? <tr>
                                                            <td>Late Come Deduction</td>
                                                            <td>₹ {staffSalary.lateComeDeduction}</td>
                                                        </tr> : null} */}
                                                        {/* {staffSalary.lunchLateComeDeduction != 0 ?
                                                            <tr>
                                                                <td>Lunch Late Come Deduction</td>
                                                                <td>₹ {staffSalary.lunchLateComeDeduction}</td>
                                                            </tr>
                                                            : null} */}
                                                        {staffSalary.salaryAdvanceAmount != 0 ?
                                                            <tr>
                                                                <td>Salary Advance</td>
                                                                <td>₹ {staffSalary.salaryAdvanceAmount}</td>
                                                            </tr> : null}
                                                        {staffSalary.loanAmount != 0 ?
                                                            <tr>
                                                                <td>Loan</td>
                                                                <td>₹ {staffSalary.loanAmount}</td>
                                                            </tr> : null}
                                                        <tr>
                                                            <td><b>Gross Deductions</b></td>
                                                            <td><b>₹ {Number(staffSalary.getTotalPfAmount) + Number(staffSalary.salaryAdvanceAmount) + Number(staffSalary.loanAmount)}</b></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </section>
                                        </div>
                                    </div>

                                    <div className="row mt-4">                                     

                                        <CardHeader className={`bg-l${darkModeStatus ? 'o25' : '25'
                                            }-success bg-l${darkModeStatus ? 'o50' : '10'
                                            }-success-hover transition-base rounded-1 mb-0`}>
                                            <div className='justify-content-start'>
                                                <CardLabel className='align-middle'>
                                                    <CardTitle tag='div' className='h5'>TOTAL NET PAYABLE</CardTitle>
                                                    <CardSubTitle className='text-muted '>Gross Earnings - Total Deductions</CardSubTitle>
                                                </CardLabel>
                                            </div>
                                            <div className='justify-content-end'>                                               
                                                <CardLabel className='mt-2 align-middle'>
                                                    <CardTitle tag='div' className='h4'>₹ {totalSalaryAmount}</CardTitle>
                                                </CardLabel>
                                            </div>
                                        </CardHeader>
                                    </div>
                                    <div className="col-12 mt-3">
                                        <CardSubTitle className='d-flex justify-content-end'><span>Amount In Words : {" "}</span><b> {" "} {amountToWords(totalSalaryAmount)}</b></CardSubTitle>
                                    </div>
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <br />
                                    <div className='col-1'></div>
                                    <div className='col-4 justify-self-center text-center'>
                                        <CardLabel className='mt-2'><CardTitle tag='div' className='h5'> Verified By</CardTitle>
                                            <CardSubTitle className='text-muted '><b>(Accounts)</b></CardSubTitle>
                                        </CardLabel>
                                    </div>
                                    <div className='col-4 justify-self-center text-center'>
                                        <CardLabel className='mt-2'><CardTitle tag='div' className='h5'>Authorized By</CardTitle>
                                            <CardSubTitle className='text-muted '><b>(Director)</b></CardSubTitle>
                                        </CardLabel>
                                    </div>
                                    <div className='col-3 justify-self-center text-center'>
                                        <CardLabel className='mt-2'><CardTitle tag='div' className='h5'>Received By</CardTitle>
                                            <CardSubTitle className='text-muted '><b>({staffSalary.staffName})</b></CardSubTitle>
                                        </CardLabel>
                                    </div>
                                    <hr className='mt-2' />
                                    <div className="col-12 mt-1">
                                        <p className='text-muted text-center'>-- This is a system-generated document. --</p>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Page>
                </PageWrapper>
            ))}
        </>)
}

export default Payslip