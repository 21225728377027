import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import NoDataMsg from "../../../common/components/NoDataMsg";
import AuthContext from "../../../contexts/authContext";
import useSortableData from "../../../hooks/useSortableData";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import AlertService from "../../../services/AlertService";
import { getLicenseKey } from "../../../services/application.settings";
import { getCurrentDateAndTime, updateFilter, onlyAllowNumber } from "../../../services/common.service";
import { TableLoader, showLoader } from "../../../services/loader.services";
import { addStaffSalaryReviseForBulkUpload, getStaffSalaryReviseForBulkUpload } from "../../../services/salary.service";
import { toasts } from "../../../services/toast.service";
import { dataPagination } from "../../PaginationButtons";
import Button from "../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from "../../bootstrap/Card";
import FormGroup from "../../bootstrap/forms/FormGroup";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { useNavigate } from "react-router-dom";
import Checks from "../../bootstrap/forms/Checks";
import Select from "../../bootstrap/forms/Select";
import InputGroup, { InputGroupText } from "../../bootstrap/forms/InputGroup";


const StaffRevisedSalaryDetailsBulk = () => {

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        const splitDate = getCurrentDateAndTime('date').split('-');
        const year = splitDate[0];
        const month = splitDate[1];
        getStaffRevisedSalaryList(year, month);
    }, []);

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        validateOnChange: false,
        onSubmit: () => { },
    });

    const [staffSalaryReviseForBulkData, setStaffSalaryReviseForBulkData] = useState<any>([]);
    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [isLoader, setIsLoader] = useState(false);

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(1000);
    const { items, requestSort, getClassNamesFor } = useSortableData(staffSalaryReviseForBulkData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [noDataMsg, setNoDataMsg] = useState('');

    const [staffReviseDetailsArray, setStaffReviseDetailsArray] = useState<any>([]);
    const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([]);
    const [reviseTypeId, setReviseTypeId] = useState<any>('');

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);

    const [amountInCtc, setAmountInCtc] = useState<any>('');
    const [percentInCTC, setPercentInCTC] = useState<any>('');
    const [isConsiderForPfContribution, setIsConsiderForPfContribution] = useState<any>(false);
    const [isCheckAll, setIsCheckAll] = useState(false);

    // Filter
    const filteredData: any = onCurrentPageData.filter(
        (i) =>
            (i.userType !== null &&
                i.userType
                    .toString()
                    .toLowerCase()
                    .includes(columnVisibilityForm.values.searchInput.toLowerCase())) ||
            (i.staffName !== null &&
                i.staffName
                    .toString()
                    .toLowerCase()
                    .includes(columnVisibilityForm.values.searchInput.toLowerCase())),
    );

    function getStaffRevisedSalaryList(year: any, month: any) {
        getStaffSalaryReviseForBulkUpload(year, month,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffSalaryReviseForBulkUpload;
                    if (data != undefined) {
                        setStaffSalaryReviseForBulkData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            data[i].toAnnualCTC = '';
                            data[i].totalAnnualCTC = data[i].fromAnnualCTC;
                            data[i].isSelect = false;
                            data[i].isEditable = true;
                        }
                        setIsLoader(false);
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setStaffSalaryReviseForBulkData([]);
                    setIsLoader(false);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    function setRevisedSalaryDetails() {
        return ({
            staffReviseDetails: staffReviseDetailsArray,
            licenseKey: getLicenseKey,
            revisedBy: userAccountId
        })
    }

    function updateRevisedSalaryDetailsSubmit() {
        showLoader(true);
        if (staffReviseDetailsArray.length > 0) {
            let revisedSalaryPostData = setRevisedSalaryDetails()
            addStaffSalaryReviseForBulkUpload(revisedSalaryPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(`../salary/staffRevisedSalaryDetails`)
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "warning" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )

        } else if (staffReviseDetailsArray.length == 0) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    // const updateStaffPfContributionValue = (e: any, cell: number) => {

    //     const newData: any = [];
    //     if (e == true) {
    //         filteredData.forEach((item: any, index: any) => {
    //             if (item['staffDetailsId'] == cell) {
    //                 item['isConsiderForPfContribution'] = true;
    //             }
    //             newData.push(item);
    //         });
    //         setFilteredDataToRefresh(newData);
    //     } else if (e == false) {
    //         filteredData.forEach((item: any, index: any) => {
    //             if (item['staffDetailsId'] == cell) {
    //                 item['isConsiderForPfContribution'] = false;
    //             }
    //             newData.push(item);
    //         });
    //         setFilteredDataToRefresh(newData);
    //     }

    //     let PfContribution = e ? 1 : 0

    //     if (staffReviseDetailsArray.length == 0) {
    //         const obj = {
    //             staffDetailsId: cell,
    //             isConsiderForPfContribution: PfContribution,
    //         };
    //         staffReviseDetailsArray.push(obj);
    //     } else {
    //         let selectedItem: any;
    //         staffReviseDetailsArray.forEach((item: any, index: any) => {
    //             if (item['staffDetailsId'] == cell) {
    //                 selectedItem = item;
    //                 selectedItem['staffDetailsId'] = cell;
    //             }
    //         });
    //         if (selectedItem != undefined) {
    //             0
    //             selectedItem['staffDetailsId'] = cell;
    //         } else {
    //             var obj;
    //             obj = {
    //                 staffDetailsId: cell,
    //                 isConsiderForPfContribution: PfContribution,
    //             };
    //             staffReviseDetailsArray.push(obj);
    //         }
    //     }
    // };

    const updateToAnnualValue = (toAnnualCTC: any, cell: number, fromAnnualCTC: any, salaryTemplateId: any) => {
        const newData: any = [];

        filteredData.forEach((item: any, index: any) => {
            if (item['staffDetailsId'] == cell) {
                item['toAnnualCTC'] = toAnnualCTC;
                if (reviseTypeId == 1) {
                    item['totalAnnualCTC'] = Number(fromAnnualCTC) + Number(toAnnualCTC);

                } else {
                    item['totalAnnualCTC'] = Number(fromAnnualCTC) + ((Number(fromAnnualCTC) * Number(toAnnualCTC)) / 100)
                }
            }
            newData.push(item);
        });
        setFilteredDataToRefresh(newData);

        // let PfContribution = isConsiderForPfContribution ? 1 : 0

        if (staffReviseDetailsArray.length == 0) {
            const obj = {
                staffDetailsId: cell,
                salaryTemplateId: salaryTemplateId,
                toAnnualCTC: toAnnualCTC,
                fromCtcAmount: fromAnnualCTC,
                flatAmount: reviseTypeId == 1 ? toAnnualCTC : 0,
                percentage: reviseTypeId == 2 ? toAnnualCTC : 0,
                // isConsiderForPfContribution: PfContribution,
            };
            staffReviseDetailsArray.push(obj);
        } else {
            let selectedItem: any;
            staffReviseDetailsArray.forEach((item: any, index: any) => {
                if (item['staffDetailsId'] == cell) {
                    selectedItem = item;
                    selectedItem['toAnnualCTC'] = toAnnualCTC;
                    selectedItem['salaryTemplateId'] = salaryTemplateId;
                    selectedItem['flatAmount'] = reviseTypeId == 1 ? toAnnualCTC : 0;
                    selectedItem['percentage'] = reviseTypeId == 2 ? toAnnualCTC : 0;
                    // selectedItem['isConsiderForPfContribution'] = PfContribution;
                }
            });
            if (selectedItem != undefined) {
                selectedItem['toAnnualCTC'] = toAnnualCTC;
                selectedItem['salaryTemplateId'] = salaryTemplateId;
                selectedItem['flatAmount'] = reviseTypeId == 1 ? toAnnualCTC : 0;
                selectedItem['percentage'] = reviseTypeId == 2 ? toAnnualCTC : 0;
                // selectedItem['isConsiderForPfContribution'] = PfContribution;
            } else {
                var obj;
                obj = {
                    staffDetailsId: cell,
                    salaryTemplateId: salaryTemplateId,
                    toAnnualCTC: toAnnualCTC,
                    fromCtcAmount: fromAnnualCTC,
                    flatAmount: reviseTypeId == 1 ? toAnnualCTC : 0,
                    percentage: reviseTypeId == 2 ? toAnnualCTC : 0,
                    // isConsiderForPfContribution: PfContribution,
                };
                staffReviseDetailsArray.push(obj);
            }
        }
    };

    const reviseTypeData = [{ reviseTypeId: 1, reviseType: 'Amount' }, { reviseTypeId: 2, reviseType: 'Percentage' }]

    function selectReviseTypeId(value: any) {
        setReviseTypeId(value)
        const newData: any = [];
        filteredData.forEach((item: any) => {
            item.isSelect = false
            item.isEditable = true
            item['toAnnualCTC'] = '';
            item['totalAnnualCTC'] = item['fromAnnualCTC'];
            item['isConsiderForPfContribution'] = false
            newData.push(item);
        });
        setFilteredDataToRefresh(newData);
        if (value == 1) {
            setAmountInCtc('');
            setStaffReviseDetailsArray([])
        } else {
            setPercentInCTC('');
            setStaffReviseDetailsArray([])
        }
    }

    // function selectAllPFContribution(e: any) {

    //     if (e.target.checked == true) {
    //         setIsConsiderForPfContribution(true);

    //         filteredData.forEach((item: any, index: any) => {
    //             // if (item['isSelect'] == true) {
    //             updateStaffPfContributionValue(e, item['staffDetailsId'])
    //             // }
    //         });

    //     } else {
    //         setIsConsiderForPfContribution(false);
    //         setStaffReviseDetailsArray([])
    //         const newData: any = [];
    //         filteredData.forEach((item: any, index: any) => {
    //             item['isConsiderForPfContribution'] = false;
    //             newData.push(item);
    //         });
    //         setFilteredDataToRefresh(newData);
    //     }
    // }

    function addAmountOrPercentage(value: any, type: any) {


        if (type == 'amount') {
            setAmountInCtc(value);
            setPercentInCTC('');

            filteredData.forEach((item: any, index: any) => {
                if (item['isSelect'] == true) {
                    updateToAnnualValue(value, item['staffDetailsId'], item['fromAnnualCTC'], item['salaryTemplateId'])
                }
            });

        } else {
            setPercentInCTC(value);
            setAmountInCtc('');
            filteredData.forEach((item: any, index: any) => {
                if (item['isSelect'] == true) {
                    updateToAnnualValue(value, item['staffDetailsId'], item['fromAnnualCTC'], item['salaryTemplateId'])
                }
            });
        }
    }

    const selectAllStudents = (e: any) => {
        setIsCheckAll(e)
        const newData: any = [];
        if (e == true) {
            if (reviseTypeId == 1) {

                filteredData.forEach((item: any, index: any) => {
                    item.isSelect = true
                    item.isEditable = false
                    updateToAnnualValue(amountInCtc, item['staffDetailsId'], item['fromAnnualCTC'], item['salaryTemplateId'])
                    // if (isConsiderForPfContribution == true) {
                    //     updateStaffPfContributionValue(e, item['staffDetailsId'])
                    // }
                });

            } else {

                filteredData.forEach((item: any, index: any) => {
                    item.isSelect = true
                    item.isEditable = false
                    updateToAnnualValue(percentInCTC, item['staffDetailsId'], item['fromAnnualCTC'], item['salaryTemplateId'])
                    // if (isConsiderForPfContribution == true) {
                    //     updateStaffPfContributionValue(e, item['staffDetailsId'])
                    // }
                });
            }
        } else {
            if (e == false) {

                filteredData.forEach((item: any) => {
                    item.isSelect = false
                    item.isEditable = true
                    item['toAnnualCTC'] = '';
                    item['totalAnnualCTC'] = item['fromAnnualCTC'];
                    item['isConsiderForPfContribution'] = false
                    newData.push(item);
                });

                setStaffReviseDetailsArray([])
            }
        }
    }

    const updateStaffDetailsIdValue = (e: any, rowData: any) => {
        const newData: any = [];
        if (e == true) {
            filteredData.forEach((item: any, index: any) => {
                if (item['staffDetailsId'] == rowData.staffDetailsId) {
                    item['isSelect'] = true;
                    item['isEditable'] = false;
                }
                newData.push(item);
            });
            setFilteredDataToRefresh(newData);

            if (reviseTypeId == 1) {

                updateToAnnualValue(amountInCtc, rowData.staffDetailsId, rowData.fromAnnualCTC, rowData.salaryTemplateId)
                // if (isConsiderForPfContribution == true) {
                //     updateStaffPfContributionValue(e, rowData.staffDetailsId)
                // }
            } else {

                updateToAnnualValue(percentInCTC, rowData.staffDetailsId, rowData.fromAnnualCTC, rowData.salaryTemplateId)
                // if (isConsiderForPfContribution == true) {
                //     updateStaffPfContributionValue(e, rowData.staffDetailsId)
                // }
            }
        } else {

            filteredData.forEach((item: any) => {
                if (item['staffDetailsId'] == rowData.staffDetailsId) {
                    item['isSelect'] = false;
                    item['isEditable'] = true;
                    item['toAnnualCTC'] = '';
                    item['isConsiderForPfContribution'] = false
                }
                newData.push(item);
            });
            setFilteredDataToRefresh(newData);

            staffReviseDetailsArray.splice(staffReviseDetailsArray.indexOf(rowData.staffDetailsId), 1);
        }
    }

    return (
        <PageWrapper title='Staff Revised Salary'>
            <SubHeader>
                <SubHeaderLeft>
                    <Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
                        Back to List
                    </Button>
                    <SubheaderSeparator />
                </SubHeaderLeft>
            </SubHeader>

            <Page container='fluid'>
                {!dataSuccess ?
                    <div className='col-lg-12 text-center'>
                        <TableLoader />
                    </div> :
                    <>
                        <Card stretch data-tour='list'>
                            <CardHeader borderSize={1}>
                                <div className='d-flex col-5 justify-content-start'>
                                    <CardLabel icon='List' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>
                                            Staff Salary Bulk Increment
                                        </CardTitle>
                                    </CardLabel>
                                </div>
                                <div className='d-flex col-7 justify-content-center g-4'>
                                    <div className='col-5 justify-content-center'></div>
                                    <div
                                        className='d-flex col-5 justify-content-start'
                                        data-tour='search'>
                                        <label
                                            className='hide-in-pdf border-0 bg-transparent cursor-pointer mt-2'
                                            htmlFor='searchInput'>
                                            <Icon
                                                icon='Search'
                                                size='2x'
                                                color='primary'
                                            />
                                        </label>
                                        <Input
                                            id='searchInput'
                                            type='search'
                                            placeholder='Search...'
                                            onChange={columnVisibilityForm.handleChange}
                                            value={
                                                columnVisibilityForm.values.searchInput
                                            }
                                        />
                                    </div>
                                    &nbsp;&nbsp;
                                    <div className='col-2 justify-content-center'>
                                        <Button
                                            icon='Save'
                                            color='primary'
                                            isDisable={
                                                staffReviseDetailsArray.length > 0
                                                    ? false
                                                    : true
                                            }
                                            onClick={() => {
                                                updateRevisedSalaryDetailsSubmit();
                                            }}>
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </CardHeader>
                            <CardBody className='table-responsive'>
                                <div className='row h-100 pb-3'>

                                    <div className="row">
                                        <div className='col-3'>
                                            <FormGroup
                                                id='reviseTypeId'
                                                label='Revise Type'
                                                isFloating>
                                                <Select
                                                    ariaLabel='reviseType'
                                                    onChange={(e: any) => selectReviseTypeId(e.target.value)}
                                                    value={reviseTypeId}
                                                    // isValid={addPayRunForm.isValid}
                                                    // onBlur={addPayRunForm.handleBlur}
                                                    // isTouched={addPayRunForm.touched.paymentStatusId}
                                                    // invalidFeedback={addPayRunForm.errors.paymentStatusId}
                                                    list={reviseTypeData.map((data: any) => (
                                                        { value: data.reviseTypeId, text: data.reviseType }
                                                    ))}
                                                />
                                            </FormGroup>
                                        </div>

                                        {reviseTypeId == 1 ?
                                            <div className='col-3'>
                                                <FormGroup id='amountInCtc' label='Increment Amount' isFloating>
                                                    <Input
                                                        onInput={(e: any) => addAmountOrPercentage(e.target.value, 'amount')}
                                                        value={amountInCtc}
                                                    // onBlur={addPayRunForm.handleBlur}
                                                    // isValid={addPayRunForm.isValid}
                                                    // isTouched={addPayRunForm.touched.amount}
                                                    // invalidFeedback={addPayRunForm.errors.amount}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                        {reviseTypeId == 2 ?
                                            <div className='col-3'>
                                                <InputGroup>
                                                    <FormGroup
                                                        id='percentInCTC'
                                                        label='Increment Percentage'
                                                        isFloating>
                                                        <Input
                                                            onInput={(e: any) => addAmountOrPercentage(e.target.value, 'percent')}
                                                            value={percentInCTC}
                                                        // onBlur={addPayRunForm.handleBlur}
                                                        // isValid={addPayRunForm.isValid}
                                                        // isTouched={addPayRunForm.touched.amount}
                                                        // invalidFeedback={addPayRunForm.errors.amount}
                                                        />
                                                    </FormGroup>
                                                    <InputGroupText>%</InputGroupText>
                                                </InputGroup>
                                            </div> : null}

                                        {/* {reviseTypeId != "" ?
                                            <div className='col-4 fw-bold align-self-center'>
                                                <Checks
                                                    name='flexRadioDefault'
                                                    id='flexRadioDefault2'
                                                    label={' Select All Staff To PF Contribution'}
                                                    value={isConsiderForPfContribution == true ? 1 : 0}
                                                    onChange={(e: any) =>
                                                        selectAllPFContribution(e)
                                                    }
                                                    checked={isConsiderForPfContribution}
                                                />
                                            </div> : null} */}
                                    </div>

                                    <table className='table table-modern table-hover text-nowrap mt-3'>
                                        <thead>
                                            <tr>
                                                <th scope='col'>
                                                    <Checks
                                                        onChange={(e: any) => { selectAllStudents(e.target.checked) }}
                                                        checked={isCheckAll}
                                                    />
                                                </th>
                                                <th
                                                    scope='col'
                                                    onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline text-nowrap'>
                                                    S.No{' '}
                                                    <Icon
                                                        size='lg'
                                                        className={getClassNamesFor('sno')}
                                                        icon='FilterList'
                                                    />
                                                </th>
                                                <th
                                                    scope='col'
                                                    onClick={() => requestSort('staffName')}
                                                    className='cursor-pointer text-decoration-underline text-nowrap'>
                                                    Staff Name{' '}
                                                    <Icon
                                                        size='lg'
                                                        className={getClassNamesFor(
                                                            'staffName',
                                                        )}
                                                        icon='FilterList'
                                                    />
                                                </th>
                                                <th
                                                    scope='col'
                                                    onClick={() => requestSort('userType')}
                                                    className='cursor-pointer text-decoration-underline text-nowrap'>
                                                    User Type{' '}
                                                    <Icon
                                                        size='lg'
                                                        className={getClassNamesFor(
                                                            'userType',
                                                        )}
                                                        icon='FilterList'
                                                    />
                                                </th>
                                                <th>From Annual CTC</th>
                                                {/* <th>Is Consider For Pf</th> */}
                                                <th>To Annual CTC</th>
                                                <th>Total Annual CTC</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData != '' ? (
                                                <>
                                                    {filteredData.map((rowData: any) => (
                                                        <tr key={rowData.staffDetailsId}>
                                                            <td>
                                                                <Checks
                                                                    value={rowData.staffDetailsId}
                                                                    checked={rowData.isSelect}
                                                                    onChange={(e: any) =>
                                                                        updateStaffDetailsIdValue(
                                                                            e.target.checked,
                                                                            rowData,
                                                                        )
                                                                    }
                                                                />
                                                            </td>
                                                            <td>{rowData.sno}</td>
                                                            <td>{rowData.empNumber} - {rowData.staffName}</td>
                                                            <td>{rowData.userType}</td>
                                                            <td>{rowData.fromAnnualCTC}</td>
                                                            {/* <td><Checks className="border border-primary"
                                                                value={rowData.isConsiderForPfContribution ? 1 : 0}
                                                                checked={rowData.isConsiderForPfContribution}
                                                                disabled={rowData.isEditable}
                                                                onChange={(e: any) =>
                                                                    updateStaffPfContributionValue(
                                                                        e.target.checked,
                                                                        rowData.staffDetailsId
                                                                    )
                                                                }
                                                            />
                                                            </td> */}
                                                            {reviseTypeId != 2 ?
                                                                <td scope='col'>
                                                                    <FormGroup id='toAnnualCTC'>
                                                                        <Input
                                                                            className='w-80'
                                                                            placeholder=''
                                                                            value={rowData.toAnnualCTC}
                                                                            disabled={rowData.isEditable}
                                                                            onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                                            onInput={(e: any) =>
                                                                                updateToAnnualValue(
                                                                                    e.target
                                                                                        .value,
                                                                                    rowData.staffDetailsId, rowData.fromAnnualCTC, rowData.salaryTemplateId
                                                                                )
                                                                            }
                                                                        />
                                                                    </FormGroup>
                                                                </td> :
                                                                <td scope='col'>
                                                                    <FormGroup id='percentInCTC'>
                                                                        <InputGroup>
                                                                            <Input
                                                                                className='w-80'
                                                                                placeholder=''
                                                                                value={rowData.toAnnualCTC}
                                                                                disabled={rowData.isEditable}
                                                                                onKeyDown={(e: any) => onlyAllowNumber(e)}
                                                                                onInput={(e: any) =>
                                                                                    updateToAnnualValue(
                                                                                        e.target
                                                                                            .value,
                                                                                        rowData.staffDetailsId, rowData.fromAnnualCTC, rowData.salaryTemplateId
                                                                                    )
                                                                                }
                                                                            />
                                                                            <InputGroupText>%</InputGroupText>
                                                                        </InputGroup>
                                                                    </FormGroup>
                                                                </td>}
                                                            <td>{rowData.totalAnnualCTC}</td>
                                                        </tr>
                                                    ))}
                                                </>
                                            ) : (
                                                <NoDataMsg
                                                    columnsCount={6}
                                                    msg={noDataMsg}
                                                />
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </CardBody>
                        </Card>
                    </>}
            </Page>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />

        </PageWrapper >
    );
};

export default StaffRevisedSalaryDetailsBulk;