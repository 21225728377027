import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getWorkingDayList = (year: any, month: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getWorkingDayList/${getLicenseKey}/${year}/${month}`,
        response => {
            success(response)
        },
        error => {
            failure("getWorkingDayList - " + error.message)
            console.log('error caught in service : getWorkingDayList')
        },
    );

    export const addWorkingDay = (workingDayPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addWorkingDay`, workingDayPostData,
        response => {
            success(response)
        },
        error => {
            failure("addWorkingDay - " + error.message)
            console.log('error caught in service : addWorkingDay')
        },
    );