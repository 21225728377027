import React, { useContext, useEffect, useRef, useState } from 'react';
import {
	getEmployeeLeaveDetailsList,
	getEmployeeList,
	getLeaveStatus,
} from '../../services/employee.service';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Collapse from '../bootstrap/Collapse';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Select from '../bootstrap/forms/Select';
import Button, { ButtonGroup } from '../bootstrap/Button';
import AuthContext from '../../contexts/authContext';
import OpenCardComponent from '../../common/components/OpenCardComponents';
import { useReactToPrint } from 'react-to-print';
import Icon from '../icon/Icon';
import Input from '../bootstrap/forms/Input';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../services/export.service';
import {
	getColumnsForDataTable,
	updateColumnsForDataTable,
	updateFilter,
} from '../../services/common.service';
import NoDataMsg from '../../common/components/NoDataMsg';
import { TableLoader, showLoader } from '../../services/loader.services';
import useSortableData from '../../hooks/useSortableData';
import PaginationButtons, { dataPagination } from '../PaginationButtons';
import { useFormik } from 'formik';
import { getLicenseKey } from '../../services/application.settings';
import { toasts } from '../../services/toast.service';
import SearchableSelect from '../../common/components/SearchableSelect';

const EmployeeLeaveDetails = () => {
	const { userAccountId, userTypeId} = useContext(AuthContext);
	const componentRef = useRef(null);

	useEffect(() => {
		getEmployeeDetailsList();
		getLeaveStatusByList();
		// getEmployeeLeaveDetails(userAccountId == 1 ? 0 : userAccountId, 0);
	}, []);

	//search function
	const columnVisibileForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => {},
		validateOnChange: false,
		onSubmit: () => {},
	});

	const [employeeListData, setEmployeeListData] = useState<any>([]);
	const [leaveStatusData, setLeaveStatusData] = useState([]);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [columnDataById, setColumnDataById] = useState([]);
	const [employeeLeaveDetailsData, setEmployeeLeaveDetailsData] = useState<any>([]);

	// For Table
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(50);
	const { items, requestSort, getClassNamesFor } = useSortableData(employeeLeaveDetailsData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [isLoader, setIsLoader] = useState(false);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [isChecked, setIsChecked] = useState(false);

	const [leaveStatusId, setLeaveStatusId] = useState<any>('');
	const [employeeDetailsId, setEmployeeDetailsId] = useState<any>('');
	const [noDataMsg, setNoDataMsg] = useState('');
	const [dataStatus, setDataStatus] = useState(false);

	// Filtered Data
	const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibileForm);

	function viewEmployeeLeaveDetailsList() {
		let employeeId = userAccountId == 1 ? employeeDetailsId?.value : userAccountId;
		getEmployeeLeaveDetails(employeeId, leaveStatusId?.value);
	}

	function getEmployeeLeaveDetails(employeeDetailsId: any, leaveStatusId: any) {
		setDataStatus(false);
		setIsLoader(true);
		getEmployeeLeaveDetailsList(
			employeeDetailsId,
			leaveStatusId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.employeeLeaveDetailsList;
					if (data != undefined) {
						setDataStatus(true);
						setEmployeeLeaveDetailsData(data);
						setIsLoader(false);
						setIsOpenListCard(false);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
							data[i].statusName = (
								<div>
									<Button
										isLink
										color={
											data[i].statusName == 'Approved'
												? 'success'
												: data[i].statusName == 'Requested'
													? 'warning'
													: 'danger'
										}
										icon='Circle'
										className='text-nowrap'>
										{data[i].statusName}
									</Button>
								</div>
							);
						}
						setDataStatus(true);
						getColumnsForDataTableList('getEmployeeLeaveDetailsList', 'get');
					} else {
						setDataStatus(true);
						toasts('Undefined Data', 'Error');
						setIsLoader(false);
					}
				} else if (response.data.success === false) {
					getColumnsForDataTableList('getEmployeeLeaveDetailsList', 'get');
					setEmployeeLeaveDetailsData([]);
					setNoDataMsg(response.data.message);
					setIsLoader(false);
					setDataStatus(true);
					setIsOpenListCard(false);
					// toasts(response.data.message, 'Error');
				} else {
					setDataStatus(true);
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
					setIsLoader(false);
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getEmployeeDetailsList() {
		getEmployeeList(
			1,
			2,
			0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.employeeList;
					if (data != undefined) {
						setEmployeeListData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getLeaveStatusByList() {
		getLeaveStatus(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.leaveStatus;
					if (data != undefined) {
						const leaveStatusData: any = [
							{ leaveStatusId: 0, statusName: 'Select All' },
						];
						data.forEach((obj: { leaveStatusId: number; statusName: string }) => {
							leaveStatusData.push(obj);
						});
						setLeaveStatusData(leaveStatusData);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getColumnsForDataTableList(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						let keyData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData);

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => !item.isDefault,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const updateColumnsSubmit = (columnVisibilityId: any, isDisplay: any, type: any) => {
		if (columnVisibileForm.isValid) {
			setColumnDataById(isDisplay);
			const postData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
							{
								columnVisibilityId: columnVisibilityId,
								isDisplay: isDisplay ? 0 : 1,
							},
						],
			};

			updateColumnsForDataTable(
				postData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForDataTableList('getEmployeeLeaveDetailsList', 'get');
					} else if (data.success == false) {
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibileForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	};

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<PageWrapper title='Employee Leave Details'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card stretch data-tour='list'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Employee Leave Details
								</CardTitle>
							</CardLabel>
						</CardHeader>
						<CardBody>
							<div className='row g-4'>
								{userAccountId == 1 ? (
									<div className='col-3'>
										<FormGroup
											id='employeeDetailsId'
											label='Employee Name'
											isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Employee Name'
												onChange={(e: any) => setEmployeeDetailsId(e)}
												value={employeeDetailsId}
												list={employeeListData.map((data: any) => ({
													value: data.employeeDetailsId,
													label: data.employeeFullName,
												}))}
											/>
										</FormGroup>
									</div>
								) : null}
								{employeeDetailsId || userAccountId != 1 ? (
									<div className='col-3'>
										<FormGroup
											id='leaveStatusId'
											label='Leave Status'
											isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Leave Status'
												onChange={(e: any) => setLeaveStatusId(e)}
												value={leaveStatusId}
												list={leaveStatusData.map((data: any) => ({
													value: data.leaveStatusId,
													label: data.statusName,
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{leaveStatusId ? (
									<div className='col-2 mt-4 d-flex align-self-center'>
										<Button
											icon='ArrowDownward'
											color='primary'
											isDisable={leaveStatusId?.value == undefined}
											onClick={viewEmployeeLeaveDetailsList}>
											View
										</Button>
									</div>
								) : null}
							</div>
						</CardBody>
					</Card>
				</Collapse>

				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>

				{employeeLeaveDetailsData != '' ? (
					<Card stretch data-tour='list' ref={componentRef} id='pdf'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Employee Leave Details List
								</CardTitle>
							</CardLabel>
							<CardActions className='d-print-none'>
								<ButtonGroup color='primary'>
									<Icon
										className='mt-1'
										icon='Search'
										size='2x'
										color='primary'
									/>
									<Input
										id='searchInput'
										type='search'
										placeholder='Search...'
										onChange={columnVisibileForm.handleChange}
										value={columnVisibileForm.values.searchInput}
									/>
								</ButtonGroup>
								<Dropdown
									isOpen={columnVisibilityMenu}
									setIsOpen={setColumnVisibilityMenu}
									isButtonGroup>
									<DropdownToggle>
										<Button icon='FilterAlt' color='primary' isLight>
											Filter
										</Button>
									</DropdownToggle>
									<DropdownMenu
										isAlignmentEnd
										size='lg'
										isCloseAfterLeave={false}>
										<DropdownItem>
											<div className='container py-2'>
												<form className='row g-3'>
													<div className='col-12'>
														<FormGroup>
															<h6>Select All</h6>
															<Checks
																id='available'
																type='switch'
																label='Select All Columns'
																onChange={() =>
																	getColumnsForDataTableList(
																		'getEmployeeLeaveDetailsList',
																		'post',
																	)
																}
																checked={isChecked}
																ariaLabel='Available status'
															/>
														</FormGroup>
													</div>
													<div className='col-12'>
														<FormGroup>
															<h6>Columns</h6>
															<ChecksGroup>
																{allColumnsData.map((i: any) => (
																	<Checks
																		key={i.columnVisibilityId}
																		id={i.columnVisibilityId}
																		label={i.columnName}
																		onChange={() =>
																			updateColumnsSubmit(
																				i.columnVisibilityId,
																				i.isDisplay,
																				'',
																			)
																		}
																		checked={i.isDisplay}
																		disabled={i.isDefault}
																	/>
																))}
															</ChecksGroup>
														</FormGroup>
													</div>
												</form>
											</div>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
								<Dropdown isButtonGroup>
									<DropdownToggle>
										<Button color='primary' isLight icon='CloudDownload'>
											{' '}
											Export{' '}
										</Button>
									</DropdownToggle>
									<DropdownMenu isAlignmentEnd>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadExcel(
														'EmployeeLeaveDetails',
														employeeLeaveDetailsData,
														columnVisibilityData,
													)
												}>
												{' '}
												Excel{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='PictureAsPdf'
												onClick={() =>
													convertJsonToPdf(
														employeeLeaveDetailsData,
														columnVisibilityData,
														'EmployeeLeaveDetails',
													)
												}>
												{' '}
												PDF
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='CloudDownload'
												onClick={() =>
													downloadFile(
														'EmployeeLeaveDetails',
														employeeLeaveDetailsData,
														columnVisibilityData,
													)
												}>
												{' '}
												CSV{' '}
											</Button>
										</DropdownItem>
										<DropdownItem>
											<Button
												color='primary'
												isLight
												icon='Print'
												onClick={() => handlePrint()}>
												{' '}
												Print{' '}
											</Button>
										</DropdownItem>
									</DropdownMenu>
								</Dropdown>
							</CardActions>
						</CardHeader>
						<CardBody className='table-responsive' isScrollable>
							{!dataStatus ? (
								<TableLoader />
							) : (
								<table className='table table-modern table-hover'>
									<thead>
										<tr>
											<th
												scope='col'
												onClick={() => requestSort('sno')}
												className='cursor-pointer text-decoration-underline'>
												S.No{' '}
												<Icon
													size='lg'
													className={getClassNamesFor('sno')}
													icon='FilterList'
												/>
											</th>
											{columnVisibilityData.map((column: any) => (
												<th
													key={column.keyName}
													scope='col'
													onClick={() => requestSort(column.keyName)}
													className='cursor-pointer text-decoration-underline'>
													{column.columnName}
													<Icon
														size='lg'
														className={getClassNamesFor(column.keyName)}
														icon='FilterList'
													/>
												</th>
											))}
											<td className='d-print-none' />
										</tr>
									</thead>
									<tbody>
										{filteredData != '' ? (
											<>
												{filteredData.map((rowData: any) => (
													<tr key={rowData.employeeLeaveDetailsId}>
														<td width={'1%'}>{rowData.sno}</td>
														{columnVisibilityData.map((column: any) =>
															column.isDisplay &&
															rowData[column.keyName] ? (
																<td
																	key={`${rowData.leaveTypeId}-${column.keyName}`}>
																	{rowData[column.keyName]}
																</td>
															) : (
																<td
																	key={`empty-${column.keyName}`}
																/>
															),
														)}
													</tr>
												))}
											</>
										) : (
											<NoDataMsg
												columnsCount={columnVisibilityData.length + 3}
												msg={noDataMsg}
											/>
										)}
									</tbody>
								</table>
							)}
						</CardBody>

						<PaginationButtons
							className='d-print-none'
							data={items}
							label='items'
							setCurrentPage={setCurrentPage}
							currentPage={currentPage}
							perPage={perPage}
							setPerPage={setPerPage}
						/>
					</Card>
				) : (
					<NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />
				)}
			</Page>
		</PageWrapper>
	);
};

export default EmployeeLeaveDetails;
