import React, { FC, useState } from 'react'
import Modal, { ModalBody, ModalFooter } from '../../components/bootstrap/Modal'
import FormGroup from '../bootstrap/forms/FormGroup';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import showNotification from '../extras/showNotification';
import Icon from '../icon/Icon';
import { useFormik } from 'formik';
import { CardFooter } from '../bootstrap/Card';
import Button from '../bootstrap/Button';
import Input from '../bootstrap/forms/Input';
import Tooltips from '../bootstrap/Tooltips';
import Select from '../bootstrap/forms/Select';


interface IModalProps {
  isDownload: boolean;
  title: any;
  setIsDownload(...args: unknown[]): unknown;
}
const Download: FC<IModalProps> = ({ setIsDownload, isDownload, title }) => {
  const navigate = useNavigate();
  const [lastSave, setLastSave] = useState<Dayjs | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [modal, setModal] = useState(false);
  const handleSave = () => {
    setLastSave(dayjs());
    setIsLoading(false);
    showNotification(
      <span className='d-flex align-items-center'>
        <Icon icon='Info' size='lg' className='me-1' />
        <span>Updated Successfully</span>
      </span>,
      "The user's account details have been successfully updated.",
    );
  };

  const designationDownload = useFormik({
    initialValues: {
      encoding: '/',
      delimiter: 'Comma (,)'
    },
    validate: (values) => {
      const errors: {

        encoding?: string;
        delimiter?: string;
      } = {};

      if (!values.encoding) {
        errors.encoding = 'Required';
      }
      if (!values.delimiter) {
        errors.delimiter = 'Required';
      }

      return errors;
    },
    onSubmit: () => {
      setIsLoading(true);
      setTimeout(handleSave, 2000);
    },
  });

  

  return (
    <>

      <Modal isOpen={isDownload} setIsOpen={setIsDownload} titleId='addAcademicPeriod' isCentered isAnimation={true} size={'lg'}>
        <></>
        <ModalBody>
          <div className="row">
            <h5 className='h5 text-start fs-5 col-lg-6'>
              <b>{title} - Select File</b></h5>
          </div>
          <hr />
          <div className='col-12'>
            <p className='mt-4'>Download a
              <Button
                color='primary'
                isLink
                tag='a'
                to='/somefile.txt'
                target='_blank'
                download
              >
                <span className='text-primary'>sample file</span>
              </Button>
              and compare it with your import file to ensure that the file is ready to import.
            </p>
            <div className='row g-4 align-items-center '>
              <div className='col-lg '>
                <div className='row g-4 align-items-center ms-2 mt-2'>
                  <div className='col-9 align-items-center mt-2'>
                    <Input
                      type='file'
                      autoComplete='photo'
                      ariaLabel='Upload image file'
                    />
                    <p className='text-center mt-2 text-muted'>Drop files here or click here to upload</p>
                    <p className='mt-2 text-muted text-center'>Maximum File Size: 5 MB | File Format: CSV or TSV or XLS</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row  mt-2'>
            <div className='col-md-6'>
              <div className="row">
                <h5 className='h6 text-start fs-6 col-lg-6'><Tooltips placement='right' title='By default the character encoding is UTF-8(Unicode). Ensure you have selected the correct character encoding based on the import file.'><b>Character Encoding <span className='text-danger'>*</span>
                </b></Tooltips></h5>
              </div>

              <FormGroup id='encoding' isFloating>
                <Select
                  ariaLabel='Field Separator'

                  list={[
                    { value: 'UTF-8 (Unicode)', text: 'UTF-8 (Unicode)' },
                    { value: 'UTF-16 (Unicode)', text: 'UTF-16 (Unicode)' },
                    { value: 'ISO-8859-1', text: 'ISO-8859-1' },
                    { value: 'GB2312 (Simplified Chinese)', text: 'GB2312 (Simplified Chinese)' },
                  ]}
                  onChange={designationDownload.handleChange}
                  onBlur={designationDownload.handleBlur}
                  value={designationDownload.values.encoding}
                  isValid={designationDownload.isValid}
                  isTouched={designationDownload.touched.encoding}
                  invalidFeedback={designationDownload.errors.encoding}
                />
              </FormGroup>
              <div className="row">
                <h5 className='h6 text-start fs-6 col-lg-6 mt-4'><Tooltips placement='right' title='By default the character encoding is UTF-8(Unicode). Ensure you have selected the correct character encoding based on the import file.'><b>File Delimiter<span className='text-danger'>*</span></b></Tooltips></h5>
              </div>
              <FormGroup id='delimiter' isFloating>
                <Select
                  ariaLabel='File Delimiter'

                  list={[
                    { value: 'Comma (,)', text: 'Comma (,)' },
                    { value: 'Semi-Colon (;)', text: 'Semi-Colon (;)' },
                  ]}
                  onChange={designationDownload.handleChange}
                  onBlur={designationDownload.handleBlur}
                  value={designationDownload.values.delimiter}
                  isValid={designationDownload.isValid}
                  isTouched={designationDownload.touched.delimiter}
                  invalidFeedback={designationDownload.errors.delimiter}
                />
              </FormGroup>
            </div>
          </div>
        </ModalBody>
        <CardFooter borderSize={1}>
          <div className='col-md-1'>
            <Button color='info' type="submit" isDisable>Next</Button>
          </div>
          <div className='col-md-1'>
            <Button isOutline color='info' isLight onClick={() => setIsDownload(false)} > Cancel</Button>
          </div>

          <div className='col-10 text-end'>
            <p className='text-danger'>* indicates mandatory fields</p>
          </div>
        </CardFooter>
      </Modal >
    </>
  )
}

export default Download