import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import NoDataMsg from "../../../common/components/NoDataMsg";
import OpenCardComponent from "../../../common/components/OpenCardComponents";
import SearchableSelect from "../../../common/components/SearchableSelect";
import AuthContext from "../../../contexts/authContext";
import useSortableData from "../../../hooks/useSortableData";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import AlertService from "../../../services/AlertService";
import { getLicenseKey } from "../../../services/application.settings";
import { getCurrentDateAndTime, updateFilter, getColumnsForDataTable, updateColumnsForDataTable, onlyAllowNumber } from "../../../services/common.service";
import { showLoader } from "../../../services/loader.services";
import { getSalaryTemplateList, getStaffRevisedSalaryDetails, updateRevisedSalaryDetails } from "../../../services/salary.service";
import { getStaffListByUserTypeId } from "../../../services/staff.service";
import { toasts } from "../../../services/toast.service";
import { getUserType } from "../../../services/userType.service";
import PaginationButtons, { dataPagination } from "../../PaginationButtons";
import Button, { ButtonGroup } from "../../bootstrap/Button";
import Card, { CardHeader, CardLabel, CardTitle, CardActions, CardBody } from "../../bootstrap/Card";
import Collapse from "../../bootstrap/Collapse";
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from "../../bootstrap/Dropdown";
import OffCanvas, { OffCanvasHeader, OffCanvasTitle, OffCanvasBody } from "../../bootstrap/OffCanvas";
import Checks, { ChecksGroup } from "../../bootstrap/forms/Checks";
import FormGroup from "../../bootstrap/forms/FormGroup";
import InputGroup, { InputGroupText } from "../../bootstrap/forms/InputGroup";
import Textarea from "../../bootstrap/forms/Textarea";
import Icon from "../../icon/Icon";
import Input from "../../bootstrap/forms/Input";
import { useNavigate } from "react-router-dom";


const StaffRevisedSalaryDetails = () => {

	const { userAccountId, userTypeId, organisationDetailsId } = useContext(AuthContext);
	const [revisedTypeId, setRevisedTypeId] = useState('1');

	useEffect(() => {
		getUserTypeList();
	}, []);

	const columnVisibilityForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		validateOnChange: false,
		onSubmit: () => { },
	});

	const addSalaryReviseForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			staffSalaryReviseId: 0,
			fromCtcAmount: '',
			toCtcAmount: '',
			effectiveDate: getCurrentDateAndTime('date'),
			staffDetailsId: '',
			remarks: '',
			flatAmount: '',
			percentage: '',
			salaryTemplateId: '',
		},
		validate: (values) => {
			const errors: {
				staffDetailsId?: string;
				staffSalaryReviseId?: string;
				toCtcAmount?: string;
				effectiveDate?: string;
				flatAmount?: string;
				percentage?: string;
				salaryTemplateId?: string;
			} = {};
			// if (toCtcAmount == '') {
			// 	errors.toCtcAmount = 'Required';
			// }
			if (salaryTemplateId == '') {
				errors.salaryTemplateId = 'Required';
			}
			if (!values.effectiveDate) {
				errors.effectiveDate = 'Required';
			}
			if (revisedTypeId == "1") {
				if (amountInCtc == "") {
					errors.flatAmount = 'Required';
				}
			} else if (revisedTypeId == "2") {
				if (percentInCTC == "") {
					errors.percentage = 'Required';
				}
			}
			// console.log(errors);

			return errors;
		},
		// validateOnChange: true,
		onSubmit: () => { updateRevisedSalaryDetailsSubmit() },
	});

	const [userTypeData, setUserTypeData] = useState<any>([]);
	const [staffDetailsData, setStaffDetailsData] = useState<any>([]);
	const [staffSalaryData, setStaffSalaryData] = useState<any>([]);
	const [staffDetailsId, setStaffDetailsId] = useState<any>('');
	const [staffTypeId, setStaffTypeId] = useState<any>('');
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [allColumnsData, setAllColumnsData] = useState([]);
	const [isChecked, setIsChecked] = useState(false);
	const [columnDataById, setColumnDataById] = useState([]);

	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(staffSalaryData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [noDataMsg, setNoDataMsg] = useState('');
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);

	const [addSalaryReviseCanvas, setAddSalaryReviseCanvas] = useState(false);
	const [fromAnnualCTC, setFromAnnualCTC] = useState<any>('');
	const [amountInCtc, setAmountInCtc] = useState<any>('');
	const [percentInCTC, setPercentInCTC] = useState<any>('');
	const [toCtcAmount, setToCtcAmount] = useState<any>(0);
	// const [isConsiderForPfContribution, setIsConsiderForPfContribution] = useState(false);
	const [salaryTemplateId, setSalaryTemplateId] = useState<any>('');
	const [salaryTemplateData, setSalaryTemplateData] = useState<any>([]);

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);
	const [showCTCInput, setShowCTCInput] = useState(true);
	const navigate = useNavigate();

	const filteredData = updateFilter(
		onCurrentPageData,
		columnVisibilityData,
		columnVisibilityForm,
	);

	const selectUserType = (e: any) => {
		setStaffDetailsId('')
		setDataSuccess(false)
		setIsOpenListCard(true)
		let userTypeId = e;
		setStaffTypeId(userTypeId);
		if (userTypeId?.value != undefined) {
			getStaffListByUserType(userTypeId?.value);
		}
	};

	const selectStaffDetailsId = (e: any) => {
		setDataSuccess(false)
		setIsOpenListCard(true)
		let staffDetailsId = e;
		setStaffDetailsId(staffDetailsId);
	};

	const ViewSalaryList = () => {
		columnVisibilityForm.resetForm()
		setDataSuccess(false);
		setIsLoader(true);
		getStaffRevisedSalaryList(staffDetailsId?.value);
	};

	function openAddStaffSalary() {
		getSalaryTemplate(organisationDetailsId, 0)
		setAddSalaryReviseCanvas(true)
	}


	function onActivate(i: any) {
		if (i != undefined) {
			let selectedRevisedSalary = i
			setFromAnnualCTC(selectedRevisedSalary.fromAnnualCTC)
			setToCtcAmount(selectedRevisedSalary.toAnnualCTC)
			setShowCTCInput(false)
			// setIsConsiderForPfContribution(selectedRevisedSalary.isConsiderForPfContribution)
			setAmountInCtc(selectedRevisedSalary.flatAmount);
			setPercentInCTC(selectedRevisedSalary.percentage);
			setRevisedTypeId(selectedRevisedSalary.percentage > 0 ? "2" : "1")
			setSalaryTemplateId({ value: selectedRevisedSalary.salaryTemplateId, label: selectedRevisedSalary.templateName });

			addSalaryReviseForm.setValues({
				staffDetailsId: selectedRevisedSalary.staffDetailsId,
				staffSalaryReviseId: selectedRevisedSalary.staffSalaryReviseId,
				fromCtcAmount: selectedRevisedSalary.fromAnnualCTC,
				toCtcAmount: selectedRevisedSalary.toAnnualCTC,
				effectiveDate: selectedRevisedSalary.effectiveToAnnualCTC,
				remarks: selectedRevisedSalary.remarks,
				flatAmount: selectedRevisedSalary.flatAmount,
				percentage: selectedRevisedSalary.percentage,
				salaryTemplateId: selectedRevisedSalary.salaryTemplateId,
			});
			getSalaryTemplate(organisationDetailsId, 0)
			setAddSalaryReviseCanvas(true)
		}
	}

	function getUserTypeList() {
		getUserType(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.userType;
					if (data != undefined) {
						setUserTypeData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setUserTypeData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStaffRevisedSalaryList(staffDetailsId: any) {
		getStaffRevisedSalaryDetails(
			staffDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffRevisedSalaryDetails;
					if (data != undefined) {
						getColumnsForTable('getStaffRevisedSalaryDetails', 'get');
						setStaffSalaryData(data);
						setFromAnnualCTC(data[0].toAnnualCTC)
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
						}
						setIsLoader(false);
						setDataSuccess(true);
						setIsOpenListCard(false);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setStaffSalaryData([]);
					getColumnsForTable('getStaffRevisedSalaryDetails', 'get');
					setIsLoader(false);
					setDataSuccess(true);
					setIsOpenListCard(false);
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getStaffListByUserType(userTypeId: any) {
		getStaffListByUserTypeId(
			userTypeId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.staffListByUserTypeId;
					if (data != undefined) {
						setStaffDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setStaffDetailsData([]);

				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getColumnsForTable(apiName: any, type: any) {
		getColumnsForDataTable(userTypeId, apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData)
						let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
						setColumnVisibilityData(columnVisibilityData)

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						}
						else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

							for (let i = 0; i < nonDefaultValue.length; i++) {

								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
								}
								all.push(obj);
							}
							updateColumnsSubmit('', '', all)
						}
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.message, "warning")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)

	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

		if (columnVisibilityForm.isValid) {

			setColumnDataById(isDisplay)
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type ? type : [
					{
						columnVisibilityId: columnVisibilityId,
						isDisplay: isDisplay ? 0 : 1
					}
				]
			}

			updateColumnsForDataTable(columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForTable('getStaffRevisedSalaryDetails', 'get');
					}
					else if (data.success == false) {
						// toasts(data.success, "Error")
					}
					else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, "Error")
					}
				}
				, (error) => {
					toasts(error, "Error")
				}
			)
		} else if (columnVisibilityForm.isValid == false) {
			toasts("Please fill all the details!", "warning")
		}
	}

	function setRevisedSalaryDetails() {
		return ({
			staffDetailsId: staffDetailsId?.value,
			fromCtcAmount: fromAnnualCTC,
			toCtcAmount: toCtcAmount,
			effectiveDate: addSalaryReviseForm.values.effectiveDate,
			flatAmount: amountInCtc != "" ? amountInCtc : 0,
			percentage: percentInCTC != "" ? percentInCTC : 0,
			staffSalaryReviseId: addSalaryReviseForm.values.staffSalaryReviseId,
			remarks: addSalaryReviseForm.values.remarks,
			salaryTemplateId: salaryTemplateId?.value,
			// isConsiderForPfContribution: isConsiderForPfContribution == true ? 1 : 0,
			licenseKey: getLicenseKey,
			revisedBy: userAccountId
		})
	}

	function updateRevisedSalaryDetailsSubmit() {
		showLoader(true);
		if (addSalaryReviseForm.isValid) {
			let revisedSalaryPostData = setRevisedSalaryDetails()
			updateRevisedSalaryDetails(revisedSalaryPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						getStaffRevisedSalaryList(staffDetailsId?.value)
						closeAndResetForm();
					}
					else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "warning" });
						setIsOpen(true);
					}
					else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: "error" });
						setIsOpen(true);
					}
				}
				, (error) => {
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
				}
			)

		} else if (addSalaryReviseForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
		}
	}

	function getSalaryTemplate(organisationDetailsId: any, salaryTemplateId: any) {
		getSalaryTemplateList(organisationDetailsId, salaryTemplateId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.salaryTemplateList;
					if (data != undefined) {
						setSalaryTemplateData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					setSalaryTemplateData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function closeAndResetForm() {
		addSalaryReviseForm.resetForm()
		setFromAnnualCTC(staffSalaryData != '' ? staffSalaryData[0].toAnnualCTC : 0)
		setSalaryTemplateId(staffSalaryData != '' ? { value: staffSalaryData[0].salaryTemplateId, label: staffSalaryData[0].templateName } : '')
		setAddSalaryReviseCanvas(false)
		resetForm()
	}

	function resetForm() {
		setPercentInCTC('')
		setAmountInCtc('')
		setToCtcAmount(0)
		setRevisedTypeId('1')

		setShowCTCInput(true)
		// setIsConsiderForPfContribution(false)
	}

	return (
		<PageWrapper title='Staff Salary Increment'>
			<Page container='fluid'>
				<Collapse isOpen={isOpenListCard}>
					<Card stretch data-tour='list'>
						<CardHeader borderSize={1}>
							<CardLabel icon='List' iconColor='info'>
								<CardTitle tag='div' className='h5'>
									Staff Salary Increment Details
								</CardTitle>
							</CardLabel>
							<CardActions>
								<Button
									className='mt-2'
									icon='ArrowDownward'
									color='primary' onClick={() => navigate(`../salary/staffRevisedSalaryDetailsBulk`)}>
									Bulk Upload
								</Button>
							</CardActions>
						</CardHeader>
						<CardBody>
							<div className='row'>
								<div className='col-3'>
									<FormGroup id='userTypeId' label='User Type' isFloating>
										<SearchableSelect
											isFloating
											ariaLabel='User Type'
											placeholder='Select User Type'
											onChange={selectUserType}
											value={staffTypeId}
											list={userTypeData.map((data: any) => ({
												value: data.userTypeId,
												label: data.userType,
											}))}
										/>
									</FormGroup>
								</div>

								{staffTypeId?.value != undefined ? (
									<div className='col-4'>
										<FormGroup id='staffDetailsId' label='Staff' isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Staff'
												placeholder='Select Staff'
												onChange={(e: any) => selectStaffDetailsId(e)}
												value={staffDetailsId}
												list={staffDetailsData.map((data: any) => ({
													value: data.staffDetailsId,
													label: data.empAndStaffName,
												}))}
											/>
										</FormGroup>
									</div>
								) : null}

								{staffDetailsId?.value != undefined ? (
									<div className='col-3'>
										<Button
											className='mt-2'
											icon='ArrowDownward'
											color='primary'
											onClick={ViewSalaryList}>
											View
										</Button>
									</div>
								) : null}
							</div>
						</CardBody>
					</Card>
				</Collapse>
				<OpenCardComponent
					isOpenListCard={isOpenListCard}
					setIsOpenListCard={setIsOpenListCard}
					isLoader={isLoader}
				/>
				{dataSuccess ? (
					<>
						<Card stretch data-tour='list' id='pdf'>
							<CardHeader borderSize={1}>
								<CardLabel>
									<CardTitle>
										<Icon icon='FormatIndentIncrease' size='2x' color='primary' />
										&nbsp; Salary Increment List
									</CardTitle>
								</CardLabel>
								<CardActions className='d-print-none'>
									<ButtonGroup color='primary'>
										<Icon className='mt-1' icon='Search' size='2x' color='primary' />
										<Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
									</ButtonGroup>
									<Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
										<DropdownToggle>
											<Button icon='FilterAlt' color='primary' isLight>Filter</Button>
										</DropdownToggle>
										<DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
											<DropdownItem>
												<div className='container py-2'>
													<form className='row g-3'>
														<div className='col-12'>
															<FormGroup>
																<h6>Select All</h6>
																<Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStaffRevisedSalaryDetails', 'post')} checked={isChecked} ariaLabel='Available status' />
															</FormGroup>
														</div>
														<div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
															<FormGroup>
																<h6>Columns</h6>
																<ChecksGroup>
																	{allColumnsData.map((i: any) => (
																		<Checks key={i.columnVisibilityId} label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
																	))}
																</ChecksGroup>
															</FormGroup>
														</div>
													</form>
												</div>
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
									<Button color='primary' icon='Add' isLight onClick={() => { closeAndResetForm(); openAddStaffSalary(); }}>
										Add New
									</Button>
								</CardActions>
							</CardHeader>
							<CardBody className='table-responsive' isScrollable>

								<table className='table table-modern table-hover text-nowrap'>
									<thead>
										<tr>
											<th
												scope='col'
												onClick={() => requestSort('sno')}
												className='cursor-pointer text-decoration-underline'>
												S.No{' '}
												<Icon
													size='lg'
													className={getClassNamesFor('sno')}
													icon='FilterList'
												/>
											</th>
											{columnVisibilityData.map((column: any) => (
												<th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
													className='cursor-pointer text-decoration-underline'>
													{column.columnName}
													<Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
												</th>
											))}
											<th scope='col' className='d-print-none'>
												Action
											</th>
										</tr>
									</thead>
									<tbody>
										{filteredData != "" ?
											<>
												{filteredData.map((rowData: any) => (
													<tr key={rowData.staffSalaryReviseId}>
														<td width={'1%'}>{rowData.sno}</td>
														{columnVisibilityData.map((column: any) => (
															(column.isDisplay && rowData[column.keyName]) ?
																<td key={`${rowData.staffSalaryReviseId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
														))}
														<td>
															<Button color='dark' isLight icon='Edit' aria-label='Edit' onClick={() => { onActivate(rowData) }} />
														</td>
													</tr>
												))
												}</>
											:
											<NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
									</tbody>
								</table>
							</CardBody>
							<PaginationButtons
								className='d-print-none'
								data={items}
								label='items'
								setCurrentPage={setCurrentPage}
								currentPage={currentPage}
								perPage={perPage}
								setPerPage={setPerPage}
							/>
						</Card>
					</>
				) : null}
			</Page>

			<OffCanvas
				setOpen={setAddSalaryReviseCanvas}
				isOpen={addSalaryReviseCanvas}
				titleId='addSalaryReviseCanvas'
				placement='end'
				isBodyScroll
				tag='form'
				noValidate
				onSubmit={addSalaryReviseForm.handleSubmit} isNotClose>
				<OffCanvasHeader setOpen={setAddSalaryReviseCanvas} onClick={closeAndResetForm}>
					<OffCanvasTitle id='addAdvanceSalaryOffCanvas'>Update Salary Increment</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<div className='row g-4'>
						<div className='col-12'>
							<FormGroup
								id='salaryTemplateId'
								label='Salary Templates'>
								<SearchableSelect

									ariaLabel=''
									placeholder='Select Template'
									onChange={(e: any) =>
										setSalaryTemplateId(e)
									}
									value={salaryTemplateId}
									onBlur={addSalaryReviseForm.handleBlur}
									isValid={addSalaryReviseForm.isValid}
									invalidFeedback={
										addSalaryReviseForm.errors.salaryTemplateId
									}
									isTouched={
										addSalaryReviseForm.touched.salaryTemplateId
									}
									list={salaryTemplateData.map(
										(data: any) => ({
											value: data.salaryTemplateId,
											label: data.templateName,
										}),
									)}
								/>
							</FormGroup>
						</div>
						<div className='col-12'>
							<FormGroup id='fromCtcAmount' label='From CTC Amount'>
								<Input
									onChange={addSalaryReviseForm.handleChange}
									value={fromAnnualCTC}
									isValid={addSalaryReviseForm.isValid}
									onBlur={addSalaryReviseForm.handleBlur}
									isTouched={addSalaryReviseForm.touched.fromCtcAmount}
									invalidFeedback={addSalaryReviseForm.errors.fromCtcAmount}
									placeholder='From CTC Amount'
									onKeyDown={(e: any) => onlyAllowNumber(e)}
									disabled
								/>
							</FormGroup>
						</div>

						{!showCTCInput ?
							<div className='col-12'>
								<FormGroup id='toCtcAmount' label='To CTC Amount'>
									<Input
										onChange={addSalaryReviseForm.handleChange}
										value={toCtcAmount}
										isValid={addSalaryReviseForm.isValid}
										onBlur={addSalaryReviseForm.handleBlur}
										isTouched={addSalaryReviseForm.touched.toCtcAmount}
										invalidFeedback={addSalaryReviseForm.errors.toCtcAmount}
										placeholder='0'
										onKeyDown={(e: any) => onlyAllowNumber(e)}
										onInput={(e: any) => setToCtcAmount(e.target.value)}
										disabled
									/>
								</FormGroup>
							</div> : null}

						<div className='col-12'>
							<div className='fw-bold'> Revised Type : </div>
						</div>
						<div className='col-6'>
							<Checks
								type='radio'
								name='flexRadioDefault'
								id='flexRadioDefault1'
								label='CTC In Amount'
								value='1'
								onChange={() => { setRevisedTypeId('1'); setAmountInCtc(''); setPercentInCTC('') }}
								checked={revisedTypeId}
							/>
						</div>
						<div className='col-6'>
							<Checks
								type='radio'
								name='flexRadioDefault'
								id='flexRadioDefault2'
								label={'CTC In Percentage'}
								value='2'
								onChange={() => { setRevisedTypeId('2'); setAmountInCtc(''); setPercentInCTC('') }}
								checked={revisedTypeId}
							/>
						</div>

						{revisedTypeId == '1' ?
							<div className='col-12'>
								<FormGroup id='flatAmount' label='To CTC Amount'>
									<Input
										onChange={addSalaryReviseForm.handleChange}
										value={amountInCtc}
										isValid={addSalaryReviseForm.isValid}
										onBlur={addSalaryReviseForm.handleBlur}
										isTouched={addSalaryReviseForm.touched.flatAmount}
										invalidFeedback={addSalaryReviseForm.errors.flatAmount}
										placeholder='0'
										onKeyDown={(e: any) => onlyAllowNumber(e)}
										onInput={(e: any) => setAmountInCtc(e.target.value)}
									/>
								</FormGroup>
							</div> :
							<div className='col-12'>
								<FormGroup id='percentage' label='To CTC Percentage'>
									<InputGroup>
										<Input
											onChange={addSalaryReviseForm.handleChange}
											value={percentInCTC}
											isValid={addSalaryReviseForm.isValid}
											onBlur={addSalaryReviseForm.handleBlur}
											isTouched={addSalaryReviseForm.touched.percentage}
											invalidFeedback={addSalaryReviseForm.errors.percentage}
											placeholder='0 %'
											onKeyDown={(e: any) => onlyAllowNumber(e)}
											onInput={(e: any) => setPercentInCTC(e.target.value)}
										/>
										<InputGroupText>%</InputGroupText>
									</InputGroup>
								</FormGroup>
							</div>}

						<div className='col-12'>
							<FormGroup id='effectiveDate' label='Effective Date'>
								<Input
									aria-placeholder=''
									onChange={addSalaryReviseForm.handleChange}
									onBlur={addSalaryReviseForm.handleBlur}
									value={addSalaryReviseForm.values.effectiveDate}
									isValid={addSalaryReviseForm.isValid}
									isTouched={addSalaryReviseForm.touched.effectiveDate}
									invalidFeedback={addSalaryReviseForm.errors.effectiveDate}
									type='date'
								/>
							</FormGroup>
						</div>

						{/* <div className='col-12'>
							<div className='fw-bold'>
								<Checks
									className='mt-1'
									type='checkbox'
									label='Consider for EPF Contribution.'
									onChange={() => { setIsConsiderForPfContribution(!isConsiderForPfContribution) }}
									checked={isConsiderForPfContribution}
								/>
							</div>
						</div> */}

						<div className='col-12'>
							<FormGroup id='remarks' label='Remarks'>
								<Textarea
									autoComplete=''
									onChange={addSalaryReviseForm.handleChange}
									onBlur={addSalaryReviseForm.handleBlur}
									value={addSalaryReviseForm.values.remarks}
								// isValid={addSalaryReviseForm.isValid}
								// isTouched={addSalaryReviseForm.touched.remarks}
								// invalidFeedback={addSalaryReviseForm.errors.remarks}
								/>
							</FormGroup>
						</div>

						<div className='col-12'><br /><br /><br /></div>
					</div>
				</OffCanvasBody>
				<div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
					<div className='row m-0'>
						<Button
							color='info'
							icon='Save'
							className='w-100'
							type='submit'
							isDisable={!addSalaryReviseForm.isValid && !!addSalaryReviseForm.submitCount}>
							Save
						</Button>
					</div>
				</div>
			</OffCanvas>

			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

		</PageWrapper>
	);
};

export default StaffRevisedSalaryDetails;