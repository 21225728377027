/// Select style
export const customStyles = (isDarkMode: any) => ({
    control: (provided: any) => ({
        ...provided,
        fontWeight: 600,
        border: 'transparent',
        backgroundColor: 'transparent',
        boxShadow: 'transparent',
        position: 'absolute',
        top: '10px',
        left: '2px',
        width: 'calc(100% - 6px)',
        height: 'calc(100% - 40px)',
        fontSize: '1rem',
        color: isDarkMode ? '#fff' : '#000', // Dynamic text color
    }),
    clearIndicator: (provided: any) => ({
        ...provided,
        paddingLeft: '10px',
        padding: '5px',
        marginTop: '0px',
        height: '5px',
        position: 'absolute',
        top: '-1px',
        right: '2px',
        color: isDarkMode ? '#fff' : '#000000', // Dynamic text color
        '&:hover': {
            color: isDarkMode ? '#fff' : '#000000',
        }
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        cursor: "pointer",
        lineHeight: '1.5',
        minHeight: '1.2em',
        fontWeight: '500',
        textShadow: "none",
        padding: '1px 15px 1px',
        boxSizing: 'border-box',
        position: 'relative',
        zIndex: 2,
        fontFamily: 'inherit',
        color: isDarkMode ? '#fff' : '#000', // Dynamic text color
        backgroundColor: state.isSelected
            ? (isDarkMode ? '#444' : '#ddd')  // Example selected background color
            : (isDarkMode ? '#333' : '#fff'), // Example background color
        '&:hover': {
            backgroundColor: isDarkMode ? '#007bff' : '#007bff',
            color: isDarkMode ? '#fff' : '#FFFFFF', // Hover color for dark mode and light mode
        }
    }),
    menu: (provided: any) => ({
        ...provided,
        zIndex: 9999,
        overflowY: "auto",
        position: 'absolute',
        fontFamily: 'inherit',
        fontWeight: 'normal',
        color: isDarkMode ? '#fff' : '#000', // Dynamic text color
        backgroundColor: isDarkMode ? '#333' : '#fff', // Dynamic background color
    }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: () => ({ display: "none" }),
    placeholder: (provided: any) => ({
        ...provided,
        pointerEvents: 'none',
        color: isDarkMode ? '#fff' : '#000', // Dynamic text color
        fontSize: '13px',
        lineHeight: 1,
        paddingBottom: '0.155rem',
        fontFamily: 'inherit'
    }),
    noOptionsMessage: (provided: any) => ({
        ...provided,
        color: isDarkMode ? '#fff' : 'red', // Dynamic text color
        fontFamily: 'inherit',
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: isDarkMode ? '#fff' : '#000000', // Dynamic text color for selected value
    }),
    input: (provided: any) => ({
        ...provided,
        color: isDarkMode ? '#fff' : '#000000', // Change the text color of the input to white for dark mode
    }),
});

export const customStylesForCanvas = (isDarkMode: any) => ({
    control: (provided: any) => ({
        ...provided,
        width: 'calc(100% - 6px)',
        fontSize: '1rem',
        fontWeight: 600,
        lineWeight: 1.5,
        color: '#323232',
        appearance: 'none',
        backgroundColor: 'transparent',
        border: 'transparent',
        borderRadius: '1rem',
        minHeight: '0px',
        borderStyle: '0px',
        boxShadow: 'transparent',
        padding: 0
    }),
    clearIndicator: (provided: any) => ({
        ...provided,
        paddingLeft: '10px', // Adjust padding left as needed
        padding: '5px',
        marginTop: '0px', // Move the value container up by adjusting the top margin
        height: '1px', // Set the height of the clear indicator
        position: 'absolute', // Adjust position if necessary
        top: '2px', // Adjust top position if necessary
        right: '0px', // Adjust right position if necessary
        color: isDarkMode ? '#fff' : '#000000', // Dynamic text color
        '&:hover': {
            color: isDarkMode ? '#fff' : '#000000',
        }
    }),
    option: (provided: any, state: any) => ({
        ...provided,
        cursor: "pointer",
        lineHeight: '1.5',
        minHeight: '1.2em',
        fontWeight: '500',
        textShadow: "none",
        padding: '1px 15px 1px',
        boxSizing: 'border-box',
        position: 'relative',
        zIndex: 2,
        fontFamily: 'inherit',
        color: isDarkMode ? '#fff' : '#000', // Dynamic text color
        backgroundColor: state.isSelected
            ? (isDarkMode ? '#444' : '#ddd')  // Example selected background color
            : (isDarkMode ? '#333' : '#fff'), // Example background color
        '&:hover': {
            backgroundColor: isDarkMode ? '#007bff' : '#007bff',
            color: isDarkMode ? '#fff' : '#FFFFFF', // Hover color for dark mode and light mode
        }
    }),
    menu: (provided: any) => ({
        ...provided,
        zIndex: 9999, // Increase zIndex to ensure it appears on top of other content
        overflowY: "auto",
        position: 'absolute',
        // maxHeight: "250px",
        // backgroundColor: '#f8f9fa',
        fontFamily: 'inherit',
        fontWeight: 'normal',
        // textShadow: "none",
        color: isDarkMode ? '#fff' : '#000', // Dynamic text color
        backgroundColor: isDarkMode ? '#333' : '#fff', // Dynamic background color
    }),
    indicatorSeparator: () => ({ display: "none" }),
    dropdownIndicator: () => ({ display: "none" }),
    noOptionsMessage: (provided: any) => ({
        ...provided,
        color: isDarkMode ? '#fff' : 'red', // Dynamic text color
        fontFamily: 'inherit',
    }),
    singleValue: (provided: any) => ({
        ...provided,
        color: isDarkMode ? '#fff' : '#000000', // Dynamic text color for selected value
    }),
    input: (provided: any) => ({
        ...provided,
        color: isDarkMode ? '#fff' : '#000000', // Change the text color of the input to white for dark mode
    }),
});