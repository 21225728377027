import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

export const getStaffListForPayRunReport = (year: any, month: any, paymentStatusId: any, paymentTypeId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffListForPayRunReport/${getLicenseKey}/${year}/${month}/${paymentStatusId}/${paymentTypeId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffListForPayRunReport - " + error.message)
            console.log('error caught in service : getStaffListForPayRunReport')
        },
    );

export const getCumulativeSalaryReport = (fromYear: any, fromMonth: any, toYear: any, toMonth: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getCumulativeSalaryReport/${getLicenseKey}/${fromYear}/${fromMonth}/${toYear}/${toMonth}`,
        response => {
            success(response)
        },
        error => {
            failure("getCumulativeSalaryReport - " + error.message)
            console.log('error caught in service : getCumulativeSalaryReport')
        },
    );

export const getMonthlySalaryReport = (fromYear: any, fromMonth: any, toYear: any, toMonth: any, paymentStatusId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getMonthlySalaryReport/${getLicenseKey}/${fromYear}/${fromMonth}/${toYear}/${toMonth}/${paymentStatusId}`,
        response => {
            success(response)
        },
        error => {
            failure("getMonthlySalaryReport - " + error.message)
            console.log('error caught in service : getMonthlySalaryReport')
        },
    );

export const getStaffPfDetailsReport = (year: any, month: any, staffDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getStaffPfDetailsReport/${getLicenseKey}/${year}/${month}/${staffDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getStaffPfDetailsReport - " + error.message)
            console.log('error caught in service : getStaffPfDetailsReport')
        },
    );