import React, { useContext, useEffect, useRef, useState } from 'react'
import PageWrapper from '../../layout/PageWrapper/PageWrapper'
import Page from '../../layout/Page/Page'
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card'
import Button, { ButtonGroup } from '../bootstrap/Button'
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas'
import FormGroup from '../bootstrap/forms/FormGroup'
import Input from '../bootstrap/forms/Input'
import { useFormik } from 'formik'
import Select from '../bootstrap/forms/Select'
import axios from 'axios'
import Icon from '../icon/Icon'
import { TableLoader, showLoader } from '../../services/loader.services'
import AlertService from '../../services/AlertService'
import AuthContext from '../../contexts/authContext'
import Download from '../Download/download'
import useDarkMode from '../../hooks/useDarkMode'
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown'
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks'
import { useReactToPrint } from 'react-to-print'
import useSortableData from '../../hooks/useSortableData'
import classNames from 'classnames'
import Textarea from '../bootstrap/forms/Textarea'
import PaginationButtons, { dataPagination } from '../PaginationButtons'
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../services/export.service'
import { convertDateToEpoch, getColumnsForDataTable, getCurrentDateAndTime, updateColumnsForDataTable, updateFilter } from '../../services/common.service'
import OpenCardComponent from '../../common/components/OpenCardComponents'
import Collapse from '../bootstrap/Collapse'
import { useParams } from 'react-router-dom'
import { addEmployeeLeave, getLeaveDurationTypeList, getLeaveRequestList, getLeaveType, updateEmployeeLeave, updateLeaveReportStatus } from '../../services/employee.service'
import dayjs from 'dayjs'
import NoDataMsg from '../../common/components/NoDataMsg'
import { getLicenseKey } from '../../services/application.settings'
import { toasts } from '../../services/toast.service'
import SearchableSelect from '../../common/components/SearchableSelect'

function leaveReport() {

    useEffect(() => {
        getEmployee();
        getLeaveTypeList();
        getLeaveDurationType();
        getLeaveRequest();
        // showLoader(false)
    }, []);

    const LeaveCreateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            employeeLeaveDetailsId: '',
            leaveTypeId: '',
            leaveDurationTypeId: '',
            fromDate: getCurrentDateAndTime('date'),
            toDate: getCurrentDateAndTime('date'),
            reason: '',
        },
        validate: (values) => {
            const errors: {
                leaveTypeId?: string;
                leaveDurationTypeId?: string;
                fromDate?: string;
                toDate?: string;
                reason?: string;
            } = {};

            if (!leaveTypeId) {
                errors.leaveTypeId = 'Required';
            }
            if (!leaveDurationTypeId) {
                errors.leaveDurationTypeId = 'Required';
            }

            if (!values.reason) {
                errors.reason = 'Required';
            }
            return errors;
        },
        validateOnChange: false,
        onSubmit: () => { AddEmployeeLeaveSubmit() },
    });


    const leaveEditForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            employeeLeaveDetailsId: '',
            leaveStatusId: '',
            updatedBy: '',
            employeeDetailsId: '',
            leaveTypeId: '',
            leaveDurationTypeId: '',
            fromDate: '',
            toDate: '',
            reason: '',
            remarks: '',
            totalLeaveDays: '',
            noOfdaysLeaveTaken: '',
        },

        validateOnChange: false,
        onSubmit: () => { },
    });

    const columnVisibileForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        validateOnChange: false,
        onSubmit: () => { },
    });


    const LeaveForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            fromDate: getCurrentDateAndTime('date'),
            toDate: getCurrentDateAndTime('date'),
        },
        validate: (values) => {
            const errors: {
                fromDate?: string;
                toDate?: string;
                feeTypeId?: string;
            } = {};
            if (!values.fromDate) {
                errors.fromDate = 'Required';
            }
            if (!values.toDate) {
                errors.toDate = 'Required';
            }

            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    function viewLeaveReportList() {
        setDataSuccess(false);
        setIsLoader(false);
        let fromDate = LeaveForm.values.fromDate
        let toDate = LeaveForm.values.toDate
        let fromDateInMilliSec = convertDateToEpoch(fromDate)
        let toDateInMilliSec = convertDateToEpoch(toDate)
        getLeaveRequest()
    }

    const { themeStatus, darkModeStatus } = useDarkMode();
    const [isLoader, setIsLoader] = useState(false)
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { userAccountId, userTypeId, } = useContext(AuthContext);
    const [addLeaveReportOffCanvas, setAddLeaveReportOffCanvas] = useState(false)
    const [editLeaveReportOffCanvas, setEditLeaveReportOffCanvas] = useState(false)
    const [EmployeeData, setEmployeeData] = useState<any>([]);
    const [exitEmployees, setExitEmployees] = useState<any>('')
    const [leaveData, setLeaveData] = useState<any>([]);
    const [leaveDuration, setLeaveDuration] = useState<any>([]);
    const [leaveRequestList, setLeaveRequestList] = useState<any>([]);
    const [leaveTypeId, setLeaveTypeId] = useState<any>(Number);
    const [leaveDurationTypeId, setLeaveDurationTypeId] = useState<any>(Number);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isDownload, setIsDownload] = useState(false);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const componentRef = useRef(null);
    const [showApprovedOrReject, setShowApprovedOrReject] = useState(false);
    const { items, requestSort, getClassNamesFor } = useSortableData(leaveRequestList);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [isChecked, setIsChecked] = useState(false)
    const [columnDataById, setColumnDataById] = useState([])
    const [fromDate, setFromDate] = useState<any>('')
    const [toDate, setToDate] = useState<any>('')
    const [totalDays, setTotalDays] = useState<any>('')
    const [employeeDetailsId, setEmployeeDetailsId] = useState<any>(Number)
    const [noDataMsg, setNoDataMsg] = useState('')
    const [dataSuccess, setDataSuccess] = useState(false)
    const [dataStatus, setDataStatus] = useState(false);

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedLeave = i
            setFromDate(selectedLeave.fromDate)
            setToDate(selectedLeave.toDate)
            setEditLeaveReportOffCanvas(true)
            setLeaveTypeId({ value: selectedLeave.leaveTypeId, label: selectedLeave.leaveTypeName })
            setLeaveDurationTypeId({ value: selectedLeave.leaveDurationTypeId, label: selectedLeave.durationType })
            leaveEditForm.setValues({
                employeeLeaveDetailsId: selectedLeave.employeeLeaveDetailsId,
                leaveStatusId: selectedLeave.leaveStatusId,
                updatedBy: selectedLeave.updatedBy,
                employeeDetailsId: selectedLeave.employeeDetailsId,
                leaveTypeId: selectedLeave.leaveTypeId,
                leaveDurationTypeId: selectedLeave.leaveDurationTypeId,
                fromDate: selectedLeave.fromDate,
                toDate: selectedLeave.toDate,
                reason: selectedLeave.reason,
                remarks: selectedLeave.remarks,
                totalLeaveDays: selectedLeave.totalLeaveDays,
                noOfdaysLeaveTaken: selectedLeave.noOfdaysLeaveTaken,
            });
            setEmployeeDetailsId(selectedLeave.employeeDetailsId)
        }
    }

    function getLeaveRequest() {
        setDataStatus(false);
        getLeaveRequestList(userAccountId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.leaveRequestList;
                    if (data != undefined) {
                        setDataStatus(true);
                        setLeaveRequestList(data);
                        getColumnsForDataTableList('getLeaveRequestList', 'get')
                        setDataSuccess(true);
                        setIsOpenListCard(false);

                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            data[i].action = <div>
                                {data[i].leaveStatusId == 1 ?
                                    <Button isOutline={!darkModeStatus} color='dark' isLight={darkModeStatus} className={classNames('text-nowrap', { 'border-light': !darkModeStatus, })} icon='Edit'>
                                    </Button> : null
                                }
                            </div>
                            data[i].statusName = <div>
                                <Button
                                    isLink
                                    color={data[i].statusName == 'Approved' ? 'success' : data[i].statusName == 'Requested' ? 'warning' : 'danger'}
                                    icon='Circle'
                                    className='text-nowrap'>
                                    {data[i].statusName}
                                </Button>
                            </div>
                        }
                        setDataStatus(true);
                    } else {
                        setDataStatus(true);
                        toasts("Undefined Data", "Error")
                        setIsOpenListCard(true);
                    }
                }
                else if (response.data.success === false) {
                    setDataStatus(true);
                    setLeaveRequestList([]);
                    getColumnsForDataTableList('getLeaveRequestList', 'get')
                    setNoDataMsg(response.data.message)
                    setIsOpenListCard(false);
                    setDataSuccess(true);
                }
            }, error => {
                setDataStatus(true);
                toasts(error, "Error")
                setIsOpenListCard(true);
            }
        )
    }

    function days_between(date1: any, date2: any) {

        setFromDate(date1)
        setToDate(date2)

        const ONE_DAY = 1000 * 60 * 60 * 24;
        const differenceMs = Math.abs(convertDateToEpoch(date1) - convertDateToEpoch(date2));
        let totalDays = Math.round(differenceMs / ONE_DAY);
        setTotalDays(totalDays);
    }

    function setAddEmployeeLeaveDetails() {
        return ({
            userAccountId: userAccountId,
            licenseKey: getLicenseKey,
            employeeDetailsId: userAccountId,
            fromDate: LeaveCreateForm.values.fromDate,
            toDate: LeaveCreateForm.values.toDate,
            reason: LeaveCreateForm.values.reason,
            leaveTypeId: leaveTypeId?.value,
            leaveDurationTypeId: leaveDurationTypeId?.value,


        })
    }

    function setUpdateEmployeeDetails() {
        return ({
            userAccountId: userAccountId,
            licenseKey: getLicenseKey,
            employeeDetailsId: userAccountId,
            employeeLeaveDetailsId: leaveEditForm.values.employeeLeaveDetailsId,
            fromDate: fromDate,
            toDate: toDate,
            reason: leaveEditForm.values.reason,
            leaveTypeId: leaveTypeId?.value,
            leaveDurationTypeId: leaveDurationTypeId?.value,

        })
    }

    function AddEmployeeLeaveSubmit() {
        showLoader(true)
        if (LeaveCreateForm.isValid) {
            let leavepostData = setAddEmployeeLeaveDetails()
            addEmployeeLeave(leavepostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        let fromDate = LeaveForm.values.fromDate
                        let toDate = LeaveForm.values.toDate
                        let fromDateInMilliSec = convertDateToEpoch(fromDate)
                        let toDateInMilliSec = convertDateToEpoch(toDate)
                        getLeaveRequest();
                        closeAndResetForm()
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )

        } else if (LeaveCreateForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }

    }

    function updateEmployeeLeaveSubmit() {
        showLoader(true)
        if (leaveEditForm.isValid) {
            let leavepostData = setUpdateEmployeeDetails()
            updateEmployeeLeave(leavepostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        let fromDate = LeaveForm.values.fromDate
                        let toDate = LeaveForm.values.toDate
                        let fromDateInMilliSec = convertDateToEpoch(fromDate)
                        let toDateInMilliSec = convertDateToEpoch(toDate)
                        getLeaveRequest();
                        closeAndResetForm()
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )

        } else if (leaveEditForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }

    }

    function getEmployee() {
        // axios.get(apiUrl + `getEmployeeList/5566/1/2`)
        //     .then((response) => {
        //         if (response.data.success) {
        //             let data = response.data.data.employeeList;
        //             if (data != undefined) {
        //                 setEmployeeData(data);

        //             } else {
        //                 toasts("Undefined Data", "Error")
        //             }
        //         }
        //         else if (response.data.success === false) {
        //             setDataSuccess(false)
        //             setExitEmployees(response.data.message)
        //         } else {
        //             let errorCode = response.data.error[0].error_code;
        //             let errorDescription = response.data.error[0].error_description;
        //             toasts(errorDescription, "Error")
        //         }
        //     }).catch((error) => {
        //         toasts("Error", "Error")
        //     });
    }

    function getLeaveTypeList() {
        getLeaveType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.leaveType;
                    if (data != undefined) {
                        setLeaveData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }
    function getLeaveDurationType() {
        getLeaveDurationTypeList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.leaveDurationTypeList;
                    if (data != undefined) {
                        setLeaveDuration(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }
    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibileForm);


    function updateLeaveStatus(leaveDetailsId: any) {
        showLoader(true);
        if (leaveEditForm.isValid) {

            let leaveReportPostData = {
                leaveStatusId: leaveDetailsId,
                remarks: leaveEditForm.values.remarks != '' ? leaveEditForm.values.remarks : null,
                updatedBy: userAccountId,
                employeeDetailsId: employeeDetailsId,
                employeeleaveDetailsId: leaveEditForm.values.employeeLeaveDetailsId,
                licenseKey: getLicenseKey
            }

            updateLeaveReportStatus(leaveReportPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        let fromDate = LeaveForm.values.fromDate
                        let toDate = LeaveForm.values.toDate
                        let fromDateInMilliSec = convertDateToEpoch(fromDate)
                        let toDateInMilliSec = convertDateToEpoch(toDate)
                        getLeaveRequest();
                        setShowApprovedOrReject(false);

                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false);
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )

        } else if (leaveEditForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }

    }

    function getColumnsForDataTableList(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        let keyData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == true)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    const updateColumnsSubmit = (columnVisibilityId: any, isDisplay: any, type: any) => {

        if (columnVisibileForm.isValid) {

            setColumnDataById(isDisplay)
            const postData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(postData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForDataTableList('getLeaveRequestList', 'get')
                    }
                    else if (data.success == false) {
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibileForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }


    function closeAndResetForm() {
        setAddLeaveReportOffCanvas(false)
        setEditLeaveReportOffCanvas(false);
        setShowApprovedOrReject(false)
        LeaveCreateForm.resetForm()
        setLeaveTypeId('');
        setLeaveDurationTypeId('');
    }

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function resetStateAddNew() {
        setLeaveTypeId('');
        setLeaveDurationTypeId('');
        LeaveCreateForm.resetForm();
        leaveEditForm.resetForm();
    }

    return (
        <PageWrapper title='Leave Report'>
            <Page container='fluid'>
                <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                    <CardHeader borderSize={1}>
                        <CardLabel>
                            <CardTitle>
                                <Icon
                                    icon='PlaylistAdd'
                                    size="2x"
                                    color='primary' />&nbsp;
                                Leave Report
                            </CardTitle>
                        </CardLabel>
                        <CardActions className="d-print-none">
                            <ButtonGroup color='primary'>
                                <Icon
                                    className="mt-1"
                                    icon='Search'
                                    size='2x'
                                    color='primary'
                                />
                                <Input
                                    id='searchInput'
                                    type='search'
                                    placeholder='Search...'
                                    onChange={columnVisibileForm.handleChange}
                                    value={columnVisibileForm.values.searchInput}
                                />
                            </ButtonGroup>
                            <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                <DropdownToggle>
                                    <Button
                                        icon='FilterAlt'
                                        color='primary'
                                        isLight>Filter
                                    </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                    <DropdownItem className="d-print-none" >
                                        <div className='container py-2'>
                                            <form className='row g-3'>
                                                <div className='col-12'>
                                                    <FormGroup>
                                                        <h6>Select All</h6>
                                                        <Checks
                                                            id='available'
                                                            type='switch'
                                                            label='Select All Columns'
                                                            onChange={() => getColumnsForDataTableList('getLeaveRequestList', 'post')}
                                                            checked={isChecked}
                                                            ariaLabel='Available status'
                                                        />
                                                    </FormGroup>
                                                </div>
                                                <div className='col-12'>
                                                    <FormGroup>
                                                        <h6>Columns</h6>
                                                        <ChecksGroup>
                                                            {allColumnsData.map((i: any) => (
                                                                <Checks
                                                                    key={i.columnVisibilityId}
                                                                    label={i.columnName}
                                                                    onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')}
                                                                    checked={i.isDisplay} />
                                                            ))}
                                                        </ChecksGroup>
                                                    </FormGroup>
                                                </div>
                                            </form>
                                        </div>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            <Dropdown isButtonGroup>
                                <DropdownToggle>
                                    <Button
                                        color='primary'
                                        isLight
                                        icon='CloudDownload'
                                    >
                                        Export </Button>
                                </DropdownToggle>
                                <DropdownMenu isAlignmentEnd>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel('leaveRequestList', leaveRequestList, columnVisibilityData)}> Excel </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(leaveRequestList, columnVisibilityData, 'leaveRequestList')}> PDF
                                        </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='CloudDownload'
                                            onClick={() => downloadFile('leaveRequestList', leaveRequestList, columnVisibilityData)}> CSV </Button>
                                    </DropdownItem>
                                    <DropdownItem>
                                        <Button color='primary' isLight icon='Print'
                                            onClick={() => handlePrint()}> Print </Button>
                                    </DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                            {userTypeId != 1 ? (
                                <Button
                                    color='primary'
                                    isLight
                                    onClick={() => { setAddLeaveReportOffCanvas(true); resetStateAddNew() }}
                                >
                                    Apply Leave
                                </Button>
                            ) : null}
                        </CardActions>
                    </CardHeader>
                    <CardBody className='table-responsive' isScrollable>
                        {!dataStatus ? (
                            <TableLoader />
                        ) : (
                            <table className='table table-modern table-hover text-noWarp'>
                                <thead>
                                    <tr>
                                        <th scope='col' onClick={() => requestSort('sno')}
                                            className='cursor-pointer text-decoration-underline'>
                                            S.No{' '}
                                            <Icon size='lg' className={getClassNamesFor('sno')}
                                                icon='FilterList' />
                                        </th>
                                        {columnVisibilityData.map((column: any) => (
                                            <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                className='cursor-pointer text-decoration-underline'>
                                                {column.columnName}
                                                <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                            </th>
                                        ))}
                                        <td className="d-print-none" />
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredData != "" ?
                                        <>
                                            {filteredData.map((rowData: any) => (
                                                <tr key={rowData.employeeLeaveDetailsId}>
                                                    <td >{rowData.sno}</td>
                                                    {columnVisibilityData.map((column: any) => (
                                                        (column.isDisplay && rowData[column.keyName]) ?
                                                            <td key={`${rowData.employeeLeaveDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />
                                                    ))}
                                                    {rowData.leaveStatusId == 1 ?
                                                        <td className="d-print-none" onClick={() => onActivate(rowData)}>
                                                            {rowData.action}
                                                        </td> : <td className="d-print-none"><Icon className='ms-2' icon={rowData.leaveStatusId == 3 ? 'DoNotDisturbAlt' : 'CheckCircleOutline'} size="lg" color={rowData.leaveStatusId == 3 ? 'danger' : 'success'} /></td>
                                                    }
                                                </tr>
                                            ))
                                            }</>
                                        :
                                        <NoDataMsg columnsCount={columnVisibilityData.length + 3} msg={noDataMsg} />}
                                </tbody>
                            </table>
                        )}
                    </CardBody>
                    <PaginationButtons className="d-print-none"
                        data={items}
                        label='items'
                        setCurrentPage={setCurrentPage}
                        currentPage={currentPage}
                        perPage={perPage}
                        setPerPage={setPerPage}
                    />
                </Card>
            </Page>
            <OffCanvas
                setOpen={setAddLeaveReportOffCanvas}
                isOpen={addLeaveReportOffCanvas}
                titleId='addLeaveReportOffCanvas'
                placement='end'
                isBodyScroll tag='form' noValidate onSubmit={LeaveCreateForm.handleSubmit}
            >
                <OffCanvasHeader setOpen={setAddLeaveReportOffCanvas} onClick={closeAndResetForm}>
                    <OffCanvasTitle id="addDepartmentOffCanvas">Apply Leave</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-4">
                        <div className='col-12'>
                            <FormGroup id='leaveTypeId' label='Leave Type'>
                                <SearchableSelect
                                    ariaLabel='select Leave Type'
                                    onChange={(e: any) => setLeaveTypeId(e)}
                                    value={leaveTypeId}
                                    list={leaveData.map((data: any) => ({
                                        value: data.leaveTypeId,
                                        label: data.leaveTypeName,
                                    }))}
                                    onBlur={LeaveCreateForm.handleBlur}
                                    isValid={LeaveCreateForm.isValid}
                                    isTouched={LeaveCreateForm.touched.leaveTypeId}
                                    invalidFeedback={LeaveCreateForm.errors.leaveTypeId}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='leaveDurationTypeId' label='Leave Duration'>
                                <SearchableSelect
                                    ariaLabel='select Duration Type'
                                    onChange={(e: any) => setLeaveDurationTypeId(e)}
                                    value={leaveDurationTypeId}
                                    list={leaveDuration.map((data: any) => ({
                                        value: data.leaveDurationTypeId,
                                        label: data.durationType,
                                    }))}
                                    onBlur={LeaveCreateForm.handleBlur}
                                    isValid={LeaveCreateForm.isValid}
                                    isTouched={LeaveCreateForm.touched.leaveDurationTypeId}
                                    invalidFeedback={LeaveCreateForm.errors.leaveDurationTypeId}
                                />
                            </FormGroup>
                        </div>
                        <br />
                        <div className='col-6'>
                            <FormGroup id='fromDate' label=' From Date'>
                                <Input
                                    aria-placeholder=''
                                    onChange={LeaveCreateForm.handleChange}
                                    onBlur={LeaveCreateForm.handleBlur}
                                    value={LeaveCreateForm.values.fromDate}
                                    isValid={LeaveCreateForm.isValid}
                                    isTouched={LeaveCreateForm.touched.fromDate}
                                    invalidFeedback={LeaveCreateForm.errors.fromDate}
                                    type='date'
                                />
                            </FormGroup>
                        </div>
                        <div className='col-6'>
                            <FormGroup id='toDate' label='To Date'>
                                <Input
                                    aria-placeholder=''
                                    onChange={LeaveCreateForm.handleChange}
                                    onBlur={LeaveCreateForm.handleBlur}
                                    value={LeaveCreateForm.values.toDate}
                                    isValid={LeaveCreateForm.isValid}
                                    isTouched={LeaveCreateForm.touched.toDate}
                                    invalidFeedback={LeaveCreateForm.errors.toDate}
                                    type='date'
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='reason' label='Reason'>
                                <Textarea
                                    autoComplete=''
                                    onChange={LeaveCreateForm.handleChange}
                                    onBlur={LeaveCreateForm.handleBlur}
                                    value={LeaveCreateForm.values.reason}
                                    isValid={LeaveCreateForm.isValid}
                                    isTouched={LeaveCreateForm.touched.reason}
                                    invalidFeedback={LeaveCreateForm.errors.reason}
                                />
                            </FormGroup>
                        </div>
                    </div>
                </OffCanvasBody>
                <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                    <div className='row m-0'>
                        <Button
                            color='info'
                            icon="Save"
                            className='w-100'
                            type="submit"
                            isDisable={!LeaveCreateForm.isValid && !!LeaveCreateForm.submitCount}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </OffCanvas>

            <OffCanvas
                setOpen={setEditLeaveReportOffCanvas}
                isOpen={editLeaveReportOffCanvas}
                titleId='editLeaveReportOffCanvas'
                placement='end'
                isBodyScroll tag='form' noValidate onSubmit={leaveEditForm.handleSubmit}
            >
                <OffCanvasHeader setOpen={setEditLeaveReportOffCanvas} onClick={closeAndResetForm}>
                    <OffCanvasTitle id="editLeaveReportOffCanvas">Leave Request</OffCanvasTitle>
                </OffCanvasHeader>
                <OffCanvasBody>
                    <div className="row g-2">
                        {userTypeId != 3 ? (
                            <div className='col-12'>
                                <FormGroup id='noOfdaysLeaveTaken' label='No Of Days Leave Taken'>
                                    <Input onChange={leaveEditForm.handleChange}
                                        value={leaveEditForm.values.noOfdaysLeaveTaken}
                                        isValid={leaveEditForm.isValid}
                                        onBlur={leaveEditForm.handleBlur}
                                        isTouched={leaveEditForm.touched.noOfdaysLeaveTaken}
                                        invalidFeedback={leaveEditForm.errors.noOfdaysLeaveTaken}
                                        placeholder="Enter No Of Days Leave Taken"
                                        disabled />

                                </FormGroup>
                            </div>
                        ) : null}
                        <div className='col-12'>
                            <FormGroup id='leaveTypeId' label='Leave Type'>
                                <SearchableSelect
                                    ariaLabel='select Leave Type'
                                    onChange={(e: any) => setLeaveTypeId(e)}
                                    onBlur={leaveEditForm.handleBlur}
                                    value={leaveTypeId}
                                    isValid={leaveEditForm.isValid}
                                    isTouched={leaveEditForm.touched.leaveTypeId}
                                    invalidFeedback={leaveEditForm.errors.leaveTypeId}
                                    disabled={userTypeId == 1 ? true : false}
                                    list={leaveData.map((data: any) => ({
                                        value: data.leaveTypeId,
                                        label: data.leaveTypeName,
                                    }))} required
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='leaveDurationTypeId' label='Leave Duration'>
                                <SearchableSelect
                                    ariaLabel='select Duration Type'
                                    onChange={(e: any) => setLeaveDurationTypeId(e)}
                                    value={leaveDurationTypeId}
                                    list={leaveDuration.map((data: any) => ({
                                        value: data.leaveDurationTypeId,
                                        label: data.durationType,
                                    }))}
                                    onBlur={leaveEditForm.handleBlur}
                                    isValid={leaveEditForm.isValid}
                                    isTouched={leaveEditForm.touched.leaveDurationTypeId}
                                    invalidFeedback={leaveEditForm.errors.leaveDurationTypeId}
                                    disabled={userTypeId == 1 ? true : false} required
                                />
                            </FormGroup>
                        </div>
                        <br />
                        <div className='col-6'>
                            <FormGroup id='fromDate' label=' From Date'>
                                <Input
                                    aria-placeholder=''
                                    onChange={(e: any) => days_between(e.target.value, toDate)}
                                    onBlur={leaveEditForm.handleBlur}
                                    value={fromDate}
                                    isValid={leaveEditForm.isValid}
                                    isTouched={leaveEditForm.touched.fromDate}
                                    invalidFeedback={leaveEditForm.errors.fromDate}
                                    type='date'
                                    disabled={userTypeId == 1 ? true : false}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-6'>
                            <FormGroup id='toDate' label=' To Date'>
                                <Input
                                    aria-placeholder=''
                                    onChange={(e: any) => days_between(fromDate, e.target.value)}
                                    onBlur={leaveEditForm.handleBlur}
                                    value={toDate}
                                    isValid={leaveEditForm.isValid}
                                    isTouched={leaveEditForm.touched.toDate}
                                    invalidFeedback={leaveEditForm.errors.toDate}
                                    type='date'
                                    disabled={userTypeId == 1 ? true : false}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='totalLeaveDays' label='No of days leave'>
                                <Input
                                    ariaLabel='Paper Type'
                                    onChange={leaveEditForm.handleChange}
                                    onBlur={leaveEditForm.handleBlur}
                                    value={totalDays != '' ? totalDays : leaveEditForm.values.totalLeaveDays}
                                    isValid={leaveEditForm.isValid}
                                    isTouched={leaveEditForm.touched.totalLeaveDays}
                                    invalidFeedback={leaveEditForm.errors.totalLeaveDays}
                                    disabled={userTypeId == 1 ? true : false}
                                />
                            </FormGroup>
                        </div>
                        <div className='col-12'>
                            <FormGroup id='reason' label='Reason'>
                                <Textarea
                                    autoComplete=''
                                    onChange={leaveEditForm.handleChange}
                                    onBlur={leaveEditForm.handleBlur}
                                    value={leaveEditForm.values.reason}
                                    isValid={leaveEditForm.isValid}
                                    isTouched={leaveEditForm.touched.reason}
                                    invalidFeedback={leaveEditForm.errors.reason}
                                    disabled={userTypeId == 1 ? true : false}
                                />
                            </FormGroup>
                        </div>

                        {showApprovedOrReject ?
                            <div className='col-12'>
                                <FormGroup id='remarks' label='Remark'>
                                    <Textarea
                                        placeholder='Remark'
                                        autoComplete=''
                                        onChange={leaveEditForm.handleChange}
                                        onBlur={leaveEditForm.handleBlur}
                                        value={leaveEditForm.values.remarks}
                                        isValid={leaveEditForm.isValid}
                                        isTouched={leaveEditForm.touched.remarks}
                                        invalidFeedback={leaveEditForm.errors.remarks}
                                    />
                                </FormGroup>
                                <br /><br /><br /><br />
                            </div> : null}
                    </div>
                </OffCanvasBody>

                {userTypeId != 1 && leaveEditForm?.values?.leaveStatusId == '1' ?
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <div className='row m-0'>
                            <Button
                                color='info'
                                icon="Save"
                                className='w-100'
                                onClick={updateEmployeeLeaveSubmit}
                            >
                                Submit
                            </Button>
                        </div>
                    </div> : null
                }

                {userTypeId == 1 ? (
                    <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                        <div className='row m-0'>
                            {showApprovedOrReject ?
                                <>
                                    <div className='col-12 p-3'>
                                        <Button
                                            color='danger'
                                            icon="Save"
                                            className='w-100'
                                            onClick={() => updateLeaveStatus(3)}
                                        >
                                            Reject
                                        </Button>
                                    </div>
                                </> :

                                <>
                                    <br />
                                    <div className="col-6 p-3 mt-4">
                                        <Button
                                            color='danger'
                                            icon="Save"
                                            className='w-100'
                                            type="submit"
                                            onClick={() => setShowApprovedOrReject(!showApprovedOrReject)}
                                        >
                                            Reject
                                        </Button>
                                    </div>
                                    <div className='col-6 p-3 mt-4'>
                                        <Button
                                            color='info'
                                            icon="Save"
                                            className='w-100'
                                            type="submit"
                                            onClick={() => updateLeaveStatus(2)}
                                        >
                                            Approve
                                        </Button>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                ) : null}

            </OffCanvas >
        
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
            <Download setIsDownload={setIsDownload} isDownload={isDownload} title={'LeaveReport'} />
        </PageWrapper >
    )
}

export default leaveReport;
