import { AxiosResponse } from "axios";
import service from "../api/api.services";
import { getAPIURL, getLicenseKey } from "./application.settings";

const api = service()

// EMPLOYEE 
export const getEmployeeList = (organisationDetailsId: any, employeeStatusId: any, employeeDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getEmployeeList/${getLicenseKey}/${organisationDetailsId}/${employeeStatusId}/${employeeDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getEmployeeList - " + error.message)
            console.log('error caught in service : getEmployeeList')
        },
    );

export const addEmployeeDetails = (employeePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addEmployeeDetails`, employeePostData,
        response => {
            success(response)
        },
        error => {
            failure("addEmployeeDetails - " + error.message)
            console.log('error caught in service : addEmployeeDetails')
        },
    );

export const updateEmployeeDetails = (employeePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateEmployeeDetails`, employeePostData,
        response => {
            success(response)
        },
        error => {
            failure("updateEmployeeDetails - " + error.message)
            console.log('error caught in service : updateEmployeeDetails')
        },
    );

export const deleteEmployeeDetails = (employeePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `deleteEmployeeDetails`, employeePostData,
        response => {
            success(response)
        },
        error => {
            failure("deleteEmployeeDetails - " + error.message)
            console.log('error caught in service : deleteEmployeeDetails')
        },
    );

export const getIncompleteEmployee = (organisationDetailsId: any, employeeStatusId: any, employeeDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getEmployeeList/${getLicenseKey}/${organisationDetailsId}/${employeeStatusId}/${employeeDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getIncompleteEmployee - " + error.message)
            console.log('error caught in service : getIncompleteEmployee')
        },
    );

export const getEmployeeStatus = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getEmployeeStatus/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getEmployeeStatus - " + error.message)
            console.log('error caught in service : getEmployeeStatus')
        },
    );
export const addEmployeeLeave = (leavePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `addEmployeeLeave`, leavePostData,
        response => {
            success(response)
        },
        error => {
            failure("addEmployeeLeave - " + error.message)
            console.log('error caught in service : addEmployeeLeave')
        },
    );

// LEAVE
export const getLeaveRequestList = (employeeDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getLeaveRequestList/${getLicenseKey}/${employeeDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getLeaveRequestList - " + error.message)
            console.log('error caught in service : getLeaveRequestList')
        },
    );

export const getLeaveType = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getLeaveType/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getLeaveType - " + error.message)
            console.log('error caught in service : getLeaveType')
        },
    );
export const getLeaveDurationTypeList = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getLeaveDurationTypeList/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getLeaveDurationTypeList - " + error.message)
            console.log('error caught in service : getLeaveDurationTypeList')
        },
    );
export const updateLeaveReportStatus = (leaveReportPostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateLeaveStatus`, leaveReportPostData,
        response => {
            success(response)
        },
        error => {
            failure("updateLeaveStatus - " + error.message)
            console.log('error caught in service : updateLeaveStatus')
        },
    );

// EMPLOYEE PROFILE
export const getEmployeeProfile = (employeeDetailsId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getEmployeeProfile/${getLicenseKey}/${employeeDetailsId}`,
        response => {
            success(response)
        },
        error => {
            failure("getEmployeeProfile - " + error.message)
            console.log('error caught in service : getEmployeeList')
        },
    );
export const updateEmployeeLeave = (leavePostData: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.post(getAPIURL() + `updateEmployeeLeave`, leavePostData,
        response => {
            success(response)
        },
        error => {
            failure("updateEmployeeLeave - " + error.message)
            console.log('error caught in service : updateEmployeeLeave')
        },
    );



export const getEmployeeSalaryDetails = (salaryTemplateId: any, ctcAmount: any, basicAmount: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getEmployeeSalaryDetails/${getLicenseKey}/${salaryTemplateId}/${ctcAmount}/${basicAmount}`,
        response => {
            success(response)
        },
        error => {
            failure("getEmployeeProfile - " + error.message)
            console.log('error caught in service : getEmployeeList')
        },
    );
export const getLeaveStatus = (success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getLeaveStatus/${getLicenseKey}`,
        response => {
            success(response)
        },
        error => {
            failure("getLeaveStatus - " + error.message)
            console.log('error caught in service : getLeaveStatus')
        },
    );

export const getEmployeeLeaveDetailsList = (employeeDetailsId: any, leaveStatusId: any, success: (arg0: AxiosResponse<any, any>) => any, failure: (arg0: any) => void,) =>

    api.get(getAPIURL() + `getEmployeeLeaveDetailsList/${getLicenseKey}/${employeeDetailsId}/${leaveStatusId}`,
        response => {
            success(response)
        },
        error => {
            failure("getEmployeeLeaveDetailsList - " + error.message)
            console.log('error caught in service : getEmployeeLeaveDetailsList')
        },
    );