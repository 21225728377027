import React, { useContext, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Icon from '../icon/Icon';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import FormGroup from '../bootstrap/forms/FormGroup';
import { useReactToPrint } from 'react-to-print';
import Button from '../bootstrap/Button';
import { useFormik } from 'formik';
import Input from '../bootstrap/forms/Input';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import useSortableData from '../../hooks/useSortableData';
import { dataPagination } from '../PaginationButtons';
import Collapse from '../bootstrap/Collapse';
import OpenCardComponent from '../../common/components/OpenCardComponents';
import { convertNumToMonth, getColumnsForDataTable, getCurrentDateAndTime, getPaymentType, onlyAllowNumber } from '../../services/common.service';
import NoDataMsg from '../../common/components/NoDataMsg';
import { toasts } from '../../services/toast.service';
import AlertService from '../../services/AlertService';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../services/export.service';
import '../../common/components/toPrint.css'
import useDarkMode from '../../hooks/useDarkMode';
import { getStaffPfDetailsReport } from '../../services/report.service';

const StaffPfDetailsReport = () => {

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(100);
    const { items, requestSort, getClassNamesFor } = useSortableData([]);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [isLoader, setIsLoader] = useState(false);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [staffPfDetailsData, setStaffPfDetailsData] = useState<any>([]);
    const [noDataMsg, setNoDataMsg] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const componentRef = useRef(null);
    const navigate = useNavigate();

    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [columnDataById, setColumnDataById] = useState([]);

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);

    const payRunForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            monthAndYear: '',
        },
        validate: (values) => {
            const errors: {
                monthAndYear?: string;
            } = {};

            if (!values.monthAndYear) {
                errors.monthAndYear = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { viewSalaryDetails() },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    function viewSalaryDetails() {
        setDataSuccess(false);
        setIsLoader(true);
        let monthAndYear = payRunForm.values.monthAndYear;
        const splitDate = monthAndYear.split('-');
        const year = splitDate[0];
        const month = splitDate[1];

        setYear(year);
        setMonth(month);
        getStaffSalaryList(year, month, 0);
    }

    function getStaffSalaryList(year: any, month: any, staffDetailsId: any,) {
        setIsLoader(true);
        getStaffPfDetailsReport(year, month, staffDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffPfDetailsReport;
                    if (data != undefined) {
                        setStaffPfDetailsData(data);
                        getColumnsForTable('getStaffPfDetailsReport', 'get');
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        setIsOpenListCard(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setIsLoader(false);
                    getColumnsForTable('getStaffPfDetailsReport', 'get');
                    setStaffPfDetailsData([]);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            //   updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title='Staff Pf Details Report'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card
                        stretch
                        data-tour='list'
                        tag='form'
                        noValidate
                        onSubmit={payRunForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Staff Pf Details Report
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-3'>
                                    <FormGroup id='monthAndYear' label='Month' isFloating>
                                        <Input
                                            onChange={payRunForm.handleChange}
                                            value={payRunForm.values.monthAndYear}
                                            onBlur={payRunForm.handleBlur}
                                            isValid={payRunForm.isValid}
                                            isTouched={payRunForm.touched.monthAndYear}
                                            invalidFeedback={payRunForm.errors.monthAndYear}
                                            type='month'
                                        />
                                    </FormGroup>
                                </div>

                                <div className='col-3'>
                                    <Button
                                        className='mt-2'
                                        icon='ArrowDownward'
                                        color='primary'
                                        type='submit'
                                        isDisable={!payRunForm.isValid && !!payRunForm.submitCount}>
                                        View
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent
                    isOpenListCard={isOpenListCard}
                    setIsOpenListCard={setIsOpenListCard}
                    isLoader={isLoader}
                />

                {dataSuccess ? (
                    <Card stretch ref={componentRef}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className='col-lg-6'>
                                <CardTitle tag='div' className='h5'>
                                    <span className='text-primary'>{convertNumToMonth(month)} - {year}</span> Staff Pf Details Report
                                </CardTitle>
                            </CardLabel>
                            <CardActions className='d-print-none'>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'>
                                            Export
                                        </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel('StaffPfDetailsReport', staffPfDetailsData, columnVisibilityData)}>
                                                Excel
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(staffPfDetailsData, columnVisibilityData, 'StaffPfDetailsReport')}>
                                                PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile('StaffPfDetailsReport', staffPfDetailsData, columnVisibilityData)}>
                                                CSV
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={() => handlePrint()}>
                                                Print
                                            </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardActions>
                        </CardHeader>

                        <CardBody>
                            <div className='row g-4 mt-2'>
                                <table className='table table-modern table-hover mt-2'>
                                    <thead>
                                        <tr className='table-primary'>
                                            <td className='text-decoration-underline'>
                                                <strong>S.No</strong>
                                            </td>
                                            <td className='text-decoration-underline'>
                                                <strong>Staff Name</strong>
                                            </td>
                                            <td className='text-decoration-underline'>
                                                <strong>User Type</strong>
                                            </td>
                                            <td className='text-decoration-underline'>
                                                <strong>Date Of Salary</strong>
                                            </td>
                                            <td className='text-decoration-underline'>
                                                <strong>Epf Number</strong>
                                            </td>
                                            <td className='text-decoration-underline'>
                                                <strong>Pf Amount Deduction</strong>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {staffPfDetailsData != '' ? (
                                            <>
                                                {staffPfDetailsData.map(
                                                    (salary: any, index: any) => (
                                                        <tr key={index}>
                                                            <td>{salary.sno}</td>
                                                            <td>{salary.staffName} <br /> <span className='text-center text-info'>{salary.empNumber}</span></td>
                                                            <td>{salary.userType}</td>
                                                            <td>{salary.dateOfSalaryView}</td>
                                                            <td>{salary.epfNumber}</td>
                                                            <td><span className='text-center text-danger'>{salary.pfAmountDeduction}</span></td>
                                                        </tr>
                                                    )
                                                )}
                                            </>
                                        ) : (
                                            <NoDataMsg columnsCount={13} msg={noDataMsg} />
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>
                ) : null}
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
};

export default StaffPfDetailsReport;
