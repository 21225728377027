import Page from "../../layout/Page/Page";
import PageWrapper from "../../layout/PageWrapper/PageWrapper";
import FormGroup from "../bootstrap/forms/FormGroup";
import Input from "../bootstrap/forms/Input";
import Icon from "../icon/Icon";
import AlertService from "../../services/AlertService";
import AuthContext from '../../contexts/authContext';
import Tooltips from '../bootstrap/Tooltips';
import Card, { CardActions, CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTitle } from "../bootstrap/Card";
import Checks from "../bootstrap/forms/Checks";
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator, } from '../../layout/SubHeader/SubHeader';
import Modal, { ModalBody } from '../bootstrap/Modal';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas';
import { useFormik } from 'formik';
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getEmployeeList, updateEmployeeDetails } from "../../services/employee.service";
import { getGenderDetailsList, getStates, getStatesAndCity, onlyAllowNumber, pictNotLoading, profilePic, profilePicUpload } from "../../services/common.service";
import { getDepartmentList, getDesignationList, getUserRoleList, getWorkLocationsList } from '../../services/setting.services';
import { showLoader } from '../../services/loader.services';
import { getSalaryTemplateList } from '../../services/salary.service';
import { getLicenseKey } from "../../services/application.settings";
import { toasts } from "../../services/toast.service";
import SearchableSelect from "../../common/components/SearchableSelect";
import Button from "../bootstrap/Button";

function UpdateEmployee() {

	let { employeeDetailsId } = useParams()
	const { userAccountId, organisationDetailsId } = useContext(AuthContext);

	useEffect(() => {
		getEmployee(employeeDetailsId)
		getGenderDetails();
		getWorkLocations();
		getDepartment();
		getDesignation();
		getUserRole();
		getStatesList();
		getSalaryTemplate(organisationDetailsId, 0);
	}, [])

	const TABS = {
		WORK_DETAIL: 'Personal',
		WORK_INFORMATION: 'Work',
		ADDRESS_DETAILS: 'Address',
		PAYMENT_INFORMATION: 'Payment',
		SALARY_DETAILS: 'Salary Details',
		UPLOAD: 'Profile Upload',
	};

	
	const navigate = useNavigate();

	const [isBankTransfer, setIsBankTransfer] = useState(true);
	const [rolesData, setRolesData] = useState<any>([]);
	const [allGenderData, setAllGenderData] = useState<any>([]);
	const [allWorkLocationData, setAllWorkLocationData] = useState<any>([]);
	const [departmentData, setDepartmentData] = useState([]);
	const [designationData, setDesignationData] = useState([]);
	const [statesData, setStatesData] = useState([]);
	const [cityData, setCityData] = useState([]);
	const [stateId, setStateId] = useState<any>('')
	const [cityId, setCityId] = useState<any>('')
	const [designationId, setDesignationId] = useState<any>([])
	const [userRoleId, setUserRoleId] = useState<any>([])
	const [departmentId, setDepartmentId] = useState<any>([])
	const [employeeData, setEmployeeData] = useState<any>([]);
	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
	const [isOpen, setIsOpen] = useState(false);
	const [accountDetailsOffCanvas, setAccountDetailsOffCanvas] = useState(false);
	const [advanceSalaryOffCanvas, setAdvanceSalaryOffCanvas] = useState(false);
	const [isNavigate, setIsNavigate] = useState<any>(null);
	const [activeTab, setActiveTab] = useState(TABS.WORK_DETAIL);
	const [selectedFile, setSelectedFile] = useState<any>('')
	const [dateOfBirth, setBirthday] = useState('');
	const [age, setAge] = useState<any>('');

	// Salary Templates
	const [annualCTC, setAnnualCTC] = useState<any>('')
	const [basicMonthlyAmount, setBasicMonthlyAmount] = useState<any>(0)
	const [basicAnnualAmount, setBasicAnnualAmount] = useState<any>(0)
	const [houseRentAnnualAmount, setHouseRentAnnualAmount] = useState<any>(0)
	const [houseRentMonthlyAmount, setHouseMonthlyAmount] = useState<any>(0)
	const [fixedAllowanceMonthlyAmount, setFixedAllowanceMonthlyAmount] = useState<any>(0)
	const [fixedAllowanceAnnualAmount, setFixedAllowanceAnnualAmount] = useState<any>(0)
	const [totalMonthlyAmount, setTotalMonthlyAmount] = useState<any>(0)
	const [totalAnnualAmount, setTotalAnnualAmount] = useState<any>(0)
	const [conveyanceAllowance, setConveyanceAllowance] = useState<any>('')
	const [conveyanceAllowanceAnnualAmount, setConveyanceAllowanceAnnualAmount] = useState<any>(0)
	const [salaryTemplateList, setSalaryTemplateList] = useState<any>([])
	const [salaryTemplateId, setSalaryTemplateId] = useState<any>('')
	const [salaryTemplateListById, setSalaryTemplateListById] = useState<any>([])
	const [genderId, setGenderId] = useState<any>('')
	const [workLocationsId, setWorkLocationsId] = useState<any>('')
	const [accountTypeId, setAccountTypeId] = useState<any>(Number)


	const updateEmployeeForm = useFormik({
		initialValues: {
			employeeFirstName: '',
			employeeMiddleName: '',
			employeeLastName: '',
			fatherName: '',
			email: '',
			mobile: '',
			dateOfBirth: '',
			age: '',
			genderId: '',
			workLocationsId: '',
			isPortalAccess: 0,
			isLabourWelfareFund: 0,
			isProfessionalTax: 0,
			isInterestedofCompany: 0,
			employeeId: '',
			workEmail: '',
			dateOfJoining: '',
			panNumber: '',
			address1: '',
			address2: '',
			pinCode: '',
			accountHolderName: '',
			bankName: '',
			accountNumber: '',
			ifsc: '',
			accountType: '',
			paymentTypeId: '',
			selectTemplate: '',
			annualCTC: '',
			employeeDetailsId: '',
			currentSalary: '',
			requestedAmount: '',
			requestedDate: '',
			departmentId: '',
			designationId: '',
			stateId: '',
			cityId: '',
			salaryTemplateId: '',
		},
		validate: (values) => {
			const errors: {
				employeeFirstName?: string;
				email?: string;
				mobile?: string;
				dateOfBirth?: string;
				genderId?: string;
				workLocationsId?: string;
				employeeId?: string;
				workEmail?: string;
				dateOfJoining?: string;
				panNumber?: string;
				userRoleId?: string;
				departmentId?: string;
				designationId?: string;
				address1?: string;
				address2?: string;
				pinCode?: string;
				stateId?: string;
				cityId?: string;
				salaryTemplateId?: string;
				annualCTC?: string;
			} = {};

			if (!values.employeeFirstName) {
				errors.employeeFirstName = 'Required';
			}
			if (!values.email) {
				errors.email = 'Required';
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
				errors.email = 'Invalid email address';
			}
			if (!/[A-Z]{5}[0-9]{4}[A-Z]{1}/i.test(values.panNumber) && values.panNumber != '') {
				errors.panNumber = 'Formate - AAAAA0000A';
			}
			if (!values.mobile) {
				errors.mobile = 'Required';
			} else if (values.mobile.length < 10 || values.mobile.length > 10) {
				errors.mobile = 'Must be 10 Numbers'
			} else if (!/^[6-9]\d{9}$/i.test(values.mobile)) {
				errors.mobile = 'Please enter valid mobile number'
			}
			if (!workLocationsId) {
				errors.workLocationsId = 'Required';
			}
			if (!genderId) {
				errors.genderId = 'Required';
			}
			if (!values.workEmail) {
				errors.workEmail = 'Required';
			} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.workEmail)) {
				errors.workEmail = 'Invalid email address';
			}
			if (!values.panNumber) {
				errors.panNumber = 'Required';
			}
			if (!userRoleId) {
				errors.userRoleId = 'Required';
			}
			if (!departmentId) {
				errors.departmentId = 'Required';
			}
			if (!designationId) {
				errors.designationId = 'Required';
			}
			if (!values.address1) {
				errors.address1 = 'Required';
			}
			if (!cityId) {
				errors.cityId = 'Required';
			}
			if (!stateId) {
				errors.stateId = 'Required';
			}
			if (!values.pinCode) {
				errors.pinCode = 'Required'
			}
			else if (!/^\d{6}$/.test(values.pinCode)) {
				errors.pinCode = 'Must be 6 Numbers';
			}
			if (!salaryTemplateId) {
				errors.salaryTemplateId = 'Required';
			}
			if (!annualCTC) {
				errors.annualCTC = 'Required';
			}

			return errors;
		},
		onSubmit: () => { updateEmployeeSubmit() },
	});

	//SALARY 
	function multiply(annualCTC: any, conveyanceAllowance: any) {

		setAnnualCTC(Number(annualCTC))
		let basicAnnualAmount = ((annualCTC / 100) * 50)
		setBasicAnnualAmount((basicAnnualAmount).toFixed(2))

		let basicMonthlyAmount = (basicAnnualAmount / 12)
		setBasicMonthlyAmount(Math.round(basicMonthlyAmount).toFixed(2))

		let houseRentAnnualAmount = ((basicAnnualAmount / 100) * 50)
		setHouseRentAnnualAmount((houseRentAnnualAmount).toFixed(2))

		let houseRentMonthlyAmount = (houseRentAnnualAmount / 12)
		setHouseMonthlyAmount((houseRentMonthlyAmount).toFixed(2))

		let fixedAllowanceAnnualAmount = (Number(annualCTC) - (basicAnnualAmount + houseRentAnnualAmount))
		setFixedAllowanceAnnualAmount((fixedAllowanceAnnualAmount).toFixed(2))

		let fixedAllowanceMonthlyAmount = (fixedAllowanceAnnualAmount / 12)
		setFixedAllowanceMonthlyAmount((fixedAllowanceMonthlyAmount).toFixed(2))

		if (conveyanceAllowance != '') {
			setConveyanceAllowance(conveyanceAllowance)

			let conveyanceAllowanceAnnualAmount = (conveyanceAllowance * 12)
			setConveyanceAllowanceAnnualAmount((conveyanceAllowanceAnnualAmount).toFixed(2))

			let calculateFixedAllowanceMonthlyAmount = (fixedAllowanceMonthlyAmount - conveyanceAllowance)
			setFixedAllowanceMonthlyAmount((calculateFixedAllowanceMonthlyAmount).toFixed(2))

			let calculateFixedAllowanceAnnualAmount = (fixedAllowanceAnnualAmount - conveyanceAllowanceAnnualAmount)
			setFixedAllowanceAnnualAmount((calculateFixedAllowanceAnnualAmount).toFixed(2))
		}

		setTotalMonthlyAmount((basicMonthlyAmount + houseRentMonthlyAmount + fixedAllowanceMonthlyAmount).toFixed(2))
		setTotalAnnualAmount((basicAnnualAmount + houseRentAnnualAmount + fixedAllowanceAnnualAmount).toFixed(2))
	}

	function selectSalaryTemplate(salaryTemplateId: any) {
		setSalaryTemplateId(salaryTemplateId?.value)
		let salaryTemplate = salaryTemplateList.filter((item: any) => item.salaryTemplateId == salaryTemplateId?.value)
		setSalaryTemplateListById(salaryTemplate[0])
	}

	function closeAndResetForm() {
		// setAdvanceSalaryOffCanvas(false)
		// updateEmployeeForm.resetForm()
	}

	//SALARY API
	function getSalaryTemplate(organisationDetailsId : any, salaryTemplateId : any) {
		getSalaryTemplateList(organisationDetailsId, salaryTemplateId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.salaryTemplateList;
					if (data != undefined) {
						setSalaryTemplateList(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setSalaryTemplateList([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	// DATE AND AGE
	function getAge(dateOfBirth: any) {
		setBirthday(dateOfBirth)
		let bday = new Date(dateOfBirth)
		let today = new Date()
		let distance = today.getTime() - bday.getTime()
		let daysOld = Math.floor(distance / (1000 * 60 * 60 * 24))
		let yearsOld = Number((daysOld / 365).toFixed(0))
		setAge(yearsOld)
	}

	const handleFileChange = (e: any) => {
		if (e.target.files && e.target.files[0]) {
			setSelectedFile(e.target.files[0]);
		}
	};

	function onUpload(employeeDetailsId: any) {
		const fileData = new FormData();
		fileData.append('profilePic', selectedFile, selectedFile.name)
		fileData.append('licenseKey', getLicenseKey.toString())
		fileData.append('employeeDetailsId', employeeDetailsId.toString())
		fileData.append('userAccountId', userAccountId)

		profilePicUpload(fileData,
			(response) => {
				showLoader(false);
				setAlertStatus({ message: response.data.message, type: "success" });
				setIsOpen(true);
				setIsNavigate(`../employee/employeeList`)
			}
			, (error) => {
				showLoader(false);
				setAlertStatus({ message: 'Profile pic not upload', type: "error" });
				setIsOpen(true);

			}
		)
	}

	// API
	function getEmployee(employeeDetailsId: any) {
		getEmployeeList(1, 0, employeeDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.employeeList[0];
					if (data != undefined) {
						setEmployeeData(data);
						setDepartmentId(data.departmentId != null ? { value: data.departmentId, label: data.departmentName } : '')
						setDesignationId(data.designationId != null ? { value: data.designationId, label: data.designationName } : '')
						setUserRoleId(data.userRoleId != null ? { value: data.userRoleId, label: data.roleName } : '')
						setBirthday(data.dob)
						getAge(data.dob)
						if (data.stateId != null) {
							getCityList(data.stateId)
						}
						setCityId(data.cityId != null ? { value: data.cityId, label: data.cityName } : '')
						setStateId(data.stateId != null ? { value: data.stateId, label: data.stateName } : '')

						setSalaryTemplateId(data.salaryTemplateId != null ? { value: data.salaryTemplateId, label: data.templateName } : '')
						multiply(data.annualCTC, conveyanceAllowance)

						setGenderId(data.genderId != null ? { value: data.genderId, label: data.gender } : '')
						setWorkLocationsId(data.workLocationsId != null ? { value: data.workLocationsId, label: data.workLocationsName } : '')

						if (data.accountType == 1) {
							setAccountTypeId({ value: 1, label: 'Current' })
						} else if (data.accountType == 2) {
							setAccountTypeId({ value: 2, label: 'Savings' })
						} else {
							setAccountTypeId('')
						}

						updateEmployeeForm.setValues({
							employeeDetailsId: data.employeeDetailsId,
							employeeFirstName: data.firstName,
							employeeMiddleName: data.middleName,
							employeeLastName: data.lastName,
							fatherName: data.fatherName,
							email: data.emailAddress,
							mobile: data.mobileNumber,
							dateOfBirth: data.dateOfBirth,
							age: data.age,
							genderId: data.genderId,
							workLocationsId: data.workLocationsId,
							designationId: data.designationId,
							departmentId: data.departmentId,
							isPortalAccess: data.portalAccess,
							isLabourWelfareFund: data.labourWelfareFund,
							isInterestedofCompany: data.isInterestedofCompany,
							isProfessionalTax: data.professionalTax,
							employeeId: data.employeeId,
							workEmail: data.workEmail,
							dateOfJoining: data.dateOfJoining,
							panNumber: data.pan,
							address1: data.address1,
							address2: data.address2,
							pinCode: data.pinCode,
							accountHolderName: data.accountHolderName,
							bankName: data.bankName,
							accountNumber: data.accountNumber,
							ifsc: data.ifsc,
							accountType: data.accountType,
							paymentTypeId: data.paymentTypeId,
							selectTemplate: data.selectTemplate,
							annualCTC: data.annualCTC,
							currentSalary: data.currentSalary,
							requestedAmount: data.requestedAmount,
							requestedDate: data.requestedDate,
							stateId: data.stateId,
							cityId: data.cityId,
							salaryTemplateId: data.salaryTemplateId,
						})
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function setUpdateEmployeeDetails() {
		return {
			employeeDetailsId: employeeDetailsId,
			employeeFirstName: updateEmployeeForm.values.employeeFirstName,
			employeeMiddleName: updateEmployeeForm.values.employeeMiddleName,
			employeeLastName: updateEmployeeForm.values.employeeLastName,
			fatherName: updateEmployeeForm.values.fatherName,
			emailAddress: updateEmployeeForm.values.email,
			mobile: updateEmployeeForm.values.mobile,
			dateOfBirth: dateOfBirth,
			genderId: genderId?.value,
			workLocationsId: workLocationsId?.value,
			salaryTemplateId: salaryTemplateId?.value,
			isPortalAccess: updateEmployeeForm.values.isPortalAccess,
			isProfessionalTax: updateEmployeeForm.values.isProfessionalTax,
			isLabourWelfareFund: updateEmployeeForm.values.isLabourWelfareFund,
			isInterestedofCompany: updateEmployeeForm.values.isInterestedofCompany,
			employeeId: updateEmployeeForm.values.employeeId,
			workEmail: updateEmployeeForm.values.workEmail,
			dateOfJoining: updateEmployeeForm.values.dateOfJoining,
			panNumber: updateEmployeeForm.values.panNumber,
			departmentId: departmentId?.value,
			designationId: designationId?.value,
			address1: updateEmployeeForm.values.address1,
			address2: updateEmployeeForm.values.address2,
			cityId: cityId?.value,
			stateId: stateId?.value,
			pincode: updateEmployeeForm.values.pinCode,
			accountHolderName: updateEmployeeForm.values.accountHolderName,
			bankName: updateEmployeeForm.values.bankName,
			accountNumber: updateEmployeeForm.values.accountNumber,
			ifsc: updateEmployeeForm.values.ifsc,
			accountType: accountTypeId?.value,
			paymentTypeId: '2',
			updatedBy: userAccountId,
			licenseKey: getLicenseKey
		}
	}

	function updateEmployeeSubmit() {
		showLoader(true);
		if (updateEmployeeForm.isValid) {
			let employeePostData = setUpdateEmployeeDetails()
			updateEmployeeDetails(employeePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						setIsNavigate(`../employee/employeeList`)
					}
					else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "error" });
						setIsOpen(true);
						setIsNavigate(null)
					}
					else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: "error" });
						setIsOpen(true);
						setIsNavigate(null)
					}
				}
				, (error) => {
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
					setIsNavigate(null)
				}
			)
		} else if (updateEmployeeForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
			setIsNavigate(null)
		}
	}

	// DROPDOWN API

	function getWorkLocations() {
		getWorkLocationsList(userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.workLocationsList;
					if (data != undefined) {
						setAllWorkLocationData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setAllWorkLocationData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}
	function getGenderDetails() {
		getGenderDetailsList(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.genderDetails;
					if (data != undefined) {
						setAllGenderData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setAllGenderData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getDepartment() {
		getDepartmentList(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.departmentList;
					if (data != undefined) {
						setDepartmentData(data);

					} else {
						toasts("undefined data", "error")
					}
				}
				else if (response.data.success === false) {
					toasts(response.data.success, "error")
				}
				else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getDesignation() {
		getDesignationList(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.designationList;
					if (data != undefined) {
						setDesignationData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setDesignationData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getUserRole() {
		getUserRoleList(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.userRoleList;
					if (data != undefined) {
						setRolesData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setRolesData([]);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, "Error")
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	const selectState = (e: any) => {
		let stateId = e
		setStateId(stateId);
		if (stateId?.value != undefined) {
			getCityList(stateId?.value)
		}
	}

	function getStatesList() {
		getStates(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.statesAndCity;
					if (data != undefined) {
						setStatesData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setStatesData([]);
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	function getCityList(stateId: number) {
		getStatesAndCity(stateId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.statesAndCity[0]['city'];
					if (data != undefined) {
						setCityData(data);
					} else {
						toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
					setCityData([]);
				}
			}, error => {
				toasts(error, "Error")
			}
		)
	}

	const accountTypeData = [{ accountTypeId: 1, accountType: 'Current' }, { accountTypeId: 1, accountType: 'Savings' }]


	return (
		<PageWrapper>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
						Back to List
					</Button>
					<SubheaderSeparator />
					<img style={{ borderRadius: '50%' }}
						src={employeeData.profilePath != null ? employeeData.profilePath : profilePic(employeeData.genderId)}
						height='40' width='40' onError={(e: any) => pictNotLoading(e, employeeData.genderId)}
					/>
					<span>
						<strong>{employeeData.employeeFullName}</strong>
					</span>

					<span className='border border-success border-2 text-success fw-bold px-3 py-2 rounded'>
						{employeeData.employeeId}
					</span>
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				<div className='row h-100'>
					<div className='col-xl-3 col-lg-4 col-6'>
						<Card stretch>
							<CardHeader borderSize={1}>
								<CardLabel icon='EditNote' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Update Employee
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody isScrollable>
								<div className='row g-3'>
									<div className='col-12'>
										<Button
											icon='Contacts'
											color="info"
											className='w-100 p-3 text-start'
											isLight={TABS.WORK_DETAIL !== activeTab}
											onClick={() => setActiveTab(TABS.WORK_DETAIL)}>
											{TABS.WORK_DETAIL}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Assignment'
											color="info"
											className='w-100 p-3 text-start'
											isLight={TABS.WORK_INFORMATION !== activeTab}
											onClick={() => setActiveTab(TABS.WORK_INFORMATION)}>
											{TABS.WORK_INFORMATION}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Home'
											color="info"
											className='w-100 p-3 text-start'
											isLight={TABS.ADDRESS_DETAILS !== activeTab}
											onClick={() => setActiveTab(TABS.ADDRESS_DETAILS)}>
											{TABS.ADDRESS_DETAILS}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Book'
											color='info'
											className='w-100 p-3 text-start'
											isLight={TABS.SALARY_DETAILS !== activeTab}
											onClick={() => setActiveTab(TABS.SALARY_DETAILS)}>
											{TABS.SALARY_DETAILS}
										</Button>
									</div>
									<div className='col-12'>
										<Button
											icon='Payment'
											color='info'
											className='w-100 p-3 text-start'
											isLight={TABS.PAYMENT_INFORMATION !== activeTab}
											onClick={() => setActiveTab(TABS.PAYMENT_INFORMATION)}>
											{TABS.PAYMENT_INFORMATION}
										</Button>
									</div>
								</div>
							</CardBody>
							<CardFooter>
								<Button
									icon='Upload'
									color='success'
									className='w-100 p-3 text-start'
									isLight={TABS.UPLOAD !== activeTab}
									onClick={() => setActiveTab(TABS.UPLOAD)}>
									{TABS.UPLOAD}
								</Button>
							</CardFooter>
						</Card>
					</div>

					<div className='col-9 col-8'>
						{TABS.WORK_DETAIL === activeTab && (
							<Card stretch tag='form' noValidate onSubmit={updateEmployeeForm.handleSubmit}>
								<CardHeader>
									<CardLabel icon='Contacts' iconColor='info'>
										<CardTitle tag='div' className='h5'>
											Personal Info
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody isScrollable>
									<div className='row'>
										<div className="col-12">
											<div className="row g-3">
												<div className='col-4'>
													<FormGroup id='employeeFirstName' isFloating label='First Name'>
														<Input
															aria-placeholder='FirstName'
															autoComplete='-name'
															onChange={updateEmployeeForm.handleChange}
															onBlur={updateEmployeeForm.handleBlur}
															value={updateEmployeeForm.values.employeeFirstName}
															isValid={updateEmployeeForm.isValid}
															invalidFeedback={updateEmployeeForm.errors.employeeFirstName}
														/>
													</FormGroup>
												</div>
												<div className='col-4'>
													<FormGroup id='employeeMiddleName' isFloating label='Middle	Name'>
														<Input
															aria-placeholder='MiddleName'
															autoComplete='-name'
															onChange={updateEmployeeForm.handleChange}
															onBlur={updateEmployeeForm.handleBlur}
															value={updateEmployeeForm.values.employeeMiddleName}
															isValid={updateEmployeeForm.isValid}
															invalidFeedback={updateEmployeeForm.errors.employeeMiddleName}
														/>
													</FormGroup>
												</div>
												<div className='col-4'>
													<FormGroup id='employeeLastName' isFloating label='Last Name'>
														<Input
															aria-placeholder='LastName'
															autoComplete='-name'
															onChange={updateEmployeeForm.handleChange}
															onBlur={updateEmployeeForm.handleBlur}
															value={updateEmployeeForm.values.employeeLastName}
															isValid={updateEmployeeForm.isValid}
															invalidFeedback={updateEmployeeForm.errors.employeeLastName}
														/>
													</FormGroup>
												</div>
												<div className='col-md-4'>
													<FormGroup id='fatherName' isFloating label='Father
																	Name'>
														<Input
															aria-placeholder='fatherName'
															autoComplete=''
															onChange={updateEmployeeForm.handleChange}
															onBlur={updateEmployeeForm.handleBlur}
															value={updateEmployeeForm.values.fatherName}
															isValid={updateEmployeeForm.isValid}
															invalidFeedback={updateEmployeeForm.errors.fatherName}
														/>
													</FormGroup>
												</div>
												<div className='col-md-4'>
													<FormGroup id='email' isFloating label='Personal Email Address'>
														<Input
															type='email'
															placeholder='Email address'
															autoComplete='emailAddress'
															onChange={updateEmployeeForm.handleChange}
															onBlur={updateEmployeeForm.handleBlur}
															value={updateEmployeeForm.values.email}
															isTouched={updateEmployeeForm.touched.email}
															invalidFeedback={
																updateEmployeeForm.errors.email
															}
														/>
													</FormGroup>
												</div>
												<div className='col-md-4'>
													<FormGroup id='mobile' label='Mobile Number' isFloating>
														<Input
															onChange={updateEmployeeForm.handleChange}
															onBlur={updateEmployeeForm.handleBlur}
															value={updateEmployeeForm.values.mobile}
															isTouched={updateEmployeeForm.touched.mobile}
															invalidFeedback={updateEmployeeForm.errors.mobile}
														/>
													</FormGroup>
												</div>
												<div className='col-md-4'>
													<FormGroup id='dateOfBirth' isFloating label='Date Of Birth'>
														<Input
															aria-placeholder=''
															onChange={(e: any) => getAge(e.target.value)}
															onBlur={updateEmployeeForm.handleBlur}
															value={dateOfBirth}
															isValid={updateEmployeeForm.isValid}
															invalidFeedback={updateEmployeeForm.errors.dateOfBirth}
															type='date'
														/>
													</FormGroup>
												</div>
												<div className='col-md-4'>
													<FormGroup id='age' isFloating label='Age'>
														<Input
															aria-placeholder='age'
															autoComplete=''
															onChange={updateEmployeeForm.handleChange}
															onBlur={updateEmployeeForm.handleBlur}
															value={age}
															isValid={updateEmployeeForm.isValid}
															invalidFeedback={updateEmployeeForm.errors.age}
															disabled
														/>
													</FormGroup>
												</div>

												<div className='col-4'>
													<FormGroup id='genderId' isFloating label='Gender'>
														<SearchableSelect isFloating
															ariaLabel='Gender'
															aria-placeholder=''
															list={allGenderData.map((data: any) => (
																{ value: data.genderId, label: data.gender }
															))}
															onChange={updateEmployeeForm.handleChange}
															onBlur={updateEmployeeForm.handleBlur}
															value={genderId}
															isValid={updateEmployeeForm.isValid}
															invalidFeedback={updateEmployeeForm.errors.genderId}
														/>
													</FormGroup>
												</div>
											</div>
										</div>
									</div>
									<div className='col-12 mt-4'>
										<FormGroup id='workLocationsId' isFloating label='Work Location'>
											<SearchableSelect isFloating
												ariaLabel='workLocationsId'
												aria-placeholder=''
												placeholder='WorkLocation'
												list={allWorkLocationData.map((data: any) => (
													{ value: data.workLocationsId, label: data.workLocationsName }
												))}
												onChange={(e: any) => setWorkLocationsId(e)}
												onBlur={updateEmployeeForm.handleBlur}
												value={workLocationsId}
												isValid={updateEmployeeForm.isValid}
												invalidFeedback={updateEmployeeForm.errors.workLocationsId}
											/>
										</FormGroup>
									</div>


									<div className="row mt-3">
										<div className="col-12">
											<Card>
												<CardBody>
													<Checks
														type='checkbox'
														id='isInterestedofCompany'
														label={
															<>
																<b className="text-primary">Director / person</b><br />
																<span>
																	Employee is a Director/person with substantial interest in the company
																</span>
																&nbsp;
																<Tooltips title=
																	'Substantial interest in a company means that the employee is a beneficial owner of shares and carries at least 20% of the voting power. This detail will help us fill out Form 12BA for this employee.' placement='right'><Icon icon='info' />
																</Tooltips>
															</>
														}
														onChange={updateEmployeeForm.handleChange}
														checked={updateEmployeeForm.values.isInterestedofCompany == 0 ? false : true}
													/>
												</CardBody>
											</Card>
										</div>
										<div className="col-6 mt-2">
											<Card>
												<CardBody>
													<Checks
														type='checkbox'
														id='isPortalAccess'
														label={
															<>
																<b>Enable Portal Access</b><br />
																<span>
																	The employee will be able to view payslips, submit their IT declaration and create reimbursement claims through the employee portal.
																</span>
															</>
														}
														onChange={updateEmployeeForm.handleChange}
														checked={updateEmployeeForm.values.isPortalAccess == 0 ? false : true}
													/>
												</CardBody>
											</Card>
										</div>
										<div className="col-6">
											<Card>
												<CardBody>
													<div><b className='fs-5'>Statutory Components</b><br /><span>
														Enable the necessary benefits and tax applicable for this employee.</span>
													</div>
													<div className="row mt-3">
														<div className="col-6">
															<Checks
																type='checkbox'
																id='isProfessionalTax'
																label='Professional Tax'
																onChange={updateEmployeeForm.handleChange}
																checked={updateEmployeeForm.values.isProfessionalTax == 1 ? true : false}
															/>
														</div>
														<div className="col-6">
															<Checks
																type='checkbox'
																id='isLabourWelfareFund'
																label='Labour Welfare Fund'
																onChange={updateEmployeeForm.handleChange}
																checked={updateEmployeeForm.values.isLabourWelfareFund == 1 ? true : false}
															/>
														</div>
													</div>
												</CardBody>
											</Card>
										</div>
									</div>
								</CardBody>

								<CardFooter>
									<CardFooterRight>
										<Button type='submit' icon='Save' color='info' isOutline>
											Save
										</Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						)}

						{TABS.WORK_INFORMATION === activeTab && (
							<Card stretch tag='form' noValidate onSubmit={updateEmployeeForm.handleSubmit}>
								<CardHeader>
									<CardLabel icon='Assignment' iconColor='info'>
										<CardTitle tag='div' className='h5'>
											Work Info
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row g-4'>

										<div className='col-md-6'>
											<FormGroup id='workEmail' isFloating label='Work Email'>
												<Input
													type='email'
													placeholder='Email address'
													autoComplete='workEmail'
													onChange={updateEmployeeForm.handleChange}
													onBlur={updateEmployeeForm.handleBlur}
													value={updateEmployeeForm.values.workEmail}
													isTouched={updateEmployeeForm.touched.workEmail}
													invalidFeedback={
														updateEmployeeForm.errors.workEmail
													}
												/>
											</FormGroup>
										</div>

										<div className='col-6 '>
											<FormGroup id='dateOfJoining' isFloating label='Date of Joining'>
												<Input
													aria-placeholder=''
													autoComplete=''
													onChange={updateEmployeeForm.handleChange}
													onBlur={updateEmployeeForm.handleBlur}
													value={updateEmployeeForm.values.dateOfJoining}
													isTouched={updateEmployeeForm.touched.dateOfJoining}
													invalidFeedback={updateEmployeeForm.errors.dateOfJoining}
													type='date'
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup id='panNumber' isFloating label=' PAN'>
												<Input
													aria-placeholder='panNumber'
													autoComplete=''
													onChange={updateEmployeeForm.handleChange}
													onBlur={updateEmployeeForm.handleBlur}
													value={updateEmployeeForm.values.panNumber}
													isTouched={updateEmployeeForm.touched.panNumber}
													invalidFeedback={updateEmployeeForm.errors.panNumber
													}
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup id='userRoleId' isFloating label='Role'>
												<SearchableSelect isFloating
													ariaLabel='Role'
													onChange={(e: any) => setUserRoleId(e)}
													value={userRoleId}
													list={rolesData.map((data: any) => (
														{ value: data.userRoleId, label: data.roleName }
													))}
													onBlur={updateEmployeeForm.handleBlur}
													isValid={updateEmployeeForm.isValid}
													invalidFeedback={updateEmployeeForm.errors.workEmail}
													isTouched={updateEmployeeForm.touched.workEmail}
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup id='departmentId' isFloating label='Department'>
												<SearchableSelect isFloating
													placeholder='department'
													ariaLabel='department'
													onChange={(e: any) => setDepartmentId(e)}
													value={departmentId}
													list={departmentData.map((data: any) => (
														{ value: data.departmentId, label: data.departmentName }
													))}
													onBlur={updateEmployeeForm.handleBlur}
													isValid={updateEmployeeForm.isValid}
													invalidFeedback={updateEmployeeForm.errors.departmentId}
													isTouched={updateEmployeeForm.touched.departmentId}
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup id='designationId' isFloating label='Designation'>
												<SearchableSelect isFloating
													placeholder='designation'
													ariaLabel='designation'
													onChange={(e: any) => setDesignationId(e)}
													value={designationId}
													list={designationData.map((data: any) => (
														{ value: data.designationId, label: data.designationName }
													))}
													onBlur={updateEmployeeForm.handleBlur}
													isValid={updateEmployeeForm.isValid}
													invalidFeedback={updateEmployeeForm.errors.designationId}
													isTouched={updateEmployeeForm.touched.designationId}
												/>
											</FormGroup>
										</div>
									</div>
								</CardBody>
								<CardFooter>
									<CardFooterRight>
										<Button type='submit' icon='Save' color='info' isOutline>
											Save
										</Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						)}

						{TABS.ADDRESS_DETAILS === activeTab && (
							<Card stretch tag='form' noValidate onSubmit={updateEmployeeForm.handleSubmit}>
								<CardHeader>
									<CardLabel icon='Home' iconColor='info'>
										<CardTitle tag='div' className='h5'>
											Address Info
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody>
									<div className='row g-4'>
										<div className='col-md-12'>
											<FormGroup id='address1' isFloating label=' Residential Address'>
												<Input
													aria-placeholder='addressLine1'
													autoComplete=''
													onChange={updateEmployeeForm.handleChange}
													onBlur={updateEmployeeForm.handleBlur}
													value={updateEmployeeForm.values.address1}
													isValid={updateEmployeeForm.isValid}
													invalidFeedback={updateEmployeeForm.errors.address1}
													isTouched={updateEmployeeForm.touched.address1}
												/>
											</FormGroup>
										</div>
										<div className='col-md-12'>
											<FormGroup id='address2' isFloating label='AddressLine2'>
												<Input
													aria-placeholder='addressLine2'
													onChange={updateEmployeeForm.handleChange}
													onBlur={updateEmployeeForm.handleBlur}
													value={updateEmployeeForm.values.address2}
												// isValid={updateEmployeeForm.isValid}
												// invalidFeedback={updateEmployeeForm.errors.address2}
												// isTouched={updateEmployeeForm.touched.address2}
												/>
											</FormGroup>
										</div>

										<div className='col-md-6'>
											<FormGroup id='stateId' label='State' isFloating>
												<SearchableSelect isFloating
													placeholder='State'
													ariaLabel='State'
													onChange={selectState}
													value={stateId}
													list={statesData.map((data: any) => (
														{ value: data.stateId, label: data.stateName }
													))}
													isValid={updateEmployeeForm.isValid}
													invalidFeedback={updateEmployeeForm.errors.stateId}
													isTouched={updateEmployeeForm.touched.stateId}
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup id='cityId' label='City' isFloating>
												<SearchableSelect isFloating
													ariaLabel='City'
													placeholder='City'
													onChange={(e: any) => setCityId(e)}
													value={cityId}
													list={cityData.map((data: any) => (
														{ value: data.cityId, label: data.cityName }
													))}
													isValid={updateEmployeeForm.isValid}
													invalidFeedback={updateEmployeeForm.errors.cityId}
													isTouched={updateEmployeeForm.touched.cityId}
												/>
											</FormGroup>
										</div>
										<div className='col-md-6'>
											<FormGroup id='pinCode' label='Pincode' isFloating>
												<Input
													onChange={updateEmployeeForm.handleChange}
													onBlur={updateEmployeeForm.handleBlur}
													value={updateEmployeeForm.values.pinCode}
													isValid={updateEmployeeForm.isValid}
													invalidFeedback={updateEmployeeForm.errors.pinCode}
													isTouched={updateEmployeeForm.touched.pinCode}
												/>
											</FormGroup>
										</div>
									</div>
								</CardBody>
								<CardFooter>
									<CardFooterRight>
										<Button type='submit' icon='Save' color='info' isOutline>
											Save
										</Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						)}

						{TABS.SALARY_DETAILS === activeTab && (
							<Card stretch tag='form' noValidate onSubmit={updateEmployeeForm.handleSubmit}>
								<CardHeader>
									<CardLabel icon='Book' iconColor='info'>
										<CardTitle tag='div' className='h5'>
											Salary Details
										</CardTitle>
									</CardLabel>
									<CardActions>
										<Button
											color='primary'
											isLight
											onClick={() => setAdvanceSalaryOffCanvas(true)}
										>
											Advance Salary
										</Button>
									</CardActions>
								</CardHeader>
								<CardBody isScrollable>
									<div className='row'>
										<div className='col-4'>
											<FormGroup id='salaryTemplateId' isFloating label='Salary Template'>
												<SearchableSelect isFloating
													ariaLabel='salaryTemplateId'
													placeholder='Select Template'
													list={salaryTemplateList.map((data: any) => (
														{ value: data.salaryTemplateId, label: data.templateName }
													))}
													onChange={(e: any) => selectSalaryTemplate(e)}
													onBlur={updateEmployeeForm.handleBlur}
													value={salaryTemplateId}
													isValid={updateEmployeeForm.isValid}
													invalidFeedback={updateEmployeeForm.errors.salaryTemplateId}
													isTouched={updateEmployeeForm.touched.salaryTemplateId}
												/>
											</FormGroup>
										</div>
										<div className='col-4'>
											<FormGroup id='annualCTC' isFloating label='Annual CTC (Per Year)'>
												<Input
													aria-placeholder=''
													placeholder='Enter Annual CTC (Per Year)'
													onChange={(e: any) => multiply(e.target.value, conveyanceAllowance)}
													value={annualCTC}
													isValid={updateEmployeeForm.isValid}
													invalidFeedback={updateEmployeeForm.errors.annualCTC}
													isTouched={updateEmployeeForm.touched.annualCTC}
													onKeyDown={(e: any) => onlyAllowNumber(e)}
												/>
											</FormGroup>
										</div>
									</div>

									{salaryTemplateListById != '' ?
										<div className='col-12 mt-4'>
											<table className='table table-modern table-hover text-nowrap'>
												<thead>
													<tr className='table-success text-decoration-underline mt-4' >
														<th>Salary Components</th>
														<th>Calculation Type</th>
														<th>Monthly Amount</th>
														<th>Annual Amount</th>
													</tr>
												</thead>

												{salaryTemplateListById.details?.map((i: any) => (
													<tbody key={i.salaryTemplateId}>
														<tr>
															<td>
																{i.earningName}
															</td>
															<td>
																{i.labelValue}
															</td>
															<td>
																{basicMonthlyAmount}
															</td>
															<td>
																{basicAnnualAmount}
															</td>
														</tr>

														<tr>
															<td>Fixed Allowance <br />Monthly CTC - Sum of all other components</td>
															<td>
																<div className='mt-2 ms-1'>Fixed amount</div>
															</td>
															<td>
																<div className='mt-2 ms-1'>0.00</div>
															</td>
															<td>
																<div className='mt-2 ms-1'>0.00</div>
															</td>
														</tr>
														<tr>
															<td colSpan={2}>
																<strong>Cost to Company</strong>
															</td>
															<td>
																<strong className='mt-2 ms-1'>₹ {totalMonthlyAmount}</strong>
															</td>
															<td>
																<strong className='mt-2 ms-1'>₹ {totalAnnualAmount}</strong>
															</td>
														</tr>
													</tbody>))}
											</table>
										</div>
										:

										<div className='col-12 mt-4'>
											<table className='table table-modern table-hover text-nowrap'>
												<thead >
													<tr className='table-success text-decoration-underline mt-4' >
														<th>Salary Components</th>
														<th>Calculation Type</th>
														<th>Monthly Amount</th>
														<th>Annual Amount</th>
													</tr>
												</thead>

												<tbody>
													<tr>
														<td>Basic</td>
														<td>
															{'50 % of CTC'}
														</td>
														<td>
															{basicMonthlyAmount}
														</td>
														<td>
															{basicAnnualAmount}
														</td>
													</tr>
													<tr>
														<td>
															House Rent Allowance
														</td>
														<td>
															{'50 % of Basic'}
														</td>
														<td>
															{houseRentMonthlyAmount}
														</td>
														<td>{houseRentAnnualAmount}</td>
													</tr>
													<tr>
														<td>Conveyance Allowance</td>
														<td>Fixed amount
														</td>
														<td width={'20%'}>
															<FormGroup id='conveyanceAllowance'>
																<Input className="input-group" onInput={(e: any) => multiply(annualCTC, e.target.value)} value={conveyanceAllowance} onKeyDown={(e: any) => onlyAllowNumber(e)} />
															</FormGroup>
														</td>
														<td>
															<strong>{conveyanceAllowanceAnnualAmount}</strong>
														</td>
													</tr>
													<tr>
														<td>Fixed Allowance <br />Monthly CTC - Sum of all other components</td>
														<td>
															<div className='mt-2 ms-1'>Fixed amount</div>
														</td>
														<td>
															<div className='mt-2 ms-1'>0.00</div>
														</td>
														<td className='text-start'>
															<div className='mt-2 ms-1'>0.00</div>
														</td>
													</tr>

													<tr>
														<td colSpan={2}><strong>Cost to Company</strong></td>
														<td>
															<strong className='mt-2 '>₹ {totalMonthlyAmount}</strong>
														</td>
														<td><strong className='mt-2 ms-1'>₹ {totalAnnualAmount}
														</strong>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									}
								</CardBody>
								<CardFooter>
									<CardFooterRight>
										<Button type='submit' icon='Save' color='info' isOutline>
											Save
										</Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						)}

						{TABS.PAYMENT_INFORMATION === activeTab && (
							<Card stretch tag='form' noValidate onSubmit={updateEmployeeForm.handleSubmit}>
								<CardHeader>
									<CardLabel icon='Payment' iconColor='info'>
										<CardTitle tag='div' className='h5'>
											Payment Info
										</CardTitle>
									</CardLabel>
								</CardHeader>
								<CardBody isScrollable>
									<div className="row g-4">
										<div className='col-6'>
											<FormGroup id='accountHolderName' label='Account Holder Name' isFloating>
												<Input
													aria-placeholder=''
													placeholder='Enter Account Holder Name'
													onChange={updateEmployeeForm.handleChange}
													onBlur={updateEmployeeForm.handleBlur}
													value={updateEmployeeForm.values.accountHolderName}
													isValid={updateEmployeeForm.isValid}
													invalidFeedback={updateEmployeeForm.errors.accountHolderName}
													type='text'
												/>
											</FormGroup>
										</div>
										<div className='col-6'>
											<FormGroup id='bankName' label='Bank Name' isFloating>
												<Input
													aria-placeholder=''
													placeholder='Enter Bank Name'
													onChange={updateEmployeeForm.handleChange}
													onBlur={updateEmployeeForm.handleBlur}
													value={updateEmployeeForm.values.bankName}
													isValid={updateEmployeeForm.isValid}
													invalidFeedback={updateEmployeeForm.errors.bankName}
												/>
											</FormGroup>
										</div>
										<div className='col-6'>
											<FormGroup id='accountNumber' label='Account Number' isFloating>
												<Input
													aria-placeholder=''
													placeholder='Enter Account Number'
													onChange={updateEmployeeForm.handleChange}
													onBlur={updateEmployeeForm.handleBlur}
													value={updateEmployeeForm.values.accountNumber}
													isValid={updateEmployeeForm.isValid}
													invalidFeedback={updateEmployeeForm.errors.accountNumber}
												/>
											</FormGroup>
										</div>
										<div className='col-6'>
											<FormGroup id='ifsc' label='IFSC' isFloating>
												<Input
													aria-placeholder=''
													placeholder='Enter IFSC Code'
													onChange={updateEmployeeForm.handleChange}
													onBlur={updateEmployeeForm.handleBlur}
													value={updateEmployeeForm.values.ifsc}
													isValid={updateEmployeeForm.isValid}
													invalidFeedback={updateEmployeeForm.errors.ifsc}
												/>
											</FormGroup>
										</div>
										<div className='col-6'>
											<FormGroup id='accountType' label='Account Type' isFloating>
												<SearchableSelect isFloating
													ariaLabel=''
													placeholder='Account Type'
													onChange={(e: any) => setAccountTypeId(e)}
													value={accountTypeId}
													list={accountTypeData.map((data: any) => (
														{ value: data.accountTypeId, label: data.accountType }
													))}
													onBlur={updateEmployeeForm.handleBlur}
												/>
											</FormGroup>
										</div>
									</div>
								</CardBody>

								<CardFooter>
									<CardFooterRight>
										<Button type='submit' icon='Save' color='info' isOutline>
											Save
										</Button>
									</CardFooterRight>
								</CardFooter>
							</Card>
						)}

						{TABS.UPLOAD === activeTab && (
							<Card stretch tag='form' noValidate onSubmit={updateEmployeeForm.handleSubmit}>
								<CardBody isScrollable>

									<div className="row g-4 mt-2">
										<div className="col-12 d-flex justify-content-center">
											{selectedFile ?
												<img style={{ borderRadius: '50%' }}
													src={URL.createObjectURL(selectedFile)}
													height='130' width='130' />
												:
												<img style={{ borderRadius: '50%' }}
													src={employeeData.profilePath != null ? employeeData.profilePath : profilePic(employeeData.genderId)}
													height='130' width='130' onError={(e: any) => pictNotLoading(e, employeeData.genderId)}
												/>}
										</div>
										<div className="col-4 d-flex justify-content-center"></div>
										<div className="col-4 d-flex justify-content-center">
											<Input
												type='file'
												autoComplete='photo'
												ariaLabel='Upload image file'
												onChange={handleFileChange}
											/>
										</div>
										<div className="col-12 d-flex justify-content-center">
											<Button
												color='info'
												icon="Upload"
												isDisable={selectedFile != '' ? false : true}
												onClick={() => onUpload(employeeDetailsId)}
											>
												Upload
											</Button>
										</div>
									</div>
								</CardBody>
							</Card>
						)}

					</div>
				</div>
			</Page>

			{/* Account Details OffCanvas*/}
			{/* <OffCanvas
				setOpen={setAccountDetailsOffCanvas}
				isOpen={accountDetailsOffCanvas}
				titleId='accountDetailsOffCanvas'
				isBodyScroll
				placement='end'
			>
				<OffCanvasHeader setOpen={setAccountDetailsOffCanvas}>
					<OffCanvasTitle id='accountDetailsOffCanvas'>Account Details</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<>
						<div className="row g-3">
							<div className='col-12'>
								<FormGroup id='accountHolderName' label='Account Holder Name'>
									<Input
										aria-placeholder=''
										autoComplete=''
										onChange={updateEmployeeForm.handleChange}
										onBlur={updateEmployeeForm.handleBlur}
										value={updateEmployeeForm.values.accountHolderName}
										isValid={updateEmployeeForm.isValid}
										invalidFeedback={updateEmployeeForm.errors.accountHolderName}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='bankName' label='Bank Name'>
									<Input
										aria-placeholder=''
										autoComplete=''
										onChange={updateEmployeeForm.handleChange}
										onBlur={updateEmployeeForm.handleBlur}
										value={updateEmployeeForm.values.bankName}
										isValid={updateEmployeeForm.isValid}
										invalidFeedback={updateEmployeeForm.errors.bankName}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='accountNumber' label='Account Number'>
									<Input
										aria-placeholder=''
										autoComplete=''
										onChange={updateEmployeeForm.handleChange}
										onBlur={updateEmployeeForm.handleBlur}
										value={updateEmployeeForm.values.accountNumber}
										isValid={updateEmployeeForm.isValid}
										invalidFeedback={updateEmployeeForm.errors.accountNumber}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='ifsc' label='IFSC'>
									<Input
										aria-placeholder=''
										autoComplete=''
										onChange={updateEmployeeForm.handleChange}
										onBlur={updateEmployeeForm.handleBlur}
										value={updateEmployeeForm.values.ifsc}
										isValid={updateEmployeeForm.isValid}
										invalidFeedback={updateEmployeeForm.errors.ifsc}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<Label className='fw-bold'>Account Type</Label>
								<ChecksGroup isInline>
									<Checks
										type='radio'
										name='accountType'
										id='accountType'
										label='Current'
										value='1'
										onChange={updateEmployeeForm.handleChange}
										checked={updateEmployeeForm.values.accountType}
									/>
									<Checks
										type='radio'
										name='accountType'
										id='accountType1'
										label='Savings'
										value='2'
										onChange={updateEmployeeForm.handleChange}
										checked={updateEmployeeForm.values.accountType}
									/>
								</ChecksGroup>
							</div>
						</div>
					</>
				</OffCanvasBody>
			</OffCanvas> */}


			<OffCanvas
				setOpen={setAdvanceSalaryOffCanvas}
				isOpen={advanceSalaryOffCanvas}
				titleId='advanceSalaryOffCanvas'
				isBodyScroll
				placement='end'
			>
				<OffCanvasHeader setOpen={setAdvanceSalaryOffCanvas}>
					<OffCanvasTitle id='advanceSalaryOffCanvas'>Advance Salary Details</OffCanvasTitle>
				</OffCanvasHeader>
				<OffCanvasBody>
					<>
						<div className="row g-4">
							<div className='col-12'>
								<FormGroup id='currentSalary' label='Current Salary'>
									<Input
										placeholder="Enter Current Salary"
										onChange={updateEmployeeForm.handleChange}
										onBlur={updateEmployeeForm.handleBlur}
										value={updateEmployeeForm.values.currentSalary}
										isValid={updateEmployeeForm.isValid}
										invalidFeedback={updateEmployeeForm.errors.currentSalary}
										onKeyDown={(e: any) => onlyAllowNumber(e)}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='requestedAmount' label='Requested Amount'>
									<Input
										placeholder="Enter Requested Amount"
										onChange={updateEmployeeForm.handleChange}
										onBlur={updateEmployeeForm.handleBlur}
										value={updateEmployeeForm.values.requestedAmount}
										isValid={updateEmployeeForm.isValid}
										invalidFeedback={updateEmployeeForm.errors.requestedAmount}
										onKeyDown={(e: any) => onlyAllowNumber(e)}
									/>
								</FormGroup>
							</div>
							<div className='col-12'>
								<FormGroup id='requestedDate' label='Date Of Request'>
									<Input
										onChange={updateEmployeeForm.handleChange}
										onBlur={updateEmployeeForm.handleBlur}
										value={updateEmployeeForm.values.requestedDate}
										isValid={updateEmployeeForm.isValid}
										invalidFeedback={updateEmployeeForm.errors.requestedDate}
										type='date'
									/>
								</FormGroup>
							</div>
						</div>
					</>
				</OffCanvasBody>
			</OffCanvas>

			<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />
		</PageWrapper >
	)
}
export default UpdateEmployee;