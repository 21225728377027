import React, { lazy, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import AuthContext from '../../contexts/authContext';
import Login from '../../pages/presentation/auth/Login';
import contentRoutes from '../../routes/contentRoutes';
import { toasts } from '../../services/toast.service';
import DashboardPage from '../../pages/presentation/dashboard/DashboardPage';
import { getAssignedScreenByUserAccountId } from '../../services/screen.service';

const PAGE_404 = lazy(() => import('../../pages/presentation/auth/Page404'));
const UnauthorizedPage = lazy(() => import('../../pages/presentation/auth/UnauthorizedPage'));

const ContentRoutes = () => {

	const [getMenu, setGetMenu] = useState<any>([]);
	const { userAccountId, userTypeId} = useContext(AuthContext);

	useEffect(() => {
		if (userAccountId != "") {
			getAssignedScreenByUser(userAccountId);
		}
	}, []);

	function getAssignedScreenByUser(userAccountId: number) {
		getAssignedScreenByUserAccountId(userAccountId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.assignedScreenByUserAccountId;
					if (data != undefined) {
						generateMenu(data);
					} else {
						// toasts("Undefined Data", "Error")
					}
				}
				else if (response.data.success === false) {
					// toasts(response.data.message, "Error")
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					// toasts(errorDescription, "Error")
				}
			}, error => {
				// toasts(error, "Error")
			}
		)
	}

	function generateMenu(screenData: any) {

		let menu: any = [];

		for (let i = 0; i < screenData.length; i++) {
			let screenGroup = screenData[i]
			for (let k = 0; k < screenGroup.screens.length; k++) {
				let screen = screenGroup.screens[k]

				for (let l = 0; l < contentRoutes().contentsMenu.length; l++) {

					let page = contentRoutes().contentsMenu[l]

					if (screen.screenPath == page.path) {
						menu.push({
							path: screen.screenPath,
							element: page.element,
						})
					}
				}
			}
		}
		menu = menu

		const screen = [...contentRoutes().commonContents, ...menu]
		setGetMenu(screen)
	}

	const screen = getMenu

	return (
		<Routes>
			{userAccountId != "" ?
				<>

					{screen != "" ?
						<>
							{screen.map((page: any) => (
								// eslint-disable-next-line react/jsx-props-no-spreading
								<Route key={page.path} {...page} />
							))}
						</>
						:
						<>
							{contentRoutes().contentsMenu.map((page: any) => (
								// eslint-disable-next-line react/jsx-props-no-spreading
								<Route key={page.path} {...page} />
							))}
						</>
					}
				</>
				:
				<>
					<Route path='/login' element={<Login />} />
				</>
			}
			{/* <Route path='*' element={<PAGE_404 />} /> */}
			<Route path='*' element={<UnauthorizedPage />} />
			<Route path='/' element={<DashboardPage />} />
		</Routes>
	);
};

export default ContentRoutes;