import { useContext, useEffect, useRef, useState } from 'react';
import useDarkMode from '../../hooks/useDarkMode';
import useSortableData from '../../hooks/useSortableData';
import Button, { ButtonGroup } from '../bootstrap/Button';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import Input from '../bootstrap/forms/Input';
import FormGroup from '../bootstrap/forms/FormGroup';
import { useFormik } from 'formik';
import AlertService from '../../services/AlertService';
import { useNavigate } from 'react-router-dom';
import Icon from '../icon/Icon';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import { useReactToPrint } from 'react-to-print';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../services/export.service';
import AuthContext from '../../contexts/authContext';
import PaginationButtons, { dataPagination } from '../PaginationButtons';
import {
	deleteEmployeeDetails,
	getEmployeeList,
	getEmployeeProfile,
	getEmployeeStatus,
} from '../../services/employee.service';
import { TableLoader, showLoader } from '../../services/loader.services';
import {
	getColumnsForDataTable,
	pictNotLoading,
	profilePic,
	updateColumnsForDataTable,
	updateFilter,
} from '../../services/common.service';
import NoDataMsg from '../../common/components/NoDataMsg';
import { getLicenseKey } from '../../services/application.settings';
import { toasts } from '../../services/toast.service';
import Collapse from '../bootstrap/Collapse';
import SearchableSelect from '../../common/components/SearchableSelect';
import OpenCardComponent from '../../common/components/OpenCardComponents';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../bootstrap/OffCanvas';
import Textarea from '../bootstrap/forms/Textarea';

function EmployeeList() {

	const navigate = useNavigate();

	useEffect(() => {
		getEmployeeStatusList();
	}, []);

	const { userAccountId, userTypeId, organisationDetailsId } = useContext(AuthContext);
	const [employeeStatusId, setEmployeeStatusId] = useState<any>({ value: 1, label: "All" });
	const [employeeDetailsId, setEmployeeDetailsId] = useState<any>([]);
	const [isOpenListCard, setIsOpenListCard] = useState(true);
	const [dataSuccess, setDataSuccess] = useState(false);
	const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
	const [isChecked, setIsChecked] = useState(false);
	const [EmployeeData, setEmployeeData] = useState<any>([]);
	const [employeeStatusData, setEmployeeStatusData] = useState<any>([]);
	const { themeStatus, darkModeStatus } = useDarkMode();
	const componentRef = useRef(null);

	// For Table and offCanvas
	const [currentPage, setCurrentPage] = useState(1);
	const [perPage, setPerPage] = useState(10);
	const { items, requestSort, getClassNamesFor } = useSortableData(EmployeeData);
	const onCurrentPageData = dataPagination(items, currentPage, perPage);

	// For Alert
	const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
	const [isOpen, setIsOpen] = useState(false);

	const [allColumnsData, setAllColumnsData] = useState([]);
	const [columnDataById, setColumnDataById] = useState([]);
	const [columnVisibilityData, setColumnVisibilityData] = useState([]);
	const [deleteEmployeeCanvas, setDeleteEmployeeCanvas] = useState(false)
	const [isDeleteOpen, setIsDeleteOpen] = useState(false)

	const [noDataMsg, setNoDataMsg] = useState('');
	const [isLoader, setIsLoader] = useState(false);
	const [employeeProfileData, setEmployeeProfileData] = useState<any>([]);

	const deleteEmployeeForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			remarks: '',
		},
		validate: (values) => {
			const errors: {
				remarks?: string;
			} = {};
			if (!values.remarks) {
				errors.remarks = 'Required';
			}
			return errors;
		},
		//validateOnChange: false,
		onSubmit: () => { setIsDeleteOpen(!isDeleteOpen) },
	})

	const columnVisibleForm = useFormik({
		enableReinitialize: true,
		initialValues: {
			searchInput: '',
			available: false,
		},
		validate: (values) => { },
		validateOnChange: false,
		onSubmit: () => { },
	});

	// Filter
	const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibleForm);


	function onActivate(i: any) {
		if (i != undefined) {
			let selectedEmployee = i
			setEmployeeDetailsId({ value: selectedEmployee.employeeDetailsId, label: selectedEmployee.employeeFullName })
			getEmployeeProfileList(selectedEmployee.employeeDetailsId)
		}
	}

	const selectEmployeeStatus = (e: any) => {
		let employeeStatusId = e;
		setEmployeeStatusId(employeeStatusId);
	};

	const selectEmployeeList = () => {
		columnVisibleForm.resetForm();
		setDataSuccess(false);
		setIsLoader(true);
		getEmployee(organisationDetailsId, employeeStatusId?.value);
	};

	function getEmployee(organisationDetailsId: any, employeeStatusId: any) {
		getEmployeeList(organisationDetailsId, employeeStatusId, 0,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.employeeList;
					if (data != undefined) {
						getColumnsForDataTableList('getEmployeeList', 'get');
						setEmployeeData(data.filter((item: any) => item.userRoleId != 1));
						setIsLoader(false);
						setDataSuccess(true);
						setIsOpenListCard(false);
						for (let i = 0; i < data.length; i++) {
							data[i].sno = i + 1;
							// data[i].id = data[i].employeeDetailsId.toString();
						}
					} else {
						setIsLoader(false);
						setDataSuccess(true);
						setIsOpenListCard(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success == false) {
					setEmployeeData([]);
					setIsLoader(false);
					setDataSuccess(true);
					setIsOpenListCard(false);
					getColumnsForDataTableList('getEmployeeList', 'get');
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setIsLoader(false);
					setDataSuccess(true);
					setIsOpenListCard(false);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setIsLoader(false);
				setDataSuccess(true);
				setIsOpenListCard(false);
				toasts(error, 'Error');
			},
		);
	}

	function getEmployeeStatusList() {
		getEmployeeStatus(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.employeeStatus;
					if (data != undefined) {
						setEmployeeStatusData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setNoDataMsg(response.data.message);
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function getColumnsForDataTableList(apiName: any, type: any) {
		getColumnsForDataTable(
			userTypeId,
			apiName,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.columnsForDataTable;
					if (data != undefined) {
						let allColumnsData = data;
						setAllColumnsData(allColumnsData);
						let columnVisibilityData = allColumnsData.filter(
							(item: any) => item.isDisplay == 1,
						);
						setColumnVisibilityData(columnVisibilityData);

						if (type == 'get') {
							const allData = allColumnsData.map((item: any) => item.isDisplay);
							const allColumns = allData.filter((item: any) => item == false);
							setIsChecked(allColumns == '' ? true : false);
						} else if (type == 'post') {
							const all = [];
							let nonDefaultValue = allColumnsData.filter(
								(item: any) => !item.isDefault,
							);

							for (let i = 0; i < nonDefaultValue.length; i++) {
								const obj = {
									columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
									isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0,
								};
								all.push(obj);
							}
							updateColumnsSubmit('', '', all);
						}
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {
		if (columnVisibleForm.isValid) {
			setColumnDataById(isDisplay);
			const columnVisibilityPostData = {
				licenseKey: getLicenseKey,
				userAccountId: userAccountId,
				columns: type
					? type
					: [
						{
							columnVisibilityId: columnVisibilityId,
							isDisplay: isDisplay ? 0 : 1,
						},
					],
			};
			updateColumnsForDataTable(
				columnVisibilityPostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						getColumnsForDataTableList('getEmployeeList', 'get');
					} else if (data.success == false) {
						// toasts(data.success, "Error")
					} else {
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						toasts(errorDescription, 'Error');
					}
				},
				(error) => {
					toasts(error, 'Error');
				},
			);
		} else if (columnVisibleForm.isValid == false) {
			toasts('Please fill all the details!', 'Error');
		}
	}

	function getEmployeeProfileList(employeeDetailsId: any) {
		getEmployeeProfile(employeeDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.employeeProfile;
					if (data != undefined) {
						setEmployeeProfileData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	function setDeleteEmployeeDetails() {
		return {
			licenseKey: getLicenseKey,
			employeeDetailsId: employeeDetailsId?.value,
			remarks: deleteEmployeeForm.values.remarks,
			updatedBy: userAccountId
		}
	}

	function deleteEmployeeSubmit() {
		showLoader(true);
		if (deleteEmployeeForm.isValid) {
			let employeePostData = setDeleteEmployeeDetails()
			deleteEmployeeDetails(employeePostData,
				(response) => {
					const data = response.data;
					if (data.success == true) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "success" });
						setIsOpen(true);
						setDataSuccess(false);
						setIsOpenListCard(true);
						closeAndReset()
					}
					else if (data.success == false) {
						showLoader(false);
						setAlertStatus({ message: data.message, type: "error" });
						setIsOpen(true);
					}
					else {
						showLoader(false);
						let errorCode = response.data.error[0].error_code;
						let errorDescription = response.data.error[0].error_description;
						setAlertStatus({ message: errorDescription, type: "error" });
						setIsOpen(true);
					}
				}
				, (error) => {
					setAlertStatus({ message: error, type: "error" });
					setIsOpen(true);
				}
			)
		} else if (deleteEmployeeForm.isValid == false) {
			showLoader(false);
			setAlertStatus({ message: 'Please fill all the details!', type: "error" });
			setIsOpen(true);
		}
	}

	function closeAndReset() {
		deleteEmployeeForm.resetForm()
		setIsDeleteOpen(false)
	}

	// Print
	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});

	return (
		<>
			<PageWrapper title='Employee List'>
				<Page container='fluid'>
					<Collapse isOpen={isOpenListCard}>
						<Card stretch data-tour='list'>
							<CardHeader borderSize={1}>
								<CardLabel icon='List' iconColor='info'>
									<CardTitle tag='div' className='h5'>
										Employee List
									</CardTitle>
								</CardLabel>
							</CardHeader>
							<CardBody>
								<div className='row'>
									<div className='col-3'>
										<FormGroup
											id='employeeStatusId'
											label='Employee Status'
											isFloating>
											<SearchableSelect
												isFloating
												ariaLabel='Employee Status'
												onChange={selectEmployeeStatus}
												value={employeeStatusId}
												list={employeeStatusData.map((data: any) => ({
													value: data.employeeStatusId,
													label: data.statusName,
												}))}
											/>
										</FormGroup>
									</div>

									<div className='col-3 mt-2'>
										<Button
											icon='ArrowDownward'
											color='primary'
											isDisable={employeeStatusId?.value == undefined}
											onClick={selectEmployeeList}>
											View
										</Button>
									</div>
								</div>
							</CardBody>
						</Card>
					</Collapse>

					<OpenCardComponent
						isOpenListCard={isOpenListCard}
						setIsOpenListCard={setIsOpenListCard}
						isLoader={isLoader}
					/>

					{dataSuccess ? (
						<>
							<Card stretch data-tour='list' ref={componentRef} id='pdf'>
								<CardHeader borderSize={1}>
									<CardLabel icon='List' iconColor='info'>
										<CardTitle tag='div' className='h5'>
											Employee List
										</CardTitle>
									</CardLabel>
									<CardActions className='d-print-none'>
										<ButtonGroup color='primary'>
											<Icon icon='Search' size='2x' color='primary' />
											<Input
												id='searchInput'
												type='search'
												placeholder='Search...'
												onChange={columnVisibleForm.handleChange}
												value={columnVisibleForm.values.searchInput}
											/>
										</ButtonGroup>

										<Dropdown
											isOpen={columnVisibilityMenu}
											setIsOpen={setColumnVisibilityMenu}
											isButtonGroup>
											<DropdownToggle>
												<Button icon='FilterAlt' color='primary' isLight>
													Filter
												</Button>
											</DropdownToggle>
											<DropdownMenu
												isAlignmentEnd
												size='lg'
												isCloseAfterLeave={false}>
												<DropdownItem>
													<div className='container py-2'>
														<form className='row g-3'>
															<div className='col-12'>
																<FormGroup>
																	<h6>Select All</h6>
																	<Checks
																		id='available'
																		type='switch'
																		label='Select All Columns'
																		onChange={() =>
																			getColumnsForDataTableList(
																				'getEmployeeList',
																				'post',
																			)
																		}
																		checked={isChecked}
																		ariaLabel='Available status'
																	/>
																</FormGroup>
															</div>
															<div className='col-12'>
																<FormGroup>
																	<h6>Columns</h6>
																	<ChecksGroup>
																		{allColumnsData.map(
																			(i: any) => (
																				<Checks
																					key={
																						i.columnVisibilityId
																					}
																					label={
																						i.columnName
																					}
																					onChange={() =>
																						updateColumnsSubmit(
																							i.columnVisibilityId,
																							i.isDisplay,
																							'',
																						)
																					}
																					checked={
																						i.isDisplay
																					}
																					disabled={
																						i.isDefault
																					}
																				/>
																			),
																		)}
																	</ChecksGroup>
																</FormGroup>
															</div>
														</form>
													</div>
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>
										<Dropdown isButtonGroup>
											<DropdownToggle>
												<Button
													isLight
													icon='CloudDownload'
													color='primary'>
													{' '}
													Export{' '}
												</Button>
											</DropdownToggle>
											<DropdownMenu isAlignmentEnd>
												<DropdownItem>
													<Button
														color='primary'
														isLight
														icon='CloudDownload'
														onClick={() =>
															downloadExcel(
																'EmployeeDetails',
																EmployeeData,
																columnVisibilityData,
															)
														}>
														{' '}
														Excel{' '}
													</Button>
												</DropdownItem>
												<DropdownItem>
													<Button
														color='primary'
														isLight
														icon='PictureAsPdf'
														onClick={() =>
															convertJsonToPdf(
																EmployeeData,
																columnVisibilityData,
																'EmployeeDetails',
															)
														}>
														{' '}
														PDF
													</Button>
												</DropdownItem>
												<DropdownItem>
													<Button
														color='primary'
														isLight
														icon='CloudDownload'
														onClick={() =>
															downloadFile(
																'EmployeeDetails',
																EmployeeData,
																columnVisibilityData,
															)
														}>
														{' '}
														CSV{' '}
													</Button>
												</DropdownItem>
												<DropdownItem>
													<Button
														color='primary'
														isLight
														icon='Print'
														onClick={handlePrint}>
														{' '}
														Print{' '}
													</Button>
												</DropdownItem>
											</DropdownMenu>
										</Dropdown>

										<Button
											icon='Add'
											color='primary'
											isLight
											onClick={() => navigate('../employee/addEmployee')}>
											Add New
										</Button>
									</CardActions>
								</CardHeader>
								<CardBody className='table-responsive' isScrollable>
									<table className='table table-modern table-hover'>
										<thead>
											<tr>
												<th
													scope='col'
													onClick={() => requestSort('sno')}
													className='cursor-pointer text-decoration-underline'>
													S.No{' '}
													<Icon
														size='lg'
														className={getClassNamesFor('sno')}
														icon='FilterList'
													/>
												</th>
												{columnVisibilityData.map((column: any) => (
													<th
														key={column.keyName}
														scope='col'
														onClick={() =>
															requestSort(column.keyName)
														}
														className='cursor-pointer text-decoration-underline'>
														{column.columnName}
														<Icon
															size='lg'
															className={getClassNamesFor(
																column.keyName,
															)}
															icon='FilterList'
														/>
													</th>
												))}
												<th className='d-print-none'>Action</th>
											</tr>
										</thead>
										<tbody>
											{filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.employeeDetailsId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.employeeDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td className='d-print-none' onClick={() => { onActivate(rowData) }}>
												<Dropdown isButtonGroup>
													<DropdownToggle>
														<Button className="text-nowrap" color='dark' isLight icon='MoreHoriz' aria-label='MoreHoriz'>More</Button>
													</DropdownToggle>
													<DropdownMenu isAlignmentEnd className='card-body-scrollable' size='lg'>
														<DropdownItem>
															<Button icon="PersonPin" isLight color="info" onClick={() => navigate(`../employee/employeeProfile/${employeeDetailsId?.value}`)}>Employee Profile</Button>
														</DropdownItem>
														<DropdownItem>
															<Button icon="Update" isLight color="success" onClick={() => navigate(`../employee/updateEmployee/${employeeDetailsId?.value}}`)}>Update Employee Details</Button>
														</DropdownItem>
														{/* <DropdownItem>
															<Button icon="DirectionsBus" isLight color="primary" onClick={updateStudentTransport}>Update Transport
															</Button>
														</DropdownItem>
														<DropdownItem>
															<Button icon="RequestQuote" isLight color="primary" onClick={() => navigate(`../general/termFeesCollection/${studentDetailsId?.value}`)}>Fee Details</Button>
														</DropdownItem>
														<DropdownItem>
															<Button icon="EditLocation" isLight color="primary" onClick={() => navigate(`../academic/studentCertificateTracker/${studentDetailsId?.value}`)}>Certificate Tracker</Button>
														</DropdownItem>
														<DropdownItem>
															<Button icon="UploadFile" isLight color="primary" onClick={assignScholarshipForStudent}>Assign Scholarship</Button>
														</DropdownItem>*/}
														<DropdownItem>
															<Button icon="Delete" isLight color='danger' onClick={() => { setDeleteEmployeeCanvas(true) }}>Delete</Button>
														</DropdownItem>
													</DropdownMenu>
												</Dropdown></td></tr>))}</> :
												<NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
										</tbody>
									</table>
								</CardBody>
								<PaginationButtons
									className='d-print-none'
									data={items}
									label='items'
									setCurrentPage={setCurrentPage}
									currentPage={currentPage}
									perPage={perPage}
									setPerPage={setPerPage}
								/>
							</Card>
						</>
					) : null}
				</Page>

				<OffCanvas
					setOpen={setDeleteEmployeeCanvas}
					isOpen={deleteEmployeeCanvas}
					titleId='deleteEmployeeCanvas'
					isBodyScroll
					placement='end'
					tag="form" noValidate onSubmit={deleteEmployeeForm.handleSubmit}>
					<OffCanvasHeader setOpen={setDeleteEmployeeCanvas} onClick={closeAndReset}>
						<OffCanvasTitle id="deleteEmployeeCanvas">Delete Employee</OffCanvasTitle>
					</OffCanvasHeader>
					<OffCanvasBody>
						<div className="row">
							{employeeProfileData.map((employee: any) => (
								<div className='col-12' key={employee.employeeDetailsId}>
									<Card className={`bg-l${darkModeStatus ? 'o25' : '25'
										}-info bg-l${darkModeStatus ? 'o50' : '10'
										}-info-hover transition-base rounded-2 mb-4`}
										shadow='sm'>
										<CardBody>
											<div className='d-flex align-items-center pb-3'>
												<div className='flex-shrink-0 ms-2'>
													<img style={{ borderRadius: '50%' }} src={employee.profilePath != null ? employee.profilePath : profilePic(employee.genderId)} height={65} width={65} onError={(e: any) => pictNotLoading(e, employee.genderId)} />
												</div>
												<div className='flex-grow-1 ms-3'>
													<div className='fw-bold fs-4 text-dark mb-0'>
														{employee.employeeFullName}
													</div>
													<div className='text-muted'>
														( {employee.employeeId} )
													</div>
												</div>
											</div>
										</CardBody>
									</Card>
								</div>
							))}
							<div className="col-12 mt-3">
								<FormGroup id='remarks' label="Remarks" >
									<Textarea
										placeholder="Enter Remarks"
										onChange={deleteEmployeeForm.handleChange}
										value={deleteEmployeeForm.values.remarks}
										onBlur={deleteEmployeeForm.handleBlur}
										isValid={deleteEmployeeForm.isValid}
										isTouched={deleteEmployeeForm.touched.remarks}
										invalidFeedback={deleteEmployeeForm.errors.remarks}
									/>
								</FormGroup>
							</div>
						</div>
					</OffCanvasBody>
					<div className='row'>
						<div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
							<Collapse isOpen={isDeleteOpen} className="dropdown-menu">
								<div className="row g-3 mt-2">
									<div className="col-12 d-flex justify-content-center">
										<h5><strong>Are you sure want to delete ?</strong></h5>
									</div>
									<div className='col-10'>
										<div className='d-flex gap-2 justify-content-end'>
											<Button color='danger' size={'sm'} onClick={deleteEmployeeSubmit}>
												Yes
											</Button>

											<Button color='info' size={'sm'} onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
												No
											</Button>
										</div>
									</div>
									<div className='col-10'></div>
								</div>
							</Collapse>
						</div>
						<div className='col-12 position-absolute bottom-0 end-0 p-3'>
							<Button
								color='danger'
								icon='Delete'
								className='w-100' type="submit" isDisable={!deleteEmployeeForm.isValid && !!deleteEmployeeForm.submitCount}>
								Delete
							</Button>
						</div>
					</div>
				</OffCanvas>

				<AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
			</PageWrapper >
		</>
	);
}
export default EmployeeList;
