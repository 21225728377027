import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react'
import FormGroup from '../bootstrap/forms/FormGroup';
import Select from '../bootstrap/forms/Select';
import Button, { ButtonGroup } from '../bootstrap/Button';
import Checks from '../bootstrap/forms/Checks';
import classNames from 'classnames';
import Card, { CardBody, CardFooter, CardFooterRight, CardTitle } from '../bootstrap/Card';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Tooltips from '../bootstrap/Tooltips';
import { Calendar as DatePicker } from 'react-date-range';
import { useNavigate, useParams } from 'react-router-dom';
import ListGroup, { ListGroupItem } from '../bootstrap/ListGroup';
import Icon from '../icon/Icon';
import dayjs from 'dayjs';
import AlertService from '../../services/AlertService';
import { showLoader } from '../../services/loader.services';
import { getPayScheduleDetails, updatePaySchedule } from '../../services/setting.services';
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';
import { getLicenseKey } from '../../services/application.settings';
import { toasts } from '../../services/toast.service';

const edit = () => {
    useEffect(() => {
        getPaySchedule()
        getmonthAndYear()
    }, [])

    let { payScheduleId } = useParams()
    const navigate = useNavigate();
    const [date, setDate] = useState(new Date());
    const [payScheduleData, setPayScheduleData] = useState<any>({})
    const [weekDays, setWeekDays] = useState<any>([])

    const [firstPayrollFromData, setFirstPayrollFromData] = useState<any>([])
    const [firstPayrollFrom, setFirstPayrollFrom] = useState<any>([])
    const [dateOfSalary, setDateOfSalary] = useState<any>('')
    const [workingWeek, setWorkingWeek] = useState<any>('')
    const [monthDate, setMonthDate] = useState([])
    const [workingDayLength, setWorkingDayLength] = useState<any>(4)
    const [isDifference, setIsDifference] = useState<any>(true)

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [isNavigate, setIsNavigate] = useState<any>(null);
    const [lastWorkingDayOfEveryMonth, setLastWorkingDayOfEveryMonth] = useState('')
    const [hide, setHide] = useState(true)

    const payScheduleEditForm = useFormik({
        initialValues: {
            payScheduleId: '',
            workWeek: '',
            actualDaysInaMonth: '',
            organisationWorkingDays: '',
            lastWorkingDayOfEveryMonth: '',
            dayOfEveryMonth: '',
            organisationDetailsId: 1,
            firstPayrollFrom: '',
            dateOfSalary: ''
        },
        validate: () => {
            const errors: {
                workWeek?: string;
                actualDaysInaMonth?: string;
                organisationWorkingDays?: string;
                lastWorkingDayOfEveryMonth?: string;
                dayOfEveryMonth?: string;
                firstPayrollFrom?: string;
                dateOfSalary?: string;
            } = {};
            if (!workingWeek) {
                errors.workWeek = 'Required';
            }
            return errors;
        },
        onSubmit: () => {
            updatePayScheduleDetails()
        },
    });

    function selectPayYourEmployeeOn(lastWorkingDayOfEveryMonth: any) {
        setFirstPayrollFrom('')
        setLastWorkingDayOfEveryMonth(lastWorkingDayOfEveryMonth);
        setHide(false)
    }

    function getmonthAndYear() {

        var date = new Date();

        let currentYear = date.getFullYear();
        let currentMonth = date.toLocaleString('default', { month: 'numeric' })
        let lastDateTheMonth = new Date(currentYear, Number(currentMonth), 0)

        let monthDate: any = [];

        for (let i = 0; i < lastDateTheMonth.getDate(); i++) {
            let date = 1 + i;
            monthDate.push({ date: date })
        }
        setMonthDate(monthDate)

        let data: any = []

        for (let i = 0; i < 13; i++) {
            let month = new Date().getMonth() + 1 + i;
            let year = new Date().getFullYear();
            let monthId = i + 1;
            if (month > 12) {
                month = month - 12;
                year = year + 1;
            }
            const nameMonth: any = {
                1: "January",
                2: "February",
                3: "March",
                4: "April",
                5: "May",
                6: "June",
                7: "July",
                8: "August",
                9: "September",
                10: "October",
                11: "November",
                12: "December",
            };
            let monthData = nameMonth[month] + "-" + year
            data.push({ monthId: monthId, noMonth: month, monthName: monthData, year: year })
        }
        setFirstPayrollFromData(data)
    }

    function onSelectedMonth(data: any) {

        setFirstPayrollFrom(data)
        setHide(true)
        let selectedMonth = firstPayrollFromData.filter((item: any) => item.monthName == data)

        let lastDateTheMonth = new Date(selectedMonth[0].year, lastWorkingDayOfEveryMonth == '3' ? selectedMonth[0].noMonth : selectedMonth[0].noMonth,lastWorkingDayOfEveryMonth == '4' ? Number(payScheduleEditForm.values.dayOfEveryMonth) : 0)
        setDateOfSalary(dayjs(lastDateTheMonth).format('YYYY-MM-DD'))
        setDate(lastDateTheMonth)
    }

    function onSelectWeekDay(data: any) {

        const newData: any = [];

        weekDays.forEach((item: any, index: any) => {
            if (item['dayId'] == data.dayId) {
                item['isSelect'] = data.isSelect == 1 ? 0 : 1
            }
            newData.push(item)
        });
        setWeekDays(newData)

        let checkWeekDay = newData.filter((item: any) => item.isSelect == 1)
        setWorkingDayLength(checkWeekDay.length)

        let dayId = checkWeekDay.map((item: any) => item.dayId)
        let weekDayId = dayId.slice(1).map(function (n: any, i: any) { return n - dayId[i]; })
        let isDifference = weekDayId.every((value: any) => value == 1)
        setIsDifference(isDifference)

        let workingWeek: any = ''
        weekDays.forEach((item: any, index: any) => {
            workingWeek += item['isSelect']
        })
        setWorkingWeek(workingWeek)
    }

    function setWorkLocationEditDetails() {
        return ({
            payScheduleId: payScheduleId,
            workWeek: workingWeek,
            actualDaysInaMonth: payScheduleEditForm.values.actualDaysInaMonth == '1' ? '1' : '0',
            organisationWorkingDays: payScheduleEditForm.values.actualDaysInaMonth == '2' ? payScheduleEditForm.values.organisationWorkingDays : '0',
            lastWorkingDayOfEveryMonth: lastWorkingDayOfEveryMonth == '3' ? '1' : '0',
            dayOfEveryMonth: lastWorkingDayOfEveryMonth == '4' ? payScheduleEditForm.values.dayOfEveryMonth : '0',
            firstPayrollFrom: firstPayrollFrom,
            dateOfSalary: dateOfSalary,
            organisationDetailsId: payScheduleEditForm.values.organisationDetailsId,
            licenseKey: getLicenseKey,
            updatedBy: 1,
        })
    }

    function getPaySchedule() {
        getPayScheduleDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.payScheduleDetails;
                    if (data != undefined) {
                        setPayScheduleData(data[0]);
                        setWorkingWeek(data[0].workWeek)
                        setDateOfSalary(data[0].dateOfSalary)
                        setFirstPayrollFrom(data[0].firstPayrollFrom)
                        setDate(new Date(data[0].dateOfSalary))
                        setLastWorkingDayOfEveryMonth(data[0].lastWorkingDayOfEveryMonth == "1" ? '3' : '4')
                        payScheduleEditForm.setValues({
                            payScheduleId: data[0].payScheduleId,
                            workWeek: data[0].workWeek,
                            actualDaysInaMonth: data[0].actualDaysInaMonth == "1" ? '1' : '2',
                            organisationWorkingDays: data[0].organisationWorkingDays,
                            lastWorkingDayOfEveryMonth: data[0].lastWorkingDayOfEveryMonth == "1" ? '3' : '4',
                            dayOfEveryMonth: data[0].dayOfEveryMonth,
                            organisationDetailsId: 1,
                            firstPayrollFrom: data[0].firstPayrollFrom,
                            dateOfSalary: data[0].dateOfSalary
                        })

                        for (let i = 0; i < data.length; i++) {
                            data[i].weekDay = [
                                {
                                    dayId: 1,
                                    weekDay: 'SUN',
                                    isSelect: data[0].workWeek.substring(1, 0),
                                },
                                {
                                    dayId: 2,
                                    weekDay: 'MON',
                                    isSelect: data[0].workWeek.substring(2, 1),
                                },
                                {
                                    dayId: 3,
                                    weekDay: 'TUE',
                                    isSelect: data[0].workWeek.substring(3, 2),
                                },
                                {
                                    dayId: 4,
                                    weekDay: 'WED',
                                    isSelect: data[0].workWeek.substring(4, 3),
                                },
                                {
                                    dayId: 5,
                                    weekDay: 'THU',
                                    isSelect: data[0].workWeek.substring(5, 4),
                                },
                                {
                                    dayId: 6,
                                    weekDay: 'FRI',
                                    isSelect: data[0].workWeek.substring(6, 5),
                                },
                                {
                                    dayId: 7,
                                    weekDay: 'SAT',
                                    isSelect: data[0].workWeek.substring(7, 6),
                                }
                            ]
                        }
                        setWeekDays(data[0].weekDay);

                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                }

            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function updatePayScheduleDetails() {
        showLoader(true);
        if (payScheduleEditForm.isValid) {
            let updatePaySchedulePostDate = setWorkLocationEditDetails()
            updatePaySchedule(updatePaySchedulePostDate,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setIsNavigate(`../Payschedule/pay`)
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                        setIsNavigate(null)
                    }
                }
                , (error) => {
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                    setIsNavigate(null)
                }
            )

        } else if (payScheduleEditForm.isValid == false || !isDifference || workingDayLength < 4) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
            setIsNavigate(null)
        }
    }

    return (
        <>
            <PageWrapper>
                <SubHeader>
                    <SubHeaderLeft>
                        <Button
                            color='info'
                            icon='ArrowBack'
                            isLink
                            onClick={() => navigate('../Payschedule/pay')}
                        >
                            Back to List
                        </Button>
                        <SubheaderSeparator />
                    </SubHeaderLeft>

                </SubHeader>
                <Card>
                    <form noValidate onSubmit={payScheduleEditForm.handleSubmit}>
                        <CardBody className='pb-0'>
                            <Card>
                                <CardBody>
                                    <CardTitle className='h5'>Select your work week <span className='text-danger'> *</span><br /><span className='h6'>The days worked in a calendar week</span></CardTitle>
                                    <ListGroup isHorizontal>
                                        {weekDays.map((data: any) => (
                                            <ListGroupItem onClick={() => onSelectWeekDay(data)} key={data.dayId}
                                                className={classNames(`mt-2 ms-1 cursor-pointer border border-primary ${data.isSelect == 1 ? 'bg-primary text-white' : ''}`)}
                                            >
                                                {data.weekDay}
                                            </ListGroupItem>
                                        ))}
                                    </ListGroup>
                                    {workingDayLength < 4 ? <div className="mt-2"><small className='text-danger'>A minimum of 4 working days has to be present in a work week.</small></div> : !isDifference ? <div className="mt-2"><small className='text-danger'>Please select continuous working days.</small></div> : null}
                                </CardBody>
                            </Card>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <Card>
                                        <CardBody>
                                            <CardTitle>Calculate monthly salary based on<span className='text-danger'> * </span><Tooltips title="Your employees' monthly salaries will be calculated based on the actual days in a month or the number of working days you consider in a month"><Icon icon='info' /></Tooltips></CardTitle>
                                            <div className='col-lg-12 mt-3'>
                                                <Checks
                                                    type='radio'
                                                    name='actualDaysInaMonth'
                                                    id='actualDaysInaMonth1'
                                                    label='Actual days in a month'
                                                    value='1'
                                                    onChange={payScheduleEditForm.handleChange}
                                                    checked={payScheduleEditForm.values.actualDaysInaMonth}
                                                />
                                            </div>
                                            <div className='col-lg-12 mt-2'>
                                                <ButtonGroup>
                                                    <div className='mt-2'>
                                                        <Checks
                                                            type='radio'
                                                            name='actualDaysInaMonth'
                                                            id='actualDaysInaMonth2'
                                                            label='Organisation working days - &nbsp;'
                                                            value='2'
                                                            onChange={payScheduleEditForm.handleChange}
                                                            checked={payScheduleEditForm.values.actualDaysInaMonth}
                                                        />
                                                    </div>
                                                    <FormGroup id='organisationWorkingDays'>
                                                        <Select
                                                            ariaLabel=''
                                                            placeholder='Select'
                                                            onChange={payScheduleEditForm.handleChange}
                                                            onBlur={payScheduleEditForm.handleBlur}
                                                            value={payScheduleEditForm.values.organisationWorkingDays}
                                                            isValid={payScheduleEditForm.isValid}
                                                            isTouched={payScheduleEditForm.touched.organisationWorkingDays}
                                                            disabled={payScheduleEditForm.values.actualDaysInaMonth == '2' ? false : true}
                                                            list={monthDate.slice(19, 31).map((data: any) => (
                                                                { value: data.date, text: data.date }
                                                            ))}
                                                        />
                                                    </FormGroup>
                                                    <h6 className='mt-2'>&nbsp;&nbsp;days per month</h6>
                                                </ButtonGroup >
                                            </div >
                                        </CardBody>
                                    </Card>
                                </div>
                                <div className='col-md-6'>
                                    <Card>
                                        <CardBody>
                                            <CardTitle>Pay your employees on<span className='text-danger'> *</span></CardTitle>

                                            <div className='col-lg-12 mt-3'>
                                                <Checks
                                                    type='radio'
                                                    name='lastWorkingDayOfEveryMonth'
                                                    id='lastWorkingDayOfEveryMonth1'
                                                    label='the last working day of every month'
                                                    value='3'
                                                    onChange={() => selectPayYourEmployeeOn('3')}
                                                    checked={lastWorkingDayOfEveryMonth}
                                                />
                                            </div>                                            
                                            <div className='col-lg-12 mt-2'>
                                                <ButtonGroup>
                                                    <div className='mt-2'>
                                                        <Checks
                                                            type='radio'
                                                            name='lastWorkingDayOfEveryMonth'
                                                            id='lastWorkingDayOfEveryMonth2'
                                                            label='day &nbsp;&nbsp;'
                                                            value='4'
                                                            onChange={() => selectPayYourEmployeeOn('4')}
                                                            checked={lastWorkingDayOfEveryMonth}
                                                        />
                                                    </div>
                                                    <FormGroup id='dayOfEveryMonth'>
                                                        <Select
                                                            ariaLabel=""
                                                            placeholder='Select'
                                                            onChange={payScheduleEditForm.handleChange}
                                                            onBlur={payScheduleEditForm.handleBlur}
                                                            value={payScheduleEditForm.values.dayOfEveryMonth}
                                                            isValid={payScheduleEditForm.isValid}
                                                            isTouched={payScheduleEditForm.touched.dayOfEveryMonth}
                                                            disabled={lastWorkingDayOfEveryMonth == '4' ? false : true}
                                                            list={monthDate.map((data: any) => (
                                                                { value: data.date, text: data.date }
                                                            ))}
                                                        />
                                                    </FormGroup>
                                                    <h6 className='mt-2'>&nbsp;&nbsp;of every month</h6>
                                                </ButtonGroup>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                                <p>&nbsp;&nbsp;&nbsp;
                                    <b>Note</b> : When payday falls on a non-working day or a holiday, employees will get paid on the previous working day.
                                </p>
                            </div>
                            <Card>
                                <CardBody>
                                    <div className="row g-4 mt-1">
                                        <div className='col-6'>
                                            <FormGroup id='position' label='Start your first payroll from'>
                                                <Select
                                                    ariaLabel='City'
                                                    placeholder='Select'
                                                    onChange={(e: any) => onSelectedMonth(e.target.value)}
                                                    value={firstPayrollFrom}
                                                    list={firstPayrollFromData.map((data: any) => (
                                                        { value: data.monthName, text: data.monthName }
                                                    ))}
                                                />
                                            </FormGroup>
                                            {hide ?
                                                <FormGroup id='position' label={`Salary for the month of ${firstPayrollFrom} will be paid on `} className='mt-4'>
                                                    <Select
                                                        ariaLabel=""
                                                        placeholder='Select a pay date'
                                                        value={dateOfSalary}
                                                        list={[
                                                            { value: dateOfSalary, text: dateOfSalary }
                                                        ]}
                                                    />
                                                </FormGroup> : null
                                            }
                                        </div>
                                        {hide ?
                                            <div className=' mt-n4 col-4'>
                                                <DatePicker
                                                    onChange={(item: any) => setDate(item)}
                                                    date={date}
                                                    minDate={date}
                                                    maxDate={date}
                                                    color={process.env.REACT_APP_PRIMARY_COLOR}
                                                />
                                            </div> : null
                                        }
                                    </div>
                                </CardBody>
                            </Card>
                        </CardBody>
                        <CardFooter>
                            <CardFooterRight>
                                <Button
                                    type='submit'
                                    icon='Save'
                                    color='info'
                                >Save
                                </Button>
                            </CardFooterRight>
                        </CardFooter>
                    </form>
                </Card>
            </PageWrapper>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} isNavigate={isNavigate} />

        </>
    )
};

export default edit
