import React, { useContext, useEffect, useRef, useState } from 'react';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Collapse from '../bootstrap/Collapse';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../bootstrap/Card';
import FormGroup from '../bootstrap/forms/FormGroup';
import Input from '../bootstrap/forms/Input';
import OpenCardComponent from '../../common/components/OpenCardComponents';
import Button, { ButtonGroup } from '../bootstrap/Button';
import useSortableData from '../../hooks/useSortableData';
import Icon from '../icon/Icon';
import { toasts } from '../../services/toast.service';
import { dataPagination } from '../PaginationButtons';
import NoDataMsg from '../../common/components/NoDataMsg';
import { useFormik } from 'formik';
import { showLoader } from '../../services/loader.services';
import { getLicenseKey } from '../../services/application.settings';
import AuthContext from '../../contexts/authContext';
import AlertService from '../../services/AlertService';
import { convertDateToEpoch, getColumnsForDataTable, getCurrentDateAndTime } from '../../services/common.service';
import SubHeader, { SubHeaderLeft, SubHeaderRight, SubheaderSeparator } from '../../layout/SubHeader/SubHeader';
import { addStaffAllowancePaidDetails, getStaffAllowancePaidDetails } from '../../services/salary.service';
import { downloadExcel, convertJsonToPdf, downloadFile } from '../../services/export.service';
import Dropdown, { DropdownToggle, DropdownMenu, DropdownItem } from '../bootstrap/Dropdown';
import Checks, { ChecksGroup } from '../bootstrap/forms/Checks';
import { useReactToPrint } from 'react-to-print';

const AddStaffAllowance = () => {

    useEffect(() => { }, []);

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [workingDayData, setWorkingDayData] = useState<any>([]);
    const [staffAllowanceArray, setStaffAllowanceArray] = useState<any>([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10000);
    const { items, requestSort, getClassNamesFor } = useSortableData(workingDayData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [isLoader, setIsLoader] = useState(false);
    const [dataSuccess, setDataSuccess] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('');
    const [startDate, setStartDate] = useState<any>('');
    const [endDate, setEndDate] = useState<any>('');
    const [dateOfPayment, setDateOfPayment] = useState<any>('');

    const [week, setWeek] = useState<any>(0);
    const [year, setYear] = useState<any>(0);

    const [alertStatus, setAlertStatus] = useState<any>({ message: '', type: '' });
    const [isOpen, setIsOpen] = useState(false);

    const [filteredData1, setFilteredData1] = useState<any>([])

    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [isChecked, setIsChecked] = useState(false);
    const [columnDataById, setColumnDataById] = useState([]);

    const workingDayForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            workingDayDate: '',
        },
        validate: (values) => {
            const errors: {
                workingDayDate?: string;
            } = {};

            if (!values.workingDayDate) {
                errors.workingDayDate = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        // validateOnChange: false,
        onSubmit: () => { },
    });

    const filteredData: any = onCurrentPageData.filter(
        (i) =>
            (i.empNumber !== null &&
                i.empNumber
                    .toString()
                    .toLowerCase()
                    .includes(columnVisibilityForm.values.searchInput.toLowerCase())) ||
            (i.staffName !== null &&
                i.staffName
                    .toString()
                    .toLowerCase()
                    .includes(columnVisibilityForm.values.searchInput.toLowerCase())) || (i.userType !== null &&
                        i.userType
                            .toString()
                            .toLowerCase()
                            .includes(columnVisibilityForm.values.searchInput.toLowerCase())),
    );

    function viewWorkingDay() {
        setWorkingDayData([]);
        setDataSuccess(false);
        setIsLoader(true);
        let workingDayDate = workingDayForm.values.workingDayDate;
        const splitDate = workingDayDate.split('-W');
        const year = splitDate[0];
        const week = splitDate[1];
        setYear(year);
        setWeek(week);
        getStaffAllowancePaidDetailsList(year, week);
        getWeekDateRange(week, year)

    }

    function getStaffAllowancePaidDetailsList(year: any, week: any) {
        setIsLoader(true);
        getStaffAllowancePaidDetails(
            year,
            week,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.staffAllowancePaidDetails;
                    let dateOfPayment = response.data.data.staffAllowancePaidDetails[0].dateOfPayment;
                    if (data != undefined) {
                        getColumnsForTable('getStaffAllowancePaidDetails', 'get');
                        setWorkingDayData(data);
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setDataSuccess(true);
                        setDateOfPayment(dateOfPayment)

                        let currentDateMilliSeconds = convertDateToEpoch(getCurrentDateAndTime('date'));
                        let dateInMilliSeconds = dateOfPayment == null ? currentDateMilliSeconds : convertDateToEpoch(dateOfPayment);

                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                            if (dateInMilliSeconds == currentDateMilliSeconds) {
                                data[i].isEditable = false;
                            } else {
                                data[i].isEditable = true;
                            }

                            if (staffAllowanceArray.length == 0) {
                                const obj = {
                                    staffDetailsId: data[i].staffDetailsId,
                                    staffAllowanceDetailsId: data[i].staffAllowanceDetailsId,
                                    totalDays: data[i].totalDays != "" ? data[i].totalDays : 0,
                                    totalFare: data[i].totalFare != "" ? Number(data[i].totalFare) : 0,
                                    totalExtraTrip: data[i].totalExtraTrip != "" ? data[i].totalExtraTrip : 0,
                                    totalFinalAmount: data[i].totalFinalAmount != "" ? data[i].totalFinalAmount : 0,
                                };
                                staffAllowanceArray.push(obj);
                            } else {
                                let selectedItem: any;
                                staffAllowanceArray.forEach((item: any, index: any) => {
                                    if (item['staffDetailsId'] == data[i].staffDetailsId) {
                                        selectedItem = item;
                                    }
                                });
                                if (selectedItem != undefined) {
                                    selectedItem['totalExtraTrip'] = data[i].totalExtraTrip != "" ? Number(data[i].totalExtraTrip) : 0;
                                    selectedItem['totalDays'] = data[i].totalDays != "" ? Number(data[i].totalDays) : 0;
                                    selectedItem['totalFare'] = data[i].totalFare != "" ? Number(data[i].totalFare) : 0;
                                    selectedItem['totalFinalAmount'] = data[i].totalFinalAmount != "" ? data[i].totalFinalAmount : 0;
                                } else {
                                    var obj;
                                    obj = {
                                        staffDetailsId: data[i].staffDetailsId,
                                        staffAllowanceDetailsId: data[i].staffAllowanceDetailsId,
                                        totalDays: data[i].totalDays != "" ? data[i].totalDays : 0,
                                        totalFare: data[i].totalFare != "" ? Number(data[i].totalFare) : 0,
                                        totalExtraTrip: data[i].totalExtraTrip != "" ? data[i].totalExtraTrip : 0,
                                        totalFinalAmount: data[i].totalFinalAmount != "" ? data[i].totalFinalAmount : 0,
                                    };
                                    staffAllowanceArray.push(obj);
                                }
                            }
                        }
                    } else {
                        setIsLoader(false);
                        setDataSuccess(false);
                        setIsOpenListCard(false);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    getColumnsForTable('getStaffAllowancePaidDetails', 'get');
                    setIsLoader(false);
                    setWorkingDayData([]);
                    setDataSuccess(true);
                    setIsOpenListCard(false);
                    setNoDataMsg(response.data.message);
                }
            },
            (error) => {
                setIsLoader(false);
                toasts(error, 'Error');
            },
        );
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            //   updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    const updateTotalWorkingDay = (cell: number, totalDays: any, totalFare: any, totalExtraTrip: any, staffAllowanceDetailsId: any) => {
        const newData: any = [];

        workingDayData.forEach((item: any, index: any) => {
            if (item['staffDetailsId'] == cell) {
                item['totalDays'] = totalDays;
                item['totalFare'] = Number(totalFare);
                item['totalExtraTrip'] = totalExtraTrip;
                item['totalFinalAmount'] = (Number(totalDays) * Number(totalFare)) + Number(totalExtraTrip);
            }
            newData.push(item);
        });
        setWorkingDayData(newData);

        if (staffAllowanceArray.length == 0) {
            const obj = {
                staffDetailsId: cell,
                staffAllowanceDetailsId: staffAllowanceDetailsId,
                totalDays: totalDays != "" ? totalDays : 0,
                totalFare: totalFare != "" ? Number(totalFare) : 0,
                totalExtraTrip: totalExtraTrip != "" ? totalExtraTrip : 0,
                totalFinalAmount: (Number(totalDays) * Number(totalFare)) + Number(totalExtraTrip),
            };
            staffAllowanceArray.push(obj);
        } else {
            let selectedItem: any;
            staffAllowanceArray.forEach((item: any, index: any) => {
                if (item['staffDetailsId'] == cell) {
                    selectedItem = item;
                }
            });
            if (selectedItem != undefined) {
                selectedItem['totalExtraTrip'] = totalExtraTrip != "" ? Number(totalExtraTrip) : 0;
                selectedItem['totalDays'] = totalDays != "" ? Number(totalDays) : 0;
                selectedItem['totalFare'] = totalFare != "" ? Number(totalFare) : 0;
                selectedItem['totalFinalAmount'] = (Number(totalDays) * Number(totalFare)) + Number(totalExtraTrip);
            } else {
                var obj;
                obj = {
                    staffDetailsId: cell,
                    staffAllowanceDetailsId: staffAllowanceDetailsId,
                    totalDays: totalDays != "" ? totalDays : 0,
                    totalFare: totalFare != "" ? Number(totalFare) : 0,
                    totalExtraTrip: totalExtraTrip != "" ? totalExtraTrip : 0,
                    totalFinalAmount: (Number(totalDays) * Number(totalFare)) + Number(totalExtraTrip),
                };
                staffAllowanceArray.push(obj);
            }
        }
    };

    const updateTotalExtraTrip = (cell: number, totalExtraTrip: any, totalDays: any, totalFare: any, staffAllowanceDetailsId: any, totalFinalAmount: any) => {
        const newData: any = [];

        workingDayData.forEach((item: any, index: any) => {
            if (item['staffDetailsId'] == cell) {
                // item['totalDays'] = totalDays;
                // item['totalFare'] = Number(totalDays) * Number(totalFare);
                item['totalExtraTrip'] = totalExtraTrip;
                item['totalFinalAmount'] = (Number(totalDays) * Number(totalFare)) + Number(totalExtraTrip);
            }
            newData.push(item);
        });
        setWorkingDayData(newData);

        if (staffAllowanceArray.length == 0) {
            const obj = {
                staffDetailsId: cell,
                staffAllowanceDetailsId: staffAllowanceDetailsId,
                totalDays: totalDays != "" ? totalDays : 0,
                totalFare: totalFare != "" ? Number(totalFare) : 0,
                totalExtraTrip: totalExtraTrip != "" ? totalExtraTrip : 0,
                totalFinalAmount: (Number(totalDays) * Number(totalFare)) + Number(totalExtraTrip),
            };
            staffAllowanceArray.push(obj);
        } else {
            let selectedItem: any;
            staffAllowanceArray.forEach((item: any, index: any) => {
                if (item['staffDetailsId'] == cell) {
                    selectedItem = item;
                }
            });
            if (selectedItem != undefined) {
                selectedItem['totalExtraTrip'] = totalExtraTrip != "" ? Number(totalExtraTrip) : 0;
                selectedItem['totalDays'] = totalDays != "" ? Number(totalDays) : 0;
                selectedItem['totalFare'] = totalFare != "" ? Number(totalFare) : 0;
                selectedItem['totalFinalAmount'] = (Number(totalDays) * Number(totalFare)) + Number(totalExtraTrip);
            } else {
                var obj;
                obj = {
                    staffDetailsId: cell,
                    staffAllowanceDetailsId: staffAllowanceDetailsId,
                    totalDays: totalDays != "" ? totalDays : 0,
                    totalFare: totalFare != "" ? Number(totalFare) : 0,
                    totalExtraTrip: totalExtraTrip != "" ? totalExtraTrip : 0,
                    totalFinalAmount: (Number(totalDays) * Number(totalFare)) + Number(totalExtraTrip),
                };
                staffAllowanceArray.push(obj);
            }
        }
    };


    function setAddStaffAllowancePaidDetailsPostData() {
        return {
            staffAllowanceArray: staffAllowanceArray,
            licenseKey: getLicenseKey,
            year: year,
            week: week,
            userAccountId: userAccountId,
        };
    }

    function updateWorkingDaySubmit() {
        showLoader(true);
        if (staffAllowanceArray.length > 0) {
            let addStaffAllowancePaidDetailsPostData = setAddStaffAllowancePaidDetailsPostData();
            addStaffAllowancePaidDetails(
                addStaffAllowancePaidDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'success' });
                        setIsOpen(true);
                        closeAndReset();
                    } else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: 'warning' });
                        setIsOpen(true);
                    } else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: 'error' });
                        setIsOpen(true);
                    }
                },
                (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: 'error' });
                    setIsOpen(true);
                },
            );
        } else if (staffAllowanceArray.length == 0) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: 'warning' });
            setIsOpen(true);
        }
    }

    function closeAndReset() {
        setIsOpenListCard(true);
        setDataSuccess(false);
        setStaffAllowanceArray([]);
        setWorkingDayData([]);
        workingDayForm.resetForm();
    }

    function getWeekDateRange(weekNumber: any, year: any) {
        // Get the first day of the year
        const firstDayOfYear = new Date(year, 0, 1);

        // Calculate the number of days to add
        const days = (weekNumber - 1) * 7;

        // Set the start date to the beginning of the specified week
        const startDate = new Date(firstDayOfYear);
        startDate.setDate(firstDayOfYear.getDate() + days);

        // Set the end date to 6 days after the start date
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        setStartDate(startDate)
        setEndDate(endDate)

        // return { startDate, endDate };
    }

    function formatDate(date: any) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
    }

    const componentRef = useRef(null);

    // Export Configuration
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });


    return (
        <PageWrapper title='Add Staff Allowance'>
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card
                        stretch
                        data-tour='list'
                        tag='form'
                        noValidate
                        onSubmit={workingDayForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Add Staff Allowance
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row g-4'>
                                <div className='col-3'>
                                    <FormGroup id='workingDayDate' label='Week' isFloating>
                                        <Input
                                            onChange={workingDayForm.handleChange}
                                            value={workingDayForm.values.workingDayDate}
                                            onBlur={workingDayForm.handleBlur}
                                            isValid={workingDayForm.isValid}
                                            isTouched={workingDayForm.touched.workingDayDate}
                                            invalidFeedback={workingDayForm.errors.workingDayDate}
                                            type='week'
                                        />
                                    </FormGroup>
                                </div>

                                <div className='col-3'>
                                    <Button
                                        className='mt-2'
                                        icon='ArrowDownward'
                                        color='primary'
                                        onClick={viewWorkingDay}
                                        isDisable={
                                            workingDayForm.values.workingDayDate != ''
                                                ? false
                                                : true
                                        }>
                                        View
                                    </Button>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>

                <OpenCardComponent
                    isOpenListCard={isOpenListCard}
                    setIsOpenListCard={setIsOpenListCard}
                    isLoader={isLoader}
                />

                {dataSuccess ? (
                    <>
                        <Card stretch data-tour='list' ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <div className='d-flex col-md-10 justify-content-start'>
                                    <CardLabel icon='List' iconColor='info'>
                                        <CardTitle tag='div' className='h5'>
                                            Staff Allowance List - Week <span className='text-primary'>{week}</span> From <span className='text-primary'> {formatDate(startDate)}</span> To <span className='text-primary'> {formatDate(endDate)}</span>
                                        </CardTitle>
                                    </CardLabel>
                                </div>
                                {convertDateToEpoch(getCurrentDateAndTime('date')) != convertDateToEpoch(dateOfPayment == null ? getCurrentDateAndTime('date') : dateOfPayment) ?
                                    <CardActions className='d-print-none'>
                                        <Dropdown isButtonGroup>
                                            <DropdownToggle>
                                                <Button color='primary' isLight icon='CloudDownload'>
                                                    Export
                                                </Button>
                                            </DropdownToggle>
                                            <DropdownMenu isAlignmentEnd>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel('StaffAllowanceList', workingDayData, columnVisibilityData)}>
                                                        Excel
                                                    </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(workingDayData, columnVisibilityData, 'StaffAllowanceList')}>
                                                        PDF
                                                    </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile('StaffAllowanceList', workingDayData, columnVisibilityData)}>
                                                        CSV
                                                    </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='Print' onClick={() => handlePrint()}>
                                                        Print
                                                    </Button>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </CardActions> : null}
                                {/* <div className='d-flex col-md-7 justify-content-end'>
                                    <div
                                        className='d-flex col-8 justify-content-end'
                                        data-tour='search'>
                                        <ButtonGroup
                                            className='col-lg-8 d-print-none'
                                            color='primary'>
                                            <Icon
                                                className='mt-1'
                                                icon='Search'
                                                size='2x'
                                                color='primary'
                                            />
                                            <Input
                                                id='searchInput'
                                                type='search'
                                                placeholder='Search...'
                                                onChange={columnVisibilityForm.handleChange}
                                                value={columnVisibilityForm.values.searchInput}
                                            />
                                        </ButtonGroup>
                                    </div>
                                </div> */}
                            </CardHeader>
                            <CardBody className='table-responsive'>
                                <table className='table table-modern table-hover mt-3'>
                                    <thead>
                                        <tr className='table-primary'>
                                            <td style={{ width: '5px' }} className='text-decoration-underline'>
                                                <strong>S.No</strong>
                                            </td>

                                            <td style={{ width: '75px' }} className='text-decoration-underline'>
                                                <strong>Staff Name</strong>
                                            </td>
                                            <td style={{ width: '5px' }} className='text-decoration-underline'>
                                                <strong>Total Days</strong>
                                            </td>
                                            <td style={{ width: '5px' }} className='text-decoration-underline'>
                                                <strong>Fare Amount</strong>
                                            </td>
                                            <td style={{ width: '5px' }} className='text-decoration-underline'>
                                                <strong>Extra Trip Amount</strong>
                                            </td>
                                            <td style={{ width: '5px' }} className='text-decoration-underline'>
                                                <strong>Total</strong>
                                            </td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != '' ? (
                                            <>
                                                {filteredData.map((staff: any, index: any) => (
                                                    <tr key={staff.staffDetailsId}>
                                                        <td>{staff.sno}</td>
                                                        <td>{staff.staffName} <br /> <span className='text-center text-info'>{staff.empNumber}</span> - <span className='text-center text-info'>{staff.designationName}</span> </td>
                                                        <td scope='col'>
                                                            <FormGroup id='totalDays'>
                                                                <Input
                                                                    className='w-60'
                                                                    placeholder=''
                                                                    value={staff.totalDays}
                                                                    disabled={staff.isEditable}
                                                                    onInput={(e: any) =>
                                                                        updateTotalWorkingDay(
                                                                            staff.staffDetailsId,
                                                                            e.target.value, staff.totalFare, staff.totalExtraTrip, staff.staffAllowanceDetailsId
                                                                        )
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                        <td scope='col'>
                                                            <FormGroup id='totalFare'>
                                                                <Input disabled
                                                                    className='w-60'
                                                                    placeholder=''
                                                                    value={staff.totalFare}
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                        <td scope='col'>
                                                            <FormGroup id='totalExtraTrip'>
                                                                <Input className='w-60'
                                                                    placeholder=''
                                                                    value={staff.totalExtraTrip}
                                                                    disabled={staff.isEditable}
                                                                    onInput={(e: any) =>
                                                                        updateTotalExtraTrip(
                                                                            staff.staffDetailsId,
                                                                            e.target.value, staff.totalDays, staff.totalFare, staff.staffAllowanceDetailsId, staff.totalFinalAmount
                                                                        )
                                                                    }
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                        <td scope='col'>
                                                            <FormGroup id='totalFinalAmount'>
                                                                <Input disabled
                                                                    className='w-60'
                                                                    placeholder=''
                                                                    value={staff.totalFinalAmount}
                                                                />
                                                            </FormGroup>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </>
                                        ) : (
                                            <NoDataMsg columnsCount={4} msg={noDataMsg} />
                                        )}
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                        <SubHeader>
                            <SubHeaderLeft><></></SubHeaderLeft>
                            <SubHeaderRight>
                                <SubheaderSeparator />
                                <Button color='primary' type='submit' icon='Save' isLight
                                    onClick={updateWorkingDaySubmit}>
                                    Submit
                                </Button>
                            </SubHeaderRight>
                        </SubHeader>
                    </>
                ) : null}
            </Page>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper>
    );
};

export default AddStaffAllowance;
