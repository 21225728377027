import React, { FC, useState } from 'react';
import Collapse from '../../components/bootstrap/Collapse';
import Button from '../../components/bootstrap/Button';

interface IDeleteProps {
    isDeleteOpen: boolean;
    setIsDeleteOpen(...args: unknown[]): unknown;
    deleteFunction: any;
}

const DeleteComponents: FC<IDeleteProps> = ({ isDeleteOpen, setIsDeleteOpen, deleteFunction }) => {

    // const [isDeleteOpen, setIsDeleteOpen] = useState(false);

    return (
        <>
            <Collapse isOpen={isDeleteOpen} className="dropdown-menu">
                <div className="row g-4 mt-2">
                    <div className="col-12 d-flex justify-content-center">
                        <h5><strong>Are you sure want to delete ?</strong></h5>
                    </div>
                    <div className='col-10'>
                        <div className='d-flex gap-2 justify-content-end'>
                            <Button color='danger' size={'sm'} onClick={deleteFunction}>
                                Yes
                            </Button>
                            <Button color='info' size={'sm'} onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                No
                            </Button>
                        </div>
                    </div>
                    <div className='col-10'></div>
                </div>
            </Collapse>
        </>
    );
}

export default DeleteComponents